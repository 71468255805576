import { Checkbox, Col, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FadeIn from 'src/components/styled/FadeIn'
import { VAT_PERCENTAGE, VAT_RATE } from 'src/config'
import { setOrderedProduct } from 'src/features/OrdersSlice'
import {
  fetchReceiptingOrders,
  fetchReceiptingsForOrderedProducts,
} from 'src/features/ReceiptingSlice'
import styled from 'styled-components/macro'
import _ from 'underscore'

const OrderedProducts = props => {
  const { venueId } = props
  const dispatch = useDispatch()
  const [checkBoxName, setCheckBoxName] = useState('All')

  const filteredOrders = useSelector(state => state.orders.filtered)
  const orders = filteredOrders.filter(
    order =>
      order.supplierConfirmed === true || order.supplierConfirmed === undefined,
  )
  const filter = useSelector(state => state.filter.orders)
  const receiptingOrders = useSelector(
    state => state.receipting.receiptingOrders,
  )
  const orderedProductReceiptings = useSelector(
    state => state.receipting.orderedProductReceiptings,
  )

  const [dataSource, setDataSource] = useState([])
  useEffect(() => {
    if (orders.some(order => order.receipting !== undefined)) {
      dispatch(fetchReceiptingsForOrderedProducts())
    }
    //Changed dependency orders to orders.length because our component doesn't make sense for unlimited API calls / unlimited rendering
  }, [orders.length])

  useEffect(() => {
    dispatch(fetchReceiptingOrders(venueId, filter.startDate, filter.endDate))
  }, [filter])

  useEffect(() => {
    if (orderedProductReceiptings && receiptingOrders) {
      let filteredReceiptingOrders = []
      if (checkBoxName === 'Receipted') {
        filteredReceiptingOrders = receiptingOrders.filter(
          el =>
            el.receiptingStatus !== undefined &&
            el.receiptingStatus !== 'Not Saved',
        )
      } else if (checkBoxName === 'Unreceipted') {
        filteredReceiptingOrders = receiptingOrders.filter(
          el =>
            el.receiptingStatus === undefined ||
            el.receiptingStatus === 'Not Saved',
        )
      }
      let newOrders = []
      if (checkBoxName === 'All') {
        newOrders = orders
      } else {
        const set = new Set(
          filteredReceiptingOrders.flatMap(order => order.orderIds),
        )
        newOrders = orders.filter(obj => set.has(obj._id))
      }

      if (
        (newOrders.some(order => order.receipting !== undefined) &&
          orderedProductReceiptings.length > 0) ||
        newOrders.every(order => order.receipting === undefined)
      ) {
        const mappedProducts = newOrders.map(order => {
          if (order.receipting !== undefined) {
            const currentReceipting = orderedProductReceiptings.find(
              rec => rec._id === order.receipting,
            )

            return {
              ...order,
              title:
                currentReceipting?.updatedTitle || order.product?.title || '',
              price:
                currentReceipting?.updatedPrice ||
                order.price ||
                order.product.price,
              size: currentReceipting?.updatedSize || order.product?.size || '',
              sku: currentReceipting?.updatedSku || order.product?.sku || '',
              quantity:
                currentReceipting?.updatedQuantity ||
                order.updatedQuantity ||
                order.quantity,
              hasVat: order.product?.hasVat || false,
            }
          }
          return {
            ...order,
            title: order.product?.title || '',
            price: order.product?.price || '0.00',
            size: order.product?.size || '',
            sku: order.product?.sku || '',
            quantity: order.updatedQuantity || order.quantity,
            hasVat: order?.hasVat || order?.product?.hasVat || false,
          }
        })

        const groupedByNecessaryFields = _(mappedProducts).groupBy(
          order =>
            `${order?.title || ''}-${order.price}-${order.sku}-${order.size}`,
        )
        const objs = Object.entries(groupedByNecessaryFields).map(
          ([, groupedArray]) => {
            let productRow = null
            for (const product of groupedArray) {
              if (!productRow) {
                productRow = { ...product }
                productRow.quantity =
                  productRow.updatedQuantity || productRow.quantity
              } else {
                productRow.quantity +=
                  product.updatedQuantity || product.quantity || 0
              }
            }
            return productRow
          },
        )
        const result = objs
          .map(order => {
            const currentPrice =
              !Number.isNaN(Number(order.OMSPrice)) &&
              order.OMSPrice !== null &&
              order.OMSPrice !== undefined
                ? order.OMSPrice
                : order.product.OMSPrice
            const netNumber = Number(currentPrice * order.quantity).toFixed(2)
            const vatNumber = order.hasVat
              ? Number(currentPrice * order.quantity * VAT_PERCENTAGE).toFixed(
                  2,
                )
              : Number(0).toFixed(2)
            const grossNumber =
              order?.product?.hasVat || false
                ? Number(currentPrice * order.quantity * VAT_RATE).toFixed(2)
                : Number(currentPrice * order.quantity).toFixed(2)
            return {
              key: order._id,
              product: order.title,
              supplierName: order.product?.supplier.title || '',
              sku: order.sku,
              size: order.size,
              quantity: order.quantity,
              price: currentPrice,
              hasVat: order.hasVat,
              type: order.type,
              netNumber,
              netString: `£${netNumber}`,
              vatNumber,
              vatString: `£${vatNumber}`,
              grossNumber,
              grossString: `£${grossNumber}`,
            }
          })
          .sort((a, b) => (a.product > b.product ? 1 : -1))

        setDataSource(result)
      }
    }
  }, [orderedProductReceiptings, checkBoxName, receiptingOrders])

  if (dataSource.length > 0) dispatch(setOrderedProduct(dataSource))

  return (
    <>
      <div style={{ display: 'flex', margin: '0 10px' }}>
        <Checkbox
          checked={checkBoxName === 'All'}
          onChange={() => setCheckBoxName('All')}
        >
          All
        </Checkbox>
        <Checkbox
          checked={checkBoxName === 'Receipted'}
          onChange={() => setCheckBoxName('Receipted')}
        >
          Receipted
        </Checkbox>
        <Checkbox
          checked={checkBoxName === 'Unreceipted'}
          onChange={() => setCheckBoxName('Unreceipted')}
        >
          Unreceipted
        </Checkbox>
      </div>
      <Wrapper>
        <Table
          dataSource={dataSource}
          pagination={false}
          loading={dataSource.length < 1}
          columns={[
            {
              title: 'Product',
              dataIndex: 'product',
              key: 'product',
              className: 'restrictShrink',
              sorter: (a, b) =>
                String(a.product)
                  .trim()
                  .localeCompare(String(b.product).trim()),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Qty',
              dataIndex: 'quantity',
              key: 'quantity',
              className: 'restrictShrink',
              sorter: (a, b) => a.quantity - b.quantity,
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Size',
              dataIndex: 'size',
              key: 'size',
              className: 'restrictShrink',
              sorter: (a, b) =>
                String(a.size).trim().localeCompare(String(b.size).trim()),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Price',
              dataIndex: 'price',
              key: 'price',
              className: 'restrictShrink',
              render: (text, item) => {
                return `£${Number(text).toFixed(2) || 0}${
                  item.product.hasVat ? ' + VAT' : ''
                }`
              },
              sorter: (a, b) =>
                (a.product.hasVat ? VAT_RATE * a.price : a.price) -
                (b.product.hasVat ? VAT_RATE * b.price : b.price),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Supplier',
              dataIndex: 'supplierName',
              key: 'supplierName',
              className: 'restrictShrink',
              sorter: (a, b) => a.supplierName - b.supplierName,
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'SKU',
              dataIndex: 'sku',
              key: 'sku',
              className: 'restrictShrink',
              sorter: (a, b) =>
                String(a.supplierName)
                  .trim()
                  .localeCompare(String(b.sku).trim()),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'VAT',
              dataIndex: 'vatString',
              key: 'vatString',
              className: 'restrictShrink',
              sorter: (a, b) => a.vatNumber - b.vatNumber,
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Net',
              dataIndex: 'netString',
              key: 'netString',
              className: 'restrictShrink',
              sorter: (a, b) => a.netNumber - b.netNumber,
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Gross',
              dataIndex: 'grossString',
              key: 'grossString',
              className: 'restrictShrink',
              sorter: (a, b) => a.grossNumber - b.grossNumber,
              sortDirections: ['ascend', 'descend'],
            },
          ]}
          footer={() => {
            return (
              <Row>
                <Row>
                  <Col span={19}></Col>
                  <Col span={3}>
                    <strong>Net total:</strong>
                  </Col>
                  <Col span={2}>
                    {dataSource
                      ?.reduce((sum, item) => {
                        return sum + parseFloat(item.netNumber)
                      }, 0)
                      .toFixed(2)}
                  </Col>
                </Row>
                <Row>
                  <Col span={19}></Col>
                  <Col span={3}>
                    <strong>Total VAT:</strong>
                  </Col>
                  <Col span={2}>
                    {dataSource
                      ?.reduce((sum, item) => {
                        return sum + parseFloat(item.vatNumber)
                      }, 0)
                      .toFixed(2)}
                  </Col>
                </Row>
                <Row>
                  <Col span={19}></Col>
                  <Col span={3}>
                    <strong>Gross total:</strong>
                  </Col>
                  <Col span={2}>
                    {dataSource
                      ?.reduce((sum, item) => {
                        return sum + parseFloat(item.grossNumber)
                      }, 0)
                      .toFixed(2)}
                  </Col>
                </Row>
              </Row>
            )
          }}
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled(FadeIn)`
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-table-tbody {
    .restrictShrink {
      min-width: 90px;
      background-color: white;
    }
  }
`
export default OrderedProducts
