import moment from 'moment'

const ResolvedNotesColumns = () => {
  return [
    {
      title: 'PO Number',
      dataIndex: 'PON',
      key: 'PON',
      width: '15%',
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      key: 'SKU',
      width: '15%',
    },
    {
      title: 'Product',
      dataIndex: 'productTitle',
      key: 'product_title',
      width: '25%',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierTitle',
      key: 'supplier_title',
      width: '15%',
      sorter: (a, b) =>
        String(a.supplierTitle)
          .trim()
          .split(' ')
          .join('')
          .localeCompare(String(b.supplierTitle).trim().split(' ').join('')),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Credit quantity',
      dataIndex: 'quantity',
      key: 'credit_quantity',
      width: '10%',
    },
    {
      title: 'Credit amount',
      dataIndex: 'amount',
      key: 'credit_amount',
      width: '10%',
    },
    {
      title: 'Report status',
      dataIndex: 'reportStatus',
      key: 'credit_reportStatus',
      width: '10%',
    },
    {
      title: 'Ordered Date',
      dataIndex: 'createdAt',
      key: 'dateOfOrder',
      width: '10%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['ascend', 'descend'],
      render: dateTime => {
        const date = moment(dateTime).format('DD-MM-YYYY')
        return date
      },
    },
    // {
    //   title: 'Resolved',
    //   dataIndex: 'resolved',
    //   key: 'checkbox_resolved',
    //   render: (value, obj) => {
    //     return (
    //       <ActionWrapper>
    //         <CardButton
    //           title='Are you sure the credit due is resolved?'
    //           placement='topRight'
    //           onConfirm={() => makeOrderResolvedValue(obj.receiptingId)}
    //           okText='Yes'
    //           cancelText='No'
    //           hidden={value}
    //         >
    //           <div onClick={e => e.stopPropagation()}>
    //             <Checkbox
    //               className='checkbox'
    //               checked={value}
    //               disabled={value}
    //             />
    //           </div>
    //         </CardButton>
    //       </ActionWrapper>
    //     )
    //   },
    // },
  ]
}

// const ActionWrapper = styled.div`
//   display: flex;
//   flex-wrap: nowrap;
//   text-align: end;

//   .checkbox {
//     flex: 0 0 25%;
//     justify-content: center;
//   }
// `
// const CardButton = styled(Popconfirm)`
//   align-self: flex-end;
//   margin-bottom: 10px;
//   margin-left: 12px;
// `
export default ResolvedNotesColumns
