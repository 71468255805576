import React, { useEffect, useState } from 'react'
import Loading from '../../components/styled/Loading'
import { DatePicker, Input, Modal, Table, TreeSelect } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import JSZip from 'jszip'
import styled from 'styled-components'

import {
  editInvoiceInDatabase,
  getInvoicesWithTempUrl,
  getInvoicesWithTempUrlByRange,
  getInvoiceUrlById,
  getUploadedInvoices,
} from '../../features/NewInvoicesSlice'
import InvoicesColumns from './InvoicesColumns'
import LoadingButton from '../../components/styled/LoadingButton'
import moment from 'moment/moment'
import AddInvoices from '../../components/AddInvoices'
const { RangePicker } = DatePicker

const InvoicesScreen = props => {
  const dispatch = useDispatch()
  const { uploadedInvoices, uploadedLoading, updatingLoading } = useSelector(
    state => state.newInvoices,
  )
  const isAdmin = useSelector(state => state.auth.account.isAdmin || null)

  const { venueId } = props

  const [dataSourse, setDataSourse] = useState([])
  const [downloading, setDownLoading] = useState(false)
  const [visibleCalendar, setVisibleCalendar] = useState(false)
  const [downloadingInvoice, setDownLoadingInvoice] = useState(null)
  const [downloadingRangeInvoices, setDownloadingRangeInvoices] =
    useState(false)
  const [rangeDate, setRangeDate] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [editInvoice, setEditInvoice] = useState(null)
  const [updatedInvoice, setUpdatedInvoice] = useState({})

  const [selectedSupplier, setSelectedSupplier] = useState(null)

  // const venueSuppliers = useSelector(state =>
  //   state.venueSuppliers.venueSuppliers.filter(
  //     venueSupplier => venueSupplier.isActive,
  //   ),
  // )

  useEffect(() => {
    dispatch(getUploadedInvoices(venueId))
  }, [venueId])

  useEffect(() => {
    filterInvoicesInfo(uploadedInvoices)
  }, [uploadedInvoices])

  const filterInvoicesInfo = invoices => {
    const filtered = invoices.map(invoice => {
      return {
        id: invoice._id,
        checked: invoice.checked,
        uploadedDate: invoice.uploadedDate,
        fileName: invoice.key,
        supplierTitle: invoice.supplierTitle || '',
        supplierId: invoice.supplier || '',
        purchaseOrderNumber: invoice.purchaseOrderNumber || '',
        notes: invoice.comment,
        invoiceUrl: invoice.invoiceUrl,
        username: invoice.userType === 'Admin' ? 'Admin' : invoice.username,
      }
    })
    setDataSourse(filtered)
    const uniqueSuppliers = invoices.reduce((unique, invoice) => {
      const exists = unique.some(item => item.value === invoice.supplierTitle)
      if (!exists && invoice.supplierTitle) {
        unique.push({
          title: invoice.supplierTitle,
          value: invoice.supplierTitle,
        })
      }

      return unique
    }, [])

    filtered.length &&
      uniqueSuppliers.push({
        title: 'Other',
        value: 'null',
      })

    setSuppliers(uniqueSuppliers)
  }

  // const updateInvoiceDB = (invoiceId, field, value) => {
  //   dispatch(updateInvoice(invoiceId, field, value))
  // }

  const downloadInvoicesToFolder = async (invoicesUrls, zipName) => {
    try {
      const zip = new JSZip()
      const photos = invoicesUrls.map(async (photo, index) => {
        const response = await fetch(photo)
        const blob = await response.blob()

        const mimeType = response.headers.get('content-type') || 'image/jpeg'

        zip.file(`invoice${index + 1}.${mimeType.split('/')[1]}`, blob, {
          binary: true,
        })
      })

      await Promise.all(photos)
      const zipBlob = await zip.generateAsync({ type: 'blob' })

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(zipBlob)
      downloadLink.download = zipName
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
      console.error('Error in downloadInvoicesToFolder', e)
    }
  }

  const downloadAllInvoicesToFolder = async () => {
    setDownLoading(true)
    try {
      const invoicesUrls = await getInvoicesWithTempUrl(
        venueId,
        selectedSupplier,
      )

      await downloadInvoicesToFolder(invoicesUrls, 'OMS_invoices.zip')
      setDownLoading(false)
    } catch (e) {
      setDownLoading(false)
      console.log('Error in downloadAllInvoicesToFolder', e)
    }
  }

  const downloadRangeInvoicesToFolder = async () => {
    setDownloadingRangeInvoices(true)
    try {
      const invoicesUrls = await getInvoicesWithTempUrlByRange(
        venueId,
        rangeDate,
      )

      await downloadInvoicesToFolder(
        invoicesUrls,
        `${rangeDate[0]}-${rangeDate[1]}.zip`,
      )
      setDownloadingRangeInvoices(false)
    } catch (e) {
      setDownloadingRangeInvoices(false)
      console.log('Error in downloadRangeInvoicesToFolder', e)
    }
  }
  async function downloadInvoice(invoiceId) {
    try {
      setDownLoadingInvoice(invoiceId)
      const invoice = await getInvoiceUrlById(venueId, invoiceId)

      const response = await fetch(invoice.invoiceUrl)

      if (!response.ok) {
        throw new Error(
          `Failed to download invoice. Status: ${response.status}`,
        )
      }
      const contentType = response.headers.get('Content-Type')
      const isPdf = contentType && contentType.includes('application/pdf')
      const contentDisposition = response.headers.get('Content-Disposition')
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename="(.+?)"/)
      const filename = filenameMatch
        ? filenameMatch[1]
        : isPdf
        ? 'invoice.pdf'
        : 'invoice.jpg'

      const blob = await response.blob()

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = filename

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setDownLoadingInvoice(null)
    } catch (error) {
      console.error('Error downloading invoice:', error.message)
    }
  }
  const disableEndDate = current => current.valueOf() > moment.now()

  const onChangeDate = (value, dateString) => {
    setVisibleCalendar(false)
    if (dateString) {
      setRangeDate(dateString)
    } else {
      setRangeDate([])
    }
  }

  return (
    <>
      {uploadedLoading ? (
        <Loading />
      ) : (
        <Content>
          <BtnsWrapper>
            <TreeSelectWrapper>
              <TreeSelect
                treeNodeFilterProp='title'
                allowClear={true}
                placeholder='Select a supplier'
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                dropdownStyle={{
                  background: 'white',
                }}
                style={{
                  height: '25px',
                  width: '200px',
                }}
                value={selectedSupplier}
                maxTagCount={1}
                maxTagPlaceholder={omittedValues =>
                  `+ ${omittedValues.length} Supplier ...`
                }
                onChange={value => {
                  setSelectedSupplier(value)
                }}
                treeData={suppliers}
              />
            </TreeSelectWrapper>
            <DownloadButton
              loading={downloading}
              onClick={downloadAllInvoicesToFolder}
              disabled={!selectedSupplier}
            >
              Download All
            </DownloadButton>

            <AddInvoices venueId={venueId} />

            <div>
              <TitleForRnage>Select date</TitleForRnage>
              <RangePicker
                onFocus={() => setVisibleCalendar(true)}
                onBlur={() => setVisibleCalendar(false)}
                open={visibleCalendar}
                disabledDate={disableEndDate}
                onChange={onChangeDate}
              />
              {rangeDate.length > 0 && (
                <DownloadRangeButton
                  loading={downloadingRangeInvoices}
                  onClick={downloadRangeInvoicesToFolder}
                >
                  {`Download invoices from ${rangeDate[0]} to ${rangeDate[1]}`}
                </DownloadRangeButton>
              )}
            </div>
          </BtnsWrapper>

          <TableWrapper>
            <StyledTable
              dataSource={dataSourse.map((invoice, index) => ({
                key: index,
                ...invoice,
              }))}
              columns={InvoicesColumns(
                venueId,
                downloadInvoice,
                downloadingInvoice,
                isAdmin,
                setEditInvoice,
              )}
            />
          </TableWrapper>
        </Content>
      )}
      <Modal
        title='Edit PONumber'
        visible={editInvoice !== null}
        onOk={() => {
          dispatch(
            editInvoiceInDatabase(venueId, editInvoice.id, updatedInvoice),
          )
          setEditInvoice(null)
        }}
        confirmLoading={updatingLoading}
        onCancel={() => {
          setEditInvoice(null)
        }}
      >
        {editInvoice ? (
          <Input
            defaultValue={editInvoice?.purchaseOrderNumber || ''}
            onChange={e =>
              setUpdatedInvoice(prevState => ({
                ...prevState,
                purchaseOrderNumber: e.target.value,
              }))
            }
          />
        ) : null}
      </Modal>
    </>
  )
}

const StyledTable = styled(Table)`
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
  }
`
const TableWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const DownloadButton = styled(LoadingButton)`
  width: 150px;
  height: 25px;
  margin-bottom: 30px;
`
const DownloadRangeButton = styled(LoadingButton)`
  width: auto;
  height: 25px;
  margin: 10px auto 30px;
`
const TitleForRnage = styled.strong`
  margin-right: 20px;
`

const BtnsWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`

const TreeSelectWrapper = styled.div`
  margin-bottom: 60px; // Adjust margin-top as needed
`
export default InvoicesScreen
