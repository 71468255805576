import React from 'react'
import styled from 'styled-components'
import { H3 } from './typography'

const Title = props => {
  return <StyledTitle>{props.children}</StyledTitle>
}

const StyledTitle = styled(H3)`
  margin-bottom: 0;
`

export default Title
