import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { setOneVenue as setOneVenueToAccount } from 'src/features/AuthSlice'
import { SuccessNotification } from 'src/notifications/SuccessNotification'
import Sentry from 'src/utils/sentry'

const venueSlice = createSlice({
  name: 'venue',
  initialState: {
    venue: null,
    isLoading: false,
    venueInfo: null,
  },
  reducers: {
    setVenue: (state, action) => {
      state.venue = action.payload
    },
    setVenueInfo: (state, action) => {
      state.venueInfo = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setVenue, setIsLoading, setVenueInfo } = venueSlice.actions

export const editVenue = (data, venueId) => async dispatch => {
  try {
    const venue = await API.post('api', `settings/${venueId}`, {
      body: data,
    })

    dispatch(setVenue(venue))
    dispatch(setOneVenueToAccount(venue))
    SuccessNotification({
      message: 'Venue settings',
      description: 'The changes have been saved',
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const updateVenueCode = venueId => async dispatch => {
  try {
    const venue = await API.put('api', `settings/${venueId}/false`, {
      body: {},
    })
    dispatch(setVenue(venue))
    dispatch(setOneVenueToAccount(venue))
    SuccessNotification({
      message: 'Venue settings',
      description: 'The venue code have been updated',
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const updateAdminVenueCode = venueId => async dispatch => {
  try {
    const venue = await API.put('api', `settings/${venueId}/true`, {
      body: {},
    })
    dispatch(setVenue(venue))
    dispatch(setOneVenueToAccount(venue))
    SuccessNotification({
      message: 'Venue settings',
      description: 'The admin venue code have been updated',
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const getVenue = venueId => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const data = await API.get('api', `venue/${venueId}`)
    dispatch(setVenue(data))
    dispatch(setIsLoading(false))
  } catch (e) {
    dispatch(setIsLoading(false))
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const createVenue = data => async () => {
  try {
    await API.post('api', `venue`, {
      body: {
        title: data.title,
        username: data.username,
        TFRVenue: data.TFRVenue,
      },
    })
    window.location.reload()
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const deleteVenue = venueId => async () => {
  try {
    await API.del('api', `venue/${venueId}`)
    window.location.reload()
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const pushAppNotification =
  (message, venueIds = [], supplierIds = []) =>
  async () => {
    try {
      const response = await API.post('api', `pushAppNotification`, {
        body: { message, venueIds, supplierIds },
      })
      SuccessNotification({
        message: 'Push Notification',
        description: response.message,
      })
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      return false
    }
  }

export const fetchVenueInfo = venueId => async dispatch => {
  try {
    const venueInfo = await API.get('api', `venueInfo/${venueId}?days=${30}`)
    dispatch(setVenueInfo(venueInfo))
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    return false
  }
}

export const toggleSubscription = data => async () => {
  try {
    data?.isSubscribed
      ? await API.post('api', `cancelSubscriptionByAdmin/${data?.venueId}`, {
          body: {
            plan: data?.title,
          },
        })
      : await API.post('api', `giveSubscriptionByAdmin/${data?.venueId}`, {
          body: {
            plan: data?.title,
          },
        })
    window.location.reload()
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default venueSlice
