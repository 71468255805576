import { Button, Col, Row, Table } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import FadeIn from '../../components/styled/FadeIn'

const Statement = venueId => {
  const statementOrders = useSelector(
    state => state.orders.filteredStatementOrders,
  )
  const orderFilter = useSelector(state => state.filter.orders)
  const { startDate, endDate } = orderFilter
  const dataArray = statementOrders.map(order => order.data)
  const customStartDate = startDate.split('/').reverse().join('-')
  const customEndDate = endDate.split('/').reverse().join('-')

  return (
    <Wrapper>
      <ButtonRow>
        <Link
          to={`/v/${venueId.venueId}/invoice/${customStartDate}/${customEndDate}`}
        >
          <Button>View statement</Button>
        </Link>
      </ButtonRow>
      <Table
        pagination={false}
        dataSource={statementOrders ? statementOrders : []}
        columns={[
          {
            title: 'Supplier title',
            dataIndex: '_id.supplier.title',
            key: '_id.supplier.title',
          },
          {
            title: 'Date of the order',
            dataIndex: 'data[0].orderedDate',
            key: 'data[0].orderedDate',
            render: item => dayjs(item).format('DD/MM/YY'),
          },
          {
            title: 'Value of the order',
            dataIndex: 'data',
            key: 'valueOfTheOrder',
            render: data => {
              return (
                data.reduce(
                  (sum, item) => sum + item.price * item.quantity,
                  0,
                ) / 100
              ).toFixed(2)
            },
          },
          {
            title: 'Value of VAT',
            dataIndex: 'data',
            key: 'valueOfVAT',
            render: data => {
              return (
                data.reduce(
                  (sum, item) =>
                    sum + (item.hasVat ? item.price * item.quantity * 0.2 : 0),
                  0,
                ) / 100
              ).toFixed(2)
            },
          },
          {
            title: 'Total due',
            dataIndex: 'data',
            key: 'totalDue',
            render: data => {
              return (
                data.reduce(
                  (sum, item) =>
                    sum +
                    (item.hasVat
                      ? item.price * item.quantity * 1.2
                      : item.price * item.quantity),
                  0,
                ) / 100
              ).toFixed(2)
            },
          },
        ]}
        bordered
        footer={() => {
          return (
            <Row>
              <Row>
                <Col span={17}></Col>
                <Col span={5}>
                  <strong>Statement total ex. VAT:</strong>
                </Col>
                <Col span={2}>
                  {(
                    dataArray.reduce((sum, item) => {
                      const total = item.reduce((sum, item) => {
                        return sum + item.price * item.quantity
                      }, 0)

                      return sum + total
                    }, 0) / 100
                  ).toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col span={17}></Col>
                <Col span={5}>
                  <strong>Total VAT:</strong>
                </Col>
                <Col span={2}>
                  {(
                    dataArray.reduce((sum, item) => {
                      const total = item.reduce((sum, item) => {
                        return (
                          sum +
                          (item.hasVat ? item.price * item.quantity * 0.2 : 0)
                        )
                      }, 0)

                      return sum + total
                    }, 0) / 100
                  ).toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col span={17}></Col>
                <Col span={5}>
                  <strong>Statement total:</strong>
                </Col>
                <Col span={2}>
                  {(
                    dataArray.reduce((sum, item) => {
                      const total = item.reduce((sum, item) => {
                        return (
                          sum +
                          (item.hasVat
                            ? item.price * item.quantity * 1.2
                            : item.price * item.quantity)
                        )
                      }, 0)

                      return sum + total
                    }, 0) / 100
                  ).toFixed(2)}
                </Col>
              </Row>
            </Row>
          )
        }}
      />
    </Wrapper>
  )
}

const ButtonRow = styled.div`
  margin-bottom: 18px;
`

const Wrapper = styled(FadeIn)`
  width: 100%;
`

export default Statement
