import {
  Button,
  Card,
  DatePicker,
  Modal,
  Popconfirm,
  Select,
  Table,
  message,
} from 'antd'
import { API } from 'aws-amplify'
import axios from 'axios'
import dayjs from 'dayjs'
import moment from 'moment'
import Papa from 'papaparse'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'
import Title from 'src/branding/Title'
import ProductImage from 'src/components/shared/ProductImage'
import SearchBar from 'src/components/shared/SearchBar'
import FadeIn from 'src/components/styled/FadeIn'
import LoadingButton from 'src/components/styled/LoadingButton'
import {
  adminEditProductFromSearch,
  performSearch,
  setTerm,
} from 'src/features/CatalogueSearchSlice'
import {
  adminAddProduct,
  adminDeleteProduct,
  adminEditProduct,
  adminFetchProducts,
  uploadProductsCSV,
  uploadProductsCSVOnRequiredDate,
} from 'src/features/ProductSlice'
import { fetchAllSuppliers } from 'src/features/SupplierSlice'
import EditProductModal from 'src/screens/products/EditProductModal'
import { arrayToCSV } from 'src/screens/products/Utils'
import Sentry from 'src/utils/sentry'
import { useWindowWidth } from 'src/utils/useWindowWidth'
import styled, { ThemeContext } from 'styled-components/macro'
import ProductCard from './ProductCard'

export const CSVButton = React.forwardRef(
  ({ data, fetchCSVData, filename, loading, isMobile }, ref) => {
    const FlexibleButton = ({ loading = false }) => (
      <LoadingButton
        style={{
          marginLeft: 0,
          marginTop: isMobile ? 15 : 0,
          marginBottom: isMobile ? 30 : 0,
        }}
        loading={loading}
        onClick={fetchCSVData}
      >
        Download CSV
      </LoadingButton>
    )
    return data && data.length > 0 ? (
      <CSVLink ref={ref} target='_self' filename={filename} data={data}>
        <FlexibleButton loading={true} />
      </CSVLink>
    ) : (
      <FlexibleButton loading={loading} />
    )
  },
)

const Products = () => {
  const dispatch = useDispatch()

  const _file = useRef(null)
  const account = useSelector(state => state.auth.account)
  const supplierAdmin = account && account.supplierAdmin
  const [editingProduct, setEditingProduct] = useState(null)
  const [selectedSupplier, setSelectedSupplier] = useState(
    supplierAdmin ? account.supplier._id : null,
  )
  const [paginationPage, setPaginationPage] = useState(1)
  const [paginationSize, setPaginationSize] = useState(10)
  const [pageLoading, setPageLoading] = useState(true)
  const [uploadCSV, setUploadCSV] = useState(false)
  const [uploadButtonText, setUploadButtonText] = useState('Upload CSV')
  const [addProductModalVisible, setAddProductModalVisible] = useState(false)
  const [addingProduct, setAddingProduct] = useState({
    title: '',
    sku: '',
    size: '',
    price: '',
    OMSPrice: '',
    GLN: null,
    supplier: '',
    type: '',
    UoM: '',
    hasVat: false,
  })
  const [dateOfUploadingProducts, setDateOfUploadingProducts] = useState(null)

  useEffect(() => {
    setPageLoading(true)
    if (suppliers.length === 0) dispatch(fetchAllSuppliers())
    dispatch(
      adminFetchProducts({ page: paginationPage, limit: paginationSize }),
    )
    setPageLoading(false)
  }, [dispatch, paginationPage])

  const upload = () => {
    setUploadCSV(true)
    if (!_file.current.files[0]) {
      setUploadCSV(false)
      alert('Choose a file first')
      return
    }

    if (!dateOfUploadingProducts) {
      Papa.parse(_file.current.files[0], {
        header: true,
        complete: function (results) {
          dispatch(
            uploadProductsCSV(
              results.data,
              selectedSupplier,
              () => setUploadCSV(false),
              setUploadButtonText,
            ),
          )
        },
        error: function (err) {
          setUploadCSV(false)
          alert(
            "Selected file could not be parsed. Are you sure it's a CSV file?",
          )
          console.log(err)
        },
      })
    } else {
      Papa.parse(_file.current.files[0], {
        header: true,
        complete: function (results) {
          dispatch(
            uploadProductsCSVOnRequiredDate(
              {
                dataOfCsvFile: results.data,
                selectedDate: dateOfUploadingProducts,
              },
              selectedSupplier,
              () => setUploadCSV(false),
              setUploadButtonText,
            ),
          )
        },
        error: function (err) {
          setUploadCSV(false)
          alert(
            "Selected file could not be parsed. Are you sure it's a CSV file?",
          )
          console.log(err)
        },
      })
    }
  }

  const products = useSelector(state => state.product.products)
  const totalProductCount = useSelector(
    state => state.product.productStats.serverCount,
  )
  const suppliers = supplierAdmin
    ? account.supplier
    : useSelector(state => state.suppliers.suppliers)

  const fetchNewProducts = (dispatch, page, size) =>
    new Promise((resolve, reject) => {
      dispatch(
        adminFetchProducts({
          page,
          limit: size,
          onSuccess: resolve,
          onFailure: reject,
        }),
      )
    })
  const changePage = async (page, limit) => {
    setPageLoading(true)
    await fetchNewProducts(dispatch, page, limit)
    setPaginationPage(page)
    setPageLoading(false)
  }
  const changePageSize = size => {
    setPaginationSize(size)
    changePage(1, size)
  }

  const [csvProducts, setCsvProducts] = useState([])
  const [csvLoading, setCsvLoading] = useState(false)
  const csvButton = useRef()
  const selectedSupplierName = suppliers.find(
    supplier => supplier._id === selectedSupplier,
  )?.title
  const selectedSupplierSlug = selectedSupplierName
    ? selectedSupplierName.replace(/[\s]/g, '-').replaceAll('.', '')
    : ''

  const { Option } = Select

  const searchTerm = useSelector(state => state.catalogueSearch.term)
  const searchLoading = useSelector(state => state.catalogueSearch.loading)
  const searchPage = useSelector(state => state.catalogueSearch.page)
  const searchProducts = useSelector(state => state.catalogueSearch.products)
  const searchPageSize = useSelector(state => state.catalogueSearch.pageSize)
  const searchTotal = useSelector(state => state.catalogueSearch.total)

  // const allTruthyTypes = products
  //   .map((product) => product.type)
  //   .filter(Boolean)
  // const uniqueProductTypes = [...new Set(allTruthyTypes)]

  const inSearchMode = Boolean(searchTerm.trim())
  const displayProducts = (inSearchMode ? searchProducts : products) || []
  const paginationConfig = inSearchMode
    ? {
        pageSize: searchPageSize,
        pageSizeOptions: ['10', '20', '50', '100', '250'],
        showSizeChanger: true,
        position: 'both',
        current: searchPage,
        total: searchTotal,
        onChange: page =>
          dispatch(
            performSearch({
              pageNumber: page,
              pageSize: searchPageSize,
            }),
          ),
        onShowSizeChange: (_, pageSize) =>
          dispatch(performSearch({ pageNumber: 1, pageSize })),
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} results`,
      }
    : {
        pageSize: paginationSize,
        pageSizeOptions: ['10', '20', '50', '100', '250'],
        showSizeChanger: true,
        position: 'both',
        current: paginationPage,
        total: totalProductCount,
        onChange: page => changePage(page, paginationSize),
        onShowSizeChange: (_, pageSize) => changePageSize(pageSize),
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }

  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg

  const handlingUpload = () => {
    if (!selectedSupplier) {
      setUploadCSV(false)
      alert('Choose a supplier first')
      return
    }
    document.getElementById('getFile').click()
  }

  const handleOnOk = () => {
    if (
      addingProduct.title &&
      addingProduct.sku &&
      addingProduct.size &&
      addingProduct.price &&
      addingProduct.OMSPrice &&
      addingProduct.supplier
    ) {
      dispatch(adminAddProduct(addingProduct))
      setAddProductModalVisible(false)
      setAddingProduct({
        title: '',
        sku: '',
        size: '',
        price: '',
        OMSPrice: '',
        supplier: null,
        GLM: null,
        type: '',
        UoM: '',
        hasVat: false,
      })
    } else {
      message.error(
        'Title, SKU, Size, OMSPrice, Supplier fields must be fulfilled',
      )
    }
  }

  const onChangeDate = date => {
    setDateOfUploadingProducts(dayjs(date).format('YYYY-MM-DD'))
  }
  const isDateDisabled = date => {
    if (date.format('YYYY-MM-DD-HH') <= moment().format('YYYY-MM-DD-HH')) {
      return true
    }
  }

  return (
    <Wrapper>
      <Title>Products</Title>
      <Card style={{ marginBottom: 25 }}>
        <DivContainer gutter={16}>
          <DivFlex>
            <Select
              style={{ width: isMobile ? '100%' : 250 }}
              onChange={e => {
                setSelectedSupplier(e)
              }}
            >
              {suppliers.map(supplier => {
                return (
                  <Option key={supplier._id} value={supplier._id}>
                    {supplier.title}&nbsp;{supplier.secondTitle}
                  </Option>
                )
              })}
            </Select>
            <DatePicker
              onChange={onChangeDate}
              disabledDate={date => isDateDisabled(date)}
            />
            <CSVButton
              ref={csvButton}
              isMobile={isMobile}
              data={csvProducts}
              fetchCSVData={async () => {
                if (!selectedSupplier) {
                  alert('Choose a supplier first')
                  return false
                }
                setCsvLoading(true)
                try {
                  const { filePath } = await API.get(
                    'api',
                    `supplier/${selectedSupplier}/products`,
                  )

                  if (filePath) {
                    const {
                      data: { products },
                    } = await axios(filePath)
                    const formattedProducts = arrayToCSV(products)
                    setCsvProducts(formattedProducts)
                    await csvButton.current.link.click()
                    setCsvProducts([])
                    setCsvLoading(false)
                  }
                } catch (e) {
                  console.log(e)
                  Sentry.captureException(e)
                  setCsvProducts([])
                  setCsvLoading(false)
                }
              }}
              filename={`oms-products-export_${selectedSupplierSlug}.csv`}
              loading={csvLoading}
            >
              Download CSV
            </CSVButton>
          </DivFlex>

          <DivFlex flexEnd>
            <StyledAddButton onClick={() => setAddProductModalVisible(true)}>
              Add product
            </StyledAddButton>
            <input
              // style={{ marginLeft: isMobile ? 0 : 25 }}
              style={{ display: 'none' }}
              id={'getFile'}
              type='file'
              ref={_file}
              onInput={upload}
            />
            <LoadingButton
              loading={uploadCSV}
              style={{
                marginTop: isMobile ? 15 : 0,
              }}
              onClick={handlingUpload}
            >
              {uploadButtonText}
            </LoadingButton>
          </DivFlex>
        </DivContainer>
      </Card>
      <SearchBar />
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        dataSource={displayProducts.map(product => ({
          key: product._id,
          ...product,
        }))}
        pagination={paginationConfig}
        loading={pageLoading || searchLoading}
        columns={[
          {
            title: '',
            dataIndex: 'cloudinaryImageId',
            key: 'cloudinaryImageId',
            render: imageId => {
              return <ProductImage imageId={imageId} />
            },
          },
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            className: 'restrictShrink',
            // sorter: (a, b) =>
            //   String(a.title).trim().localeCompare(String(b.title).trim()),
            // sortDirections: ["ascend", "descend"],
          },
          {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            className: 'restrictShrink',
            // sorter: (a, b) =>
            //   String(a.size).trim().localeCompare(String(b.size).trim()),
            // sortDirections: ["ascend", "descend"],
          },
          {
            title: 'Customer price',
            dataIndex: 'price',
            key: 'price',
            className: 'restrictShrink',
            render: text => {
              return `£${Number(text).toFixed(2)}`
            },
            // sorter: (a, b) => a.price - b.price,
            // sortDirections: ["ascend", "descend"],
          },
          {
            title: 'Supplier price',
            dataIndex: 'OMSPrice',
            key: 'OMSPrice',
            className: 'restrictShrink',
            render: text => {
              return `£${Number(text).toFixed(2)}`
            },
            // sorter: (a, b) => a.OMSPrice - b.OMSPrice,
            // sortDirections: ["ascend", "descend"],
          },
          {
            title: 'Priced by',
            dataIndex: 'pricedBy',
            key: 'PricedBy',
            className: 'restrictShrink',
            render: (pricedBy, product) => {
              return pricedBy
                ? pricedBy === 'Each'
                  ? `${pricedBy}`
                  : `Per ${pricedBy}`
                : product.UoM
                ? product.UoM === 'Each'
                  ? `${product.UoM}`
                  : `Per ${product.UoM}`
                : ''
            },
          },
          {
            title: 'Has VAT',
            dataIndex: 'hasVat',
            className: 'restrictShrink',
            render: (text, item) => {
              return <span>{item.hasVat && 'YES'}</span>
            },
          },
          {
            title: 'GLN',
            dataIndex: 'GLN',
            className: 'restrictShrink',
            render: (text, item) => {
              return <span>{item?.GLN || ''}</span>
            },
          },
          // filters: [
          //   {
          //     text: "YES",
          //     value: true,
          //   },
          //   {
          //     text: "NO",
          //     value: false,
          //   },
          // ],
          // onFilter: (value, record) => Boolean(record.hasVat) === value,
          {
            title: 'supplier',
            dataIndex: 'supplier.title',
            key: 'supplier.title',
            className: 'restrictShrink',
            // filters: suppliers.map((supplier) => ({
            //   text: supplier.title,
            //   value: supplier.title,
            // })),
            // onFilter: (value, record) => record?.supplier?.title === value,
          },
          {
            title: 'second title',
            dataIndex: 'supplier.secondTitle',
            key: 'supplier.secondTitle',
            className: 'restrictShrink',
          },
          {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            className: 'restrictShrink',
            // sorter: (a, b) =>
            //   String(a.sku).trim().localeCompare(String(b.sku).trim()),
            // sortDirections: ["ascend", "descend"],
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            className: 'restrictShrink',
            // filters: uniqueProductTypes.map((type) => ({
            //   text: type,
            //   value: type,
            // })),
            // onFilter: (value, record) => record?.type === value,
          },

          {
            title: 'Action',
            key: 'action',
            className: 'restrictShrink',
            render: (text, product) => (
              <>
                <StyledButton
                  type={'dashed'}
                  onClick={() => {
                    setEditingProduct(product)
                  }}
                >
                  <span>Edit</span>
                </StyledButton>

                {'    '}
                <Popconfirm
                  title='Are you sure you want to delete the product?'
                  placement='topRight'
                  onConfirm={() => {
                    dispatch(adminDeleteProduct(product))
                    dispatch(setTerm(''))
                  }}
                  okText='Yes'
                  cancelText='No'
                >
                  <StyledButton type={'danger'}>
                    <span>Delete</span>
                  </StyledButton>
                </Popconfirm>
              </>
            ),
          },
        ]}
      />
      {/* <ProductCard
        saveProduct={(product) => {
          setSearchTerm("");
          dispatch(adminAddProduct(product));
        }}
        suppliers={suppliers}
      /> */}
      <Modal
        title='Create a product'
        visible={addProductModalVisible}
        okText='Save'
        onOk={handleOnOk}
        onCancel={() => {
          setAddProductModalVisible(false)
        }}
      >
        <ProductCard
          suppliers={suppliers}
          addingProduct={addingProduct}
          setAddingProduct={setAddingProduct}
        />
      </Modal>

      {editingProduct && (
        <Modal
          title='Edit Product'
          visible={editingProduct !== null}
          okText='Save'
          onOk={() => {
            const editAction = inSearchMode
              ? adminEditProductFromSearch
              : adminEditProduct
            dispatch(
              editAction({
                ...editingProduct,
              }),
            )
            setEditingProduct(null)
          }}
          onCancel={() => {
            setEditingProduct(null)
          }}
        >
          <EditProductModal
            product={editingProduct}
            setEditedProduct={product => {
              setEditingProduct(product)
            }}
            suppliers={suppliers}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 110px;
  }
`
const DivContainer = styled.div`
  display: flex;
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  gap: 20px;
  width: 60%;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'flex-start')};
  padding-top: 8px;
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
  width: 80px;
`
const StyledAddButton = styled.button`
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  line-height: 1.499;
  position: relative;
  right: 20px;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colours.mayoWhite};
  border-color: ${({ theme }) => theme.colours.panSilver};
  :hover {
    background-color: ${({ theme }) => theme.colours.lettuceGreen};
    opacity: 0.8;
    cursor: pointer;
  }
`

export default Products
