export const invoices = {
  header: `We couldn't find any invoices.`,
  text: `Try adjusting your filters`,
}

export const statement = {
  header: `We couldn't find any statements`,
  text: `Try adjusting your filters`,
}

export const basket = {
  header: `Your shopping cart is empty`,
  text: `Add some products via app`,
}

export const orderedProducts = {
  header: `We couldn't find any products.`,
  text: `Try adjusting your filters`,
}

export const spendOverTime = {
  header: `We couldn't find any analytics.`,
  text: `Try adjusting your filters`,
}

export const catalogue = {
  header: `We couldn't find any products.`,
}

export const venueProducts = {
  header: `We couldn't find any products.`,
  textProduct: `Please add some product in your catalogue tab`,
}
