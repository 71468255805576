export const VAT_RATE = 1.2
export const VAT_PERCENTAGE = 0.2

const config = {
  apiGateway: {
    REGION: 'eu-west-2',
    URL: process.env.REACT_APP_API_ENDPOINT,
  },
  cognito: {
    REGION: 'eu-west-2',
    OLD_REGION: 'us-east-1',
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    USER_POOL_OLD_ID: process.env.REACT_APP_USER_POOL_OLD_ID,
    APP_CLIENT_OLD_ID: process.env.REACT_APP_APP_CLIENT_OLD_ID,
    IDENTITY_POOL_OLD_ID: process.env.REACT_APP_IDENTITY_POOL_OLD_ID,
  },
  sentry: {
    DNS: 'https://06b14f2a306e4ad8b284c434a233205c@o967398.ingest.sentry.io/5951454',
  },
}

export default config
