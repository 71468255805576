import { ShoppingCartOutlined } from '@ant-design/icons'
import { Button, Layout, Tooltip } from 'antd'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import { BodyText, H4 } from 'src/branding/typography'
import { notifications } from 'src/features/ChecklistSlice'
import { getVenue } from 'src/features/VenueSlice'
import styled from 'styled-components/macro'
import Talk from 'talkjs'
import { fetchOrders } from '../../features/OrdersSlice'

const DashboardWrapper = ({ children, venue, isAdmin, isTFRAdmin }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch('/v/:venueId')
  const venueId = match?.params?.venueId || null
  const isVenuesPage = location.pathname === '/venues'
  const isVenue = location.pathname.includes('/v/')
  const [previous, setPrevious] = useState(null)
  const productsInBasket = useSelector(state => state.orders.basket).length
  const {
    addressLine1,
    addressLine2,
    addressCity,
    addressPostCode,
    phoneNumber,
    title,
    code,
  } = venue || {}

  const hasAllSettings =
    venue &&
    addressLine1 &&
    addressLine2 &&
    addressCity &&
    addressPostCode &&
    phoneNumber

  useEffect(() => {
    if (!venue && venueId) {
      dispatch(getVenue(venueId))
    }
  }, [dispatch, venue, venueId])
  useEffect(() => {
    dispatch(fetchOrders(venueId))
  }, [])

  const handleOnClick = event => {
    history.push(isVenuesPage ? '/admin' : '/venues')
    event.target.blur()
  }

  const onOverViewClick = event => {
    history.push('/overview')
    event.target.blur()
  }

  const goToBasket = event => {
    history.push(`/v/${venueId}/purchasing/basket`)
    event.target.blur()
  }

  const handleConversation = async () => {
    Talk.ready
      .then(() => {
        const me = new Talk.User({
          id: 'oms-support',
          name: 'Orders Made Simple Support',
          welcomeMessage: 'Hey there! How are you? :-)',
          role: 'default',
        })

        if (previous) {
          previous.destroy()
        }

        const session = new Talk.Session({
          appId: 'GR4DzdvG',
          me: me,
        })

        const other = new Talk.User({
          id: venue?._id,
          name: venue?.title,
          welcomeMessage: 'Hey there! Send us a message to start a chat 😁',
          role: 'venues',
        })

        const conversation = session.getOrCreateConversation(
          Talk.oneOnOneId(me, other),
        )

        conversation.setParticipant(me)
        conversation.setParticipant(other)

        const popup = session.createPopup()

        setPrevious(popup)
        popup.select(conversation)
        popup.onClose(() => popup.destroy())
        popup.onSendMessage(() => {
          // ... make backend request here
          try {
            notifications(venue?._id, 'OMS Support')
          } catch (e) {
            console.log(e)
          }
        })
        popup.mount({ show: true })
        return
      })
      .catch(err => console.log(err))
  }

  return (
    <Layout>
      <Header>
        <LeftHeader>
          <Link to='/'>
            <Logo src={OMSLogo} alt='' />
          </Link>
        </LeftHeader>

        <MidHeader>
          <VenueTitle
            style={{
              fontWeight: '900',
              fontSize: '23px',
            }}
          >
            {venue
              ? title
              : isVenuesPage
              ? 'Venues'
              : isAdmin || isTFRAdmin
              ? 'Admin'
              : ''}
          </VenueTitle>
          <BodyText>
            {venue ? (
              hasAllSettings && code ? (
                <>
                  Venue code: <Mono>{code}</Mono>
                </>
              ) : (
                <VenueCode>
                  Complete your settings to obtain your venue code
                </VenueCode>
              )
            ) : (
              ''
            )}
          </BodyText>
        </MidHeader>

        <RightHeader>
          {isVenue && (
            <ContainerBtn>
              <Tooltip placement='bottomRight' title={'Shopping cart'}>
                <ShopButton
                  size='large'
                  ghost
                  style={{ borderWidth: '2px' }}
                  onClick={event => goToBasket(event)}
                >
                  <ShoppingCartOutlined style={{ fontSize: '30px' }} />
                </ShopButton>
              </Tooltip>
              {productsInBasket > 0 && (
                <AmountOfProducts>{productsInBasket}</AmountOfProducts>
              )}
            </ContainerBtn>
          )}
          {(isAdmin || isTFRAdmin) && isVenue && (
            <CTAButton
              size='large'
              ghost
              style={{ borderWidth: '2px' }}
              onClick={handleConversation}
            >
              Message customer
            </CTAButton>
          )}
          {!match && (
            <CTAButton
              size='large'
              ghost
              onClick={() =>
                window.open(
                  'https://ordersmadesimple.com/get-100-refer-a-friend',
                  '_blank',
                )
              }
            >
              {'Refer & Get £100'}
            </CTAButton>
          )}

          {isVenuesPage && (
            <CTAButton
              size='large'
              ghost
              onClick={event => onOverViewClick(event)}
            >
              {'Overview'}
            </CTAButton>
          )}

          {(isAdmin || !isVenuesPage) && (
            <CTAButton
              size='large'
              style={{ borderWidth: '2px' }}
              ghost
              onClick={event => handleOnClick(event)}
            >
              {isVenuesPage ? 'Admin' : 'Back to venues'}
            </CTAButton>
          )}
          <CTAButton
            size='large'
            onClick={() => Auth.signOut().then(() => history.push('/login'))}
          >
            Sign out
          </CTAButton>
        </RightHeader>
      </Header>

      <Content>{children}</Content>
    </Layout>
  )
}

const Header = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 104px !important;
  background: ${({ theme }) => theme.colours.bbqBlack} !important;
  padding: 0 ${({ theme }) => theme.spacing.xxl} !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 150px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.md} !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 190px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
`

const Logo = styled.img`
  max-height: 100%;
  height: 80px;
  margin-top: 4px;
`

const Content = styled(Layout.Content)`
  min-height: calc(100vh - 100px) !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-height: calc(100vh - 150px) !important;
  }
`

const LeftHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: center;
  width: 82px;
  /* background: beige; */
`

const RightHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  align-items: center;
  max-width: 260px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
  }
`

const MidHeader = styled.div`
  display: flex;
  position: absolute;
  max-width: 268px;
  left: 50%;
  transform: translate(-50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 75%;
    position: relative;
    max-width: 100%;
    left: 0;
    transform: translate(0%);
    justify-content: center;
    padding-right: 16px;
  }
  @media (max-width: 1480px) and (min-width: 1291px) {
    left: 40vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: flex-end;
  }
`

const VenueTitle = styled(H4)`
  text-align: center;
  color: ${({ theme }) => theme.colours.lettuceGreen};
`

const Mono = styled.span`
  font-family: monospace;
  color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: relative;
    left: 0;
    transform: translate(0%);
    justify-content: center;
    padding-right: 0px;
  }
`

const CTAButton = styled(Button)`
  height: 48px !important;
  margin-left: ${({ theme }) => theme.spacing.sm};
`

const ShopButton = styled(Button)`
  height: 48px !important;
  margin-left: ${({ theme }) => theme.spacing.sm};
  display: flex;
  justify-content: center;
  align-items: center;
`

const VenueCode = styled.div`
  max-width: 220px;
  text-align: center;
  color: ${({ theme }) => theme.colours.mayoWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 100%;
  }
`
const ContainerBtn = styled.div`
  position: relative;
`

const AmountOfProducts = styled.span`
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  position: absolute;
  right: 6px;
  top: 6px;
  padding: 3px 7px;
  line-height: initial;
  border-radius: 50%;
  font-size: 12px;
  color: ${({ theme }) => theme.colours.mayoWhite};
`

export default DashboardWrapper
