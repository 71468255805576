// web to convert pixels to rem
// https://www.ninjaunits.com/converters/pixels/pixels-rem/

const spacing = {
  tiny: '0.25rem', // '4px',
  xxs: '0.5rem', // '8px',
  xs: '0.75rem', // '12px',
  sm: '1rem', // '16px',
  md: '1.25rem', // '20px',
  lg: '2rem', // '32px',
  xl: '2.5rem', // '40px',
  xxl: '3.75rem', // '60px',
  mega: '6.25rem', // '100px',
}

// https://www.rapidtables.com/web/color/RGB_Color.html to transform hexadecimal to rgba

const colours = {
  bbqBlack: '#000000',
  seaweedGreen: '#132d07',
  lettuceGreen: '#b5d922',
  avocadoGreen: '#B4D820',
  //09/2022 Old avocadoGreen: '#a8cc53',
  oliveGrey: '#919191',
  panSilver: '#e0dfdf',
  bgGrey: '#f2f2f2',
  mayoWhite: '#FFFFFF',
  chilliRed: '#ff4d4f',
  orange: '#FB9F49',
  ordersTabGrey: '#f8f8f8',
}

const breakpoints = {
  xs: 360,
  sm: 470,
  md: 769,
  lg: 1023,
  xl: 1290,
}

const device = size => style =>
  `@media (min-width: ${breakpoints[size]}px){ ${style} }`

export const theme = {
  colours,
  spacing,
  breakpoints,
  device,
}
