import dayjs from 'dayjs'
import { Button } from 'antd'
import { CSVLink } from 'react-csv'

const StockTakeCSV = ({ stockTake, stockTakeProducts }) => {
  const rangeInString = `${dayjs(stockTake?.fromPeriod).format(
    'YYYY-MM-DD',
  )} - ${dayjs(stockTake?.toPeriod).format('YYYY-MM-DD')}`
  const tableHeaders = [
    { key: 'id' },
    { key: 'title' },
    { key: 'type' },
    { key: 'size' },
    { key: 'UoM' },
    { key: 'supplier' },
    { key: 'price' },
    { key: 'sku' },
    { key: 'prevQuantity' },
    { key: 'received' },
    { key: 'inStock' },
    { key: 'wastage' },
  ]

  const titleOfTable = `Stock take for ${rangeInString}`
  const dataOfTable = []
  dataOfTable.push({ title: titleOfTable })
  dataOfTable.push({})
  dataOfTable.push({
    id: 'id',
    title: 'Title',
    type: 'Type',
    size: 'Size',
    UoM: 'UoM',
    supplier: 'Supplier',
    price: 'Price',
    sku: 'SKU',
    prevQuantity: 'Prev quantity',
    received: 'Received',
    inStock: 'In stock',
    wastage: 'Wastage',
  })

  stockTakeProducts?.forEach(product => {
    const {
      _id: id,
      title,
      type,
      size,
      UoM,
      supplierTitle,
      price,
      sku,
      lastQuantity,
      qtySinceLastStockTake,
      inStock,
      wastage,
    } = product
    dataOfTable.push({
      id,
      title: title.replace(/,/g, '-'),
      type,
      size,
      UoM,
      supplier: supplierTitle.replace(/,/g, '-'),
      price,
      sku,
      prevQuantity: lastQuantity,
      received: qtySinceLastStockTake,
      inStock,
      wastage,
    })
  })

  return (
    <Button style={{ marginLeft: '15px', marginRight: '15px' }}>
      <CSVLink
        data={dataOfTable}
        headers={tableHeaders}
        filename={`Stock_take_${rangeInString}.csv`}
      >
        Download CSV
      </CSVLink>
    </Button>
  )
}

export default StockTakeCSV
