import React, { useEffect, useState } from 'react'
import { AutoComplete, Button, DatePicker, Table, message, Input } from 'antd'
import TableColumn from './TableColumn'
import styled from 'styled-components/macro'

const { Option } = AutoComplete

const AddInvoiceModal = ({
  showModal,
  order,
  setOrder,
  suppliers,
  products,
}) => {
  const [supplierId, setSupplierId] = useState('')
  const [filteredSupplier, setFilteredSupplier] = useState(suppliers)
  const [supplierProducts, setSupplierProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [orderedDate, setOrderedDate] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [canAddProduct, setCanAddProduct] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [poNumber, setPONumber] = useState('')

  const handleSearch = value => {
    setSupplierId('')
    setFilteredSupplier(
      suppliers.filter(supplier =>
        supplier.supplierTitle.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  }

  useEffect(() => {
    setFilteredSupplier(suppliers)
  }, [showModal])

  useEffect(() => {
    if (!showModal) {
      setSupplierId('')
      setSelectedProducts([])
      setOrderedDate(null)
      setDeliveryDate(null)
    }
  }, [showModal])

  const handleAddProduct = () => {
    const newProd = {
      key: new Date().getTime().toString(),
      orderedDate,
      deliveryDate,
      product: '',
    }
    setSelectedProducts([...selectedProducts, newProd])
  }

  const handleSelect = value => {
    const supplier = suppliers.find(s => s.supplier._id === value)
    setSupplierId(value)
    setSelectedSupplier(supplier)
    setOrder({ ...order, supplier: value })
  }
  const selectOrderedDate = date => {
    setOrderedDate(date)
    setOrder({ ...order, orderedDate: date })
  }

  const selectDeliveryDate = date => {
    const day = date.format('ddd').toLowerCase()
    if (
      selectedSupplier &&
      !selectedSupplier.supplier.deliveryDays.includes(day)
    ) {
      message.error("Selected date is not within the supplier's delivery days.")
      setDeliveryDate(null)
      setOrder({ ...order, deliveryDate: null })
    } else {
      setDeliveryDate(date)
      setOrder({ ...order, deliveryDate: date })
    }
  }

  const isEmpty = selectedProducts.length !== 0

  useEffect(() => {
    setOrder({ ...order, products: selectedProducts })
  }, [JSON.stringify(selectedProducts)])

  useEffect(() => {
    orderedDate &&
    deliveryDate &&
    selectedProducts.length < supplierProducts.length
      ? setCanAddProduct(true)
      : setCanAddProduct(false)
  }, [supplierProducts, orderedDate, deliveryDate, selectedProducts])

  useEffect(() => {
    setSupplierProducts(
      products
        .filter(product => product.product.supplier === supplierId)
        .map(venueProd => venueProd.product),
    )
  }, [supplierId])

  useEffect(() => {
    setFilteredProducts(supplierProducts)
  }, [supplierProducts])

  return (
    <div>
      <OrderHeader>
        {showModal && (
          <>
            <Input
              style={{ width: '25%' }}
              placeholder='PO Number'
              value={poNumber}
              onChange={event => {
                setPONumber(event.target.value)
                setOrder({ ...order, PONumber: poNumber })
              }}
            />
            <AutoComplete
              disabled={isEmpty}
              onSearch={handleSearch}
              onSelect={handleSelect}
              notFoundContent={'Supplier not found'}
              placeholder={'Choose your supplier'}
            >
              {filteredSupplier.map(supplier => (
                <Option key={supplier._id} value={supplier.supplier._id}>
                  {supplier.supplierTitle} &nbsp; &nbsp;
                  {supplier?.supplier?.secondTitle}
                </Option>
              ))}
            </AutoComplete>
            <DatePicker
              disabled={!supplierId || isEmpty}
              onChange={selectOrderedDate}
              placeholder={'Choose ordered date'}
              value={orderedDate}
            />
            <DatePicker
              disabled={!supplierId || isEmpty}
              onChange={selectDeliveryDate}
              placeholder={'Choose delivery date'}
              value={deliveryDate}
            />
          </>
        )}
      </OrderHeader>
      <RigthButton>
        <Button
          disabled={!canAddProduct}
          type={'primary'}
          onClick={handleAddProduct}
        >
          Add product
        </Button>
      </RigthButton>
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
        }}
        dataSource={selectedProducts.map((prod, index) => ({
          index: index,
          ...prod,
        }))}
        pagination={false}
        columns={TableColumn(
          supplierProducts,
          setSupplierProducts,
          filteredProducts,
          setFilteredProducts,
          selectedProducts,
          setSelectedProducts,
        )}
      />
    </div>
  )
}

export default AddInvoiceModal

const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
const RigthButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`
