import moment from 'moment'
import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Button, Popconfirm, Spin } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
import { deleteInvoice } from '../../features/NewInvoicesSlice'
import { useDispatch } from 'react-redux'

const InvoicesColumns = (
  venueId,
  downloadInvoice,
  downloadingInvoice,
  isAdmin,
  setEditPOModal,
) => {
  const dispatch = useDispatch()

  return [
    {
      title: 'Status',
      dataIndex: 'checked',
      key: 'checked',
      width: '5%',
      render: () => {
        // const menu = (
        //   <Menu>
        //     <Menu.Item
        //       key='1'
        //       onClick={() => {
        //         updateInvoice(invoice.id, 'checked', !status)
        //       }}
        //     >
        //       {status ? 'Make unchecked' : 'Make checked'}
        //     </Menu.Item>
        //   </Menu>
        // )
        return (
          // <Dropdown overlay={menu} trigger={['click']}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 30,
              height: 30,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#b5d922',
              //background: status ? '#b5d922' : '#FB9F49',
            }}
          >
            <CheckOutlined style={{ fontSize: '16px', color: 'white' }} />
          </div>
          // </Dropdown>
        )
      },
    },
    {
      title: 'Uploaded Date',
      dataIndex: 'uploadedDate',
      key: 'uploaded_date',
      width: '10%',
      render: dateTime => {
        const date = moment(dateTime).format('DD-MM-YYYY')
        return date
      },
    },
    {
      title: 'Uploaded By',
      dataIndex: 'username',
      key: 'uploaded_by',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'fileName',
      key: 'file_name',
      width: '15%',
      render: value => {
        const result = value.split('/').pop()
        return result
      },
    },
    // {
    //   title: 'Supplier',
    //   dataIndex: 'supplierTitle',
    //   key: 'supplier_title',
    //   width: '15%',
    //   render: supplier => {
    //     return (
    //       <AutoCompleteFilter
    //         custom={supplier}
    //         initialSuppleirs={venueSuppliers}
    //       />
    //     )
    //   },
    // },
    {
      title: 'PO Number',
      dataIndex: 'purchaseOrderNumber',
      key: 'purchaseOrderNumber',
      width: '10%',
      render: (value, invoice) => {
        return (
          <StyledPOField>
            <p style={{ marginBottom: 0 }}>{value}</p>
            {isAdmin && (
              <EditOutlined onClick={() => setEditPOModal(invoice)} />
            )}
          </StyledPOField>
        )
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: '10%',
    },
    {
      title: 'Actions',
      dataIndex: 'invoiceUrl',
      key: 'invoiceUrl',
      width: '10%',
      render: (value, invoice) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <a
              style={{
                padding: 10,
              }}
              target='_blank'
              rel='noopener noreferrer'
              href={`/invoice/${venueId}/${invoice.id}`}
            >
              View
            </a>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {downloadingInvoice === invoice.id ? (
                <Spin size={'small'} />
              ) : (
                <DownloadOutlined onClick={() => downloadInvoice(invoice.id)} />
              )}
            </div>

            {isAdmin && (
              <Popconfirm
                title='Are you sure to delete the invoice?'
                placement='topRight'
                onConfirm={() => {
                  dispatch(deleteInvoice(invoice.id))
                }}
                okText='Yes'
                cancelText='No'
              >
                <StyledButton type={'danger'}>
                  <DeleteOutlined />
                </StyledButton>
              </Popconfirm>
            )}
          </div>
        )
      },
    },
  ]
}

const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledPOField = styled.div`
  margin: 3px 3px 3px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

export default InvoicesColumns
