import {Card, DatePicker, Table, TreeSelect} from 'antd'
import Dinero from 'dinero.js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import FadeIn from 'src/components/styled/FadeIn'
import { fetchPastOrdersSpending } from 'src/features/OverViewSlice'
import styled from 'styled-components/macro'
import moment from "moment/moment";

const PastOrdersSpending = () => {
  const dispatch = useDispatch()

  const { pastOrdersSpending, paginationSpending, totalCountSpending } = useSelector(
    state => state.overView,
  )
  const dateFormat = 'YYYY-MM-DD'
  const twoWeekRange = [moment().subtract(14, 'days'), moment()]
  const [selectedDates, setSelectedDates] = useState([twoWeekRange[0].format(dateFormat), twoWeekRange[1].format(dateFormat)]);

  const supplierTitles = pastOrdersSpending.map(current => current.supplierTitle)
  const loading = useSelector(state => state.supplierOrder.loading)
  const myVenues = useSelector(state => state.auth.account.myvenues)

  const venueOptions = myVenues.map(item => {
    return {
      title: item.title,
      value: item._id,
    }
  })
  const allIds = venueOptions.map(item => item.value)
  const [selectedVenueIds, setSelectedVenuesIds] = useState(allIds)

  useEffect(() => {
    dispatch(fetchPastOrdersSpending(
        selectedVenueIds,
        { page: 1, limit: paginationSpending.limit},
        twoWeekRange[0].format(dateFormat),
        twoWeekRange[1].format(dateFormat)
    ))
  }, [selectedVenueIds])

  const stringSort = (a, b) => String(a).trim().localeCompare(String(b).trim())

  const disableEndDate = current => current.valueOf() > moment.now()

  const onDateSelection = (value, dateString) => {
    dispatch(fetchPastOrdersSpending(
        selectedVenueIds,
        { page: 1, limit: paginationSpending.limit },
        dateString[0],
        dateString[1]
    ))
    setSelectedDates(dateString);
  }

  return (
    <Wrapper>
      <HeaderScreen title={"Spending"}>
        <ActionCard>
          <h4>Select Dates</h4>
          <StyledRangePicker
              disabledDate={disableEndDate}
              format={dateFormat}
              defaultValue={twoWeekRange}
              onChange={onDateSelection}
          />
          <h4>Select Venues</h4>
          <TreeSelect
            treeNodeFilterProp='title'
            allowClear={true}
            placeholder='Select a venue'
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            dropdownStyle={{ maxHeight: '250px', width: '120px' }}
            style={{ marginBottom: '15px' }}
            onChange={ids => setSelectedVenuesIds(ids)}
            value={selectedVenueIds}
            maxTagCount={1}
            maxTagPlaceholder={omittedValues =>
              `+ ${omittedValues.length} Venues ...`
            }
            treeData={[
              {
                title:
                  selectedVenueIds.length > 0 ? (
                    <span
                      onClick={() => setSelectedVenuesIds([])}
                      style={{
                        display: 'inline-block',
                        color: '${({ theme }) => theme.colours.bbqBlack}',
                        cursor: 'pointer',
                      }}
                    >
                      Unselect all
                    </span>
                  ) : (
                    <span
                      onClick={() => setSelectedVenuesIds(allIds)}
                      style={{
                        display: 'inline-block',
                        color: '${({ theme }) => theme.colours.bgGrey}',
                        cursor: 'pointer',
                      }}
                    >
                      Select all
                    </span>
                  ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...venueOptions,
            ]}
          />
        </ActionCard>
      </HeaderScreen>
      <Br />
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: false,
        }}
        dataSource={pastOrdersSpending.map(data => ({
          ...data,
          key: data._id,
        }))}
        loading={loading}
        pagination={{
          position: 'both',
          total: totalCountSpending,
          pageSize: paginationSpending.limit,
          pageSizeOptions:  ['10', '20', '50', '100'],
          showSizeChanger: true,
          current: paginationSpending.page,
          onChange: page => {
            dispatch(fetchPastOrdersSpending(
                selectedVenueIds,
                { page: page, limit: paginationSpending.limit },
                selectedDates[0],
                selectedDates[1],
            ))
          },
          onShowSizeChange: (_, pageSize) => {
            dispatch(fetchPastOrdersSpending(
                selectedVenueIds,
                { page: 1, limit: pageSize},
                selectedDates[0],
                selectedDates[1],
            ))
          },
          showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} results`,
        }}
        columns={[
          {
            title: 'Supplier',
            dataIndex: 'supplierTitle',
            key: 'supplierTitle',
            className: 'restrictShrink',
            filters: supplierTitles.map(supplier => ({
              text: supplier,
              value: supplier,
            })),
            onFilter: (value, record) => record?.supplierTitle === value,
            sorter: (a, b) => stringSort(a.supplierTitle, b.supplierTitle),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Total orders',
            dataIndex: 'totalOrders',
            key: 'totalOrders',
            className: 'restrictShrink',
            sorter: (a, b) => a.totalSpend - b.totalSpend,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Total venues ordering',
            dataIndex: 'totalVenuesOrders',
            key: 'totalVenuesOrders',
            className: 'restrictShrink',
            sorter: (a, b) => a.totalSpend - b.totalSpend,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Total spend',
            dataIndex: 'totalSpend',
            key: 'totalSpend',
            className: 'restrictShrink',
            sorter: (a, b) => a.totalSpend - b.totalSpend,
            sortDirections: ['ascend', 'descend'],
            render: creditLimit =>
              Dinero({
                amount: parseInt(creditLimit) || 0,
                currency: 'GBP',
              }).toFormat('$0,0.00'),
          },
        ]}
      />
    </Wrapper>
  )
}
const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 95px;
  }
`
const Br = styled.br`
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
  }
  margin-bottom: 15px !important;
`

const ActionCard = styled(Card)`
  background: ${({ theme }) => theme.colours.mayoWhite};
  margin: ${({ theme }) => theme.spacing.sm} 0 !important;
  align-self: flex-end;
  border-radius: 5px !important;
  width: calc(30% - 35px);
  > .ant-card-body {
    display: flex;
    flex-direction: column;
  }
  input,
  .ant-select-selection--multiple {
    background: ${({ theme }) => theme.colours.bgGrey};
  }
`

export default PastOrdersSpending
