import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

// DEPRECATED

const venueCatalogueSlice = createSlice({
  name: 'venueCatalogue',
  initialState: {
    products: [],
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
  },
})

export const { setProducts } = venueCatalogueSlice.actions

export const fetchVenueCatalogue = venueId => async dispatch => {
  try {
    const _venueCatalogue = await API.get('api', `venue/${venueId}/catalogue`)
    dispatch(setProducts(_venueCatalogue))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default venueCatalogueSlice
