import { ResponsivePie } from '@nivo/pie'
import React from 'react'

const BarChart = ({ data, types }) => {
  return (
    <ResponsivePie
      data={data}
      keys={types}
      indexBy='supplier'
      margin={{ top: 40, right: 40, bottom: 40, left: 0 }}
      innerRadius={0.02}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      orderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor='#000'
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: '#000000',
        modifiers: [['darker', 3]],
      }}
      legends={[
        {
          itemOpacity: 0,
        },
      ]}
    />
  )
}

export default BarChart
