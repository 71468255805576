import React from 'react'
import moment from 'moment/moment'
import { Button, message, Tag, Tooltip } from 'antd'
import styled from 'styled-components/macro'
import { CheckSquareFilled, CopyOutlined, SaveFilled } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PDFExportModal from './PDFExportModal'

const dateFormat = 'DD/MM/YYYY'

const OrdersColumns = (
  setModalVisible,
  setCurrentRecord,
  setModalSend,
  pdfModal,
  setPDFModal,
  venueId,
  venueTitle,
) => {
  return [
    {
      title: 'Supplier',
      dataIndex: 'supplier.title',
      className: 'restrict-shrink',
      width: '30%',
      render: (supplierTitle, record) => {
        const isDataplus = record?.dataplus?.sent

        const handleCopy = () => {
          message.success('Copied to clipboard')
        }

        return (
          <>
            <SectionWrap>
              <div>
                <div>{supplierTitle}</div>
                <div>{record.purchaseOrderNumber}</div>
                {isDataplus && (
                  <>
                    <div>
                      <Text>Invoice Number:</Text>{' '}
                      {record.dataplus.invoiceNumber}
                    </div>
                    <div>
                      <Text>Invoice Date:</Text>{' '}
                      {moment(record.dataplus.invoiceDate).format(dateFormat)}
                    </div>
                  </>
                )}
              </div>

              <AdditionalDataplusActions>
                <CopyToClipboard
                  text={record.purchaseOrderNumber}
                  onCopy={handleCopy}
                >
                  <CopyButton type={'primary'}>
                    <CopyOutlined style={{ fontSize: '20px' }} />
                  </CopyButton>
                </CopyToClipboard>

                {isDataplus && (
                  <Button
                    type={'primary'}
                    onClick={() =>
                      setPDFModal(
                        `${record.purchaseOrderNumber}${record.supplier._id}`,
                      )
                    }
                  >
                    Get PDF
                  </Button>
                )}
              </AdditionalDataplusActions>
              {isDataplus && (
                <PDFExportModal
                  isOpen={pdfModal}
                  onClose={() => setPDFModal(null)}
                  record={record}
                  venueId={venueId}
                  venueTitle={venueTitle}
                />
              )}
            </SectionWrap>
          </>
        )
      },
    },
    {
      title: 'Ordered Amount',
      dataIndex: 'totalSpendOrdered',
      className: 'restrict-shrink',
      width: '10%',
      render: total => `£${(total / 100).toFixed(2)}`,
    },
    {
      title: 'Supplier Amount',
      dataIndex: 'totalSpendReceived',
      className: 'restrict-shrink',
      width: '10%',
      render: total => `£${(total / 100).toFixed(2)}`,
    },
    {
      title: 'Placed',
      dataIndex: 'placedVia',
      className: 'restrict-shrink',
      width: '15%',
      render: placed => (placed ? placed : 'Placed using venue code'),
    },
    {
      title: 'Ordered date',
      dataIndex: 'orderedDate',
      className: 'restrict-shrink',
      width: '10%',
      render: date => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Order status',
      dataIndex: 'status',
      className: 'restrict-shrink',
      width: '10%',
      render: status => (
        <Tag
          color={
            status === 'Confirmed'
              ? 'green'
              : status === 'Changes'
              ? 'magenta'
              : 'orange'
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      className: 'restrict-shrink',
      width: '20%',
      render: (_, record) => {
        const isDataplusSent =
          record?.dataplus?.sent || record?.dataplus?.status === 'Prepared'

        const title = isDataplusSent ? 'Attached invoices' : 'Attach invoices'

        return record.status === 'Received' ? (
          'Waiting on Supplier Approval'
        ) : (
          <ActionWrapper>
            <Tooltip placement='top' title={title}>
              <Button
                type={'primary'}
                onClick={() => {
                  setModalVisible(true)
                  setCurrentRecord(record)
                }}
              >
                <SaveFilled style={{ fontSize: '20px' }} />
              </Button>
            </Tooltip>
            <Tooltip placement='top' title={'Confirm and Send'}>
              <Button
                type={'primary'}
                disabled={isDataplusSent}
                onClick={() => {
                  setCurrentRecord(record)
                  setModalSend(true)
                }}
              >
                <CheckSquareFilled style={{ fontSize: '20px' }} />
              </Button>
            </Tooltip>
          </ActionWrapper>
        )
      },
    },
    {
      className: 'restrict-shrink',
      title: 'Integration Status',
      dataIndex: 'orderDataplusStatus',
      width: '15%',
      render: orderDataplusStatus => {
        return (
          <Tag color={orderDataplusStatus === 'Sent' ? 'green' : 'orange'}>
            {orderDataplusStatus}
          </Tag>
        )
      },
    },
  ]
}

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 200px;
  flex-wrap: wrap;
`

const Text = styled.span`
  font-weight: bold;
`

const SectionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const AdditionalDataplusActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CopyButton = styled(Button)`
  width: max-content;
  margin-bottom: 10px;
`
export default OrdersColumns
