import { Table } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import iconCaret from 'src/assets/images/icon-caret.svg'
import iconClose from 'src/assets/images/icon-close.svg'
import { clearPriceNotification } from 'src/features/ProductSlice'
import styled from 'styled-components/macro'

const ChangedPricesTable = ({ venueProducts, uniqueProductSuppliers }) => {
  const dispatch = useDispatch()
  const accountId = useSelector(state => state.auth.account._id)
  const isPriceChangesVisible = useSelector(
    state => state.venue.venue.isPriceChangesVisible,
  )
  const [tableCollapsed, setTableCollapsed] = useState(true)

  const updatedPrices = venueProducts.filter(vp => {
    /* Filter list of products down to just those which have had price updates in last 14 days, and which haven't been cleared by user */
    if (
      vp.product &&
      vp.product.priceHistory &&
      vp.product.priceHistory.length
    ) {
      const priceLastUpdated = dayjs(vp.product.priceHistory[0].updatedAt)
      const diff = dayjs().diff(priceLastUpdated, 'days')

      const notificationClearedBy = vp.product.priceNotificationClearedBy.map(
        el => el._id,
      )

      if (diff < 14 && !notificationClearedBy.includes(accountId)) {
        return vp.product
      }
    }
  })

  if (
    updatedPrices.length &&
    (isPriceChangesVisible === undefined || isPriceChangesVisible === true)
  ) {
    return (
      <Wrapper>
        <div
          className='header'
          onClick={() => setTableCollapsed(!tableCollapsed)}
        >
          <h4>
            In the last 14 days, prices have changed for{' '}
            <strong>{updatedPrices.length} products</strong> that are currently
            within your venue
          </h4>
          <img
            className={`collapse ${tableCollapsed ? 'flipped' : ''}`}
            src={iconCaret}
            alt='Collapse Table'
          />
        </div>
        {!tableCollapsed && (
          <Table
            scroll={{
              scrollToFirstRowOnChange: true,
              x: true,
            }}
            dataSource={updatedPrices.map(product => ({
              key: product._id,
              ...product,
            }))}
            pagination={false}
            loading={updatedPrices.length < 1}
            columns={[
              {
                title: 'Product',
                dataIndex: 'title',
                key: 'title',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.title).trim().localeCompare(String(b.title).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              {
                title: 'Size',
                dataIndex: 'product.size',
                key: 'product.size',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.size).trim().localeCompare(String(b.size).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              {
                title: 'Supplier',
                dataIndex: 'supplierTitle',
                key: 'supplier.title',
                className: 'restrictShrink',
                filters: uniqueProductSuppliers.map(supplier => ({
                  text: supplier,
                  value: supplier,
                })),
                onFilter: (value, record) => record?.supplier?.title === value,
              },
              {
                title: 'SKU',
                dataIndex: 'product.sku',
                key: 'product.sku',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.product.sku)
                    .trim()

                    .localeCompare(String(b.product.sku).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              {
                title: 'Price Change',
                dataIndex: 'product.price',
                key: 'price',
                className: 'restrictShrink',
                render: (text, item) => {
                  return (
                    <span className='price-change'>
                      <span className='was'>
                        Was £
                        {Number(
                          item.product.priceHistory[0].formerPrice / 100,
                        ).toFixed(2)}
                        {item.product.hasVat ? ' + VAT' : ''}
                        {item.product.priceHistory[0].formerPrice / 100 >
                        text ? (
                          <span className='icon red'>▼</span>
                        ) : (
                          <span className='icon green'>▲</span>
                        )}
                      </span>
                      Now £{text}
                      {item.product.hasVat ? ' + VAT' : ''}
                    </span>
                  )
                },
              },
              {
                key: 'action',
                className: 'restrictShrink alignRight',
                render: (text, item) => {
                  return (
                    <img
                      src={iconClose}
                      alt='Clear'
                      className='clearButton'
                      onClick={() => {
                        dispatch(
                          clearPriceNotification(item.product, accountId),
                        )
                      }}
                    />
                  )
                },
              },
            ]}
          />
        )}
      </Wrapper>
    )
  }

  return false
}

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colours.bgGrey};
  padding-left: ${({ theme }) => theme.spacing.md};
  padding-right: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  border-radius: 5px;
  margin: ${({ theme }) => theme.spacing.md} auto
    ${({ theme }) => theme.spacing.md} auto;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    height: 80px;

    h4 {
      font-size: 17px;
      font-weight: normal;
      margin: 0;
    }
    .collapse {
      transition: 150ms;
      &.flipped {
        transform: rotate(180deg);
      }
    }
  }

  .ant-table-wrapper {
    margin-top: ${({ theme }) => theme.spacing.md};
  }

  .price-change {
    .was {
      opacity: 0.3;
    }
    .icon {
      margin: 0 ${({ theme }) => theme.spacing.xxs};
      font-size: 13px;
    }
  }

  .alignRight {
    text-align: right;
  }

  .clearButton {
    cursor: pointer;
  }

  .restrictShrink {
    min-width: 95px;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
`

export default ChangedPricesTable
