import styled from 'styled-components/macro'
import React from 'react'
import moment from 'moment'

function createPDFTemplate(PONumber, orders, record, venueTitle) {
  if (!orders || !record) {
    return <></>
  }

  const dueDate = record.requiredDeliveryDay
    ? moment(record.requiredDeliveryDay).format('DD/MM/YYYY')
    : moment(record.orderedDate).format('DD/MM/YYYY')

  const calculateTax = el => {
    if (el.product.hasVat) {
      return getNetPrice(el) * 0.2 + getNetPrice(el)
    } else {
      return getNetPrice(el)
    }
  }
  const getUnitPrice = el => {
    return el.product.price
  }
  const getNetPrice = el => {
    return el.product.price * el.quantity
  }
  const calculateNetTotal = () => {
    if (!orders) {
      return 0.0
    }
    return orders.orders.reduce((accumulator, currentItem) => {
      return accumulator + getNetPrice(currentItem)
    }, 0)
  }

  const calculateTotalVat = () => {
    if (!orders) {
      return 0.0
    }
    return orders.orders.reduce((accumulator, currentItem) => {
      if (currentItem.product.hasVat) {
        return accumulator + getNetPrice(currentItem) * 0.2
      }
      return 0
    }, 0)
  }

  const calculateTotal = () => {
    if (!orders) {
      return 0.0
    }
    return orders.orders.reduce((accumulator, currentItem) => {
      return accumulator + calculateTax(currentItem)
    }, 0)
  }

  return (
    <Container>
      <Header>
        <VenueTitle>{venueTitle}</VenueTitle>

        <HeaderInfo>
          <InternalText>For Internal Use Only</InternalText>
          <HeaderInfoText>Receiving Report</HeaderInfoText>
        </HeaderInfo>
      </Header>

      <Section>
        <BlocksWrapper>
          <Block>
            <BlockTitle>Order Status</BlockTitle>
            <BlockValue>{record.status}</BlockValue>
          </Block>
          <Block>
            <BlockTitle>Invoice Date</BlockTitle>
            <BlockValue>
              {moment(record.dataplus.invoiceDate).format('DD/MM/YYYY')}
            </BlockValue>
          </Block>
          <ShadowBlock>
            <ShadowBlockTitle>Order Date</ShadowBlockTitle>
            <ShadowBlockValue>
              {moment(record.orderedDate).format('DD/MM/YYYY')}
            </ShadowBlockValue>
          </ShadowBlock>
          <Block>
            <BlockTitle>Invoice Number</BlockTitle>
            <BlockValue>{record.dataplus.invoiceNumber}</BlockValue>
          </Block>
          <ShadowBlock>
            <ShadowBlockTitle>Order Number</ShadowBlockTitle>
            <ShadowBlockValue>{PONumber}</ShadowBlockValue>
          </ShadowBlock>
        </BlocksWrapper>
      </Section>

      <Section>
        <TableContainer>
          <thead>
            <tr>
              <TableTh style={{ width: '5%' }}>Item</TableTh>
              <TableTh style={{ width: '15%' }}>Description</TableTh>
              <TableTh style={{ width: '7,5%' }}>Unit</TableTh>
              <TableTh style={{ width: '7,5%' }}>Quantity</TableTh>
              <TableTh style={{ width: '15%' }}>Due Date</TableTh>
              <TableTh style={{ width: '20%' }}>GL Code</TableTh>
              <TableTh style={{ width: '7,5%' }}>Discount</TableTh>
              <TableTh style={{ width: '7,5%' }}>Unit Price</TableTh>
              <TableTh style={{ width: '7,5%' }}>Net Price</TableTh>
              <TableTh style={{ width: '7,5%' }}>Net Inc. Tax</TableTh>
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.orders.map((el, index) => {
                return (
                  <tr key={el._id}>
                    <TableTd style={{ width: '5%' }}>{index + 1}</TableTd>
                    <TableTd style={{ width: '15%' }}>{el.title}</TableTd>
                    <TableTd style={{ width: '7,5%' }}>{el.UoM}</TableTd>
                    <TableTd style={{ width: '7,5%' }}>{el.quantity}</TableTd>
                    <TableTd style={{ width: '15%' }}>{dueDate}</TableTd>
                    <TableTd style={{ width: '20%' }}>{el.product.GLN}</TableTd>
                    <TableTd style={{ width: '7,5%' }}>0.00%</TableTd>
                    <TableTd style={{ width: '7,5%' }}>
                      {(getUnitPrice(el) / 100).toFixed(2)}
                    </TableTd>
                    <TableTd style={{ width: '7,5%' }}>
                      {(getNetPrice(el) / 100).toFixed(2)}
                    </TableTd>
                    <TableTd style={{ width: '7,5%' }}>
                      {(calculateTax(el) / 100).toFixed(2)}
                    </TableTd>
                  </tr>
                )
              })}
          </tbody>
        </TableContainer>
      </Section>

      <Section>
        <BoldRightText>
          Net Total:{(calculateNetTotal() / 100).toFixed(2)}
        </BoldRightText>
        <BoldRightText>
          VAT:{(calculateTotalVat() / 100).toFixed(2)}
        </BoldRightText>
        <BoldRightText>
          Order Total: <>{(calculateTotal() / 100).toFixed(2)}</>
        </BoldRightText>
      </Section>

      <Footer>
        <p>Notes:</p>
        <p>Copyright© 2024 of Orders Made Simple. All rights reserved.</p>
        <FlexP>
          <p>Visit </p>
          <a href='https://ordersmadesimple.com'>
            https://ordersmadesimple.com
          </a>
          <p> for more information.</p>
        </FlexP>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 40px;
  border: 1px solid #000;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const VenueTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000;
`

const HeaderInfo = styled.div`
  font-size: 24px;
  text-align: center;
`

const Block = styled.div`
  padding: 10px;
  min-height: 74px;
  border: 2px solid #9d9d9d;
  margin: 5px;
  text-align: center;
  width: 200px;
`
const BlockTitle = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`
const BlockValue = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`

const ShadowBlock = styled.div`
  padding: 10px;
  border: 1px solid black;
  background-color: #9d9d9d;
  text-align: center;
  margin: 5px;
  min-height: 74px;
  width: 200px;
  box-shadow: 5px 5px 2px 1px black;
`
const ShadowBlockTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`
const ShadowBlockValue = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
`

const InternalText = styled.p`
  font-size: 18px;
  text-align: center;
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
`
const HeaderInfoText = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`

const Footer = styled.div`
  text-align: center;
  font-size: 12px;
  color: #000;
  border-top: 2px solid #000;
  margin-top: 20px;
  padding-top: 10px;
`

const FlexP = styled.p`
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 5px;
`

const BlocksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`

const TableTh = styled.th`
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  font-size: 10px;
`

const TableTd = styled.td`
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
  font-size: 10px;
`

const BoldRightText = styled.p`
  text-align: right;
  font-weight: bold;
  margin: 5px 0;
`

export default createPDFTemplate
