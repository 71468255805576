import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, InputNumber, Modal, notification, Row, Table } from 'antd'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { GoldenFilled, LoadingOutlined } from '@ant-design/icons'
import AddProductModal from './AddProductModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchStockTakeProducts,
  saveAllChanges,
} from '../../features/StockSlice'
import dayjs from 'dayjs'
import Loading from '../../components/styled/Loading'
import { fetchVenueProducts } from '../../features/VenueProductsSlice'
import { VAT_PERCENTAGE, VAT_RATE } from '../../config'
import StockTakeCSV from './StockTakeCSV'
import Papa from 'papaparse'
import wordCaps from '../../utils/wordCaps'

const Stock = props => {
  const {
    match: {
      params: { stockTakeId, venueId },
    },
  } = props
  const stockTake = useSelector(state => state.stockTakes.stockTakes).find(
    stockTake => stockTake._id === stockTakeId,
  )
  const [addProductModalVisible, setAddProductModalVisible] = useState(false)
  const [product, setProduct] = useState({
    inStock: 0,
    type: '',
    title: '',
    size: '',
    UoM: '',
    supplierTitle: '',
    price: 0,
    lastQuantity: 0,
    OMSPrice: 0,
    wastage: 0,
    hasVat: false,
    stockTake: stockTakeId,
    qtySinceLastStockTake: 0,
  })
  const isLoading = useSelector(state => state.stockTakes.isLoading)
  const stockTakeProducts = useSelector(
    state => state.stockTakes.stockTakeProducts,
  )
  const [stockTakeProductsTypes, setProductsTypes] = useState([])
  const [products, setProducts] = useState([])
  const dispatch = useDispatch()
  const buttonLoading = useSelector(state => state.stockTakes.buttonLoading)
  const stockTakeProductsTitles = products.map(
    stockTakeProduct => stockTakeProduct.title,
  )
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      sorter: (a, b) =>
        String(a.title).trim().localeCompare(String(b.title).trim()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      className: 'smallColumn',
      sorter: (a, b) =>
        String(a.size).trim().localeCompare(String(b.size).trim()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '6%',
      filters: stockTakeProductsTypes.map(type => ({
        text: type,
        value: type.toLowerCase(),
      })),
      onFilter: (value, record) => {
        return record?.type.toLowerCase() === value
      },
    },
    {
      title: 'UoM',
      dataIndex: 'UoM',
      key: 'UoM',
      className: 'smallColumn',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierTitle',
      key: 'supplier',
      width: '10%',
      sorter: (a, b) =>
        String(a.supplierTitle)
          .trim()
          .localeCompare(String(b.supplierTitle).trim()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      className: 'centerAlign',
      render: price => {
        return `£${price}`
      },
      sorter: (a, b) =>
        (a.hasVat ? VAT_RATE * a.price : a.price) -
        (b.hasVat ? VAT_RATE * b.price : b.price),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      className: 'smallColumn',
    },
    {
      title: 'Prev qty',
      dataIndex: 'lastQuantity',
      key: 'lastQuantity',
      className: 'centerAlign smallColumn',
      sorter: (a, b) => a.lastQuantity - b.lastQuantity,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Rec'd",
      dataIndex: 'qtySinceLastStockTake',
      key: 'qtySinceLastStockTake',
      width: '95px',
      className: 'centerAlign smallColumn',
      sorter: (a, b) => a.qtySinceLastStockTake - b.qtySinceLastStockTake,
      sortDirections: ['ascend', 'descend'],
      render: item => {
        const isDecimal = Math.ceil(item) !== item
        if (isDecimal) {
          const numberWithToFixed = Number(item).toFixed(2)
          const stringifyNumber = String(numberWithToFixed)
          return stringifyNumber.endsWith('0')
            ? Number(stringifyNumber.slice(0, stringifyNumber.length - 1))
            : numberWithToFixed
        }
        return item
      },
    },
    {
      title: 'In Stock',
      key: 'inStock',
      className: 'inputColumn',
      render: product => {
        return (
          <InputNumber
            value={product.inStock}
            onChange={e => onChangeStock(product, e, 'inStock')}
            onFocus={e => e.target.select()}
            min={0}
            style={{ margin: '0, 15px' }}
          />
        )
      },
    },
    {
      title: 'Stock value',
      key: 'stockAmount',
      className: 'centerAlign',
      render: product => {
        return `£${(product.hasVat
          ? product.price * product.inStock * VAT_RATE
          : product.price * product.inStock
        ).toFixed(2)}`
      },
      sorter: (a, b) =>
        (a.hasVat ? a.price * a.inStock * VAT_RATE : a.price * a.inStock) -
        (b.hasVat ? b.price * b.inStock * VAT_RATE : b.price * b.inStock),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Wastage',
      key: 'wastage',
      className: 'inputColumn',
      render: product => {
        return (
          <InputNumber
            value={product.wastage}
            onChange={e => onChangeStock(product, e, 'wastage')}
            onFocus={e => e.target.select()}
            min={0}
          />
        )
      },
    },
    {
      title: 'Wastage value',
      key: 'wastageAmount',
      className: 'centerAlign',
      render: product => {
        return `£${(product.hasVat
          ? product.price * product.wastage * VAT_RATE
          : product.price * product.wastage
        ).toFixed(2)}`
      },
      sorter: (a, b) =>
        (a.hasVat ? a.price * a.wastage * VAT_RATE : a.price * a.wastage) -
        (b.hasVat ? b.price * b.wastage * VAT_RATE : b.price * b.wastage),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Wgt'd",
      key: 'weighted',
      className: 'centerAlign',
      render: product => {
        const includesInUoM = product.UoM
          ? product.UoM.toLowerCase() === 'kg' ||
            product.UoM.toLowerCase() === 'g'
          : false
        return includesInUoM ? <GoldenFilled /> : ''
      },
    },
    {
      title: 'VAT',
      key: 'total_vat',
      className: 'centerAlign',
      render: product => {
        const vat = product.hasVat ? product.price * VAT_PERCENTAGE : 0
        return `£${(vat * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) =>
        (a.hasVat ? a.price * VAT_PERCENTAGE * a.inStock : 0) - b.hasVat
          ? b.price * VAT_PERCENTAGE * b.inStock
          : 0,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Net',
      key: 'total_net',
      className: 'centerAlign',
      render: product => {
        return `£${(product.price * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) => a.price * a.inStock - b.price * b.inStock,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Gross',
      key: 'total_price',
      className: 'centerAlign',
      render: product => {
        const priceWithVat = product.hasVat
          ? product.price * VAT_RATE
          : product.price
        return `£${(priceWithVat * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) =>
        (a.hasVat ? VAT_RATE * a.price * a.inStock : a.price * a.inStock) -
        (b.hasVat ? VAT_RATE * b.price * b.inStock : b.price * b.inStock),
      sortDirections: ['ascend', 'descend'],
    },
  ]
  const venueProducts = useSelector(state => state.venueProducts.products)
  const [updatedProductsArray, setUpdatedProductsArray] = useState([])
  const [addedProductsArray, setAddedProductsArray] = useState([])
  const _file = useRef(null)

  useEffect(() => {
    dispatch(fetchVenueProducts(venueId))
  }, [])

  useEffect(() => {
    dispatch(fetchStockTakeProducts(venueId, stockTakeId))
  }, [dispatch, venueId])

  useEffect(() => {
    setProducts(stockTakeProducts)

    if (stockTakeProducts.length !== 0) {
      const allTruthyTypes = stockTakeProducts
        .map(product =>
          product.type
            ? wordCaps(product?.type).trim()
            : wordCaps(product?.product?.type).trim(),
        )
        .filter(type => Boolean(type))

      const uniqueProductTypes = [...new Set(allTruthyTypes)]

      setProductsTypes(uniqueProductTypes)
    }
  }, [stockTakeProducts])

  function uploadCSVFile() {
    const file = _file.current.files[0]
    if (!file) {
      alert('Choose a file first')
      return
    }

    Papa.parse(file, {
      headers: true,
      complete: function (res) {
        const data = res.data.filter((item, index) => index > 2) //delete empty fields

        let products = []

        for (let i = 1; i < data.length; i++) {
          let product = {}

          product.id = data[i][0]
          product._id = data[i][0]
          product.title = data[i][1]
          product.type = data[i][2]
          product.size = data[i][3]
          product.supplierTitle = data[i][5]
          product.price = data[i][6]
          product.sku = data[i][7]
          product.lastQuantity = Number(data[i][8])
          product.received = data[i][9]
          product.inStock = Number(data[i][10])
          product.wastage = Number(data[i][11])
          product.stockTake = stockTakeId

          products.push(product)
        }
        products = products.filter(item => item.title || item.id)

        const allChanges = {
          stockTakeId: stockTakeId,
          toAdd: [],
          toUpdate: products,
        }
        dispatch(saveAllChanges(venueId, allChanges, 'SAVE_ALL'))
      },
    })

    dispatch(fetchStockTakeProducts(venueId, stockTakeId))
  }

  const onChangeStock = (updatedProduct, value, type) => {
    if (updatedProduct._id) {
      let updatedProd = null
      let updatedArr = null
      setUpdatedProductsArray(
        updatedProductsArray.map(prod => {
          if (prod._id === updatedProduct._id) {
            updatedProd =
              type === 'wastage'
                ? { ...prod, wastage: value }
                : { ...prod, inStock: value }
            updatedArr = products.map(p => {
              if (p._id === updatedProduct._id) {
                return updatedProd
              }
              return p
            })
            return updatedProd
          }
          return prod
        }),
      )

      if (!updatedArr) {
        updatedArr = products.map(prod => {
          if (prod.id === updatedProduct._id) {
            updatedProd =
              type === 'wastage'
                ? { ...prod, wastage: value }
                : { ...prod, inStock: value }
            setUpdatedProductsArray([...updatedProductsArray, updatedProd])
            return updatedProd
          }
          return prod
        })
      }
      setProducts(updatedArr)
    } else {
      setAddedProductsArray(
        addedProductsArray.map(prod => {
          if (prod.title === updatedProduct.title) {
            setProducts(
              products.map(prod => {
                if (prod.title === updatedProduct.title) {
                  return type === 'wastage'
                    ? { ...prod, wastage: value }
                    : { ...prod, inStock: value }
                }
                return prod
              }),
            )
            return type === 'wastage'
              ? { ...prod, wastage: value }
              : { ...prod, inStock: value }
          }
          return prod
        }),
      )
    }
  }

  const handleSaveChanges = () => {
    const allChanges = {
      stockTakeId: stockTakeId,
      toAdd: addedProductsArray,
      toUpdate: updatedProductsArray,
    }
    dispatch(saveAllChanges(venueId, allChanges, 'SAVE_ALL'))
    setAddedProductsArray([])
    setUpdatedProductsArray([])
  }

  const handleAddProduct = () => {
    const isFullFilled =
      product.title.trim() !== '' &&
      product.supplierTitle.trim() !== '' &&
      product.price > 0
    if (isFullFilled) {
      setAddedProductsArray([...addedProductsArray, product])
      setProducts([...products, product])
      setProduct({
        inStock: 0,
        title: '',
        size: '',
        UoM: '',
        sku: '',
        supplierTitle: '',
        price: 0,
        lastQuantity: 0,
        OMSPrice: 0,
        wastage: 0,
        stockTake: stockTakeId,
        qtySinceLastStockTake: 0,
      })
      setAddProductModalVisible(false)
    } else {
      notification.error({
        message: 'Fill required fields',
        description: 'Title, Supplier title and price must be fulfilled.',
        placement: 'RadiusBottomrightOutlined',
        bottom: 400,
      })
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Button>
            <Link to={`/v/${venueId}/stockTakes`}>Go back</Link>
          </Button>
          <h1>
            Stock take
            <StyledSpan>
              {`${dayjs(stockTake?.fromPeriod).format('DD MMM YYYY')} 
                          - ${dayjs(stockTake?.toPeriod).format(
                            'DD MMM YYYY',
                          )}`}
            </StyledSpan>
          </h1>

          <HeaderRow>
            <CustomButton
              onClick={handleSaveChanges}
              style={{ marginRight: '1%' }}
            >
              {buttonLoading.isLoading &&
                buttonLoading.buttonType === 'SAVE_ALL' && <LoadingOutlined />}
              Save changes
            </CustomButton>
            <CustomButton onClick={() => setAddProductModalVisible(true)}>
              Add products
            </CustomButton>
            <StockTakeCSV
              stockTake={stockTake}
              stockTakeProducts={stockTakeProducts}
            />

            <Button
              onClick={() => {
                _file.current.click()
              }}
            >
              Upload CSV
            </Button>
            <input
              style={{ display: 'none' }}
              onChange={uploadCSVFile}
              accept='.csv, .CSV'
              ref={_file}
              type='file'
            />
          </HeaderRow>
          <StyledTable
            pagination={false}
            bordered
            dataSource={products}
            columns={columns}
            scroll={{ x: 2000, y: '55vh' }}
            footer={() => {
              return (
                <div>
                  <Row>
                    <Col span={12}></Col>
                    <Col span={2}>
                      <strong>Total Stock Value:</strong>
                    </Col>
                    <Col span={2}>
                      {`£${products
                        .reduce((sum, item) => {
                          return (
                            sum +
                            (item.hasVat
                              ? item.price * item.inStock * VAT_RATE
                              : item.price * item.inStock)
                          )
                        }, 0)
                        .toFixed(2)}`}
                    </Col>
                    <Col span={2}>
                      <strong>Total Wastage Value:</strong>
                    </Col>
                    <Col span={2}>
                      {`£${products
                        .reduce((sum, item) => {
                          return (
                            sum +
                            (item.hasVat
                              ? item.price * item.wastage * VAT_RATE
                              : item.price * item.wastage)
                          )
                        }, 0)
                        .toFixed(2)}`}
                    </Col>
                  </Row>
                </div>
              )
            }}
          />
          <Modal
            title='Add product'
            width='60%'
            visible={addProductModalVisible}
            onOk={handleAddProduct}
            onCancel={() => setAddProductModalVisible(false)}
            okText={'Add'}
          >
            <AddProductModal
              product={product}
              setProduct={setProduct}
              venueProducts={[...venueProducts]}
              addProductModalVisible={addProductModalVisible}
              stockTakeProductTitles={stockTakeProductsTitles}
            />
          </Modal>
        </div>
      )}
    </div>
  )
}

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const StyledSpan = styled.span`
  margin-left: 5px;
  font-size: 20px;
`

const CustomButton = styled.button`
  padding: 3px 10px;
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  color: ${({ theme }) => theme.colours.mayoWhite};
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  :hover {
    cursor: pointer;
  }
  :disabled {
    background-color: ${({ theme }) => theme.colours.chilliRed};
    :hover {
      cursor: not-allowed;
    }
  }
`
const StyledTable = styled(Table)`
  .inputColumn {
    padding: 0px 10px;
  }
  .smallColumn {
    min-width: 10px;
  }
  .centerAlign {
    text-align: center;
  }
  .ant-input-number-input {
    text-align: center !important;
  }
`

export default Stock
