import { Layout, Menu } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import OMSErrorBoundary from 'src/components/shared/ErrorBoundary'
import styled from 'styled-components/macro'

const { Item } = Menu

const VenueInternalWrapper = ({ children, venue }) => {
  const location = useLocation()
  const pathFragments = location.pathname.split('/')
  const hasSubMenu = pathFragments.includes('purchasing')
  const { _id: venueId } = venue || {}
  const slug = hasSubMenu
    ? pathFragments[pathFragments.length - 2]
    : pathFragments[pathFragments.length - 1]

  return !venue || slug === 'venues' ? null : (
    <>
      <StyledMenu mode='horizontal' selectedKeys={[slug]}>
        <Item key='purchasing'>
          <Link to={`/v/${venueId}/purchasing/orders`}>Purchasing</Link>
        </Item>
        <Item key='suppliers'>
          <Link to={`/v/${venueId}/suppliers`}>Your suppliers</Link>
        </Item>
        <Item key='catalogue'>
          <Link to={`/v/${venueId}/catalogue`}>Catalogue</Link>
        </Item>
        <Item key='products'>
          <Link to={`/v/${venueId}/products`}>Shopping lists</Link>
        </Item>
        <Item key='stockTakes'>
          <Link to={`/v/${venueId}/stockTakes`}>Stock</Link>
        </Item>
        <Item key='settings'>
          <Link to={`/v/${venueId}/settings`}>Settings</Link>
        </Item>
        <Item key='subscriptions'>
          <Link to={`/v/${venueId}/subscriptions`}>Subscriptions</Link>
        </Item>
      </StyledMenu>
      <StyledLayout hassubmenu={hasSubMenu ? 1 : 0}>
        <OMSErrorBoundary>{children}</OMSErrorBoundary>
      </StyledLayout>
    </>
  )
}

const StyledMenu = styled(Menu)`
  height: 52px;
  background: ${({ theme }) => theme.colours.mayoWhite};
  padding: 0 ${({ theme }) => theme.spacing.mega};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledLayout = styled(Layout)`
  background: ${({ theme }) => theme.colours.mayoWhite};
  min-height: calc(100vh - 167px) !important;
  padding: ${({ theme, hassubmenu }) =>
    hassubmenu ? 0 : `${theme.spacing.lg} ${theme.spacing.xxl}`};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${({ theme, hassubmenu }) =>
      hassubmenu ? 0 : `${theme.spacing.lg} ${theme.spacing.md}`};
  }
`

export default VenueInternalWrapper
