import React from 'react'
import { useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { Button } from 'antd'

const DownloadProductsCsv = ({ venueProducts }) => {
  const { title } = useSelector(state => state.venue.venue)
  const headerOfFile = `Products of ${title}`
  const productsTableHeaders = [
    { key: 'id' },
    { key: 'title' },
    { key: 'size' },
    { key: 'price' },
    { key: 'UoM' },
    { key: 'supplier' },
    { key: 'sku' },
    { key: 'type' },
    { key: 'hasVat' },
  ]
  const dataOfFile = []
  dataOfFile.push({ title: headerOfFile })
  dataOfFile.push({})
  dataOfFile.push({
    id: 'Product_ID',
    title: 'Title',
    size: 'Size',
    price: 'Price',
    UoM: 'UoM',
    supplier: 'Supplier',
    sku: 'SKU',
    type: 'Type',
    hasVat: 'Has_VAT',
  })

  venueProducts?.forEach(product => {
    const { size, price, UoM, sku, hasVat, _id } = product.product

    dataOfFile.push({
      id: _id,
      title: product.title,
      size: size,
      price: price,
      UoM: UoM,
      supplier: product.supplierTitle,
      sku: sku,
      type: product.type,
      hasVat: hasVat ? hasVat : false,
    })
  })

  return (
    <Button>
      <CSVLink
        data={dataOfFile}
        headers={productsTableHeaders}
        filename={`${title}_products.csv`}
      >
        Download CSV
      </CSVLink>
    </Button>
  )
}

export default DownloadProductsCsv
