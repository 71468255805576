import React, { useState } from 'react'
import { DatePicker } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'

const OrderProductsModal = ({ order, setOrder, validateDeliveryDay }) => {
  const [deliveryDate, setDeliveryDate] = useState(
    order.deliveryDate ? moment(order.deliveryDate) : null,
  )
  const [notes, setNotes] = useState(order.notes || '')

  const disabledDate = date => {
    if (date.format('YYYY-MM-DD-HH') <= moment().format('YYYY-MM-DD-HH')) {
      return true
    }
  }

  const selectDeliveryDate = date => {
    validateDeliveryDay(date)
    setDeliveryDate(date)
    setOrder({ ...order, deliveryDate: date ? date.toISOString() : null })
  }

  const handleOnEndChanging = () => {
    setOrder({ ...order, notes })
  }

  return (
    <div>
      <div>
        <h3>Required delivery date</h3>
        <h4 style={{ fontWeight: 'lighter', color: 'grey' }}>
          Suppliers may not be able to deliver on your chosen date every time.
          Please reach out to suppliers directly if you have any questions about
          your deliveries
        </h4>
        <DatePicker
          placeholder='Choose delivery date'
          disabledDate={date => disabledDate(date)}
          onChange={selectDeliveryDate}
          value={deliveryDate}
          style={{ marginBottom: '20px' }}
        />
      </div>
      <div>
        <h3>Notes:</h3>
        <h4 style={{ fontWeight: 'lighter', color: 'grey' }}>
          Every supplier included in this order will see your notes. If you wish
          to direct your message to a particular supplier, please include their
          name.
        </h4>
        <TextArea
          style={{ width: '50%' }}
          placeholder={`Eg. Brakes - Could you deliver this to the main entrance please?`}
          onChange={e => setNotes(e.target.value)}
          value={notes}
          onBlur={handleOnEndChanging}
        />
      </div>
    </div>
  )
}

export default OrderProductsModal
