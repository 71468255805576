import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button, Input } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getVenueSuppliers } from '../features/VenueSuppliersSlice'
import Loading from './styled/Loading'
import AutoCompleteFilter from './AutoComplete'
import { API } from 'aws-amplify'
import { getUploadedInvoices } from '../features/NewInvoicesSlice'
import { BodyText } from 'src/branding/typography'

function AddInvoices({
  venueId,
  setAttachInvoices,
  isNotDispatchInvoices = false,
}) {
  const dispatch = useDispatch()

  const [selectedFiles, setSelectedFiles] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const fileInputRef = useRef(null)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const venueSuppliers = useSelector(state =>
    state.venueSuppliers.venueSuppliers.filter(
      venueSupplier => venueSupplier.isActive,
    ),
  )
  const user = useSelector(state => state.auth)
  const username = user?.account?.name
  const isAdmin = user?.account?.isAdmin

  const venueTitle = useSelector(state => state.venue.venue.title)
  const [initialSuppleirs, setInitialSuppilers] = useState([])

  const isLoading = useSelector(state => state.venueSuppliers.isLoading)
  useEffect(() => {
    const selected = selectedFiles.filter(file => file.supplier === null)
    if (selected.length > 0 || selectedFiles.length === 0) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [selectedFiles])

  const handleFileChange = event => {
    const files = event.target.files

    if (files && files.length <= 5) {
      const updatedFiles = Array.from(files)
      const promises = updatedFiles.map(file => {
        return new Promise(resolve => {
          const reader = new FileReader()
          reader.onload = event => {
            resolve({
              filename: file.name,
              image: { base64: event.target.result },
              supplierId: null,
              supplierTitle: '',
            })
          }
          reader.readAsDataURL(file)
        })
      })

      Promise.all(promises)
        // eslint-disable-next-line promise/always-return
        .then(invoices => {
          setSelectedFiles(invoices)
          setConfirmModal(true)
          if (venueSuppliers.length === 0) {
            dispatch(getVenueSuppliers(venueId))
          }
          setInitialSuppilers(venueSuppliers)
          return
        })
        .catch(error => {
          console.error('Error converting files to base64:', error)
        })
    } else {
      alert('You can select only 5 files')
    }
  }

  const handleRemoveFile = index => {
    const updatedFiles = [...selectedFiles]
    updatedFiles.splice(index, 1)
    setSelectedFiles(updatedFiles)
  }

  const updateFiles = (supplierId, title, number) => {
    console.log(disabled)
    const updatedFiles = selectedFiles.map((item, index) =>
      number === index
        ? { ...item, supplier: supplierId, supplierTitle: title }
        : item,
    )

    setSelectedFiles(updatedFiles)
  }
  const handleConfirmUpload = async () => {
    const body = {
      venue: venueId,
      venueTitle: venueTitle,
      images: selectedFiles,
      isDashboardUpload: true,
      username,
      userType: isAdmin ? 'Admin' : 'Customer',
    }
    setConfirmLoading(true)
    try {
      const response = await API.post(
        'api',
        `venue/${venueId}/invoices_photos_to_s3`,
        {
          body,
        },
      )
      if (response.success) {
        if (setAttachInvoices)
          setAttachInvoices(curr => [
            ...response.newInvoices.map(({ _id }) => _id),
            ...curr,
          ])
        if (!isNotDispatchInvoices) await dispatch(getUploadedInvoices(venueId))

        setSelectedFiles([])
        setConfirmModal(false)
        setConfirmLoading(false)
      } else if (response.success === false && response.message) {
        setConfirmLoading(false)
        alert('Unsupported file type')
        setSelectedFiles([])
        setConfirmModal(false)
      }
    } catch (error) {
      console.error(error)
      setConfirmLoading(false)
      alert('An Error Occured,Please try again...')
      setSelectedFiles([])
      setConfirmModal(false)
    }
  }

  const addCustomSupplier = newSupplier => {
    setInitialSuppilers(prevState => [...prevState, newSupplier])
  }

  const updateFilesValues = (number, field, value) => {
    console.log(disabled)
    const updatedFiles = selectedFiles.map((item, index) =>
      number === index ? { ...item, [field]: value } : item,
    )
    setSelectedFiles(updatedFiles)
  }

  return (
    <div>
      <label htmlFor='fileInput'>
        <Button
          style={{ background: '#b5d922', width: 200 }}
          type={'primary'}
          onClick={() => fileInputRef.current.click()}
        >
          Add Invoices
        </Button>
      </label>
      <input
        type='file'
        id='fileInput'
        style={{ display: 'none' }}
        multiple
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      <Modal
        width={'40%'}
        title={'Confirm'}
        okText={'Upload'}
        visible={confirmModal}
        //confirmLoading={addInvoiceLoading}
        //okButtonProps={{ disabled: disabled }}
        onOk={async () => {
          await handleConfirmUpload()
        }}
        onCancel={() => {
          setConfirmModal(false)
        }}
      >
        {isLoading || confirmLoading ? (
          <Loading />
        ) : (
          <InvoicesWrapper>
            {selectedFiles.map((file, index) => (
              <Card key={index}>
                {file.image.base64.startsWith(
                  'data:application/pdf;base64,',
                ) ? (
                  <BodyText>{file.filename}</BodyText>
                ) : (
                  <img
                    src={file.image.base64}
                    alt={`Image ${index + 1}`}
                    style={{
                      maxWidth: '300px',
                      maxHeight: '300px',
                      marginBottom: '10px',
                    }}
                  />
                )}

                <CrossIcon onClick={() => handleRemoveFile(index)}>
                  <CloseOutlined style={{ fontSize: '15px', color: '#fff' }} />
                </CrossIcon>

                <AutoCompleteFilter
                  initialSuppleirs={initialSuppleirs}
                  addCustomSupplier={addCustomSupplier}
                  index={index}
                  updateFiles={updateFiles}
                />
                <Input
                  placeholder='PO number'
                  value={file.purchaseOrderNumber}
                  onChange={event => {
                    updateFilesValues(
                      index,
                      'purchaseOrderNumber',
                      event.target.value,
                    )
                  }}
                />
                <Input
                  placeholder='Notes'
                  value={file.comment}
                  onChange={event => {
                    updateFilesValues(index, 'comment', event.target.value)
                  }}
                />
              </Card>
            ))}
          </InvoicesWrapper>
        )}
      </Modal>
    </div>
  )
}

export default AddInvoices

const InvoicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px;
  gap: 5px;
`

const CrossIcon = styled.div`
  background: #b5d922;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
`
