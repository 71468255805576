import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, Icon, Input, message, Radio } from 'antd'
import styled from 'styled-components/macro'
import lunr from 'lunr'
import { DownOutlined, RightOutlined } from '@ant-design/icons'

const AddProductModal = ({
  product,
  setProduct,
  venueProducts,
  addProductModalVisible,
  stockTakeProductTitles,
}) => {
  const [title, setTitle] = useState('')
  const [size, setSize] = useState('')
  const [UoM, setUoM] = useState('')
  const [supplierTitle, setSupplierTitle] = useState('')
  const [price, setPrice] = useState(0)
  const [lastQuantity, setLastQuantity] = useState(0)
  const [inStock, setInStock] = useState(0)
  const [wastage, setWastage] = useState(0)
  //const isEmpty = venueProducts.length === 0
  const [filteredProducts, setFilteredProducts] = useState([])
  const [inputValue, setInputValue] = useState('')
  const refToInputTitle = useRef(null)
  const [searchValue, setSearchValue] = useState('title')
  const [availableProducts, setAvailableProducts] = useState([])
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const indexFieldsRef = useRef([searchValue])
  const inputSearchRef = useRef(null)

  const idx = useMemo(
    () =>
      lunr(function () {
        this.ref('_id')
        indexFieldsRef.current.forEach(fieldName =>
          this.field(fieldName, {
            extractor: item => {
              if (fieldName.includes('.')) {
                const fieldPath = fieldName.split('.')
                return fieldPath.reduce(
                  (accumulator, pathEl) => accumulator && accumulator[pathEl],
                  item,
                )
              }
              return item[fieldName]
            },
          }),
        )

        availableProducts.forEach(function (item) {
          this.add(item)
        }, this)
      }),
    [availableProducts, indexFieldsRef, searchValue, filteredProducts],
  )

  const validationOnNumber = (value, setValue) => {
    if (isNaN(value)) {
      message.error('Data in this field must be a numeric')
    } else setValue(value)
  }

  useEffect(() => {
    const setStockTakeProductTitles = new Set(stockTakeProductTitles)

    const availableFilteredProducts = venueProducts.filter(
      product => !setStockTakeProductTitles.has(product.title),
    )

    setAvailableProducts(availableFilteredProducts)
    const tree = groupBySupplier(availableFilteredProducts)

    setFilteredProducts(tree)
  }, [venueProducts, stockTakeProductTitles])

  useEffect(() => {
    indexFieldsRef.current = [searchValue]
  }, [searchValue])

  useEffect(() => {
    clearFunc()
  }, [addProductModalVisible])

  function clearFunc() {
    setTitle('')
    setPrice(0)
    setUoM('')
    setSupplierTitle('')
    setSize('')
    setLastQuantity(0)
    setInStock(0)
    setWastage(0)
  }

  const handleOnSearch = value => {
    setInputValue(value)

    if (value.trim() === '') {
      const tree = groupBySupplier(availableProducts)
      setFilteredProducts(tree)
      return
    }

    const result = quickSearch(value)
    const tree = groupBySupplier(result)
    setFilteredProducts(tree)
  }

  function groupBySupplier(products) {
    const groupedProducts = {}

    products.forEach((product, index) => {
      const supplierTitle = product.supplierTitle

      if (!groupedProducts[supplierTitle]) {
        groupedProducts[supplierTitle] = {
          title: supplierTitle,
          value: index,
          isOpened: false,
          children: [],
        }
      }

      groupedProducts[supplierTitle].children.push({
        title: product.product.title,
        value: product.product._id,
      })
    })

    return Object.values(groupedProducts)
  }

  function quickSearch(value) {
    const exactSearch = idx.query(q => {
      // look for an exact match and apply a large positive boost
      q.term(lunr.tokenizer(value), {
        boost: 100,
        usePipeline: true,
        presence: lunr.Query.presence.REQUIRED,
      })
    })

    const wildcardSearch = idx.query(q => {
      // look for terms that match with a partial searchTerm and apply a medium boost
      q.term(lunr.tokenizer(value), {
        boost: 10,
        usePipeline: true,
        presence: lunr.Query.presence.REQUIRED,
        wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
      })
    })

    const searchResults = [...exactSearch, ...wildcardSearch]
    const mappedResults = searchResults.reduce((accumulator, searchResult) => {
      const seenIds = accumulator.map(result => result._id)
      const alreadyAdded = seenIds.includes(searchResult.ref)
      return alreadyAdded
        ? accumulator
        : [
            ...accumulator,
            ...venueProducts.filter(item => item._id === searchResult.ref),
          ]
    }, [])

    return mappedResults
  }

  const onChangeSearchValue = ({ target: { value } }) => {
    setSearchValue(value)
  }

  const handleOnSelect = value => {
    if (value === 'create-new') {
      clearFunc()
      setDropdownVisible(false)
      refToInputTitle.current.focus()
    } else {
      const founded = venueProducts.find(
        product => product.product._id === value,
      )
      setTitle(founded.product.title)
      setPrice(founded.product.price)
      setUoM(founded.product?.UoM)
      setSupplierTitle(founded.supplierTitle)
      setSize(founded.product.size)
      setProduct({
        ...product,
        title: founded.product.title,
        price: founded.product.price,
        OMSPrice: founded.product.price,
        supplierTitle: founded.supplierTitle,
        size: founded.product.size,
        UoM: founded.product?.UoM,
        hasVat: founded.product?.hasVat,
      })
      setInputValue('')
      setDropdownVisible(false)
      inputSearchRef.current.blur()
      const tree = groupBySupplier(availableProducts)
      setFilteredProducts(tree)
    }
  }

  const handleTreeNodeClick = clickedNodeValue => {
    const updatedProducts = filteredProducts.map(treeNode => {
      if (treeNode.value === clickedNodeValue) {
        return {
          ...treeNode,
          isOpened: !treeNode.isOpened, // Toggle isOpened
        }
      } else {
        return treeNode
      }
    })

    setFilteredProducts(updatedProducts)
  }

  const renderCustomTreSelector = useCallback(() => {
    return (
      <CustomTreSelector>
        <Input
          ref={inputSearchRef}
          value={inputValue}
          placeholder={'Find product from list'}
          onFocus={() => setDropdownVisible(true)}
          onBlur={() => setDropdownVisible(false)}
          onChange={e => handleOnSearch(e.target.value)}
        />

        {dropdownVisible && (
          <DropdownWrapper onMouseDown={e => e.preventDefault()}>
            <StyledAddDiv
              onClick={() => {
                handleOnSelect('create-new')
              }}
            >
              <Icon style={{ fontSize: '13px' }} type='plus-circle' />
              <span>Add new product</span>
            </StyledAddDiv>
            {filteredProducts.map(treeNode => {
              return (
                <div key={treeNode.value}>
                  <TreeNodeParent
                    onClick={e => {
                      e.preventDefault()
                      handleTreeNodeClick(treeNode.value)
                    }}
                  >
                    {treeNode.isOpened ? (
                      <DownOutlined style={{ fontSize: 12 }} />
                    ) : (
                      <RightOutlined style={{ fontSize: 12 }} />
                    )}
                    <TreeNodeParentTitle>{treeNode.title}</TreeNodeParentTitle>
                  </TreeNodeParent>
                  {treeNode.isOpened && (
                    <TreeNodeChildren>
                      {treeNode.children.map(treeNodeChild => {
                        return (
                          <TreeNodeChildWrapper
                            onClick={() => {
                              handleOnSelect(treeNodeChild.value)
                            }}
                            key={treeNodeChild.value}
                          >
                            <TreeNodeChildTitle>
                              {treeNodeChild.title}
                            </TreeNodeChildTitle>
                          </TreeNodeChildWrapper>
                        )
                      })}
                    </TreeNodeChildren>
                  )}
                </div>
              )
            })}
          </DropdownWrapper>
        )}
      </CustomTreSelector>
    )
  }, [
    filteredProducts,
    inputValue,
    availableProducts,
    filteredProducts.length,
    handleOnSearch,
  ])

  return (
    <div>
      <StyledDiv>
        {renderCustomTreSelector()}
        <Radio.Group
          options={[
            { label: 'Title', value: 'title' },
            { label: 'SKU', value: 'product.sku' },
            { label: 'Supplier', value: 'supplierTitle' },
          ]}
          onChange={onChangeSearchValue}
          value={searchValue}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          ref={refToInputTitle}
          value={title}
          placeholder='Enter title of product'
          addonBefore='Title: '
          onChange={e => setTitle(e.target.value)}
          onBlur={() => setProduct({ ...product, title: title })}
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={size}
          placeholder='Enter size of product'
          addonBefore='Size: '
          onChange={e => setSize(e.target.value)}
          onBlur={() => setProduct({ ...product, size: size })}
          onFocus={e => e.target.select()}
          required={true}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={UoM}
          placeholder='Enter UoM'
          addonBefore='UoM: '
          onChange={e => setUoM(e.target.value)}
          onBlur={() => setProduct({ ...product, UoM: UoM })}
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={supplierTitle}
          placeholder='Enter title of supplier'
          addonBefore='Supplier: '
          onChange={e => setSupplierTitle(e.target.value)}
          onBlur={() =>
            setProduct({ ...product, supplierTitle: supplierTitle })
          }
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={price}
          placeholder='Enter price of product'
          addonBefore='Price: '
          onChange={e => validationOnNumber(e.target.value, setPrice)}
          onBlur={() =>
            setProduct({ ...product, price: price, OMSPrice: price })
          }
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={lastQuantity}
          placeholder='Last quantity'
          addonBefore='Last quantity: '
          onChange={e => validationOnNumber(e.target.value, setLastQuantity)}
          onBlur={() => setProduct({ ...product, lastQuantity: lastQuantity })}
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={inStock}
          placeholder='Enter current quantity'
          addonBefore='In stock: '
          onChange={e => validationOnNumber(e.target.value, setInStock)}
          onBlur={() => setProduct({ ...product, inStock: inStock })}
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <Input
          value={wastage}
          placeholder='Enter current wastage count'
          addonBefore='Wastage: '
          onChange={e => validationOnNumber(e.target.value, setWastage)}
          onBlur={() => setProduct({ ...product, wastage: wastage })}
          onFocus={e => e.target.select()}
        />
      </StyledDiv>
      <StyledDiv>
        <StyledSpan>Has VAT ?</StyledSpan>
        <Checkbox
          checked={product.hasVat}
          onChange={() => setProduct({ ...product, hasVat: !product.hasVat })}
        />
      </StyledDiv>
    </div>
  )
}

const StyledAddDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
const CustomTreSelector = styled.div`
  position: relative;
  margin-right: 20px;
  width: 250px;
`
const TreeNodeParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
`
const TreeNodeParentTitle = styled.p`
  margin-left: 15px;
  margin-bottom: 0;
`

const TreeNodeChildren = styled.div`
  margin-left: 30px;
`

const TreeNodeChildWrapper = styled.div``

const TreeNodeChildTitle = styled.p`
  font-size: 14px;
  padding: 3px;
`

const DropdownWrapper = styled.div`
  position: absolute;
  padding: 10px 15px;
  z-index: 999;
  background-color: white;
  width: 250px;
  height: auto;
  max-height: 200px;
  overflow: scroll;
  border: 1px solid #b5d922;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`

const StyledSpan = styled.span`
  margin-right: 7px;
`

export default AddProductModal
