import React from 'react'
import { Button, Layout } from 'antd'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import { BodyText, H4 } from 'src/branding/typography'
import styled from 'styled-components/macro'

const SupplierdasbaordWrapper = ({ supplierTitle, children }) => {
  const history = useHistory()

  return (
    <Layout>
      <Header>
        <LeftHeader>
          <Link to='/'>
            <Logo src={OMSLogo} alt='' />
          </Link>
        </LeftHeader>

        <MidHeader>
          <VenueTitle
            style={{
              fontWeight: '900',
              fontSize: '23px',
            }}
          >
            {supplierTitle ? supplierTitle : 'Supplier'}
          </VenueTitle>
          <BodyText></BodyText>
        </MidHeader>

        <RightHeader>
          <CTAButton
            size='large'
            ghost
            onClick={() =>
              window.open(
                'https://ordersmadesimple.com/get-100-refer-a-friend',
                '_blank',
              )
            }
          >
            {'Refer & Get £100'}
          </CTAButton>
          <CTAButton
            size='large'
            onClick={() => Auth.signOut().then(() => history.push('/login'))}
          >
            Sign out
          </CTAButton>
        </RightHeader>
      </Header>

      <Content>{children}</Content>
    </Layout>
  )
}

const Header = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 104px !important;
  background: ${({ theme }) => theme.colours.bbqBlack} !important;
  padding: 0 ${({ theme }) => theme.spacing.xxl} !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 150px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.md} !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 190px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
`

const Logo = styled.img`
  max-height: 100%;
  height: 80px;
  margin-top: 4px;
`

const Content = styled(Layout.Content)`
  min-height: calc(100vh - 100px) !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-height: calc(100vh - 150px) !important;
  }
`

const LeftHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: center;
  width: 82px;
  /* background: beige; */
`

const RightHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  align-items: center;
  max-width: 260px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 6px;
  }
`

const MidHeader = styled.div`
  display: flex;
  position: absolute;
  max-width: 268px;
  left: 50%;
  transform: translate(-50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 75%;
    position: relative;
    max-width: 100%;
    left: 0;
    transform: translate(0%);
    justify-content: center;
    padding-right: 16px;
  }
  @media (max-width: 1480px) and (min-width: 1291px) {
    left: 40vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: flex-end;
  }
`

const VenueTitle = styled(H4)`
  text-align: center;
  color: ${({ theme }) => theme.colours.lettuceGreen};
`
const CTAButton = styled(Button)`
  height: 48px !important;
  margin-left: ${({ theme }) => theme.spacing.sm};
`

export default SupplierdasbaordWrapper
