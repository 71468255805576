import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from '../utils/sentry'
import {
  RejectedNotification,
  SuccessNotification,
} from '../notifications/SuccessNotification'

const initialState = {
  uploadedInvoices: [],
  uploadedLoading: false,
  updatingLoading: false,
}
const NewInvoicesSlice = createSlice({
  name: 'newInvoices',
  initialState,
  reducers: {
    setUploadedInvoices: (state, action) => {
      state.uploadedInvoices = action.payload
    },
    setUploadedLoading: (state, action) => {
      state.uploadedLoading = action.payload
    },
    setUpdatingLoading: (state, action) => {
      state.uploadedLoading = action.payload
    },
    updateInvoices: (state, action) => {
      const { invoice } = action.payload

      state.uploadedInvoices = state.uploadedInvoices.map(uploadedInvoice => {
        if (invoice._id === uploadedInvoice._id) {
          return invoice
        }
        return uploadedInvoice
      })
    },
  },
})

export const {
  setUploadedInvoices,
  setUploadedLoading,
  updateInvoices,
  setUpdatingLoading,
} = NewInvoicesSlice.actions

export const getUploadedInvoices = venueId => async dispatch => {
  try {
    dispatch(setUploadedLoading(true))

    const invoices = await API.get('api', `venue/${venueId}/invoicesInfo`)

    dispatch(setUploadedInvoices(invoices))

    dispatch(setUploadedLoading(false))
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
    dispatch(setUploadedLoading(false))

    return false
  }
}

export const getInvoiceUrlById = async (venueId, invoiceId) => {
  try {
    const invoiceByIdWithUrl = await API.get(
      'api',
      `venue/${venueId}/invoice/${invoiceId}`,
    )

    return invoiceByIdWithUrl
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
  }
}
export const getInvoicesWithTempUrl = async (venueId, supplierId) => {
  try {
    const invoicesWithTempUrl = await API.get(
      'api',
      `venue/${venueId}/invoices_photos?supplierTitle=${supplierId}`,
    )

    return invoicesWithTempUrl
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
  }
}

export const getInvoicesWithTempUrlByRange = async (venueId, rangeString) => {
  try {
    console.log('rangeString', rangeString[0], rangeString[1])
    const invoicesWithTempUrl = await API.get(
      'api',
      `venue/${venueId}/invoices_photos?start=${rangeString[0]}&end=${rangeString[1]}`,
    )
    console.log('invoicesWithTempUrl', invoicesWithTempUrl)
    return invoicesWithTempUrl
  } catch (e) {
    console.log('Error in getInvoicesWithTempUrlByRange', e)
  }
}

export const deleteInvoice = invoiceId => async (dispatch, getState) => {
  try {
    const result = await API.del('api', `invoicesInfo/delete/${invoiceId}`)

    const { newInvoices } = getState()

    const updatedInvoices = newInvoices.uploadedInvoices.filter(
      invoice => invoice._id !== result.deletedInvoice,
    )

    dispatch(setUploadedInvoices(updatedInvoices))

    SuccessNotification({ message: result.message })
  } catch (e) {
    console.log('Error in deleteing invoice = ', invoiceId, e)
    RejectedNotification({
      message: 'Something went wrong',
      description: e?.message || 'Please, try again!',
    })
  }
}

export const editInvoiceInDatabase =
  (venueId, invoiceId, data) => async (dispatch, getState) => {
    try {
      dispatch(setUpdatingLoading(true))
      const result = await API.patch(
        'api',
        `venue/${venueId}/invoiceUpdate/${invoiceId}`,
        {
          body: data,
        },
      )

      const { newInvoices } = getState()

      const updatedInvoices = newInvoices.uploadedInvoices.map(invoice => {
        if (invoice._id === result.invoice._id) {
          // Replace the existing invoice with the updated invoice
          return result.invoice
        }
        return invoice
      })

      dispatch(setUploadedInvoices(updatedInvoices))
      dispatch(setUpdatingLoading(false))

      SuccessNotification({ message: result.message })
    } catch (e) {
      console.log('Error in updating invoice = ', invoiceId, e)
      dispatch(setUpdatingLoading(false))
      RejectedNotification({
        message: 'Something went wrong',
        description: e?.message || 'Please, try again!',
      })
    }
  }
export const updateInvoice = (invoiceId, field, value) => async dispatch => {
  try {
    const body = { field, value }
    const updatedInvoice = await API.put(
      'api',
      `venue/${invoiceId}/makeChecked`,
      { body },
    )
    dispatch(updateInvoices(updatedInvoice))
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
  }
}
export default NewInvoicesSlice
