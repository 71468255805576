import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const invoiceSlice = createSlice({
  name: 'credit',
  initialState: {
    invoices: null,
    transactions: [],
  },
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
  },
})

export const { setInvoices, setTransactions } = invoiceSlice.actions

export const fetchCreditData = venueId => async dispatch => {
  try {
    const data = await API.get('api', `credit/${venueId}`)
    dispatch(setInvoices(data.openInvoices))
    dispatch(setTransactions(data.creditTransactions))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default invoiceSlice
