import {Card, DatePicker, Table, Tag, TreeSelect} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import FadeIn from 'src/components/styled/FadeIn'
import { fetchPastOrders } from 'src/features/OverViewSlice'
import styled from 'styled-components/macro'
import moment from "moment";

const PastOrders = () => {
  const dispatch = useDispatch()

  const { pastOrders, paginationPastOrders, totalCountPastOrders } = useSelector(state => state.overView)
  const isLoading = useSelector(state => state.adminOrders.isLoading)
  const myVenues = useSelector(state => state.auth.account.myvenues)

  const dateFormat = 'YYYY-MM-DD'
  const twoWeekRange = [moment().subtract(14, 'days'), moment()]
  const [selectedDates, setSelectedDates] = useState([twoWeekRange[0].format(dateFormat), twoWeekRange[1].format(dateFormat)]);

  const venueOptions = myVenues.map(item => {
    return {
      title: item.title,
      value: item._id,
    }
  })
  const allIds = venueOptions.map(item => item.value)
  const [selectedVenueIds, setSelectedVenuesIds] = useState(allIds)

  useEffect(() => {
    dispatch(fetchPastOrders(
        selectedVenueIds,
        { page: 1, limit: paginationPastOrders.limit },
        twoWeekRange[0].format(dateFormat),
        twoWeekRange[1].format(dateFormat)
    ))
  }, [selectedVenueIds])

  const disableEndDate = current => current.valueOf() > moment.now()

  const stringSort = (a, b) => String(a).trim().localeCompare(String(b).trim())

  const onDateSelection = (vale, dateString) => {
    dispatch(fetchPastOrders(
        selectedVenueIds,
        { page: 1, limit: paginationPastOrders.limit },
        dateString[0],
        dateString[1]
    ))
    setSelectedDates(dateString);
  }

  return (
    <Wrapper>
      <HeaderScreen title={'Past Orders'}>
        <ActionCard>
          <h4>Select Dates</h4>
          <StyledRangePicker
              disabledDate={disableEndDate}
              format={dateFormat}
              defaultValue={twoWeekRange}
              onChange={onDateSelection}
          />
          <h4>Select Venues</h4>
          <TreeSelect
            treeNodeFilterProp='title'
            allowClear={true}
            placeholder='Select a venue'
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            dropdownStyle={{ maxHeight: '250px', width: '120px' }}
            style={{ marginBottom: '15px' }}
            onChange={ids => setSelectedVenuesIds(ids)}
            value={selectedVenueIds}
            maxTagCount={1}
            maxTagPlaceholder={omittedValues =>
              `+ ${omittedValues.length} Venues ...`
            }
            treeData={[
              {
                title:
                  selectedVenueIds.length > 0 ? (
                    <span
                      onClick={() => setSelectedVenuesIds([])}
                      style={{
                        display: 'inline-block',
                        color: '#B4D820',
                        cursor: 'pointer',
                      }}
                    >
                      Unselect all
                    </span>
                  ) : (
                    <span
                      onClick={() => setSelectedVenuesIds(allIds)}
                      style={{
                        display: 'inline-block',
                        color: '#B4D820',
                        cursor: 'pointer',
                      }}
                    >
                      Select all
                    </span>
                  ),
                value: 'xxx',
                disableCheckbox: true,
                disabled: true,
              },
              ...venueOptions,
            ]}
          />
        </ActionCard>
      </HeaderScreen>
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: false,
        }}
        dataSource={pastOrders.map(data => ({
          ...data,
          key: data._id,
        }))}
        loading={isLoading}
        pagination={{
          position: "both",
          total: totalCountPastOrders,
          pageSize: paginationPastOrders?.limit,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
          current: paginationPastOrders.page,
          onChange: page => {
            dispatch(fetchPastOrders(
                selectedVenueIds,
                {page: page, limit: paginationPastOrders.limit},
                selectedDates[0],
                selectedDates[1]
            ))
          },
          onShowSizeChange: (_, pageSize) => {
            dispatch(fetchPastOrders(
                selectedVenueIds,
                {page: 1, limit: pageSize},
                selectedDates[0],
                selectedDates[1]
            ))
          },
          showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} results`,
        }}
        columns={[
          {
            title: 'Venue',
            dataIndex: 'venueTitle',
            key: 'venueTitle',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.venueTitle, b.venueTitle),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Who Ordered',
            dataIndex: 'placedVia',
            key: 'placedVia',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.supplierTitle, b.supplierTitle),
            sortDirections: ['ascend', 'descend'],
            render: placedVia => (
              <text>
                {placedVia
                  ? `Order placed by: ${placedVia}`
                  : 'Order placed using venue code'}
              </text>
            ),
          },
          {
            title: 'Time',
            dataIndex: 'date',
            key: 'time',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.time, b.time),
            sortDirections: ['ascend', 'descend'],
            render: time => dayjs(time).format('hh.mma'),
          },
          {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.time, b.time),
            sortDirections: ['ascend', 'descend'],
            render: date => dayjs(date).format('DD/MM/YY'),
          },
          {
            title: 'Total',
            dataIndex: 'price',
            key: 'price',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.time, b.time),
            sortDirections: ['ascend', 'descend'],
            render: text => {
              return text ? `£${Number(text / 100).toFixed(2)}` : ``
            },
          },
          {
            title: 'Supplier',
            dataIndex: 'supplierTitle',
            key: 'supplierTitle',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.supplierTitle, b.supplierTitle),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.status, b.status),
            sortDirections: ['ascend', 'descend'],
            render: status => (
              <Tag
                color={
                  status === 'Approved'
                    ? 'green'
                    : status === 'Rejected'
                    ? 'magenta'
                    : 'orange'
                }
              >
                {status}
              </Tag>
            ),
          },
        ]}
      />
    </Wrapper>
  )
}
const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 95px;
  }
`

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
  }
  margin-bottom: 15px !important;
`

const ActionCard = styled(Card)`
  background: ${({ theme }) => theme.colours.mayoWhite};
  margin: ${({ theme }) => theme.spacing.sm} 0 !important;
  border-radius: 5px !important;
  width: calc(30% - 35px);
  > .ant-card-body {
    display: flex;
    flex-direction: column;
  }
  input,
  .ant-select-selection--multiple {
    background: ${({ theme }) => theme.colours.bgGrey};
  }
`

export default PastOrders
