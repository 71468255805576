import { Button } from 'antd'
import { CSVLink } from 'react-csv'

const CreditNotesCSV = ({  creditNotes }) => {
    const tableHeaders = [
        { key: 'po_number' },
        { key: 'sku' },
        { key: 'product' },
        { key: 'supplier' },
        { key: 'quantity' },
        { key: 'amount' },
        { key: 'status' },
        { key: 'date' },
        { key: 'resolved' },
    ]

    const titleOfTable = `Credit Notes`
    const dataOfTable = []
    dataOfTable.push({ title: titleOfTable })
    dataOfTable.push({})
    dataOfTable.push({
        po_number: 'PO Number',
        sku: 'SKU',
        product: 'Product',
        supplier: 'Supplier',
        quantity: 'Credit quatity',
        amount: 'Credit amount',
        status: 'Report status',
        date: 'Ordered Date',
        resolved: 'Resolved',
    })
    creditNotes?.forEach(note => {
        const {
            PON,
            SKU,
            productTitle,
            supplierTitle,
            quantity,
            amount,
            reportStatus,
            createdAt,
            resolved,
        } = note
        dataOfTable.push({
            po_number: PON,
            sku: SKU || ' ',
            product: productTitle,
            supplier: supplierTitle,
            quantity: quantity,
            amount: amount,
            status: reportStatus,
            date: createdAt,
            resolved: resolved ? "Yes" : "No"
        })
    })

    return (
        <Button style={{ marginLeft: '15px' }}>
            <CSVLink
                data={dataOfTable}
                headers={tableHeaders}
                filename={`Credit_Notes.csv`}
            >
                Download CSV
            </CSVLink>
        </Button>
    )
}

export default CreditNotesCSV