import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, InputNumber, Tooltip } from 'antd'
import React from 'react'
import { BodyText } from 'src/branding/typography'
import { removeLastBracketsWord } from 'src/utils/arrayFunctions'
import { getOMSPrice } from 'src/utils/priceFunctions'
import styled from 'styled-components/macro'

const ADMIN_ERROR = 'Adjustment due to Invoice received'
const TableColumnsCheckList = (
  receiptingState,
  setReceiptingState,
  editable,
  isAdmin,
  completed,
  orders,
  setOrders,
  table,
  locked,
  layout,
  isSubstitutedColumnShown,
) => {
  const isQuantityUpdated = order =>
    order.updatedQuantity !== undefined &&
    order.updatedQuantity !== null &&
    order.updatedQuantity !== order.quantity

  const isReceiptingCheck = (order, value) => {
    if (!order?.receipting) {
      return false
    }

    switch (value) {
      case 'price':
        if (order?.receipting?.updatedPrice) {
          return true
        } else {
          return false
        }

      case 'quantity':
        if (
          order?.receipting?.updatedQuantity ||
          order?.receipting?.updatedQuantity === 0
        ) {
          return true
        } else {
          return false
        }

      case 'weight':
        if (
          order?.UoM === 'Kg' &&
          (order?.receipting?.weight || order?.receipting?.weight === 0)
        ) {
          return true
        } else {
          return false
        }

      default:
        return false
    }
  }

  const isAdminEdit = order => {
    if (order && order?.receipting && order.receipting.error === ADMIN_ERROR) {
      return true
    } else {
      return false
    }
  }

  const handleChangeInputNumber = (
    order,
    ordersArray,
    setOrdersArray,
    value,
  ) => {
    if (value > 0) {
      order.updatedQuantity = value
      ordersArray[order.index] = order
      setOrdersArray(ordersArray)
    }
  }

  const handleAdminEditQuantity = (order, e) => {
    const findReceipt = receiptingState.find(
      receipt => receipt.order === order._id,
    )
    const isWeight = order?.UoM === 'Kg' || false

    let update = {}
    if (isWeight) {
      update = {
        weight: e,
      }
    } else {
      update = {
        updatedQuantity: e,
        previousQty: isQuantityUpdated(order)
          ? order.updatedQuantity
          : order.quantity,
      }
    }

    if (findReceipt) {
      const newReceipt = receiptingState.map(receipt => {
        if (receipt.order === order._id) {
          return {
            ...order?.receipting,
            ...receipt,
            ...update,
            orderedQuantity: order.quantity,
          }
        } else {
          return receipt
        }
      })

      setReceiptingState(newReceipt)
    } else {
      const newReceipt = {
        order: order._id,
        error: ADMIN_ERROR,
        supplier: order.supplier._id,
        purchaseOrderNumber: order.purchaseOrderNumber,
        ...update,
        orderedQuantity: order.quantity,
        placedVia: order.placedVia,
      }

      setReceiptingState([...receiptingState, newReceipt])
    }
  }

  const handleCheckOrder = (
    event,
    order,
    ordersArray,
    setOrdersArray,
    value,
  ) => {
    order.supplierConfirmed =
      order.supplierConfirmed === value ? undefined : value
    ordersArray[order.index] = order
    setOrdersArray(ordersArray)
    event.target.blur()
  }

  const buttonSize = 'default'
  const allColumns = [
    {
      title: 'SKU',
      dataIndex: table === 'approved' ? 'sku' : 'product.sku',
      key: 'sku',
      className: 'restrictShrink',
      render: sku => (
        <BodyText>
          <b>{sku}</b>
        </BodyText>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'restrictShrink quantity-padding-left',
      render: (quantity, order) => {
        const qt = order?.orderedQuantity ? order?.orderedQuantity : quantity
        return (
          <BodyText>
            <b>{qt}</b>
          </BodyText>
        )
      },
    },
    {
      title: 'Size',
      dataIndex: table === 'approved' ? 'size' : 'product.size',
      key: 'size',
      className: 'restrictShrink quantity-padding-left',
      render: (text, order) => (
        <BodyText>{order.size ? order.size : order.product.size}</BodyText>
      ),
    },
    {
      title: 'Stock',
      dataIndex: table === 'approved' ? 'title' : 'product.title',
      key: 'stock',
      className: 'restrictShrink',
      width: 140,
      render: stock => (
        <BodyTextWrap>{removeLastBracketsWord(stock || '')}</BodyTextWrap>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'OMSPrice',
      className: 'restrictShrink',
      key: 'OMSPrice',
      render: (text, order) => {
        const hasVat =
          table === 'approved' ? order.hasVat : order.product.hasVat

        if (isAdmin && completed && editable) {
          return (
            <InputNumber
              defaultValue={getOMSPrice(order)}
              onChange={e => {
                const findReceipt = receiptingState.find(
                  receipt => receipt.order === order._id,
                )
                if (findReceipt) {
                  const newReceipt = receiptingState.map(receipt => {
                    if (receipt.order === order._id) {
                      return {
                        ...receipt,
                        orderedPrice: isReceiptingCheck(order, 'price')
                          ? order.receipting.updatedPrice
                          : order?.OMSPrice || order.price || 0,
                        updatedPrice: e * 100,
                      }
                    } else {
                      return receipt
                    }
                  })

                  setReceiptingState(newReceipt)
                } else {
                  const newReceipt = {
                    order: order._id,
                    error: ADMIN_ERROR,
                    supplier: order.supplier._id,
                    purchaseOrderNumber: order.purchaseOrderNumber,
                    orderedPrice: order?.OMSPrice || order.price || 0,
                    updatedPrice: e * 100,
                    // updatedQuantity: order?.receipting.updatedQuantity || order.updatedQuantity ||  order.quantity,
                    // orderedQuantity: order.updatedQuantity || order.quantity,
                    placedVia: order.placedVia,
                  }

                  setReceiptingState([...receiptingState, newReceipt])
                }
              }}
            />
          )
        } else {
          return `£${getOMSPrice(order)}${hasVat ? ' + VAT' : ''}`
        }
      },
    },
    {
      title: 'UoM',
      dataIndex: table === 'approved' ? 'UoM' : 'product.UoM',
      className: 'restrictShrink',
      key: 'product.UoM',
      render: text => {
        return text
          ? text === 'Each'
            ? `Price ${text}`
            : `Price per ${text}`
          : ''
      },
    },
    {
      title: 'Notes',
      dataIndex: 'productNotes',
      className: 'restrictShrink',
      key: 'productNotes',
      width: 180,
      render: (text, order) => (
        <BodyText>
          {order.productNotes ? order.productNotes : order.product.productNotes}
        </BodyText>
      ),
    },
    {
      title: 'Substituted from',
      dataIndex: 'substitutedProduct',
      key: 'substitutedProduct',
      render: (text, order) => (
        <BodyText>
          {order.substitutedProduct ? order.substitutedProduct : '-'}
        </BodyText>
      ),
    },
    {
      title: table === 'approved' ? 'Stock approved' : 'Stock Available',
      dataIndex: 'quantity',
      key: 'stockAvailable',
      className: 'restrictShrink text-right',
      render: (text, order) => {
        const { supplierConfirmed, quantity, updatedQuantity = null } = order
        return (
          <DivButtons>
            <Tooltip
              placement='topRight'
              title={'Edit the delivery quantity'}
              trigger={supplierConfirmed === undefined ? 'hover' : ''}
            >
              <InputNumberChecked
                size={buttonSize}
                table={table}
                min={0}
                max={quantity}
                defaultValue={
                  isAdminEdit(order)
                    ? isReceiptingCheck(order, 'weight')
                      ? order.receipting.weight
                      : isReceiptingCheck(order, 'quantity')
                      ? order.receipting.updatedQuantity
                      : isQuantityUpdated(order)
                      ? updatedQuantity
                      : quantity
                    : supplierConfirmed === false
                    ? 0
                    : isQuantityUpdated(order)
                    ? updatedQuantity
                    : quantity
                }
                onChange={value => {
                  if (editable) {
                    return handleAdminEditQuantity(order, value)
                  } else {
                    return handleChangeInputNumber(
                      { ...order },
                      [...orders],
                      setOrders,
                      value,
                    )
                  }
                }}
                checked={
                  (supplierConfirmed !== undefined || table === 'approved') &&
                  !editable
                }
                disabled={
                  (supplierConfirmed !== undefined ||
                    locked ||
                    table === 'approved') &&
                  !editable
                }
              />
            </Tooltip>
            {table === 'approved' ? (
              isAdminEdit(order) ? (
                <StockApprovedMessage status={ADMIN_ERROR}>
                  {layout !== 'sm' && <BodyText>Changed</BodyText>}
                  {layout !== 'md' && <ExclamationCircleOutlined />}
                </StockApprovedMessage>
              ) : isQuantityUpdated(order) ? (
                <StockApprovedMessage status={'adjusted'}>
                  {layout !== 'sm' && <BodyText>Adjusted</BodyText>}
                  {layout !== 'md' && <ExclamationCircleOutlined />}
                </StockApprovedMessage>
              ) : supplierConfirmed === false ? (
                <StockApprovedMessage status={'rejected'}>
                  {layout !== 'sm' && <BodyText>Rejected</BodyText>}
                  {layout !== 'md' && <CloseCircleOutlined />}
                </StockApprovedMessage>
              ) : (
                <StockApprovedMessage status={'approved'}>
                  {layout !== 'sm' && <BodyText>Approved</BodyText>}
                  {layout !== 'md' && <CheckCircleOutlined />}
                </StockApprovedMessage>
              )
            ) : (
              <>
                <AcceptButton
                  size={buttonSize}
                  onClick={event => {
                    handleCheckOrder(
                      event,
                      { ...order },
                      [...orders],
                      setOrders,
                      true,
                    )
                    event.target.blur()
                  }}
                  checked={supplierConfirmed}
                  disabled={locked}
                >
                  <CheckOutlined
                    style={{
                      fontSize: 17,
                      paddingBottom: 6,
                    }}
                  />
                </AcceptButton>
                <RejectButton
                  size={buttonSize}
                  onClick={event => {
                    handleCheckOrder(
                      event,
                      { ...order },
                      [...orders],
                      setOrders,
                      false,
                    )
                    event.target.blur()
                  }}
                  checked={supplierConfirmed === false}
                  disabled={locked}
                >
                  <CloseOutlined
                    style={{
                      fontSize: 17,
                      paddingBottom: 6,
                    }}
                  />
                </RejectButton>
              </>
            )}
          </DivButtons>
        )
      },
    },
  ]

  const actualColumns = isSubstitutedColumnShown
    ? allColumns
    : allColumns.filter(column => column.dataIndex !== 'substitutedProduct')

  return actualColumns
}

const BodyTextWrap = styled(BodyText)`
  white-space: break-spaces;
`

const DivButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const BaseButton = styled(Button)`
  width: 60px;
  margin-left: ${({ theme }) => `${theme.spacing.xxs} !important`};
  color: ${props =>
    props.checked && `${props.theme.colours.mayoWhite} !important`};
  &:hover {
    color: ${({ theme }) => `${theme.colours.lettuceGreen} !important`};
  }
`

const AcceptButton = styled(BaseButton)`
  background-color: ${props =>
    props.checked
      ? `${props.theme.colours.lettuceGreen} !important`
      : `${props.theme.colours.bgGrey} !important`};
`

const RejectButton = styled(BaseButton)`
  background-color: ${props =>
    props.checked
      ? `${props.theme.colours.chilliRed} !important`
      : `${props.theme.colours.bgGrey} !important`};
`
const InputNumberChecked = styled(InputNumber)`
  width: ${props =>
    props.table === 'approved' ? `60px !important` : `60px !important`};
  pointer-events: ${props => props.chedcked && `none`};
  color: ${props =>
    props.checked && `${props.theme.colours.mayoWhite} !important`};
  background-color: ${props =>
    props.checked && `${props.theme.colours.bbqBlack} !important`};
  .ant-input-number-input {
    text-align: center;
    padding: 0;
  }
`

const StockApprovedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing.xxs};
  color: ${props =>
    props.status === 'approved'
      ? props.theme.colours.lettuceGreen
      : props.status === 'rejected'
      ? props.theme.colours.chilliRed
      : props.status === ADMIN_ERROR
      ? '#777877'
      : 'orange'};
  ${({ theme }) => theme.device('sm')`
    width: 80px;
  `}
  ${({ theme }) => theme.device('md')`
    width: 100px;
  `}
`

export default TableColumnsCheckList
