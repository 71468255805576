import { DatePicker, Input, Table, Tag } from 'antd'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import FadeIn from 'src/components/styled/FadeIn'
import { fetchAdminOrders } from 'src/features/AdminOrderSlice'
import useLunrSearch from 'src/utils/useLunrSearch'
import styled from 'styled-components/macro'

const AdminOrders = () => {
  const { Search } = Input
  const dispatch = useDispatch()
  const weeklyDate = [moment().subtract(7, 'days'), moment()]
  const dateFormat = 'YYYY-MM-DD'

  const allAdminOrders = useSelector(state => state.adminOrders.adminOrders)
  const isLoading = useSelector(state => state.adminOrders.isLoading)

  const [searchTerm, setSearchTerm] = useState('')
  const [searchVenueOrders, setSearchVenueOrders] = useState([])

  useEffect(() => {
    dispatch(
      fetchAdminOrders(
        weeklyDate[0].format(dateFormat),
        weeklyDate[1].format(dateFormat),
      ),
    )
  }, [dispatch])

  const onDateSelection = (value, dateString) => {
    dispatch(fetchAdminOrders(dateString[0], dateString[1]))
  }

  dayjs.extend(utc)
  const resetPagination = useCallback(() => {}, [])

  useLunrSearch(
    allAdminOrders,
    ['supplierTitle', 'venueTitle', 'status'],
    searchTerm,
    setSearchVenueOrders,
    resetPagination,
  )

  const disableEndDate = current => current.valueOf() > moment.now()

  const stringSort = (a, b) => String(a).trim().localeCompare(String(b).trim())

  return (
    <Wrapper>
      <HeaderScreen title={'Orders'}></HeaderScreen>
      <SearchFilterBlock>
        <StyledRangePicker
          disabledDate={disableEndDate}
          format={dateFormat}
          defaultValue={weeklyDate}
          onChange={onDateSelection}
        />
        <Search
          size='large'
          placeholder='Search'
          onSearch={value => setSearchTerm(value)}
          style={{ width: 300 }}
          allowClear
        />
      </SearchFilterBlock>
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: false,
        }}
        dataSource={searchVenueOrders.map(data => ({
          ...data,
          key: data._id,
        }))}
        loading={isLoading}
        pagination={true}
        columns={[
          {
            title: 'Venue',
            dataIndex: 'venueTitle',
            key: 'venueTitle',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.venueTitle, b.venueTitle),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Time',
            dataIndex: 'date',
            key: 'time',
            className: 'restrictShrink',
            render: time => dayjs(time).format('hh.mma'),
          },
          {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            className: 'restrictShrink',
            render: date => dayjs.utc(date).format('DD/MM/YY'),
          },
          {
            title: 'Total',
            dataIndex: 'price',
            key: 'price',
            className: 'restrictShrink',
            render: text => {
              return text ? `£${Number(text / 100).toFixed(2)}` : ``
            },
          },
          {
            title: 'Supplier',
            dataIndex: 'supplierTitle',
            key: 'supplierTitle',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.supplierTitle, b.supplierTitle),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.status, b.status),
            sortDirections: ['ascend', 'descend'],
            render: status => (
              <Tag
                color={
                  status === 'Approved'
                    ? 'green'
                    : status === 'Rejected'
                    ? 'magenta'
                    : 'orange'
                }
              >
                {status}
              </Tag>
            ),
          },
          {
            title: 'Checklist',
            dataIndex: 'venueId',
            key: 'venueId',
            className: 'restrictShrink',
            render: (a, item) =>
              item && (
                <Action>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      color: 'DodgerBlue',
                      textDecorationLine: 'underline',
                    }}
                    href={`/checklist/${item.venueId}/${item.supplierId}/${dayjs
                      .utc(item.date)
                      .format('YYYY-MM-DD')}/${
                      item.purchaseOrderNumber
                        ? item.purchaseOrderNumber
                        : 'all'
                    }?admin=true`}
                  >
                    Checklist
                  </a>
                  {item.placedVia ===
                  'Order placed manually on the dashboard' ? (
                    <Placed>This order was placed outside of OMS</Placed>
                  ) : (
                    ''
                  )}
                </Action>
              ),
          },
        ]}
      />
    </Wrapper>
  )
}
const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 95px;
  }
`

const Action = styled.div`
  position: relative;
`

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
  }
`

const SearchFilterBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const Placed = styled.div`
  position: absolute;
  white-space: nowrap;
  width: auto;
  left: -85px;
  bottom: -18px;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => `${theme.colours.lettuceGreen}`};
`

export default AdminOrders
