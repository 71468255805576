import React, { useEffect, useState } from 'react'
import Loading from '../../components/styled/Loading'
import { getInvoiceUrlById } from '../../features/NewInvoicesSlice'
import { Layout } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import OMSLogo from '../../assets/images/OMSLogoWht.png'
import { BodyText, H4 } from '../../branding/typography'
import moment from 'moment/moment'

const InvoiceView = props => {
  const { venueId, invoiceId } = props.match.params
  const [loading, setLoading] = useState(true)
  const [invoice, setInvoice] = useState(null)

  useEffect(() => {
    console.log(setLoading)
    getInvoiceUrlById(venueId, invoiceId)
      .then(res => {
        setInvoice(res)
        setLoading(false)
        return
      })
      .catch(console.log)
  }, [venueId, invoiceId])

  return (
    <Layout>
      <Header>
        <LeftHeader>
          <Link to='/'>
            <Logo src={OMSLogo} alt='' />
          </Link>
        </LeftHeader>
        <MidHeader>
          <InvoiceTitle
            style={{
              fontWeight: '900',
              fontSize: '23px',
            }}
          >
            {'Invoice'}
          </InvoiceTitle>
          <BodyText>
            {invoice ? (
              <InvoiceDate>
                Uploaded Date:{' '}
                {moment(invoice.invoiceInfo[0].uploadedDate).format(
                  'DD-MM-YYYY',
                )}
              </InvoiceDate>
            ) : (
              ''
            )}
          </BodyText>
        </MidHeader>
      </Header>
      {loading && !invoice ? (
        <Loading />
      ) : (
        <Content>
          {invoice.invoiceUrl?.includes('.pdf') ? (
            <div>
              <iframe src={invoice.invoiceUrl} width='100%' height={700} />
            </div>
          ) : (
            <Invoice src={invoice.invoiceUrl} />
          )}
        </Content>
      )}
    </Layout>
  )
}

export default InvoiceView

const Header = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 104px !important;
  background: ${({ theme }) => theme.colours.bbqBlack} !important;
  padding: 0 ${({ theme }) => theme.spacing.xxl} !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 150px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.md} !important;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 190px !important;
    flex-wrap: wrap;
    padding: 0 ${({ theme }) => theme.spacing.xxs} !important;
  }
`
const LeftHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: center;
  width: 82px;
  /* background: beige; */
`

const Logo = styled.img`
  max-height: 100%;
  height: 80px;
  margin-top: 4px;
`
const MidHeader = styled.div`
  display: flex;
  position: absolute;
  max-width: 268px;
  left: 50%;
  transform: translate(-50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 75%;
    position: relative;
    max-width: 100%;
    left: 0;
    transform: translate(0%);
    justify-content: center;
    padding-right: 16px;
  }
  @media (max-width: 1480px) and (min-width: 1291px) {
    left: 40vw;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    align-items: flex-end;
  }
`
const InvoiceTitle = styled(H4)`
  text-align: center;
  color: ${({ theme }) => theme.colours.lettuceGreen};
`
const InvoiceDate = styled.div`
  max-width: 220px;
  text-align: center;
  color: ${({ theme }) => theme.colours.mayoWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 100%;
  }
`
const Invoice = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 500px;
  max-height: 800px;
`
const Content = styled(Layout.Content)`
  min-height: calc(100vh - 100px) !important;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-height: calc(100vh - 150px) !important;
  }
`
