import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import FadeIn from '../../components/styled/FadeIn'
import { Helmet } from 'react-helmet'
import { BodyText, H1, H4 } from '../../branding/typography'
import OMSLogo from '../../assets/images/OMSLogoWht.png'
import Talk from 'talkjs'
import { supplierNotifications } from '../../features/ChecklistSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchChecklist,
  handleChangeOrder,
  postChecklist,
} from '../../features/ReceiptingSlice'
import { Button, Popconfirm, Table, Tooltip } from 'antd'
import { getVenue } from '../../features/VenueSlice'
import LoadingScreen from '../../components/shared/LoadingScreen'
import { SpaceBetween } from '../../components/styled/FlexBox'
import moment from 'moment'
import ChecklistColumn from './ChecklistColumn'
import { useWindowWidth, windowSize } from '../../utils/useWindowWidth'
import LoadingButton from '../../components/styled/LoadingButton'

const ReceiptingChecklist = props => {
  const dispatch = useDispatch()
  const approvedOrders = useSelector(
    state => state.receipting.approvedReceipting,
  )
  const notApprovedOrders = useSelector(
    state => state.receipting.notApprovedReceipting,
  )
  const venueSupplier = useSelector(state => state.receipting.venueSupplier)
  const isLoading = useSelector(state => state.receipting.isLoading)
  const [handledOrders, setHandledOrders] = useState([])
  const [isAllOrdersHandled, setIsAllOrdersHandled] = useState(false)
  const [chatButton, setChatButton] = useState(true)
  const { venueId, supplierId, PONumber } = props.match.params
  const layout = windowSize(useWindowWidth())

  useEffect(() => {
    if (handledOrders.length) {
      handledOrders.length === notApprovedOrders.length
        ? setIsAllOrdersHandled(true)
        : setIsAllOrdersHandled(false)
    }
  }, [handledOrders])

  useEffect(() => {
    dispatch(getVenue(venueId))
    dispatch(fetchChecklist(venueId, supplierId, PONumber))
  }, [])

  const updateOrder = order => {
    dispatch(handleChangeOrder(order))
  }

  const buttonSize =
    layout === 'md' || layout === 'sm' || layout === 'xs' ? 'default' : 'large'

  const completeOrder = () => {
    dispatch(postChecklist(notApprovedOrders))
    dispatch(fetchChecklist(venueId, supplierId, PONumber))
  }

  const renderApprovedButton = () => (
    <ApprovedButton
      size={buttonSize}
      // loading={confirmingOrder && !locked}
      disabled={!isAllOrdersHandled}
      completed={!notApprovedOrders.length ? 1 : 0}
      block
    >
      {!notApprovedOrders.length ? 'Order completed' : 'Approve Order'}
    </ApprovedButton>
  )

  const { accountNumber, supplierTitle, supplier, venue } = venueSupplier || {}

  const getRenderDate = () => {
    if (approvedOrders.length) {
      return {
        approvedDay: moment(approvedOrders[0]?.approvedDate).format('dddd'),
        approvedDate: moment(approvedOrders[0]?.approvedDate).format(
          'DD/MM/YYYY',
        ),
      }
    }
    if (notApprovedOrders.length) {
      return {
        approvedDay: moment(notApprovedOrders[0]?.approvedDate).format('dddd'),
        approvedDate: moment(notApprovedOrders[0]?.approvedDate).format(
          'DD/MM/YYYY',
        ),
      }
    }
  }

  const talkhandle = async () => {
    Talk.ready
      .then(async function () {
        const me = new Talk.User({
          id: supplier,
          name: supplierTitle,
          welcomeMessage: 'Hey there! How are you? :-)',
          role: 'default',
        })
        const other = new Talk.User({
          id: venue._id,
          name: venue.title,
          welcomeMessage: 'Hey there! Send us a message to start a chat 😁:',
          role: 'venues',
        })
        window.talkSession = new Talk.Session({
          appId: 'GR4DzdvG',
          me: me,
        })
        const conversation = window.talkSession.getOrCreateConversation(
          Talk.oneOnOneId(me, other),
        )
        conversation.setParticipant(me)
        conversation.setParticipant(other)
        const popup = window.talkSession.createPopup(conversation, {
          keepOpen: true,
        })
        popup.onSendMessage(() => {
          // ... make backend request here
          try {
            supplierNotifications(venue._id, supplier)
          } catch (e) {
            console.log(e)
          }
        })
        popup.select(conversation)
        await popup.mount({ show: true })
        setChatButton(false)
        return
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Wrapper>
          <Helmet>
            <title>Order checklist</title>
          </Helmet>
          <Header>
            <HeaderContent>
              <LeftHeader>
                <BodyText>
                  Hey {supplierTitle}, here is your Order Change Request.
                </BodyText>
                <VenueName style={{ color: '#b5d922' }}>
                  {venue ? venue.title : ''}
                </VenueName>
                {accountNumber && (
                  <BodyText>Account number: {accountNumber}</BodyText>
                )}
              </LeftHeader>
              <Logo src={OMSLogo} alt='Orders Made Simple logo' />
            </HeaderContent>
          </Header>
          <InnerBody>
            <TableHeader>
              <SpaceBetween>
                {approvedOrders.length || notApprovedOrders.length ? (
                  <H4>
                    This relates to the order you approved on{' '}
                    {getRenderDate()?.approvedDay}:{' '}
                    {getRenderDate()?.approvedDate}
                  </H4>
                ) : null}
                {chatButton && (
                  <ChatBox onClick={() => talkhandle()}>
                    Message customer
                  </ChatBox>
                )}
              </SpaceBetween>
            </TableHeader>
            {!!notApprovedOrders.length && (
              <Table
                scroll={{
                  scrollToFirstRowOnChange: true,
                }}
                dataSource={notApprovedOrders.map((order, index) => ({
                  key: order._id,
                  index: index,
                  ...order,
                }))}
                pagination={false}
                columns={ChecklistColumn(
                  setHandledOrders,
                  updateOrder,
                  'notApproved',
                  layout,
                )}
              />
            )}
            {!!approvedOrders.length && (
              <Table
                scroll={{
                  scrollToFirstRowOnChange: true,
                }}
                dataSource={approvedOrders.map((order, index) => ({
                  key: order._id,
                  index: index,
                  ...order,
                }))}
                pagination={false}
                columns={ChecklistColumn(
                  setHandledOrders,
                  updateOrder,
                  'approved',
                  layout,
                )}
              />
            )}
            <RowButton>
              <Buttons>
                {!notApprovedOrders.length ? (
                  renderApprovedButton()
                ) : !isAllOrdersHandled ? (
                  <Tooltip
                    placement='topRight'
                    title={
                      !isAllOrdersHandled
                        ? 'You need to accept or reject every product'
                        : ''
                    }
                  >
                    {renderApprovedButton()}
                  </Tooltip>
                ) : (
                  <Popconfirm
                    title='Are you sure you want to confirm this order?'
                    placement='topRight'
                    onConfirm={e => {
                      e.stopPropagation()
                      completeOrder()
                    }}
                    onCancel={e => {
                      e.stopPropagation()
                    }}
                    okText='Yes'
                    cancelText='No'
                  >
                    {renderApprovedButton()}
                  </Popconfirm>
                )}
                {/*{orderError && (*/}
                {/*  <ErrorMessage>*/}
                {/*    There has been an error accepting this order. Please contact*/}
                {/*    info@ordersmadesimple.com*/}
                {/*  </ErrorMessage>*/}
                {/*)}*/}
              </Buttons>
            </RowButton>
          </InnerBody>
        </Wrapper>
      )}
    </>
  )
}

const TableHeader = styled.div`
  margin: ${({ theme }) => `${theme.spacing.xl} 0 ${theme.spacing.lg} 0`};
`

const RowButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Wrapper = styled(FadeIn)`
  background-color: ${({ theme }) => theme.colours.mayoWhite};
`
const Header = styled.header`
  background: ${({ theme }) => theme.colours.bbqBlack};
  padding: ${({ theme }) => `${theme.spacing.xl} 0`};
`

const VenueName = styled(H1)`
  line-height: ${({ theme }) => theme.spacing.xl};
  padding: 10px 0;
`

const ApprovedButton = styled(LoadingButton)`
  cursor: ${props => props.completed && 'default'};
  background-color: ${props =>
    props.completed && `${props.theme.colours.seaweedGreen} !important`};
  color: ${props =>
    props.completed && `${props.theme.colours.mayoWhite} !important`};
`
const Buttons = styled.div`
  width: 200px;
  margin: ${({ theme }) => `${theme.spacing.lg} 0 ${theme.spacing.xl} 0`};
`

const Inner = styled.div`
  width: 60%;
  margin: 0 auto;
  ${({ theme }) => theme.device('sm')`
      padding: 0 15px;
  `}
`

const InnerBody = styled(Inner)`
  .ant-table-thead * {
    background-color: ${({ theme }) => theme.colours.mayoWhite};
    white-space: nowrap;
  }
  .restrictShrink {
    white-space: nowrap;
    padding: 12px 3px;
    ${({ theme }) => theme.device('sm')`
      padding: 14px 4px;
    `}
    ${({ theme }) => theme.device('md')`
      padding: 16px 5px;
    `}
  }
  .restrictShrink:first-child {
    padding-left: 0;
  }
  .restrictShrink:last-child {
    padding-right: 0;
  }
  .restrictShrink.quantity-padding-left {
    padding-left: 5px;
    ${({ theme }) => theme.device('sm')`
      padding-left: 10px;
    `}
  }
  .text-right {
    text-align: right;
  }
`

const HeaderContent = styled(Inner)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  * {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colours.mayoWhite};
  }
`

const LeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
`

const Logo = styled.img`
  height: 108px;
`
const ChatBox = styled(Button)`
  width: fit-content;
  margin-top: ${({ theme }) => theme.spacing.xxs};
`

export default ReceiptingChecklist
