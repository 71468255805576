import { Spin } from 'antd'
import React from 'react'
import { Center } from 'src/components/styled/FlexBox'
import styled from 'styled-components/macro'

const Loading = () => {
  return (
    <SpinWrapper>
      <Spin size='large' />
    </SpinWrapper>
  )
}

const SpinWrapper = styled(Center)`
  height: 380px;
  margin: 0 auto;
`
export default Loading
