import lunr from 'lunr'
import { useEffect, useMemo, useRef } from 'react'

const useLunrSearch = (
  items,
  searchFields,
  searchTerm,
  setSearchItems,
  resetPagination,
) => {
  const indexFields = useRef(searchFields).current
  const idx = useMemo(
    () =>
      lunr(function () {
        this.ref('_id')
        indexFields.forEach(fieldName =>
          this.field(fieldName, {
            extractor: item => {
              if (fieldName.includes('.')) {
                const fieldPath = fieldName.split('.')
                return fieldPath.reduce(
                  (accumulator, pathEl) => accumulator && accumulator[pathEl],
                  item,
                )
              }
              return item[fieldName]
            },
          }),
        )

        items.forEach(function (item) {
          this.add(item)
        }, this)
      }),
    [items, indexFields],
  )

  useEffect(() => {
    if (searchTerm === '') {
      setSearchItems(items)
    } else {
      const exactSearch = idx.query(q => {
        // look for an exact match and apply a large positive boost
        q.term(lunr.tokenizer(searchTerm), {
          boost: 100,
          usePipeline: true,
          presence: lunr.Query.presence.REQUIRED,
        })
      })

      const wildcardSearch = idx.query(q => {
        // look for terms that match with a partial searchTerm and apply a medium boost
        q.term(lunr.tokenizer(searchTerm), {
          boost: 10,
          usePipeline: true,
          presence: lunr.Query.presence.REQUIRED,
          wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
        })
      })

      // const fuzzySearch = idx.query(q => {
      //   // look for terms that match with an edit distance of 1 and apply a small boost
      //   q.term(lunr.tokenizer(searchTerm), {
      //     boost: 1,
      //     editDistance: 1,
      //     presence: lunr.Query.presence.REQUIRED,
      //   })
      // })

      // const searchResults = [...exactSearch, ...wildcardSearch, ...fuzzySearch]
      const searchResults = [...exactSearch, ...wildcardSearch]
      const mappedResults = searchResults.reduce(
        (accumulator, searchResult) => {
          const seenIds = accumulator.map(result => result._id)
          const alreadyAdded = seenIds.includes(searchResult.ref)
          return alreadyAdded
            ? accumulator
            : [
                ...accumulator,
                ...items.filter(item => item._id === searchResult.ref),
              ]
        },
        [],
      )

      setSearchItems(mappedResults)
      resetPagination()
    }
  }, [items, indexFields, searchTerm, setSearchItems, idx, resetPagination])
}

export default useLunrSearch
