import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  fetchReceiptingOrders,
  fetchReceiptingProducts,
  updateReceiptingProduct,
} from '../../features/ReceiptingSlice'
import moment from 'moment'
import OrdersColumns from './OrdersColumns'
import styled from 'styled-components'
import { Modal, Spin, Table, Input, DatePicker, Button } from 'antd'
import Loading from '../../components/styled/Loading'
import { Center } from '../../components/styled/FlexBox'
import ProductColumns from './ProductsColumns'
import { getUploadedInvoices } from '../../features/NewInvoicesSlice'
import {
  sendIntegration,
  setOrdersToSend,
} from '../../features/DataplusIntagrationSlice'
import { BodyText } from '../../branding/typography'
import AddInvoices from '../../components/AddInvoices'
import SearchInput from './SearchInput'
import { DateRangeFilter } from '../receipting/DateRangeFilter'

const DataplusScreen = props => {
  const { venueId, venueTitle } = props
  const dateFormat = 'DD/MM/YYYY'

  const dispatch = useDispatch()

  const receiptingProducts = useSelector(
    state => state.receipting.receiptingProducts,
  )
  const updatingReceiptingProduct = useSelector(
    state => state.receipting.updatingReceiptingProduct,
  )
  const receiptingLoading = useSelector(
    state => state.receipting.receiptingLoading,
  )
  const { uploadedInvoices, uploadedLoading } = useSelector(
    state => state.newInvoices,
  )
  const filterDates = useSelector(state => state.receipting.filterDates)
  const receiptingExist = useSelector(
    state => state.receipting.receiptingOrdersAlreadyExists,
  )

  const {
    ordersToSend,
    isLoading: isDataPlusLoading,
    dataplusOrders,
  } = useSelector(state => state.dataplus)
  const isReceiptingLoading = useSelector(state => state.receipting.isLoading)

  const [invoiceRecord, setCurrentRecord] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalSend, setModalSend] = useState(false)
  const [attachInvoices, setAttachInvoices] = useState([])
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [currentInvoices, setCurrentInvoices] = useState([])
  const [checkAttachedInvoices, setCheckAttachedInvoices] = useState([])
  const [invoiceDate, setInvoiceDate] = useState(moment().format(dateFormat))
  const [filteredOrders, setFilteredOrders] = useState([])
  const [currentPoductRecord, setProductRecord] = useState(null)
  const [pdfModal, setPDfModal] = useState(null) //here will be order id

  useEffect(() => {
    setFilteredOrders(filterOrders(dataplusOrders))
  }, [dataplusOrders])

  useEffect(() => {
    if (receiptingExist === venueId) return
    dispatch(
      fetchReceiptingOrders(
        venueId,
        moment(filterDates.start).format(dateFormat),
        moment(filterDates.end).format(dateFormat),
      ),
    )
  }, [filterDates, venueId])

  useEffect(() => {
    if (uploadedInvoices.length === 0 && modalVisible) {
      dispatch(getUploadedInvoices(venueId))
    }

    if (
      invoiceRecord &&
      invoiceRecord?.dataplus?.sent &&
      invoiceRecord.dataplus.attachedInvoices.length > 0 &&
      uploadedInvoices.length > 0
    ) {
      const attachedInvoices = uploadedInvoices.filter(uploadedInvoice =>
        invoiceRecord.dataplus.attachedInvoices.includes(uploadedInvoice._id),
      )

      setCurrentInvoices(attachedInvoices)
    } else {
      setCurrentInvoices(uploadedInvoices)
    }
  }, [modalVisible, uploadedInvoices])

  useEffect(() => {
    if (invoiceRecord && ordersToSend) {
      const invoices = ordersToSend.find(order => {
        const { purchaseOrderNumber, supplier_id } = order
        const { purchaseOrderNumber: currentPO = '', supplier = '' } =
          invoiceRecord
        if (purchaseOrderNumber === currentPO && supplier._id === supplier_id) {
          return true
        }
      })

      if (!invoices) return setCheckAttachedInvoices([])
      setCheckAttachedInvoices(
        uploadedInvoices.filter(uploadedInvoice =>
          invoices.attachedInvoices.includes(uploadedInvoice._id),
        ),
      )
    }
  }, [modalSend, invoiceRecord])

  const filterOrders = orders => {
    return orders.map(order => {
      return {
        ...order,
      }
    })
  }

  const renderExpandedOrder = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    const currentOrder = receiptingProducts.find(
      item =>
        item.supplier === supplierId && item.purchaseOrderNumber === PONumber,
    )
    return receiptingLoading && !currentOrder ? (
      <Center>
        <Spin size={'large'} />
      </Center>
    ) : (
      <Table
        columns={ProductColumns(setProductRecord)}
        dataSource={currentOrder?.orders}
        pagination={false}
        rowKey={record => record._id}
      />
    )
  }

  const getExpandedOrder = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    const currentOrder = receiptingProducts.find(
      item =>
        item.supplier === supplierId && item.purchaseOrderNumber === PONumber,
    )
    if (currentOrder) return
    dispatch(fetchReceiptingProducts(venueId, supplierId, PONumber))
  }

  const attachInvoicesToOrder = () => {
    const newInvoice = {
      attachedInvoices: attachInvoices,
      purchaseOrderNumber: invoiceRecord?.purchaseOrderNumber,
      supplier_id: invoiceRecord?.supplier._id,
      ordersIds: invoiceRecord?.orderIds,
    }

    dispatch(setOrdersToSend([...ordersToSend, newInvoice]))
    setAttachInvoices([])
    setModalVisible(false)
  }

  const sendIntegrationToDB = async () => {
    try {
      const invoices = ordersToSend.find(order => {
        const { purchaseOrderNumber, supplier_id } = order
        const { purchaseOrderNumber: currentPO = '', supplier = '' } =
          invoiceRecord
        if (purchaseOrderNumber === currentPO && supplier._id === supplier_id) {
          return true
        }
      })

      const body = {
        dataplusInfo: {
          attachedInvoices: invoices?.attachedInvoices || [],
          invoiceDate: invoiceDate,
          invoiceNumber,
        },
        PONumber: invoiceRecord?.purchaseOrderNumber,
        requiredDeliveryDay: invoiceRecord?.requiredDeliveryDay || null,
        orderIds: invoiceRecord.orderIds,
      }

      setInvoiceNumber('')
      await dispatch(sendIntegration(venueId, body))
      setInvoiceDate(moment())
      setCurrentRecord(null)
      setModalSend(false)
    } catch (e) {
      console.log('Error in sendIntegrationToDB', e)
    }
  }
  const orderMatchesInvoice = (order, invoiceId) => {
    if (!invoiceRecord) return

    const { purchaseOrderNumber = '', supplier = '' } = invoiceRecord
    return (
      order?.purchaseOrderNumber === purchaseOrderNumber &&
      order.supplier_id === supplier._id &&
      order.attachedInvoices.includes(invoiceId)
    )
  }

  const checkInvoice = invoiceId => {
    return (
      ordersToSend.some(order => orderMatchesInvoice(order, invoiceId)) ||
      attachInvoices.includes(invoiceId)
    )
  }

  const isSent = invoiceRecord?.dataplus?.sent

  const footerContent = (
    <FooterWrapper
      style={{ justifyContent: isSent ? 'flex-end' : 'space-between' }}
    >
      {!isSent ? (
        <AddInvoices
          venueId={venueId}
          key={'Add Invoices'}
          setAttachInvoices={setAttachInvoices}
        />
      ) : null}
      <div>
        <Button
          key='back'
          onClick={() => {
            setAttachInvoices([])
            setModalVisible(false)
          }}
        >
          Cancel
        </Button>
        <Button
          key='submit'
          type='primary'
          disabled={isSent}
          onClick={() => {
            attachInvoicesToOrder()
          }}
        >
          Attach
        </Button>
      </div>
    </FooterWrapper>
  )

  return (
    <>
      {isReceiptingLoading ? (
        <Loading />
      ) : (
        <div style={{ flex: '0 0 100%' }}>
          <FilterWrap>
            <DateRangeFilter />
            <SearchInput
              allOrders={filterOrders(dataplusOrders)}
              setOrders={setFilteredOrders}
              onClear={() => setFilteredOrders(filterOrders(dataplusOrders))}
            />
          </FilterWrap>

          <StyledTable
            dataSource={filteredOrders}
            columns={OrdersColumns(
              setModalVisible,
              setCurrentRecord,
              setModalSend,
              pdfModal,
              setPDfModal,
              venueId,
              venueTitle,
            )}
            expandedRowRender={renderExpandedOrder}
            onExpand={(expanded, record) =>
              expanded ? getExpandedOrder(record) : null
            }
            rowKey={record =>
              `${record.purchaseOrderNumber}${
                record.supplier._id
              }${record.orderIds.join()}`
            }
            bordered
          />
        </div>
      )}
      <Modal
        width={'40%'}
        title={'Select Invoices'}
        okText={'Attach'}
        visible={modalVisible}
        okButtonProps={{ disabled: invoiceRecord?.dataplus?.sent }}
        onOk={() => {
          attachInvoicesToOrder()
        }}
        footer={footerContent}
        onCancel={() => {
          setAttachInvoices([])
          setModalVisible(false)
        }}
      >
        {uploadedLoading || currentInvoices.length < 0 ? (
          <Loading />
        ) : currentInvoices.length === 0 ? (
          <div>No data</div>
        ) : (
          <InvoiceWrapper>
            {currentInvoices.map(invoice => {
              return (
                <InvoiceItem
                  key={invoice.key}
                  aria-disabled={invoiceRecord?.dataplus?.sent}
                  onClick={() => {
                    const newSet = new Set(attachInvoices)
                    if (newSet.has(invoice._id)) {
                      newSet.delete(invoice._id)
                    } else {
                      newSet.add(invoice._id)
                    }
                    setAttachInvoices([...newSet])
                  }}
                  style={
                    checkInvoice(invoice._id)
                      ? { border: '2px solid greenyellow', opacity: 0.4 }
                      : {}
                  }
                >
                  <InvoiceImg
                    src={invoice.invoiceTempUrl}
                    alt={'Try Again !!!'}
                  />
                </InvoiceItem>
              )
            })}
          </InvoiceWrapper>
        )}
      </Modal>
      <Modal
        width={'30%'}
        title={'Send  file'}
        okText={'Send'}
        visible={modalSend}
        okButtonProps={{ disabled: invoiceNumber.length === 0 }}
        onOk={async () => {
          return await sendIntegrationToDB()
        }}
        onCancel={() => {
          setInvoiceNumber('')
          setModalSend(false)
        }}
      >
        {isDataPlusLoading ? (
          <Loading />
        ) : (
          <>
            <InputWrapper>
              <BodyText>Invoice Number:</BodyText>
              <Input
                defaultValue={invoiceNumber}
                placeholder={'Invoice number'}
                onChange={e => setInvoiceNumber(e.target.value)}
              />
            </InputWrapper>

            <InputWrapper>
              <BodyText>Invoice date:</BodyText>
              <DatePicker
                style={{ marginLeft: 20 }}
                placeholder={'Invoice date'}
                format={dateFormat}
                onChange={data => setInvoiceDate(data)}
              />
            </InputWrapper>

            <ModalTitle>Your attached invoices</ModalTitle>
            {invoiceRecord && (
              <InvoiceWrapper>
                {checkAttachedInvoices.length === 0 ? (
                  <span>You don`t have attached invoices</span>
                ) : (
                  checkAttachedInvoices.map(invoice => {
                    return (
                      <InvoiceItem key={invoice._id}>
                        <InvoiceImg
                          src={invoice.invoiceTempUrl}
                          alt={'Try Again !!!'}
                        />
                      </InvoiceItem>
                    )
                  })
                )}
              </InvoiceWrapper>
            )}
          </>
        )}
      </Modal>
      <Modal
        title='Edit GLN'
        visible={currentPoductRecord !== null}
        onOk={async () => {
          await dispatch(
            updateReceiptingProduct(
              currentPoductRecord.product._id,
              { GLN: currentPoductRecord.GLN },
              currentPoductRecord,
            ),
          )

          await dispatch(
            fetchReceiptingProducts(
              venueId,
              currentPoductRecord.supplier,
              currentPoductRecord.purchaseOrderNumber,
            ),
          )

          setProductRecord(null)
        }}
        confirmLoading={updatingReceiptingProduct}
        onCancel={() => {
          setProductRecord(null)
        }}
      >
        {currentPoductRecord ? (
          <Input
            value={currentPoductRecord.product.GLN || ''}
            onChange={e =>
              setProductRecord(prevState => ({
                ...prevState,
                GLN: e.target.value,
              }))
            }
          />
        ) : null}
      </Modal>
    </>
  )
}

export default DataplusScreen

const StyledTable = styled(Table)`
  .restrict-shrink {
    min-width: 120px;
  }
`
const InvoiceImg = styled.img`
  width: 300px;
  height: auto;
`

const InvoiceWrapper = styled.div`
  display: flex;
  overflow-x: auto; /
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
`

const InvoiceItem = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    border: 2px solid greenyellow;
    opacity: 0.4;
    transition: transform 0.3s ease;
  }
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
`

const ModalTitle = styled.p`
  font-weight: bold;
  font-size: 18px;
`

const FooterWrapper = styled.div`
  display: flex;
`

const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
