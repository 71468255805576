import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from 'src/config'

// Initiating Sentry Issue Tracking \\
Sentry.init({
  dsn: config.sentry.DNS,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

export default Sentry
