import { Button, Modal, Table } from 'antd'
import dayjs from 'dayjs'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import NoDataScreen from 'src/components/shared/no-data-screen/NoDataScreen'
import ProductImage from 'src/components/shared/ProductImage'
import SearchBar from 'src/components/shared/SearchBar'
import FadeIn from 'src/components/styled/FadeIn'
import Loading from 'src/components/styled/Loading'
import { VAT_RATE } from 'src/config'
import { performSearch } from 'src/features/CatalogueSearchSlice'
import {
  addVenueProduct,
  fetchVenueProducts,
} from 'src/features/VenueProductsSlice'
import { getVenueSuppliers } from 'src/features/VenueSuppliersSlice'
import AddVenueProductModal from 'src/screens/catalogue/AddVenueProductModal'
import wordCaps from 'src/utils/wordCaps'
import styled from 'styled-components/macro'
import { createOrder } from '../../features/OrdersSlice'
import BasketFunctionality from '../basketFunctionality/BasketFunctionality'

const Catalogue = props => {
  const venueId = props.match.params.venueId
  const dispatch = useDispatch()

  const searchProducts = useSelector(state => state.catalogueSearch.products)
  const searchPage = useSelector(state => state.catalogueSearch.page)
  const searchPageSize = useSelector(state => state.catalogueSearch.pageSize)
  const searchTotal = useSelector(state => state.catalogueSearch.total)
  const searchLoading = useSelector(state => state.catalogueSearch.loading)
  const { isAdmin } = useSelector(state => state.auth.account)
  const { isPricesHidden, dataplusIntegration } = useSelector(
    state => state.venue.venue,
  )
  const venueSuppliers = useSelector(
    state => state.venueSuppliers.venueSuppliers,
  )
  const areVenueSuppliersLoading = useSelector(
    state => state.venueSuppliers.isLoading,
  )
  const isSomeVenueSupplierActive = useSelector(
    state => state.venueSuppliers.isSomeVenueSupplierActive,
  )
  const venueProducts = useSelector(state => state.venueProducts.products)

  const [showModal, setShowModal] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState({
    title: '',
    zone: '',
    secondaryZone: '',
    supplier: '',
    supplierTitle: '',
    isFavourite: false,
  })
  const [addToBasket, setAddToBasket] = useState(false)

  useEffect(() => {
    if (venueProducts.length === 0 && venueId)
      dispatch(fetchVenueProducts(venueId))
    if (venueSuppliers.length === 0 && venueId)
      dispatch(getVenueSuppliers(venueId))
  }, [dispatch, venueId])

  const venueProductsIds = venueProducts
    .filter(prod => prod.product)
    .map(vProduct => vProduct.product._id)

  const allTruthyTypes = searchProducts
    ? searchProducts
        .map(product => wordCaps(product.type).trim())
        .filter(type => Boolean(type))
    : []
  const uniqueProductTypes = [...new Set(allTruthyTypes)]

  const allTruthySuppliers = searchProducts
    ? searchProducts
        .map(product => product?.supplier?.title)
        .filter(supplier => Boolean(supplier))
    : []
  const uniqueProductSuppliers = [...new Set(allTruthySuppliers)]

  useEffect(() => {
    if (addToBasket) {
      // eslint-disable-next-line
      const { _id, ...res } = venueProducts.at(-1)
      dispatch(
        createOrder({
          ...res,
          quantity: selectedProduct.defaultQuantity || 1,
          OMSSpecialPrice:
            selectedProduct.OMSSpecialPrice !== 'NaN'
              ? selectedProduct.OMSSpecialPrice
              : 0,
          method: 'create',
        }),
      )
      setAddToBasket(false)
    }
  }, [venueProducts])

  useEffect(() => {
    if (!searchProducts?.length) {
      dispatch(performSearch({ venueId, pageNumber: 1, pageSize: 200 }))
    }
  }, [])

  const handleOnOk = () => {
    dispatch(
      addVenueProduct({
        ...selectedProduct,
        zone: selectedProduct.zone || selectedProduct.supplierTitle,
        secondaryZone: selectedProduct.secondaryZone || '',
        product: showModal,
        venue: venueId,
      }),
    )
    setShowModal(null)
  }

  return (
    <Wrapper>
      <HeaderScreen
        title={'Catalogue'}
        subTitle={'Browse products from all your active suppliers'}
      ></HeaderScreen>
      {areVenueSuppliersLoading ? (
        <Loading />
      ) : isSomeVenueSupplierActive === false ? (
        <NoDataScreen page='catalogue' />
      ) : (
        <Fragment>
          <SearchBar venueId={venueId} />
          {(searchProducts || searchLoading) && (
            <StyledTable
              scroll={{
                scrollToFirstRowOnChange: true,
                x: true,
              }}
              dataSource={(searchProducts || []).map(product => ({
                key: product._id,
                ...product,
              }))}
              pagination={{
                position: 'both',
                pageSize: searchPageSize,
                pageSizeOptions: ['10', '20', '50', '100', '250'],
                showSizeChanger: true,
                current: searchPage,
                total: searchTotal,
                onChange: page =>
                  dispatch(
                    performSearch({
                      venueId,
                      pageNumber: page,
                      pageSize: searchPageSize,
                    }),
                  ),
                onShowSizeChange: (_, pageSize) =>
                  dispatch(performSearch({ venueId, pageNumber: 1, pageSize })),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} results`,
              }}
              loading={searchLoading}
              rowClassName={product => {
                let inVenueCat = venueProductsIds.includes(product._id)
                return inVenueCat ? 'active' : ''
              }}
              columns={[
                {
                  title: '',
                  dataIndex: 'cloudinaryImageId',
                  key: 'cloudinaryImageId',
                  render: imageId => {
                    return <ProductImage imageId={imageId} />
                  },
                },
                {
                  title: 'Title',
                  dataIndex: 'title',
                  key: 'title',
                  className: 'restrictShrink',
                  sorter: (a, b) =>
                    String(a.title)
                      .trim()
                      .localeCompare(String(b.title).trim()),
                  sortDirections: ['ascend', 'descend'],
                },
                {
                  title: 'Size',
                  dataIndex: 'size',
                  key: 'size',
                  className: 'restrictShrink',
                  sorter: (a, b) =>
                    String(a.size).trim().localeCompare(String(b.size).trim()),
                  sortDirections: ['ascend', 'descend'],
                },
                (!isPricesHidden && isAdmin) || !isAdmin
                  ? {
                      title: 'Price',
                      dataIndex: 'price',
                      key: 'price',
                      className: 'restrictShrink',
                      render: (text, item) => {
                        let diffSymbol = ''
                        if (
                          Object.prototype.hasOwnProperty.call(
                            item,
                            'priceHistory',
                          ) &&
                          item.priceHistory.length
                        ) {
                          const priceLastUpdated = dayjs(
                            item.priceHistory[0].updatedAt,
                          )
                          const diff = dayjs().diff(priceLastUpdated, 'days')
                          if (diff < 14) {
                            diffSymbol = '▲'
                            if (item.price < item.priceHistory[0].formerPrice) {
                              diffSymbol = '▼'
                            }
                          }
                        }
                        return (
                          <span className='price-change'>
                            £{Number(text).toFixed(2)}
                            {item.hasVat ? ' + VAT' : ''}
                            {diffSymbol && (
                              <span className='diff-symbol'>
                                {diffSymbol}
                                <span className='tooltip'>
                                  Price has changed in the last 14 days
                                </span>
                              </span>
                            )}
                          </span>
                        )
                      },
                      sorter: (a, b) =>
                        (a.hasVat ? VAT_RATE * a.price : a.price) -
                        (b.hasVat ? VAT_RATE * b.price : b.price),
                      sortDirections: ['ascend', 'descend'],
                    }
                  : {},
                {
                  title: 'UoM',
                  dataIndex: 'UoM',
                  key: 'UoM',
                  className: 'restrictShrink',
                  render: text => {
                    return text || ''
                  },
                  sorter: (a, b) =>
                    String(a.size).trim().localeCompare(String(b.size).trim()),
                  sortDirections: ['ascend', 'descend'],
                },
                {
                  title: 'Priced by',
                  dataIndex: 'pricedBy',
                  key: 'pricedBy',
                  className: 'restrictShrink',
                  render: (text, prod) => {
                    return text
                      ? text === 'Each'
                        ? `${text}`
                        : `per ${text}`
                      : prod.UoM
                      ? prod.UoM === 'Each'
                        ? `${prod.UoM}`
                        : `per ${prod.UoM}`
                      : ''
                  },
                },
                {
                  title: 'Supplier',
                  dataIndex: 'supplier.title',
                  key: 'supplier.title',
                  className: 'restrictShrink',
                  filters: uniqueProductSuppliers.map(supplier => ({
                    text: supplier,
                    value: supplier,
                  })),
                  onFilter: (value, record) =>
                    record?.supplier?.title === value,
                },
                {
                  title: 'SKU',
                  dataIndex: 'sku',
                  key: 'sku',
                  className: 'restrictShrink',
                  sorter: (a, b) =>
                    String(a.sku).trim().localeCompare(String(b.sku).trim()),
                  sortDirections: ['ascend', 'descend'],
                },
                dataplusIntegration
                  ? {
                      title: 'GLN',
                      dataIndex: 'GLN',
                      key: 'GLN',
                      className: 'restrictShrink',
                      render: GLN => {
                        return <span>{GLN || ''}</span>
                      },
                    }
                  : {},
                {
                  title: 'Type',
                  dataIndex: 'type',
                  key: 'type',
                  className: 'restrictShrink',
                  filters: uniqueProductTypes.map(type => ({
                    text: type,
                    value: type,
                  })),
                  onFilter: (value, record) => record?.type === value,
                },
                {
                  title: 'Action',
                  key: 'action',
                  className: 'restrictShrink',
                  render: (text, product) => {
                    const isInProductList = venueProductsIds.includes(
                      product._id,
                    )

                    return isInProductList ? (
                      <span>Added</span>
                    ) : (
                      <Button
                        type={'sucsess'}
                        onClick={() => {
                          setSelectedProduct({
                            title: product.title,
                            type: product.type,
                            UoM: product.UoM,
                            supplier: product.supplier,
                            supplierTitle: product.supplier.title,
                          })
                          setShowModal(product)
                        }}
                      >
                        <span>Add</span>
                      </Button>
                    )
                  },
                },
                {
                  title: 'Add to basket',
                  key: 'addToBasket',
                  className: 'restrictShrink',
                  render: (_, product) => {
                    return (
                      <BasketFunctionality
                        order={product}
                        venueId={venueId}
                        venueProducts={venueProducts}
                      />
                    )
                  },
                },
              ]}
            />
          )}

          <Modal
            visible={showModal !== null}
            onOk={handleOnOk}
            onCancel={() => {
              setShowModal(null)
            }}
          >
            {showModal && (
              <AddVenueProductModal
                setSelectedProduct={setSelectedProduct}
                selectedProduct={selectedProduct}
                setAddToBasket={setAddToBasket}
                addToBasket={addToBasket}
              />
            )}
          </Modal>
        </Fragment>
      )}
    </Wrapper>
  )
}

const StyledTable = styled(Table)`
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
`

const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 95px;
  }

  .diff-symbol {
    position: relative;
    font-size: 13px;
    margin-left: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.colours.bgGrey};

    &:hover {
      .tooltip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }

  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 150px;
    background: white;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    border-radius: 5px;
    padding: ${({ theme }) => theme.spacing.xxs};
    color: black;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 200ms;
    transform: translate(-50%, 10px);
  }
`

export default Catalogue
