import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const SpendingSlice = createSlice({
  name: 'supplierOrder',
  initialState: {
    supplierOrder: [],
    venueReports: [],
    expandedTableLoading: false,
    loading: false,
  },
  reducers: {
    setSpending: (state, action) => {
      state.supplierOrder = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setVenueReports: (state, action) => {
      state.venueReports.push(action.payload)
    },
    clearVenueReports: (state, action) => {
      state.venueReports = action.payload
    },
    setExpandedTableLoading: (state, action) => {
      state.expandedTableLoading = action.payload
    },
  },
})

export const {
  setExpandedTableLoading,
  setSpending,
  setLoading,
  setVenueReports,
  clearVenueReports,
} = SpendingSlice.actions

export const fetchSpendingSuppliers =
  (FROM_DATE, TO_DATE) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const session = await Auth.currentAuthenticatedUser()
      const url = `supplier/spending?from=${FROM_DATE}&to=${TO_DATE}&username=${session.attributes.sub}`
      const data = await API.get('api', url)
      // console.log(data)
      dispatch(
        setSpending(
          data?.filteredWithVenueSuppliers
            ? data.filteredWithVenueSuppliers
            : [],
        ),
      )
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      Sentry.captureException(e)
      return false
    }
  }

export const fetchSpendingSuppliersVenueReport =
  (FROM_DATE, TO_DATE, supplierId, perPage, page) => async dispatch => {
    try {
      dispatch(setExpandedTableLoading(true))
      const session = await Auth.currentAuthenticatedUser()
      const url = `supplier/spending?from=${FROM_DATE}&to=${TO_DATE}&username=${session.attributes.sub}&supplierTitle=${supplierId}&perPage=${perPage}&page=${page}`
      const venueReport = await API.get('api', url)
      const report = {
        venueReportArr: venueReport,
        supplierId,
      }
      dispatch(setVenueReports(report))
      dispatch(setExpandedTableLoading(false))
    } catch (e) {
      dispatch(setExpandedTableLoading(false))
      Sentry.captureException(e)
      return false
    }
  }

export const clearAllVenueReports = () => async dispatch => {
  dispatch(clearVenueReports([]))
}

export default SpendingSlice
