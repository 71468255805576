import { Input, Switch } from 'antd'
import React from 'react'
import { isEmail, ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/style.css'
import styled from 'styled-components/macro'

const EditSupplierModal = ({ supplier, setEditingSupplier }) => {
  return (
    <Wrapper>
      <div>
        Please note that updating the values below updates all venues in the
        database
      </div>
      <Row>
        <InputComp
          value={supplier.title}
          onChange={e => {
            setEditingSupplier({ ...supplier, title: e.target.value })
          }}
          addonBefore={'Title'}
          placeholder='Title'
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.secondTitle}
          onChange={e => {
            setEditingSupplier({ ...supplier, secondTitle: e.target.value })
          }}
          addonBefore={'Second title'}
          placeholder='Second title'
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.emailAddress}
          onChange={e => {
            setEditingSupplier({
              ...supplier,
              emailAddress: e.target.value.toLowerCase(),
            })
          }}
          addonBefore={'Order email address'}
          placeholder='Email address'
        />
      </Row>
      <Row>
        <Div>
          <Span>Second Email Addresses</Span>
          <ReactMultiEmail
            placeholder=''
            emails={
              supplier.secondEmailAddresses ? supplier.secondEmailAddresses : []
            }
            onChange={_emails => {
              setEditingSupplier({ ...supplier, secondEmailAddresses: _emails })
            }}
            validateEmail={
              email => isEmail(email) // return boolean
            }
            getLabel={(email, index, removeEmail) => (
              <InnerDiv data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  x
                </span>
              </InnerDiv>
            )}
          />
        </Div>
      </Row>
      <Row>
        <InputComp
          value={supplier.phoneNumber}
          onChange={e => {
            setEditingSupplier({
              ...supplier,
              phoneNumber: e.target.value,
            })
          }}
          addonBefore={'Contact number'}
          placeholder='Contact number'
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.vendorCode}
          onChange={e => {
            setEditingSupplier({
              ...supplier,
              vendorCode: e.target.value,
            })
          }}
          addonBefore={'Vendor Code'}
          placeholder='Vendor Code'
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.integrationType}
          onChange={e => {
            setEditingSupplier({
              ...supplier,
              integrationType: e.target.value,
            })
          }}
          addonBefore={'Integration type'}
          placeholder='Integration type'
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.minOrderValue}
          onChange={e => {
            setEditingSupplier({ ...supplier, minOrderValue: e.target.value })
          }}
          addonBefore={'Minimum order value'}
          placeholder='Minimum order value'
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.orderCutOffTime}
          onChange={e => {
            setEditingSupplier({
              ...supplier,
              orderCutOffTime: e.target.value,
            })
          }}
          addonBefore={'Order cut off time'}
        />
      </Row>
      <Row>
        <InputComp
          value={supplier.logoUrl}
          onChange={e => {
            setEditingSupplier({ ...supplier, logoUrl: e.target.value })
          }}
          addonBefore={'Logo URL'}
          placeholder='Logo URL'
        />
      </Row>

      <Row>
        <SwitchSpan>Allows credit purchase</SwitchSpan>
        <SwitchComp
          value={supplier.allowsCreditPurchase}
          onChange={e => {
            setEditingSupplier({ ...supplier, allowsCreditPurchase: e })
          }}
          checked={supplier.allowsCreditPurchase}
        />
      </Row>
      <Row>
        <SwitchSpan>TFR</SwitchSpan>
        <SwitchComp
          value={supplier.TFRSupplier}
          onChange={e => {
            setEditingSupplier({ ...supplier, TFRSupplier: e })
          }}
          checked={supplier.TFRSupplier}
        />
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const InputComp = styled(Input)``

const Row = styled.div`
  margin: 15px 0;
`

const SwitchComp = styled(Switch)``

const SwitchSpan = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 7px;
`

const InnerDiv = styled.div`
  background-color: ${({ theme }) => theme.colours.lettuceGreen} !important;
`
const Span = styled.span`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 8px 10px;
  border: 1px solid ${({ theme }) => theme.colours.panSilver};
  background-color: ${({ theme }) => theme.colours.bgGrey};
`
const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .react-multi-email {
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .react-multi-email:hover,
  .react-multi-email.focused {
    border: 1px solid #b5d922 !important;
  }
`

export default EditSupplierModal
