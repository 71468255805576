import styled from 'styled-components'
import Search from 'antd/es/input/Search'
import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
export default function SearchInput({ allOrders, setOrders, onClear }) {
  const [checkBoxName, setCheckBoxName] = useState('PON')
  const [searchQuery, setSearchQuery] = useState('')
  const [isEmptySearch, setIsEmptySearch] = useState(false)

  useEffect(() => {
    setOrders(
      allOrders.filter(order => {
        if (checkBoxName === 'PON') {
          return order.purchaseOrderNumber?.toLowerCase()?.includes(searchQuery)
        } else if (checkBoxName === 'Supplier') {
          return order.supplier.title
            ?.trim()
            ?.toLowerCase()
            ?.includes(searchQuery)
        } else if (checkBoxName === 'IntegrationStatus') {
          if (searchQuery === 'sent') {
            return order.orderDataplusStatus?.toLowerCase() === 'sent'
          }
          return order.orderDataplusStatus?.toLowerCase()?.includes(searchQuery)
        } else return true
      }),
    )
    if (!searchQuery) onClear()
  }, [searchQuery, checkBoxName])

  const onSearch = () => {
    if (!searchQuery.trim()) {
      return setIsEmptySearch(true)
    }
  }

  return (
    <SearchWrapper>
      <SearchContainer>
        <Search
          size='large'
          placeholder='Search by'
          style={{ width: 300, margin: '0 5px 0 10px' }}
          type='text'
          value={searchQuery}
          allowClear
          onPressEnter={onSearch}
          onChange={e => {
            if (isEmptySearch) setIsEmptySearch(false)
            setSearchQuery(e.target.value.toLowerCase())
          }}
        />
        {isEmptySearch && <SearchError>Please enter a search term</SearchError>}
      </SearchContainer>
      <div style={{ margin: '0 10px' }}>
        <Checkbox
          checked={checkBoxName === 'Supplier'}
          onChange={() => setCheckBoxName('Supplier')}
        >
          Supplier
        </Checkbox>
        <Checkbox
          checked={checkBoxName === 'PON'}
          onChange={() => setCheckBoxName('PON')}
        >
          PON
        </Checkbox>
        <Checkbox
          checked={checkBoxName === 'IntegrationStatus'}
          onChange={() => setCheckBoxName('IntegrationStatus')}
        >
          Integration Status
        </Checkbox>
      </div>
    </SearchWrapper>
  )
}
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`
const SearchContainer = styled.div`
  position: relative;
`

const SearchError = styled.p`
  color: #ff4d4f;
  position: absolute;
  bottom: 100%;
  padding: 0;
  margin: 0;
  left: 10px;
`
