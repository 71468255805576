import { Checkbox, Input } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutocompleteInput from 'src/components/shared/AutocompleteInput'
import wordCaps from 'src/utils/wordCaps'
import styled from 'styled-components'
import Title from 'src/branding/Title'
import { editVenue } from '../../features/VenueSlice'

const AddVenueProductModal = ({
  selectedProduct,
  setSelectedProduct,
  setAddToBasket,
  addToBasket,
}) => {
  const venueProducts = useSelector(state => state.venueProducts.products)
  const venue = useSelector(state => state.venue.venue)
  const dispatch = useDispatch()
  const existingZones = [
    ...new Set(venueProducts.map(product => wordCaps(product.zone).trim())),
  ]
  const existingTypes = [
    ...new Set(venueProducts.map(product => wordCaps(product.type).trim())),
  ]
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Add to your products</Title>
      </TitleWrapper>
      <DescriptionLine1>This product will be availble on your</DescriptionLine1>
      <DescriptionLine2>Orders Made Simple app</DescriptionLine2>
      <Row>
        <InputComp
          value={selectedProduct.title}
          onChange={e => {
            setSelectedProduct({ ...selectedProduct, title: e.target.value })
          }}
          addonBefore={'Title'}
          placeholder='Title'
        />
      </Row>
      <Row>
        <AutocompleteInput
          fieldName='Zone'
          options={existingZones}
          selected={selectedProduct.zone}
          onChange={val => {
            setSelectedProduct({
              ...selectedProduct,
              zone: val,
            })
          }}
        />
      </Row>
      <Row>
        <AutocompleteInput
          fieldName='Secondary Zone'
          options={venue.secondaryZones}
          selected={selectedProduct.secondaryZone}
          onChange={val => {
            if (!venue.secondaryZones.includes(val)) {
              const updatedVenue = {
                ...venue,
                secondaryZones: [...venue.secondaryZones, val],
              }
              dispatch(editVenue(updatedVenue, venue._id))
            }

            setSelectedProduct({
              ...selectedProduct,
              secondaryZone: val,
            })
          }}
        />
      </Row>
      <Row>
        <InputComp
          value={selectedProduct.defaultQuantity}
          onChange={e => {
            setSelectedProduct({
              ...selectedProduct,
              defaultQuantity: e.target.value,
            })
          }}
          addonBefore={'Default Quantity'}
          placeholder='Default Quantity'
        />
      </Row>
      <Row>
        <AutocompleteInput
          fieldName='Type'
          options={existingTypes}
          selected={selectedProduct.type}
          onChange={val => {
            setSelectedProduct({
              ...selectedProduct,
              type: val,
            })
          }}
        />
      </Row>
      <Row>
        <InputComp
          value={selectedProduct.monthlyLimit}
          onChange={e => {
            setSelectedProduct({
              ...selectedProduct,
              monthlyLimit: e.target.value,
            })
          }}
          addonBefore={'Monthly Order Limit'}
          placeholder='Leave blank for no limit'
        />
      </Row>
      <Row>
        <Checkbox
          onChange={() => {
            setSelectedProduct({
              ...selectedProduct,
              isFavourite: !selectedProduct.isFavourite,
            })
          }}
          checked={selectedProduct.isFavourite}
        >
          Is Favourite
        </Checkbox>
        <Checkbox
          onChange={() => {
            setAddToBasket(!addToBasket)
          }}
          checked={addToBasket}
        >
          Add to basket
        </Checkbox>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const DescriptionLine1 = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colours.oliveGrey};
  margin-top: 3px;
`
const DescriptionLine2 = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colours.oliveGrey};
  margin-bottom: 3px;
`
const InputComp = styled(Input)``

const Row = styled.div`
  margin: 15px 0;
`
export default AddVenueProductModal
