import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const overViewSlice = createSlice({
  name: 'overView',
  initialState: {
    pastOrders: [],
    pastOrdersSpending: [],
    isLoading: false,
    paginationPastOrders: {page: 1, limit: 10},
    paginationSpending: {page: 1, limit: 10},
    totalCountPastOrders: 0,
    totalCountSpending: 0,
  },
  reducers: {
    setPastOrders: (state, action) => {
      state.pastOrders = action.payload
    },
    setPastOrdersSpending: (state, action) => {
      state.pastOrdersSpending = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setPaginationPastOrders: (state, action) => {
      state.paginationPastOrders = action.payload
    },
    setPaginationSpending: (state, action) => {
      state.paginationSpending = action.payload
    },
    setTotalCountPastOrders: (state, action) => {
      state.totalCountPastOrders = action.payload
    },
    setTotalCountSpending: (state, action) => {
      state.totalCountSpending = action.payload
    }
  },
})

export const { setPastOrders, setPastOrdersSpending, setIsLoading, setPaginationPastOrders, setPaginationSpending, setTotalCountSpending, setTotalCountPastOrders } =
  overViewSlice.actions

export const fetchPastOrders = (venueIds, pagination, startDate, endDate) => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const session = await Auth.currentAuthenticatedUser()
    const url = `pastOrders?username=${session.attributes.sub}&venueIds=${venueIds}&isOrder=true&page=${pagination.page}&limit=${pagination.limit}&startDate=${startDate}&endDate=${endDate}`
    const data = await API.get('api', url)
    dispatch(setPastOrders(data.pastOrders))
    dispatch(setTotalCountPastOrders(data.totalCount))
    dispatch(setPaginationPastOrders(pagination))
    dispatch(setIsLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setIsLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export const fetchPastOrdersSpending = (venueIds, pagination, startDate, endDate) => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const session = await Auth.currentAuthenticatedUser()
    const url = `venueSpendings?username=${session.attributes.sub}&venueIds=${venueIds}&isSpending=true&page=${pagination.page}&limit=${pagination.limit}&startDate=${startDate}&endDate=${endDate}`
    const data = await API.get('api', url)
    dispatch(setPastOrdersSpending(data.spendingArr))
    dispatch(setTotalCountSpending(data.totalCount))
    dispatch(setPaginationSpending(pagination))
    dispatch(setIsLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setIsLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export default overViewSlice
