import styled from 'styled-components/macro'

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
