import { Button, Modal, Table, Tag } from 'antd'
import Dinero from 'dinero.js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Title from 'src/branding/Title'
import FadeIn from 'src/components/styled/FadeIn'
import { editAccount, fetchAccounts } from 'src/features/AccountsSlice'
import { fetchAllSuppliers } from 'src/features/SupplierSlice'
import EditAccountModal from 'src/screens/accounts/EditAccountModal'
import styled from 'styled-components/macro'

const Accounts = () => {
  const dispatch = useDispatch()
  const accounts = useSelector(state => state.accounts.accounts)
  const isLoading = useSelector(state => state.accounts.isLoading)
  const Allvenues = useSelector(state => state.auth.account.venues)
  const suppliers = useSelector(state => state.suppliers.suppliers)

  const [editingAccount, setEditingAccount] = useState(null)

  useEffect(() => {
    dispatch(fetchAccounts())
    if (suppliers.length === 0) dispatch(fetchAllSuppliers())
  }, [dispatch])

  return (
    <Wrapper>
      <Title>Accounts</Title>

      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        dataSource={(accounts || []).map(account => ({
          ...account,
          key: account._id,
        }))}
        loading={isLoading}
        pagination={false}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'restrictShrink',
          },
          {
            title: 'Email address',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            className: 'restrictShrink',
          },
          {
            title: 'Venue owned',
            dataIndex: '_id',
            key: 'venue',
            className: 'restrictShrink',
            render: accountId =>
              Allvenues.find(venue => venue.account === accountId)?.title,
          },
          {
            title: 'Credit limit',
            dataIndex: 'creditLimit',
            key: 'creditLimit',
            className: 'restrictShrink',
            render: creditLimit =>
              Dinero({
                amount: creditLimit || 0,
                currency: 'GBP',
              }).toFormat('$0,0.00'),
          },
          {
            title: 'Days credit after statement',
            dataIndex: 'creditDays',
            key: 'creditDays',
            className: 'restrictShrink',
          },
          {
            title: 'Credit approvals',
            dataIndex: 'creditApproved',
            key: 'creditApproved',
            className: 'restrictShrink',
            render: approvals => (
              <Tag color={approvals?.length ? 'green' : 'red'}>
                {approvals?.length
                  ? `${approvals.length} Approval${
                      approvals.length === 1 ? '' : 's'
                    }`
                  : 'No approvals'}
              </Tag>
            ),
          },
          {
            title: 'Action',
            key: 'action',
            className: 'restrictShrink',
            render: (text, account) => (
              <>
                <StyledButton
                  type='dashed'
                  onClick={() => {
                    setEditingAccount(account)
                  }}
                >
                  Edit
                </StyledButton>
                &nbsp;
              </>
            ),
          },
        ]}
      />

      <Modal
        title='Edit account'
        visible={editingAccount !== null}
        onOk={() => {
          dispatch(
            editAccount({
              ...editingAccount,
            }),
          )
          setEditingAccount(null)
        }}
        onCancel={() => {
          setEditingAccount(null)
        }}
      >
        {editingAccount && (
          <EditAccountModal
            allVenues={Allvenues}
            account={editingAccount}
            suppliers={suppliers}
            setEditingAccount={account => {
              setEditingAccount(account)
            }}
          />
        )}
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)``

const StyledButton = styled(Button)`
  margin: 3px;
`

export default Accounts
