import React, {useEffect} from "react";
import {clearFullHistory, getFullHistory} from "../../features/ReceiptingSlice";
import Loading from "../../components/styled/Loading";
import {Link} from "react-router-dom";
import {Button, Table} from "antd";
import {useSelector, useDispatch} from "react-redux";
import FullHistoryColumns from "./FullHistoryColumns";
import styled from 'styled-components/macro'

const FullReceiptingHistory = (props) => {
    const dispatch = useDispatch();
    const {
        history,
        totalCount,
        isLoading,
        pagination
    } = useSelector(state => state.receipting);
    const { venueId } = props.match.params

    useEffect(() => {
        dispatch(getFullHistory(venueId, pagination))
    }, [])

    useEffect(() => {
        return () => {
            dispatch(clearFullHistory())
        }
    }, [])

    return (
        <>
            {
                isLoading
                    ? <Loading />
                    : (
                        <div>
                            <Button>
                                <Link to={`/v/${venueId}/purchasing/receipting`}>Go back</Link>
                            </Button>
                            <StyledTable
                                dataSource={history}
                                columns={FullHistoryColumns()}
                                pagination={{
                                    position: 'both',
                                    total: totalCount,
                                    pageSize: pagination.limit,
                                    pageSizeOptions: ['10', '20', '50', '100', '250'],
                                    showSizeChanger: true,
                                    current: pagination.page,
                                    onChange: page => {
                                        dispatch(getFullHistory(venueId, {page: page, limit: pagination.limit}))
                                    },
                                    onShowSizeChange: (_, pageSize) =>
                                        dispatch(getFullHistory(venueId, {page: pagination.page, limit: pageSize})),
                                    showTotal: (total, range) =>
                                        `${range[0]}-${range[1]} of ${total} results`,
                                }}
                            />
                        </div>
                    )
            }
        </>
    );
}

const StyledTable = styled(Table)`
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
`

export default FullReceiptingHistory;
