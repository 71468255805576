import React from 'react'
import { CSVLink } from 'react-csv'
import { Button } from 'antd'
import dayjs from 'dayjs'
import { VAT_PERCENTAGE, VAT_RATE } from '../../config'

const ValuationCSV = ({ stockTakeProducts, stockTake, totals, endpoint }) => {
  const isEndPointWastage = endpoint === "wastage-report";
  const rangeInString = `${dayjs(stockTake?.fromPeriod).format(
    'YYYY-MM-DD',
  )} - ${dayjs(stockTake?.toPeriod).format('YYYY-MM-DD')}`
  const headersStockTakeValuation = [
    { key: 'product' },
    { key: 'size' },
    { key: 'UoM' },
    { key: 'supplier' },
    { key: 'price' },
    { key: 'prev_qty' },
    { key: 'received' },
    { key: 'case_quantity' },
    { key: 'stock_value' },
    { key: 'wastage' },
    { key: 'wastage_value' },
    { key: 'weighted' },
    { key: 'VAT' },
    { key: 'Net' },
    { key: 'Gross' },
  ].filter(header => {
    if (isEndPointWastage) {
      return header.key !== 'case_quantity' && header.key !== 'stock_value';
    } else {
      return header.key !== 'wastage' && header.key !== 'wastage_value';
    }
  })
  const dataOfReport = []
  dataOfReport.push({ product: `Report ${rangeInString}` })
  dataOfReport.push({})
  dataOfReport.push({
    product: 'Product',
    size: 'Size',
    UoM: 'UoM',
    supplier: 'Supplier',
    price: 'Price',
    prev_qty: 'Prev qty',
    received: 'Received',
    case_quantity: 'Stock Amount',
    stock_value: 'Stock Value',
    wastage: 'Wastage',
    wastage_value: 'Wastage value',
    weighted: 'Weighted',
    VAT: 'VAT',
    Net: 'Net',
    Gross: 'Gross',
  })
  stockTakeProducts.forEach(product => {
    const currentQuantity = isEndPointWastage ? product.wastage : product.inStock
    dataOfReport.push({
      product: product.title,
      size: product.size,
      UoM: product.UoM,
      supplier: product.supplierTitle,
      price: product.price,
      prev_qty: product.lastQuantity,
      received: product.qtySinceLastStockTake,
      case_quantity: currentQuantity,
      stock_value: product.hasVat
        ? product.price * product.inStock * VAT_RATE
        : product.price * product.inStock,
      wastage: product.wastage,
      wastage_value: product.hasVat
        ? product.price * product.wastage * VAT_RATE
        : product.price * product.wastage,
      weighted: product.UoM
        ? product.UoM.toLowerCase() === 'kg' ||
          product.UoM.toLowerCase() === 'g'
        : false,
      VAT: product.hasVat
        ? VAT_PERCENTAGE * (currentQuantity * product.price)
        : 0,
      Net: product.price * currentQuantity,
      Gross: product.hasVat
        ? VAT_RATE * currentQuantity * product.price
        : currentQuantity * product.price,
    })
  })
  dataOfReport.push(
    {},
    {},
    {
      product: 'Totals:',
      VAT: `£${totals.VAT}`,
      Net: `£${totals.net}`,
      Gross: `£${totals.gross}`,
    },
  )

  return (
    <Button style={{ marginRight: '10px' }}>
      <CSVLink
        data={dataOfReport}
        headers={headersStockTakeValuation}
        filename={`oms-${isEndPointWastage ? 'wastage' : 'valuation'}-stock-take${rangeInString}.csv`}
      >
        Download CSV
      </CSVLink>
    </Button>
  )
}

export default ValuationCSV
