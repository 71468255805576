import { Button, Modal } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import createPDFTemplate from './createPDFTemplate'
import styled from 'styled-components/macro'
import { fetchReceiptingProducts } from '../../features/ReceiptingSlice'
import { useDispatch, useSelector } from 'react-redux'

export default function PDFExportModal({
  isOpen,
  onClose,
  record,
  venueId,
  venueTitle,
}) {
  const pdfExportComponent = useRef(null)
  const PONumber = record.purchaseOrderNumber
  const [orders, setOrders] = useState()

  const dispatch = useDispatch()
  const receiptingProducts = useSelector(
    state => state.receipting.receiptingProducts,
  )

  useEffect(() => {
    if (isOpen === `${record.purchaseOrderNumber}${record.supplier._id}`) {
      dispatch(fetchReceiptingProducts(venueId, record.supplier._id, PONumber))
    }
  }, [isOpen])

  useEffect(() => {
    if (receiptingProducts) {
      setOrders(
        receiptingProducts.find(
          item =>
            item.supplier === record.supplier._id &&
            item.purchaseOrderNumber === PONumber,
        ),
      )
    }
  }, [receiptingProducts])

  const handleExport = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save()
    }
  }

  return (
    <Modal
      title='Dataplus Order PDF'
      style={{
        backgroundColor: 'white',
        maxHeight: 700,
        overflow: 'scroll',
        paddingBottom: 0,
      }}
      width={'80%'}
      visible={
        !!(
          isOpen === `${record.purchaseOrderNumber}${record.supplier._id}` &&
          orders
        )
      }
      onCancel={() => onClose()}
      footer={
        <FooterWrapper>
          <Button type={'primary'} onClick={handleExport}>
            Download
          </Button>
        </FooterWrapper>
      }
    >
      <PDFExport
        ref={pdfExportComponent}
        paperSize='A4'
        landscape={true}
        fileName='Dataplus Order'
        style={{ fontFamily: 'Arial, sans-serif' }}
      >
        {createPDFTemplate(PONumber, orders, record, venueTitle)}
      </PDFExport>
    </Modal>
  )
}
const FooterWrapper = styled.div`
  background-color: white;
  padding: 16px 24px !important;
  box-shadow: 0 0 18px #01003026;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
`
