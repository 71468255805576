import { Layout, Typography } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Logo from 'src/assets/images/OMSLogoBlk.png'
import {
  resetEmailAddress,
  submitCodeAndPassword,
} from 'src/features/AuthSlice'
import ResetPasswordForm from 'src/screens/reset-password/ResetPasswordForm'
import styled from 'styled-components/macro'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth)
  return (
    <Wrapper className='layout'>
      <Layout.Content>
        <LoginFormWrapper>
          <img src={Logo} alt='' width='100px' />
          <ResetPasswordForm
            submitEmailAddress={address => {
              dispatch(resetEmailAddress(address))
            }}
            user={user}
            submitCodeAndPassword={data => {
              dispatch(submitCodeAndPassword(data))
            }}
          />
          {user.error && (
            <Typography.Text type='danger'>{user.error}</Typography.Text>
          )}
        </LoginFormWrapper>
      </Layout.Content>
    </Wrapper>
  )
}

const Wrapper = styled(Layout)`
  min-height: 100vh !important;

  img {
    margin-bottom: 25px;
  }

  .ant-typography-danger {
    display: block;
    text-align: center;
  }
  main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .ant-form-item-children {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`

const LoginFormWrapper = styled.div`
  width: 400px;
  background: ${({ theme }) => theme.colours.mayoWhite};
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
`

export default ResetPassword
