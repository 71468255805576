const title = {
  orders: 'Orders',
  statement: 'Overview',
  basket: 'Shopping Cart',
  ordered: 'Ordered products',
  spendTime: 'Spend over time',
  credit: 'Credit',
  receipting: 'Goods receipting',
}

const getTitle = slug => title[slug]

export default getTitle
