import styled from 'styled-components'

// Table px to rem conversion
// http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/

export const H1 = styled.h1`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem; // 40px
`

export const H2 = styled.h2`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem; // 28px
`

export const H3 = styled.h3`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem; // 24px
`

export const H4 = styled.h4`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem; // 20px
`

export const CTAText = styled.span`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem; /// 18px
`

export const BodyText = styled.span`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-size: 1rem; // 16px
  text-align: ${props => props.textAlign || 'left'};
`

export const DetailText = styled.span`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem; // 14px
`
