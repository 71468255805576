import React from 'react'
import { ResponsiveLine } from '@nivo/line'

const LineChart = ({ data, venue }) => {
  const chartData = [
    {
      id: venue,
      data:
        data && data.length > 0
          ? data.map(item => ({
              x: item.monthName,
              y: parseFloat(item.totalAmountNoVAT),
            }))
          : [],
    },
  ]

  return (
    <ResponsiveLine
      data={chartData}
      margin={{ top: 10, right: 45, bottom: 50, left: 25 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      yFormat=' >-.2f'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      colors={'grey'}
      // enablePoints={false}
      pointSize={10}
      pointColor={'white'}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
      minValue={0}
      motionConfig='default'
      enableSlices={false}
      tooltip={({
        point: {
          data: { x, y },
          serieColor,
        },
      }) => (
        <div
          style={{
            background: 'white',
            padding: '9px 12px',
            border: '1px solid #ccc',
          }}
        >
          <span
            style={{
              color: 'black',
            }}
          >
            Month: <strong>{x}</strong>
          </span>
          <br />
          <span
            style={{
              color: serieColor,
            }}
          >
            Spent:{' '}
            <strong
              style={{
                color: 'black',
              }}
            >
              £{y}
            </strong>
          </span>
        </div>
      )}
    />
  )
}

export default LineChart
