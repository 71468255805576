import { Checkbox, Input, Select } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
const { Option } = Select

const ProductCard = ({ suppliers, addingProduct, setAddingProduct }) => {
  return (
    <CardComp>
      <h3>Provide information about the new product: </h3>
      <InputComp
        value={addingProduct.title}
        addonBefore={'Title'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, title: e.target.value })
        }}
        placeholder='Title'
      />
      <InputComp
        value={addingProduct.size}
        addonBefore={'Size'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, size: e.target.value })
        }}
        placeholder='Size'
      />
      <InputComp
        value={addingProduct.price}
        addonBefore={'£'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, price: e.target.value })
        }}
        placeholder='Customer Price'
      />

      <InputComp
        value={addingProduct.OMSPrice}
        addonBefore={'£'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, OMSPrice: e.target.value })
        }}
        placeholder='OMS Price'
      />
      <StyledDiv>
        <span>UoM</span>
        <Select
          allowClear
          placeholder='Unit of measure'
          style={{ width: '75%' }}
          onChange={value => {
            setAddingProduct({ ...addingProduct, UoM: value })
          }}
        >
          <Select.Option key='Kg'>Kg</Select.Option>
          <Select.Option key='Each'>Each</Select.Option>
          <Select.Option key='Pack'>Pack</Select.Option>
          <Select.Option key='Box'>Box</Select.Option>
        </Select>
      </StyledDiv>

      <StyledDiv>
        <span>Supplier</span>

        <Select
          placeholder='Choose supplier'
          onChange={supplier => {
            setAddingProduct({ ...addingProduct, supplier: supplier })
          }}
          style={{ width: '70%' }}
        >
          <Option key='' value=''></Option>
          {suppliers.map(supplier => {
            return (
              <Option key={supplier._id} value={supplier._id}>
                {supplier.title + ' ' + supplier.secondTitle}
              </Option>
            )
          })}
        </Select>
      </StyledDiv>

      <InputComp
        value={addingProduct.sku}
        addonBefore={'SKU'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, sku: e.target.value })
        }}
        placeholder='SKU'
      />
      <InputComp
        value={addingProduct.GLN}
        addonBefore={'GLN'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, GLN: e.target.value })
        }}
        placeholder='GLN'
      />
      <InputComp
        value={addingProduct.type}
        addonBefore={'Type'}
        onChange={e => {
          setAddingProduct({ ...addingProduct, type: e.target.value })
        }}
        placeholder='Type'
      />
      <CheckboxWrapper>
        <span>Has VAT?</span>
        <Checkbox
          checked={addingProduct.hasVat}
          onChange={e => {
            setAddingProduct({ ...addingProduct, hasVat: e.target.checked })
          }}
          placeholder='Has Vat'
        />
      </CheckboxWrapper>
    </CardComp>
  )
}

const CheckboxWrapper = styled.div`
  span {
    margin-right: 10px;
  }
  .ant-checkbox-checked:after {
    border: none;
  }
`

const CardComp = styled.div``

const InputComp = styled(Input)`
  margin-bottom: 12px;
`

const StyledDiv = styled.div`
  margin-bottom: 12px;
  span {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 4px 10px;
    max-height: 32px;
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    background-color: ${({ theme }) => theme.colours.bgGrey};
  }
  display: flex;
`

export default ProductCard
