import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components/macro'

const ProductColumns = setProductRecord => {
  const selectDataIndex = (record, field) => {
    switch (field) {
      case 'title': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedTitle ||
              record.title ||
              record.product.title
          : record.title || record.product.title
      }
      case 'price': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedPrice ||
              record.price ||
              record.product.price
          : record.price || record.product.price
      }
      case 'size': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedSize || record.size || record.product.size
          : record.size || record.product.size
      }
      case 'quantity': {
        return record.status === 'Rejected by supplier'
          ? record.quantity
          : record.receipting?.updatedQuantity >= 0
          ? record.receipting?.updatedQuantity
          : record.updatedQuantity
          ? record.updatedQuantity
          : record.quantity
      }
      case 'sku': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedSku || record.sku || record.product.sku
          : record.sku || record.product.sku
      }
      case 'GLN': {
        return record?.product?.GLN || ''
      }
      case 'updatedQuantity': {
        return record.status !== 'Rejected by supplier'
          ? record.updatedQuantity
          : record.quantity
      }
    }
  }

  const getTotalPrice = record => {
    const price =
      record.status !== 'Rejected by supplier'
        ? record.receipting?.updatedPrice ||
          record.price ||
          record.product.price
        : record.price || record.product.price

    let totalPrice = 0

    totalPrice =
      record.status === 'Rejected by supplier'
        ? price * record.quantity
        : record.receipting?.updatedQuantity >= 0
        ? price * record.receipting?.updatedQuantity
        : record?.updatedQuantity
        ? price * record?.updatedQuantity
        : price * record.quantity

    return (totalPrice / 100).toFixed(2)
  }

  return [
    {
      title: 'Title',
      dataIndex: 'title' || 'product.title',
      render: (text, record) => {
        const title = selectDataIndex(record, 'title')
        return title
      },
    },
    {
      title: 'Item Price',
      dataIndex: 'price' || 'product.price',
      render: (text, record) => {
        const price = selectDataIndex(record, 'price')
        return `£${(price / 100).toFixed(2)}`
      },
    },
    {
      title: 'Total Price',
      render: (_, record) => {
        return `£${getTotalPrice(record)}`
      },
    },
    {
      title: 'Size',
      width: '10%',
      dataIndex: 'size' || 'product.size',
      render: (text, record) => {
        const size = selectDataIndex(record, 'size')
        return size
      },
    },
    {
      title: 'Ordered Qty',
      dataIndex: 'orderedQuantity',
      render: (text, record) => {
        return text ? text : record.quantity
      },
    },
    {
      title: 'Received Qty',
      render: (_, record) => {
        const qty = selectDataIndex(
          record,
          record.receipting?.updatedQuantity >= 0
            ? 'quantity'
            : record?.updatedQuantity
            ? 'updatedQuantity'
            : 'quantity',
        )
        return qty
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku' || 'product.sku',
      width: '10%',
      render: (text, record) => {
        const sku = selectDataIndex(record, 'sku')
        return sku
      },
    },
    {
      title: 'GLN',
      dataIndex: 'product.GLN',
      width: '15%',
      render: (text, record) => {
        const GLN = selectDataIndex(record, 'GLN')
        return (
          <StyledGLNField>
            <GLNText>{GLN}</GLNText>
            <GLNEditIconWrapper>
              <EditOutlined
                style={{ fontSize: '15px' }}
                onClick={() => setProductRecord(record)}
                color={'red'}
              />
            </GLNEditIconWrapper>
          </StyledGLNField>
        )
      },
    },
    {
      title: 'Weighted',
      dataIndex: 'UoM',
      render: (UoM, record) => {
        if (UoM !== 'Kg' && record.product.UoM !== 'Kg') {
          return 'Not Weighted'
        }
        return `${record.quantity} Kg`
      },
    },
  ]
}

const StyledGLNField = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`
const GLNText = styled.p`
  margin-bottom: 0;
  width: 70%;
`
const GLNEditIconWrapper = styled.div`
  height: 30px;
  width: 30px;
  background-color: #b5d922;
  border-radius: 6px;
  text-align: center;
`
export default ProductColumns
