import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, Modal, Popconfirm, Table } from 'antd'
import dayjs from 'dayjs'
import Papa from 'papaparse'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import NoDataScreen from 'src/components/shared/no-data-screen/NoDataScreen'
import ProductImage from 'src/components/shared/ProductImage'
import FadeIn from 'src/components/styled/FadeIn'
import Loading from 'src/components/styled/Loading'
import { VAT_RATE } from 'src/config'
import {
  deleteAllVenueProducts,
  deleteVenueProduct,
  editVenueProduct,
  fetchVenueProducts,
  uploadVenueProductsCSV,
} from 'src/features/VenueProductsSlice'
import ChangedPricesTable from 'src/screens/venue-products/ChangedPricesTable'
import EditVenueProductModal from 'src/screens/venue-products/EditVenueProductModal'
import useLunrSearch from 'src/utils/useLunrSearch'
import { useWindowWidth } from 'src/utils/useWindowWidth'
import wordCaps from 'src/utils/wordCaps'
import styled, { ThemeContext } from 'styled-components/macro'
import BasketFunctionality from '../basketFunctionality/BasketFunctionality'
import DownloadProductsCsv from './DownloadProductsCsv'

const { Search } = Input

const VenueProducts = props => {
  const venueId = props.match.params.venueId
  const dispatch = useDispatch()
  const account = useSelector(state => state.auth.account)
  const products = useSelector(state => state.venueProducts.products)
  const isLoading = useSelector(state => state.venueProducts.isLoading)
  const isSomeVenueSupplierActive = useSelector(
    state => state.venueSuppliers.isSomeVenueSupplierActive,
  )
  const isAdmin = account && account.isAdmin
  const isTFRAdmin = account && account.TFRAdmin
  const { isPricesHidden, dataplusIntegration } = useSelector(
    state => state.venue.venue,
  )

  const _file = useRef(null)
  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg

  const [searchTerm, setSearchTerm] = useState('')
  const [searchProducts, setSearchProducts] = useState([])

  useEffect(() => {
    if (products.length === 0) dispatch(fetchVenueProducts(venueId))
  }, [dispatch, venueId])

  const [paginationPage, setPaginationPage] = useState(1)
  const [isCSVLoading, setCSVLoading] = useState(false)
  const resetPagination = useCallback(() => setPaginationPage(1), [])

  useLunrSearch(
    products,
    ['title', 'product.sku', 'supplierTitle', 'zone', 'type'],
    searchTerm,
    setSearchProducts,
    resetPagination,
  )

  const upload = () => {
    setCSVLoading(true)
    if (!_file.current.files[0]) {
      setCSVLoading(false)
      alert('Choose a file first')
      return
    }

    Papa.parse(_file.current.files[0], {
      header: true,
      complete: function (results) {
        const newData = results.data.map(item => ({
          ...item,
          isFavourite:
            item.isFavourite && item.isFavourite.toUpperCase() == 'FALSE'
              ? false
              : item.isFavourite && item.isFavourite.toUpperCase() == 'TRUE'
              ? true
              : false,
        }))
        dispatch(uploadVenueProductsCSV(newData, venueId))
      },
      error: function (err) {
        setCSVLoading(false)
        alert(
          "Selected file could not be parsed. Are you sure it's a CSV file?",
        )
        console.log(err)
      },
    })
  }

  const [editingProduct, setEditingProduct] = useState(null)

  const allTruthyZones = products
    .map(product => wordCaps(product.zone).trim())
    .filter(zone => Boolean(zone))
  const uniqueProductZones = [...new Set(allTruthyZones)]

  const allTruthyTypes = products
    .map(product =>
      product.type
        ? wordCaps(product.type).trim()
        : wordCaps(product.product.type).trim(),
    )
    .filter(type => Boolean(type))
  const uniqueProductTypes = [...new Set(allTruthyTypes)]

  const allTruthySuppliers = products
    .filter(product => Boolean(product.supplierTitle))
    .map(product => product.supplierTitle)

  const uniqueProductSuppliers = [...new Set(allTruthySuppliers)]

  const productsWithoutNulls = products.filter(product =>
    product.product ? product : false,
  )

  const searchProductsWithoutNulls = searchProducts.filter(product =>
    product.product ? product : false,
  )

  const handleOnClickButton = () => {
    _file.current.click()
  }

  return (
    <Wrapper>
      <HeaderScreen
        title={"Your venue's products"}
        subTitle={'These products are ready to order from your app'}
      ></HeaderScreen>
      <ChangedPricesTable
        venueProducts={productsWithoutNulls}
        uniqueProductSuppliers={uniqueProductSuppliers}
        isOpen={false}
      />
      {isLoading ? (
        <Loading />
      ) : isSomeVenueSupplierActive === false && !isAdmin ? (
        <NoDataScreen page='venueProductsNoSupplier' />
      ) : productsWithoutNulls.length === 0 && !isAdmin ? (
        <NoDataScreen page='venueProductsNoProduct' />
      ) : (
        <Fragment>
          <DivContainer>
            <Search
              size='large'
              placeholder='Search'
              onSearch={value => setSearchTerm(value)}
              style={{ width: 300, marginBottom: 15 }}
              allowClear
            />
            <ButtonsDiv>
              {isAdmin && (
                <Button
                  type={'danger'}
                  onClick={() => {
                    dispatch(deleteAllVenueProducts(venueId))
                  }}
                >
                  Delete All
                </Button>
              )}
              {products && <DownloadProductsCsv venueProducts={products} />}
              {(isTFRAdmin || isAdmin) && (
                <div>
                  <input
                    style={{ marginLeft: isMobile ? 0 : 25, display: 'none' }}
                    type='file'
                    ref={_file}
                    onChange={upload}
                  />
                  <Button
                    style={{
                      marginTop: isMobile ? 15 : 0,
                    }}
                    loading={isCSVLoading}
                    onClick={handleOnClickButton}
                  >
                    Upload CSV
                  </Button>
                </div>
              )}
            </ButtonsDiv>
          </DivContainer>
          <Table
            scroll={{
              scrollToFirstRowOnChange: true,
              x: true,
            }}
            dataSource={searchProductsWithoutNulls.map(product => ({
              key: product._id,
              ...product,
              zone: wordCaps(product.zone).trim(),
              type: product.type
                ? wordCaps(product.type).trim()
                : wordCaps(product.product.type).trim(),
            }))}
            pagination={{
              current: paginationPage,
              onChange: page => setPaginationPage(page),
            }}
            loading={isLoading}
            columns={[
              {
                title: '',
                dataIndex: 'product.cloudinaryImageId',
                key: 'product.cloudinaryImageId',
                render: imageId => {
                  return <ProductImage imageId={imageId} />
                },
              },
              {
                title: 'Product',
                dataIndex: 'title',
                key: 'title',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.title).trim().localeCompare(String(b.title).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              {
                title: 'Zone',
                key: 'zone',
                className: 'restrictShrink',
                filters: uniqueProductZones.map(zone => ({
                  text: zone,
                  value: zone,
                })),
                onFilter: (value, record) => record?.zone === value,
                render: product => {
                  return (
                    <>
                      <div>{product.zone}</div>
                      {product.secondaryZone?.length > 0 && (
                        <StyledSecondaryZone>
                          {product.secondaryZone}
                        </StyledSecondaryZone>
                      )}
                    </>
                  )
                },
              },
              {
                title: 'Default qty',
                dataIndex: 'defaultQuantity',
                key: 'defaultQuantity',
                className: 'restrictShrink',
                sorter: (a, b) => a.defaultQuantity - b.defaultQuantity,
                sortDirections: ['ascend', 'descend'],
              },
              {
                title: 'Size',
                dataIndex: 'product.size',
                key: 'product.size',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.size).trim().localeCompare(String(b.size).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              (!isPricesHidden && isAdmin) || !isAdmin
                ? {
                    title: 'Price',
                    dataIndex: 'product.price',
                    key: 'price',
                    className: 'restrictShrink',
                    render: (text, item) => {
                      let diffSymbol = ''
                      if (
                        Object.prototype.hasOwnProperty.call(
                          item.product,
                          'priceHistory',
                        ) &&
                        item.product.priceHistory.length
                      ) {
                        const priceLastUpdated = dayjs(
                          item.product.priceHistory[0].updatedAt,
                        )
                        const diff = dayjs().diff(priceLastUpdated, 'days')
                        if (diff < 14) {
                          diffSymbol = '▲'
                          if (
                            item.product.price <
                            item.product.priceHistory[0].formerPrice
                          ) {
                            diffSymbol = '▼'
                          }
                        }
                      }
                      return (
                        <span className='price-change'>
                          £
                          {isAdmin || isTFRAdmin
                            ? text
                            : !isNaN(item?.specialPrice) && item?.specialPrice
                            ? item?.specialPrice
                            : text}
                          {item.product.hasVat ? ' + VAT' : ''}
                          {diffSymbol && (
                            <span className='diff-symbol'>
                              {diffSymbol}
                              <span className='tooltip'>
                                Price has changed in the last 14 days
                              </span>
                            </span>
                          )}
                        </span>
                      )
                    },
                    sorter: (a, b) =>
                      (a.product.hasVat
                        ? VAT_RATE * a.product.price
                        : a.product.price) -
                      (b.product.hasVat
                        ? VAT_RATE * b.product.price
                        : b.product.price),
                    sortDirections: ['ascend', 'descend'],
                  }
                : {},
              (isAdmin || isTFRAdmin) && !isPricesHidden
                ? {
                    title: 'Special price',
                    dataIndex: 'product.price',
                    key: 'specialPrice',
                    className: 'restrictShrink',
                    render: (text, item) => {
                      let diffSymbol = ''
                      if (
                        Object.prototype.hasOwnProperty.call(
                          item.product,
                          'priceHistory',
                        ) &&
                        item.product.priceHistory.length
                      ) {
                        const priceLastUpdated = dayjs(
                          item.product.priceHistory[0].updatedAt,
                        )
                        const diff = dayjs().diff(priceLastUpdated, 'days')
                        if (diff < 14) {
                          diffSymbol = '▲'
                          if (
                            item.product.price <
                            item.product.priceHistory[0].formerPrice
                          ) {
                            diffSymbol = '▼'
                          }
                        }
                      }
                      return (
                        <span className='price-change'>
                          £
                          {!isNaN(item?.specialPrice) && item?.specialPrice
                            ? item?.specialPrice
                            : '0'}
                          {item.product.hasVat ? ' + VAT' : ''}
                          {diffSymbol && (
                            <span className='diff-symbol'>
                              {diffSymbol}
                              <span className='tooltip'>
                                Price has changed in the last 14 days
                              </span>
                            </span>
                          )}
                        </span>
                      )
                    },
                    sorter: (a, b) =>
                      (a.product.hasVat
                        ? VAT_RATE * a.product.price
                        : a.product.price) -
                      (b.product.hasVat
                        ? VAT_RATE * b.product.price
                        : b.product.price),
                    sortDirections: ['ascend', 'descend'],
                  }
                : {},
              {
                title: 'Priced By',
                dataIndex: 'product.pricedBy',
                key: 'product.pricedBy',
                className: 'restrictShrink',
                render: (_, venueProduct) => {
                  const { pricedBy, UoM } = venueProduct.product
                  return pricedBy
                    ? pricedBy === 'Each'
                      ? `${pricedBy}`
                      : `Per ${pricedBy}`
                    : UoM
                    ? UoM === 'Each'
                      ? `${UoM}`
                      : `Per ${UoM}`
                    : ''
                },
                sorter: (a, b) =>
                  String(a.size).trim().localeCompare(String(b.size).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              {
                title: 'Supplier',
                dataIndex: 'supplierTitle',
                key: 'supplierTitle',
                className: 'restrictShrink',
                filters: uniqueProductSuppliers.map(supplier => ({
                  text: supplier,
                  value: supplier,
                })),
                onFilter: (value, record) => record.supplierTitle === value,
              },
              {
                title: 'SKU',
                dataIndex: 'product.sku',
                key: 'product.sku',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.product.sku)
                    .trim()
                    .localeCompare(String(b.product.sku).trim()),
                sortDirections: ['ascend', 'descend'],
              },
              dataplusIntegration
                ? {
                    title: 'GLN',
                    dataIndex: 'product.GLN',
                    key: 'product.GLN',
                    className: 'restrictShrink',
                    render: GLN => {
                      return GLN || ''
                    },
                  }
                : {},
              {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                className: 'restrictShrink',
                filters: uniqueProductTypes.map(type => ({
                  text: type,
                  value: type,
                })),
                onFilter: (value, record) => record?.type === value,
              },
              {
                title: 'Favourite',
                key: 'Favourite',
                filters: [{ text: 'favourite', value: 'favourite' }],
                onFilter: (value, record) => {
                  return record.isFavourite
                },

                render: product => {
                  return (
                    <Checkbox
                      checked={product.isFavourite}
                      onChange={() => {
                        dispatch(
                          editVenueProduct({
                            ...product,
                            isFavourite: !product.isFavourite,
                            venue: venueId,
                          }),
                        )
                      }}
                    />
                  )
                },
              },
              {
                title: 'Action',
                key: 'action',
                className: 'restrictShrink',
                render: (text, product) => {
                  return (
                    <StyledDiv>
                      <BasketFunctionality
                        order={product.product}
                        venueId={venueId}
                        venueProducts={products}
                      />
                      <StyledButton
                        type={'dashed'}
                        onClick={() => {
                          setEditingProduct(product)
                        }}
                      >
                        <EditOutlined />
                      </StyledButton>
                      {'  '}
                      <Popconfirm
                        title='Are you sure to delete the product?'
                        placement='topRight'
                        onConfirm={() => {
                          dispatch(deleteVenueProduct(product, venueId))
                        }}
                        okText='Yes'
                        cancelText='No'
                      >
                        <StyledButton type={'danger'}>
                          <DeleteOutlined />
                        </StyledButton>
                      </Popconfirm>
                    </StyledDiv>
                  )
                },
              },
            ]}
          />

          <Modal
            title='Edit Product'
            okText='Save'
            visible={editingProduct !== null}
            onOk={() => {
              dispatch(
                editVenueProduct({
                  ...editingProduct,
                  venue: venueId,
                }),
              )
              setEditingProduct(null)
            }}
            onCancel={() => {
              setEditingProduct(null)
            }}
          >
            {editingProduct && (
              <EditVenueProductModal
                product={editingProduct}
                setEditedProduct={product => {
                  setEditingProduct(product)
                }}
              />
            )}
          </Modal>
        </Fragment>
      )}
    </Wrapper>
  )
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
`

const Wrapper = styled(FadeIn)`
  .selected {
    background: rgba(180, 216, 32, 0.33);
  }
  .restrictShrink {
    min-width: 95px;
  }

  .diff-symbol {
    position: relative;
    font-size: 13px;
    margin-left: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.colours.panSilver};

    &:hover {
      .tooltip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }

  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 150px;
    background: white;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    border-radius: 5px;
    padding: ${({ theme }) => theme.spacing.xxs};
    color: black;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: 200ms;
    transform: translate(-50%, 10px);
  }
`

const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
`
const DivContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const ButtonsDiv = styled.div`
  display: flex;
  gap: 15px;
`

const StyledSecondaryZone = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`

export default VenueProducts
