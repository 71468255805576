import { Layout, Typography } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Logo from 'src/assets/images/OMSLogoBlk.png'
import { login } from 'src/features/AuthSlice'
import LoginForm from 'src/screens/login/LoginForm'
import styled from 'styled-components/macro'

const Login = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth)
  const { error } = user

  const showError = error =>
    error === 'User does not exist.'
      ? 'The email address you entered is incorrect'
      : error === 'Incorrect username or password.'
      ? 'The password you entered is incorrect'
      : error

  return (
    <Wrapper className='layout'>
      <Layout.Content>
        <LoginFormWrapper>
          <img src={Logo} alt='' width='100px' />
          <LoginForm
            user={user}
            submit={values => {
              dispatch(login(values))
            }}
          />
          {error && (
            <Typography.Text type='danger'>{showError(error)}</Typography.Text>
          )}
        </LoginFormWrapper>
      </Layout.Content>
    </Wrapper>
  )
}

const Wrapper = styled(Layout)`
  min-height: 100vh !important;

  img {
    margin-bottom: 25px;
  }

  .ant-typography-danger {
    display: block;
    text-align: center;
  }
  main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .ant-form-item-children {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`

const LoginFormWrapper = styled.div`
  width: 400px;
  background: ${({ theme }) => theme.colours.mayoWhite};
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
`

export default Login
