import { Button, List, Tabs, Tag } from 'antd'
import * as dayjs from 'dayjs'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import FadeIn from 'src/components/styled/FadeIn'
import {
  getOrderPrice,
  getPrice,
  getTotalPrice,
} from 'src/utils/priceFunctions'

import styled from 'styled-components/macro'

import _ from 'underscore'

const { TabPane } = Tabs

const Invoices = () => {
  const venue = useSelector(state => state.venue.venue)
  const account = useSelector(state => state.auth.account)
  const orders = useSelector(state => state.orders.filtered)

  const isAdmin = account && account.isAdmin

  const dateGrouped = _(orders).groupBy(o =>
    moment(o.orderedDate).startOf('day').toString(),
  )
  const dateKeys = Object.keys(dateGrouped).sort(
    (a, b) => moment(b) - moment(a),
  )

  const isQuantityUpdated = item =>
    item.updatedQuantity !== undefined && item.updatedQuantity !== item.quantity

  return (
    <Wrapper>
      <Tabs
        defaultActiveKey='0'
        onChange={() => {}}
        animated={false}
        type={'card'}
      >
        {dateKeys.map((key, i) => {
          let order = dateGrouped[key]
          order = order.filter(o => o.product)
          const suppliersGrouped = _(order).groupBy(o => {
            const orderNumber = o.purchaseOrderNumber
              ? o.purchaseOrderNumber
              : ''
            return `${o.product.supplier.title}\n${orderNumber}`
          })

          return (
            <TabPane tab={dayjs(key).format('DD/MM/YYYY')} key={i}>
              <ButtonRow>
                <Link
                  to={`/v/${venue._id}/invoice/${dayjs(key).format(
                    'YYYY-MM-DD',
                  )}`}
                >
                  <Button>View Invoice</Button>
                </Link>
              </ButtonRow>
              {Object.keys(suppliersGrouped).map(key => {
                const items = suppliersGrouped[key]
                const acceptedItems = items.filter(
                  i => i.supplierConfirmed === true,
                )

                const requiredDeliveryDay = dayjs(items[0].requiredDeliveryDay)

                const placedVia = items[0].placedVia

                return (
                  <div key={key}>
                    <ListComp
                      header={
                        <>
                          <Header>
                            <OrderKey>
                              {key}{' '}
                              {isAdmin && (
                                <a
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  href={`/checklist/${items[0].venue}/${
                                    items[0].supplier
                                  }/${dayjs(items[0].orderedDate).format(
                                    'YYYY-MM-DD',
                                  )}/${
                                    items[0].purchaseOrderNumber
                                      ? items[0].purchaseOrderNumber
                                      : 'all'
                                  }`}
                                >
                                  <Button
                                    style={{
                                      marginLeft: 46,
                                      marginRight: 46,
                                      marginTop: 1,
                                    }}
                                  >
                                    Checklist
                                  </Button>
                                </a>
                              )}
                            </OrderKey>

                            <h4>
                              {placedVia
                                ? `Order placed by: ${placedVia}`
                                : 'Order placed using venue code'}
                            </h4>

                            <h4 style={{ marginLeft: 12 }}>
                              {requiredDeliveryDay.isValid()
                                ? `Delivery date: ${requiredDeliveryDay.format(
                                    'DD-MM-YYYY',
                                  )}`
                                : 'Next available delivery day'}
                            </h4>
                          </Header>
                        </>
                      }
                      footer={
                        <h4>£{getTotalPrice(acceptedItems)} including VAT</h4>
                      }
                      bordered
                      dataSource={items}
                      renderItem={item => (
                        <List.Item>
                          {isQuantityUpdated(item) ? (
                            <Tag color='orange'>{item.updatedQuantity}</Tag>
                          ) : (
                            <Tag color='green'>{item.quantity}</Tag>
                          )}
                          {item.title || item.product.title} @ {getPrice(item)}
                          <br />
                          {isQuantityUpdated(item) && (
                            <span>
                              Please note: You ordered {item.quantity} but only{' '}
                              {item.updatedQuantity} accepted
                            </span>
                          )}
                          <h5 style={{marginLeft: '34px'}}>SKU: {item.product.sku}</h5>
                          <h4>
                            Order Status:{' '}
                            {item.supplierConfirmed === undefined &&
                              'Order Received'}
                            {item.supplierConfirmed === true &&
                              (isQuantityUpdated(item) ? (
                                <span style={{ color: 'orange' }}>
                                  Quantity adjusted but accepted
                                </span>
                              ) : (
                                <span style={{ color: '#B4D820' }}>
                                  Confirmed
                                </span>
                              ))}
                            {item.supplierConfirmed === false && (
                              <span style={{ color: 'red' }}>Rejected</span>
                            )}
                          </h4>
                          <h4>Price: £{getOrderPrice(item)} inc. VAT</h4>
                        </List.Item>
                      )}
                    />
                  </div>
                )
              })}
            </TabPane>
          )
        })}
      </Tabs>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)``

const ListComp = styled(List)`
  margin-bottom: 15px !important;
  background-color: ${({ theme }) => theme.colours.mayoWhite} !important;
`

const ButtonRow = styled.div`
  margin-bottom: 18px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const OrderKey = styled.h3`
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 250px;
`

export default Invoices
