import { Button, Form, Icon, Input, Tooltip } from 'antd'
import React from 'react'

const ResetPasswordForm = ({
  submitEmailAddress,
  submitCodeAndPassword,
  form,
  user,
}) => {
  const { getFieldDecorator } = form
  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        if (!user.codeSent) {
          submitEmailAddress(values.email)
        } else {
          submitCodeAndPassword(values)
        }
      }
    })
  }
  return (
    <div>
      <Form onSubmit={handleSubmit} className='login-form'>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Please input your email address' },
              { type: 'email', message: 'The input is not valid email' },
            ],
          })(
            <Input
              prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Email Address'
            />,
          )}
        </Form.Item>

        {user.codeSent && (
          <>
            <Form.Item>
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your reset code',
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type='text'
                  placeholder='Reset Code'
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your new password',
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type='password'
                  placeholder='New Password'
                />,
              )}
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
          >
            {user.codeSent ? 'Reset Password' : 'Send Reset Code'}
          </Button>
        </Form.Item>
        <Form.Item>
          <Tooltip
            placement='bottom'
            trigger='click'
            title='Passwords must contain, at least one number, one special character, one uppercase letter and one lowercase letter, and it must be 8 or more characters long.'
          >
            <span style={{ cursor: 'pointer', color: '#919191' }}>
              Password requirements
            </span>
          </Tooltip>
        </Form.Item>
      </Form>
    </div>
  )
}

const WrappedNormalResetForm = Form.create({ name: 'reset' })(ResetPasswordForm)

export default WrappedNormalResetForm
