import React from 'react'
import history from 'src/history'

const LoggedInRoute = ({ children, isLoggedIn }) => {
  return (
    <>
      {!isLoggedIn && history.push('/login')}
      {children}
    </>
  )
}

export default LoggedInRoute
