import LarsseitBold from 'src/assets/fonts/Larsseit-Bold.ttf'
import LarsseitLight from 'src/assets/fonts/Larsseit-Light.ttf'
import LarsseitMedium from 'src/assets/fonts/Larsseit-Medium.ttf'
import LarsseitRegular from 'src/assets/fonts/Larsseit.ttf'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    @font-face {
        font-family: "Larsseit";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${LarsseitRegular}) format("truetype");
    }
    @font-face {
        font-family: "Larsseit";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(${LarsseitMedium}) format("truetype");
    }
    @font-face {
        font-family: "Larsseit";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${LarsseitLight}) format("truetype");
    }
    @font-face {
        font-family: "Larsseit";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${LarsseitBold}) format("truetype");
    }
`
