import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orders: {
    startDate: null,
    endDate: null,
    types: [],
    locations: [],
    suppliers: [],
    venueSupplierSearch: [],
  },
  catalogue: {
    supplier: [],
  },
  supplierOrder: [],
}
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      const { startDate, endDate, types, locations, suppliers } = action.payload
      state.orders = {
        startDate,
        endDate,
        types,
        locations,
        suppliers,
      }
    },

    setSpending: (state, action) => {
      state.supplierOrder = action.payload
    },

    setCatalouge: (state, action) => {
      state.catalogue = {
        supplier: [action.payload],
      }
    },

    resetOrders: state => {
      state.orders = { ...initialState.orders }
    },

    resetCatalouge: state => {
      state.catalogue.supplier = []
    },
  },
})

export const {
  resetOrders,
  setOrders,
  setCatalouge,
  resetCatalouge,
  setSpending,
} = filterSlice.actions

export const setOrdersFilter = filter => dispatch => dispatch(setOrders(filter))

export const resetOrdersFilter = () => dispatch => dispatch(resetOrders())

export const setSupplierOrderFilter = filter => dispatch =>
  dispatch(setSpending(filter))

export const setCatalogueFilter = filter => dispatch =>
  dispatch(setCatalouge(filter))

export const resetCatalougeFilter = () => dispatch => dispatch(resetCatalouge())

export default filterSlice
