import { Input, Select, Switch } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { resetVenueProducts } from 'src/features/VenueProductsSlice'

const EditVenueSupplierModal = ({ venueSupplier,account ,setEditingVenueSupplier }) => {
  const {
    accountNumber,
    minOrderValue,
    // deliveryFee,
    deliveryDays,
    orderCutOffTime,
    isActive,
    isCatalogueAccessible,
  } = venueSupplier

    const isAdmin = account && account.isAdmin
    const isTfrAdmin = account && account.TFRAdmin

  const dispatch = useDispatch()

  return (
    <Wrapper>
      <Row>
        <InputComp
          value={accountNumber}
          onChange={e => {
            setEditingVenueSupplier({
              ...venueSupplier,
              accountNumber: e.target.value,
            })
          }}
          addonBefore={'OMS account number'}
        />
      </Row>
      <Row>
        <InputComp
          value={minOrderValue}
          disabled={isAdmin || isTfrAdmin ? false : true}
          onChange={e => {
            setEditingVenueSupplier({
              ...venueSupplier,
              minOrderValue: e.target.value,
            })
          }}
          addonBefore={'Minimum order value'}
        />
      </Row>
      {/*<Row>*/}
      {/*  <InputComp*/}
      {/*    value={deliveryFee}*/}
      {/*    onChange={e => {*/}
      {/*      setEditingVenueSupplier({*/}
      {/*        ...venueSupplier,*/}
      {/*        deliveryFee: e.target.value,*/}
      {/*      })*/}
      {/*    }}*/}
      {/*    addonBefore={'Delivery Fee'}*/}
      {/*  />*/}
      {/*</Row>*/}
      <Row>
        <Select
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          placeholder='Delivery Days'
          value={deliveryDays}
          disabled={isAdmin || isTfrAdmin ? false : true}
          onChange={value => {
            setEditingVenueSupplier({ ...venueSupplier, deliveryDays: value })
          }}
        >
          <Select.Option key='mon'>Monday</Select.Option>
          <Select.Option key='tue'>Tuesday</Select.Option>
          <Select.Option key='wed'>Wednesday</Select.Option>
          <Select.Option key='thu'>Thursday</Select.Option>
          <Select.Option key='fri'>Friday</Select.Option>
          <Select.Option key='sat'>Saturday</Select.Option>
          <Select.Option key='sun'>Sunday</Select.Option>
        </Select>
      </Row>
      <Row>
        <InputComp
          value={orderCutOffTime}
          onChange={e => {
            setEditingVenueSupplier({
              ...venueSupplier,
              orderCutOffTime: e.target.value,
            })
          }}
          addonBefore={'Order cut off time'}
          disabled={isAdmin || isTfrAdmin ? false : true}
        />
      </Row>
        {isAdmin || isTfrAdmin ? <Row>
            <span>Show Supplier:</span>
            <Switch
                checked={isActive}
                onChange={() => {
                    setEditingVenueSupplier({...venueSupplier, isActive: !isActive})
                    dispatch(resetVenueProducts())
                }}
            ></Switch>
        </Row> : null}
        {isAdmin || isTfrAdmin ? <Row>
        <span>Catalogue access:</span>
        <Switch
          checked={isCatalogueAccessible}
          onChange={() => {
            setEditingVenueSupplier({
              ...venueSupplier,
              isCatalogueAccessible: !isCatalogueAccessible,
            })
          }}
        ></Switch>
      </Row> : null}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const InputComp = styled(Input)``

const Row = styled.div`
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
`

export default EditVenueSupplierModal
