import React from 'react'
import moment from 'moment/moment'
import { Button, notification, Tag, Tooltip, Popconfirm } from 'antd'
import {
  CheckSquareFilled,
  PlusSquareOutlined,
  SaveFilled,
  DeleteOutlined,
} from '@ant-design/icons'
import styled from 'styled-components/macro'
import SaveToDataplusBtn from './SaveToDataplusBtn'
import { useDispatch } from 'react-redux'
import { deletedOrder } from 'src/features/ReceiptingSlice'

const OrdersColumns = (
  errors,
  weightedItems,
  onSave,
  onFullAccept,
  products,
  setOrdersToAdding,
  venueId,
  setModalShow,
  isDataplus,
) => {
  const dispatch = useDispatch()
  const findCurrentOrders = (record, array) => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    return array.filter(
      item =>
        item?.purchaseOrderNumber === PONumber && item.supplier === supplierId,
    )
  }

  const showNotesMessage = () => {
    notification.error({
      message: 'Please add comments to the text box',
      placement: 'topRight',
      top: 100,
    })
  }
  const isEmptyError = record => {
    const currentOrders = findCurrentOrders(record, errors)
    const currentWeightedItems = findCurrentOrders(record, weightedItems)
    if (!currentOrders.length && !currentWeightedItems.length) return true
    if (currentOrders.length) {
      return currentOrders.find(item => item.error === '')
    }
    if (currentWeightedItems.length) return false
    return false
  }
  const isSomethingElseError = record => {
    const currentOrders = findCurrentOrders(record, errors)
    if (currentOrders.length) {
      return currentOrders.find(
        item => item.error === 'Something Else' && !item.notes,
      )
    }
    return false
  }

  const isAccesBtnDisabled = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    return (
      !!errors.find(
        item =>
          item?.purchaseOrderNumber === PONumber &&
          item.supplier === supplierId,
      ) ||
      !products.find(
        item =>
          item?.purchaseOrderNumber === PONumber &&
          item.supplier === supplierId,
      ) ||
      !!weightedItems.find(
        item =>
          item?.purchaseOrderNumber === PONumber &&
          item.supplier === supplierId,
      ) ||
      products.find(item => {
        return (
          item?.purchaseOrderNumber === PONumber &&
          item.supplier === supplierId &&
          item.orders.some(item => item.status !== undefined)
        )
      })
    )
  }

  const setOrderDataToAdding = record => {
    const { orderedDate, purchaseOrderNumber, placedVia } = record
    setOrdersToAdding({
      orderedDate,
      placedVia,
      purchaseOrderNumber,
      supplier: record.supplier._id,
      venue: venueId,
      isOrdered: true,
      supplierConfirmed: true,
    })
    setModalShow(true)
  }

  return [
    {
      title: 'Supplier',
      dataIndex: 'supplier.title',
      className: 'restrict-shrink',
      width: '20%',
      render: (supplierTitle, record) => {
        const ableToSaveToDataplus =
          (record.status === 'Confirmed' || record.status === 'Changes') &&
          isDataplus &&
          !record.isOrderSavedToDataplus

        return (
          <div>
            <div>{supplierTitle}</div>
            <div>{record.purchaseOrderNumber}</div>

            {ableToSaveToDataplus && (
              <SaveToDataplusBtn
                venueId={venueId}
                orderIds={record?.orderIds || []}
                PONumber={record.purchaseOrderNumber}
              />
            )}
          </div>
        )
      },
    },
    {
      title: 'Ordered Amount',
      dataIndex: 'totalSpendOrdered',
      className: 'restrict-shrink',
      width: '10%',
      render: total => `£${(total / 100).toFixed(2)}`,
    },
    {
      title: 'Supplier Amount',
      dataIndex: 'totalSpendReceived' || 'totalSpend',
      className: 'restrict-shrink',
      width: '10%',
      render: total => `£${(total / 100).toFixed(2)}`,
    },
    {
      title: 'Placed',
      dataIndex: 'placedVia',
      className: 'restrict-shrink',
      width: '15%',
      render: placed => (placed ? placed : 'Placed using venue code'),
    },
    {
      title: 'Ordered date',
      dataIndex: 'orderedDate',
      className: 'restrict-shrink',
      width: '10%',
      render: date => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Order status',
      dataIndex: 'status',
      className: 'restrict-shrink',
      width: '10%',
      render: status => (
        <Tag
          color={
            status === 'Confirmed'
              ? 'green'
              : status === 'Changes'
              ? 'magenta'
              : 'orange'
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Last edit',
      dataIndex: 'savedBy',
      className: 'restrict-shrink',
      width: '10%',
    },
    {
      title: 'Actions',
      className: 'restrict-shrink',
      width: '20%',
      render: (_, record) =>
        record.status === 'Received' ? (
          'Waiting on Supplier Approval'
        ) : (
          <ActionWrapper>
            <Tooltip placement='top' title={'Accept & Save'}>
              <Button
                type={'primary'}
                disabled={isAccesBtnDisabled(record)}
                onClick={() => onFullAccept(record)}
              >
                <CheckSquareFilled style={{ fontSize: '20px' }} />
              </Button>
            </Tooltip>
            <Tooltip placement='top' title={'Save & Send'}>
              <Button
                type={'primary'}
                disabled={isEmptyError(record)}
                onClick={() =>
                  isSomethingElseError(record)
                    ? showNotesMessage()
                    : onSave(record)
                }
              >
                <SaveFilled style={{ fontSize: '20px' }} />
              </Button>
            </Tooltip>
            <Tooltip placement='top' title={'Add product'}>
              <Button
                type={'primary'}
                onClick={() => setOrderDataToAdding(record)}
              >
                <PlusSquareOutlined style={{ fontSize: '20px' }} />
              </Button>
            </Tooltip>
            {record.placedVia === 'Order placed manually on the dashboard' && (
              <Popconfirm
                title='Are you sure you want to delete the order?'
                placement='topRight'
                onConfirm={() => {
                  const supplierId = record?.supplier?._id
                  const PONumber = record?.purchaseOrderNumber
                  dispatch(deletedOrder(supplierId, PONumber, venueId))
                }}
                okText='Yes'
                cancelText='No'
              >
                <StyledButton type={'danger'}>
                  <DeleteOutlined />
                </StyledButton>
              </Popconfirm>
            )}
          </ActionWrapper>
        ),
    },
    {
      className: 'restrict-shrink',
      title: 'Receipting status',
      dataIndex: 'receiptingStatus',
      width: '15%',
      render: status => (
        <Tag
          color={
            status === 'Credit approved by supplier' ||
            status === 'Full accept' ||
            status === 'Saved'
              ? 'green'
              : status === 'Rejected by supplier'
              ? 'magenta'
              : 'orange'
          }
        >
          {status}
        </Tag>
      ),
    },
  ]
}

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 200px;
  flex-wrap: wrap;
`
const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default OrdersColumns
