import { AutoComplete, Input, InputNumber } from 'antd'
import React from 'react'

const { Option } = AutoComplete

const TableColumn = (
  supplierProducts,
  setSupplierProducts,
  filteredProducts,
  setFilteredProducts,
  selectedProducts,
  setSelectedProducts,
) => {
  const onSelectProduct = (prodId, option) => {
    const selectedProd = supplierProducts.find(prod => prod._id === prodId)
    setSelectedProducts(
      selectedProducts.map(prod => {
        if (prod.key === option.key) {
          return {
            key: prod.key,
            quantity: 1,
            product: selectedProd._id,
            title: selectedProd.title,
            OMSPrice: selectedProd.OMSPrice,
            price: selectedProd.price,
            sku: selectedProd.sku,
            size: selectedProd.size,
            type: selectedProd.type,
            hasVat: selectedProd.hasVat,
          }
        } else return prod
      }),
    )
  }

  const onSkuSearch = (value, order) => {
    setSelectedProducts(
      selectedProducts.map(prod => {
        if (prod.key === order.key) {
          prod.sku = value
          return prod
        } else return prod
      }),
    )
    setFilteredProducts(
      supplierProducts.filter(product => {
        return product.sku.toLowerCase().includes(value.toLowerCase())
      }),
    )
  }

  const onTitleSearch = (value, product) => {
    setSelectedProducts(
      selectedProducts.map(prod => {
        if (prod.key === product.key) {
          prod.title = value
          return prod
        } else return prod
      }),
    )
    setFilteredProducts(
      supplierProducts.filter(product => {
        return product.title.toLowerCase().includes(value.toLowerCase())
      }),
    )
  }

  const onChangeQuantity = (value, order) => {
    setSelectedProducts(
      selectedProducts.map(prod => {
        if (prod.key === order.key) {
          prod.quantity = value
          return prod
        } else return prod
      }),
    )
  }

  const onChangePrice = (value, product) => {
    setSelectedProducts(
      selectedProducts.map(prod => {
        if (prod.key === product.key) {
          prod.OMSPrice = value.target.value
          return prod
        } else return prod
      }),
    )
  }
  const handleRemoveProduct = key => {
    setSelectedProducts(selectedProducts.filter(prod => prod.key !== key))
  }

  return [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'key',
      width: '15%',
      render: (_, product) => (
        <AutoComplete
          onSelect={onSelectProduct}
          value={product.sku}
          onSearch={val => onSkuSearch(val, product)}
          notFoundContent={'Products not found'}
        >
          {filteredProducts.map(prod => (
            <Option key={product.key} value={prod._id}>
              {prod.sku ? prod.sku : 'No SKU'}
            </Option>
          ))}
        </AutoComplete>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_, product) => (
        <AutoComplete
          onSelect={onSelectProduct}
          value={product.title}
          onSearch={val => onTitleSearch(val, product)}
          notFoundContent={'Products not found'}
        >
          {filteredProducts.map(prod => (
            <Option key={product.key} value={prod._id}>
              {prod.title}
            </Option>
          ))}
        </AutoComplete>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_, product) => (
        <InputNumber
          min={1}
          value={product.quantity}
          onChange={value => onChangeQuantity(value, product)}
        />
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Price per unit',
      dataIndex: 'OMSPrice',
      key: 'OMSPrice',
      render: (_, product) => (
        <Input
          style={{ width: '70px' }}
          value={product.OMSPrice}
          onChange={value => onChangePrice(value, product)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, product) => (
        <a onClick={() => handleRemoveProduct(product.key)}>Delete</a>
      ),
    },
  ]
}

export default TableColumn
