import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import {
  RejectedNotification,
  SuccessNotification,
} from '../notifications/SuccessNotification'
import { setReceiptingOrders } from './ReceiptingSlice'

const initialState = {
  ordersToSend: [],
  isLoading: false,
  dataplusOrders: [],
}
const DataplusIntegrationSlice = createSlice({
  name: 'dataplus',
  initialState,
  reducers: {
    setOrdersToSend: (state, action) => {
      state.ordersToSend = action.payload
    },
    setDataPlusOrders: (state, action) => {
      state.dataplusOrders = action.payload
    },
    setDataplusSending: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setOrdersToSend, setDataplusSending, setDataPlusOrders } =
  DataplusIntegrationSlice.actions

export const sendIntegration =
  (venueId, body) => async (dispatch, getState) => {
    try {
      dispatch(setDataplusSending(true))
      const sendIntegrationToDB = await API.patch(
        'api',
        `venue/${venueId}/dataplus`,
        { body },
      )

      if (!sendIntegrationToDB || !sendIntegrationToDB.result) {
        const message = sendIntegrationToDB?.message || 'Please, try again!'

        throw Error(message)
      }

      const { message = '', result } = sendIntegrationToDB

      const { dataplus } = getState()

      const updatedOrders = dataplus.dataplusOrders.map(order => {
        const matchingDBOrder = result.find(
          dbOrder =>
            order.purchaseOrderNumber === dbOrder.purchaseOrderNumber &&
            order.supplier._id === dbOrder.supplier &&
            dbOrder.dataplusIntegration,
        )

        if (matchingDBOrder) {
          return {
            ...order,
            dataplus: {
              ...matchingDBOrder.dataplusIntegration,
            },
          }
        }

        return order
      })

      dispatch(setDataPlusOrders(updatedOrders))
      dispatch(setDataplusSending(false))
      SuccessNotification({ message: message })
    } catch (e) {
      console.log('Error in sendIntegration', e)

      dispatch(setDataplusSending(false))
      RejectedNotification({
        message: 'Something went wrong',
        description: e?.message || 'Please, try again!',
      })
    }
  }

export const saveAndAcceptedToDataplus =
  (venueId, data) => async (dispatch, getState) => {
    try {
      const result = await API.patch('api', `venue/${venueId}/dataplus`, {
        body: {
          isSaveToDataplus: true,
          ...data,
        },
      })
      const { message } = result
      const { receipting } = getState()

      const filtredReceiptingOrders = receipting.receiptingOrders.map(
        receiptingOrder => {
          if (receiptingOrder.purchaseOrderNumber === data.PONumber) {
            return {
              ...receiptingOrder,
              isOrderSavedToDataplus: true,
            }
          } else {
            return receiptingOrder
          }
        },
      )

      const filteredOrdersForDataplus = filtredReceiptingOrders.filter(
        order => order.isOrderSavedToDataplus,
      )

      dispatch(setReceiptingOrders(filtredReceiptingOrders))
      dispatch(setDataPlusOrders(filteredOrdersForDataplus))

      SuccessNotification({ message: message })
    } catch (e) {
      console.log('Error in saveAndAcceptedToDataplus', e)
      SuccessNotification({ message: 'Something went wrong' })
      throw e
    }
  }

export default DataplusIntegrationSlice
