import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSpendingSuppliersVenueReport } from '../../features/SpendingSlice'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import styled from 'styled-components/macro'

export const SpendingExpandedTable = ({
  supplierTitle,
  startDate,
  endDate,
}) => {
  const dispatch = useDispatch()
  const venueReports = useSelector(state => state.supplierOrder.venueReports)
  const isLoading = useSelector(
    state => state.supplierOrder.expandedTableLoading,
  )
  const [dataSource, setDataSource] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalSize, setTotalSize] = useState(0)

  const columns = [
    {
      title: 'Venue',
      dataIndex: '_id',
      key: 'title',
      width: '18%',
      className: 'venueTitle',
      render: (_, order) => {
        return (
          <Link to={`/v/${order._id.venueId}/purchasing/orders`}>
            {order._id.venue}
          </Link>
        )
      },
    },
    {
      title: 'Second title',
      dataIndex: '_id.supplierSecondTitle',
      key: 'title',
      width: '19%',
      className: 'venueTitle',
    },
    {
      title: 'Orders',
      dataIndex: 'orders',
      key: 'ordersCount',
      width: '14%',
    },
    {
      title: 'Products',
      dataIndex: 'totalProducts',
      key: 'totalProducts',
      width: '22%',
    },
    {
      title: 'Venue total spend',
      dataIndex: 'totalSpendWithoutVat',
      key: 'totalSpendWithoutVat',
      render: (_, order) => {
        return (order.totalSpendWithoutVat / 100).toFixed(2)
      },
      width: '15%',
    },
  ]

  useEffect(() => {
    if (!startDate || !endDate || !supplierTitle) return
    const founded = venueReports.find(
      report =>
        report.supplierId === supplierTitle &&
        report.venueReportArr.page === currentPage,
    )
    setDataSource(
      founded?.filteredWithVenueSuppliers
        ? founded?.filteredWithVenueSuppliers
        : [],
    )
    setTotalSize(founded?.total ? founded?.total : 0)
    if (!founded) {
      fetchData(currentPage, pageSize)
    }
  }, [])

  const fetchData = (page, pageSize) => {
    dispatch(
      fetchSpendingSuppliersVenueReport(
        startDate,
        endDate,
        supplierTitle,
        pageSize,
        page,
      ),
    )
  }

  useEffect(() => {
    fetchData(currentPage, pageSize)
  }, [startDate, endDate])

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    setPageSize(pageSize)
    fetchData(page, pageSize)
  }

  useEffect(() => {
    const founded = venueReports.find(
      report =>
        report.supplierId === supplierTitle &&
        report.venueReportArr.page === currentPage,
    )
    setDataSource(
      founded?.venueReportArr?.filteredWithVenueSuppliers
        ? founded?.venueReportArr?.filteredWithVenueSuppliers
        : [],
    )
    setTotalSize(
      founded?.venueReportArr?.total ? founded?.venueReportArr?.total : 0,
    )
  }, [venueReports])

  return (
    <div>
      <StyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pageSize,
          current: currentPage,
          total: totalSize,
          onChange: handlePageChange,
        }}
        loading={isLoading}
      />
    </div>
  )
}

const StyledTable = styled(Table)`
  .venueTitle {
    padding-left: 65px;
  }
`
