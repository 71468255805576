import { DatePicker, Table, Button, Row, Col, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FadeIn from 'src/components/styled/FadeIn'
import styled from 'styled-components/macro'
import dayjs from 'dayjs'
import {
  deleteSalesOrPurchaseData,
  fetchGrossProfitAndSales,
} from 'src/features/WeeklySalesSlice'
const { RangePicker } = DatePicker
import './grossprofit.css'
import { setDateRange } from '../../features/WeeklySalesSlice'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'
import SalesModal from 'src/modals/SalesModal'
import MisCostModal from 'src/modals/MiscCostModal'
const GrossProfit = () => {
  const dispatch = useDispatch()
  const [visibleCalendar, setVisibleCalendar] = useState(false)

  const [visibleSalesModal, setVisibleSalesModal] = useState(false)
  const [visibleMiscCostModal, setVisibleMiscCostModal] = useState(false)
  const { totalSaleWeek, dateRange, GP, totalMiscCosts, totalSaleAmount } =
    useSelector(state => state.sale)
  const venueId = useSelector(state => state.venue?.venue?._id)
  const defaultDateRange = {
    start: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
    end: dayjs().format('YYYY-MM-DD'),
  }

  const onChangeDate = dateString => {
    setVisibleCalendar(false)
    if (dateString) {
      dispatch(
        setDateRange({
          startDate: dateString[0],
          endDate: dateString[1],
        }),
      )
    } else {
      console.log('error')
    }
  }
  const disableEndDate = current => current.valueOf() > moment.now()

  useEffect(() => {
    if (venueId) {
      dispatch(
        setDateRange({
          startDate: defaultDateRange.start,
          endDate: defaultDateRange.end,
        }),
      )
    }
  }, [venueId])

  useEffect(() => {
    if (dateRange) {
      dispatch(
        fetchGrossProfitAndSales(
          dateRange.startDate,
          dateRange.endDate,
          venueId,
        ),
      )
    }
  }, [dateRange])

  const renderPurchases = purchases => {
    return (
      <Table
        dataSource={purchases}
        pagination={false}
        style={{ marginLeft: '20px', background: 'white' }}
        columns={[
          {
            title: '',
            dataIndex: '',
            key: '',
          },
          {
            title: '',
            dataIndex: '',
            key: '',
          },
          {
            title: 'Wages/Utility',
            dataIndex: 'text',
            key: 'text',
            className: 'restrictShrink',
            sorter: (a, b) =>
              String(a.product).trim().localeCompare(String(b.product).trim()),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Purhcase Figure',
            dataIndex: 'amount',
            key: 'amount',
            className: 'restrictShrink',
            sorter: (a, b) =>
              String(a.product).trim().localeCompare(String(b.product).trim()),
            sortDirections: ['ascend', 'descend'],
            render: text => `£${text}`,
          },
        ]}
      />
    )
  }
  return (
    <Content>
      <SalesModal
        visibleSalesModal={visibleSalesModal}
        setVisibleSalesModal={setVisibleSalesModal}
        dateRange={dateRange}
      />
      <MisCostModal
        visibleMiscCostModal={visibleMiscCostModal}
        setVisibleMiscCostModal={setVisibleMiscCostModal}
        dateRange={dateRange}
      />
      <BtnsWrapper>
        <div>
          <TitleForRnage>Select date</TitleForRnage>
          <RangePicker
            onFocus={() => setVisibleCalendar(true)}
            onBlur={() => setVisibleCalendar(false)}
            open={visibleCalendar}
            disabledDate={disableEndDate}
            onChange={onChangeDate}
          />
        </div>
        <Button
          style={{ background: '#b5d922', width: 200 }}
          type={'primary'}
          onClick={() => setVisibleSalesModal(true)}
        >
          Enter Data
        </Button>
        <Button
          style={{ background: '#b5d922', width: 200 }}
          type={'primary'}
          onClick={() => setVisibleMiscCostModal(true)}
        >
          Add Misc Costs
        </Button>
      </BtnsWrapper>
      <Wrapper>
        <Table
          dataSource={totalSaleWeek}
          expandedRowRender={record => renderPurchases(record.purchases)}
          pagination={false}
          // loading={dataSource.length < 1}
          columns={[
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
              className: 'restrictShrink',
              sorter: (a, b) =>
                String(a.product)
                  .trim()
                  .localeCompare(String(b.product).trim()),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Day',
              dataIndex: 'day',
              key: 'day',
              className: 'restrictShrink',
              sorter: (a, b) =>
                String(a.product)
                  .trim()
                  .localeCompare(String(b.product).trim()),
              sortDirections: ['ascend', 'descend'],
            },
            {
              title: 'Sale',
              dataIndex: 'sales',
              key: 'sales',
              className: 'restrictShrink',
              sorter: (a, b) => a.quantity - b.quantity,
              sortDirections: ['ascend', 'descend'],
              render: text => `£${text}`,
            },
            {
              title: 'Purchase Figures Per Day',
              dataIndex: 'miscCostPerDay',
              key: 'miscCostPerDay',
              className: 'restrictShrink',
              sorter: (a, b) => a.quantity - b.quantity,
              sortDirections: ['ascend', 'descend'],
              render: text => `£${text}`,
            },
            {
              title: 'Actions',
              dataIndex: 'id',
              className: 'restrictShrink',
              key: 'id',
              width: '10%',
              render: id => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <Popconfirm
                      title={
                        id === ''
                          ? 'Please enter sales data first'
                          : 'Are you sure to delete the sales data ?'
                      }
                      placement='topRight'
                      onConfirm={() => {
                        id === ''
                          ? console.log('ok pressed')
                          : dispatch(
                              deleteSalesOrPurchaseData(id, dateRange, venueId),
                            )
                      }}
                      okText={id === '' ? 'OK' : 'Yes'}
                      cancelText={id === '' ? 'Cacnel' : 'No'}
                    >
                      <StyledButton type={'danger'}>
                        <DeleteOutlined />
                      </StyledButton>
                    </Popconfirm>
                  </div>
                )
              },
            },
          ]}
          footer={() => {
            return (
              <Row>
                <Row>
                  <Col span={19}></Col>
                  <Col span={3}>
                    <strong>Total GP:</strong>
                  </Col>
                  <Col span={2}>{GP}%</Col>
                </Row>
                <Row>
                  <Col span={19}></Col>
                  <Col span={3}>
                    <strong>Total Misc Costs:</strong>
                  </Col>
                  <Col span={2}>£{totalMiscCosts}</Col>
                </Row>
                <Row>
                  <Col span={19}></Col>
                  <Col span={3}>
                    <strong>Total Sale Amount:</strong>
                  </Col>
                  <Col span={2}>£{totalSaleAmount}</Col>
                </Row>
              </Row>
            )
          }}
        />
      </Wrapper>
    </Content>
  )
}

const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const BtnsWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-around;
  margin-bottom: 50px;
`

const TitleForRnage = styled.strong`
  margin-right: 20px;
`

const Wrapper = styled(FadeIn)`
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-table-tbody {
    .restrictShrink {
      min-width: 90px;
      background-color: white;
    }
  }
`

export default GrossProfit
