import React from 'react'
import { BodyText } from '../../branding/typography'
import styled from 'styled-components/macro'
import { Button } from 'antd'
import { getOMSPrice } from '../../utils/priceFunctions'
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'

const ChecklistColumn = (setOrders, updateOrder, table, layout) => {
  const handleCheckOrder = (event, order, value) => {
    const updatedOrder = {
      ...order,
      receipting: {
        ...order.receipting,
        supplierApproved:
          order.receipting.supplierApproved === value ? undefined : value,
      },
    }
    updateOrder(updatedOrder)
    if (updatedOrder.receipting.supplierApproved === undefined) {
      setOrders(prev => prev.filter(item => item._id !== updatedOrder._id))
    } else {
      setOrders(prev => {
        const curOrder = prev.find(item => item._id === updatedOrder._id)
        if (curOrder) {
          return prev.map(item =>
            item._id === updatedOrder._id ? updatedOrder : item,
          )
        } else {
          return [...prev, updatedOrder]
        }
      })
    }
    event.target.blur()
  }

  const isApprovedTable = table === 'approved'

  return [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      className: 'restrictShrink',
      render: (_, order) => (
        <BodyText>
          <b>
            {order.receipting.updatedSku && !isApprovedTable
              ? order.receipting.updatedSku
              : order.sku}
          </b>
        </BodyText>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'restrictShrink quantity-padding-left',
      render: (_, order) => (
        <BodyText>
          <b>
            {(order.receipting.updatedQuantity && !isApprovedTable) ||
            (order.receipting.updatedQuantity === 0 && !isApprovedTable)
              ? order.receipting.updatedQuantity
              : order.quantity}
          </b>
        </BodyText>
      ),
    },
    {
      title: 'Size',
      className: 'restrictShrink quantity-padding-left',
      render: (text, order) => (
        <BodyText>
          {order.receipting.updatedSize && !isApprovedTable
            ? order.receipting.updatedSize
            : order.size}
        </BodyText>
      ),
    },
    {
      title: 'Stock',
      dataIndex: 'title',
      key: 'stock',
      className: 'restrictShrink',
      width: 140,
      render: (_, order) => (
        <BodyTextWrap>
          {order.receipting.updatedTitle && !isApprovedTable
            ? order.receipting.updatedTitle
            : order.title}
        </BodyTextWrap>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'OMSPrice',
      className: 'restrictShrink',
      key: 'OMSPrice',
      render: (text, order) => {
        const hasVat = order.hasVat
        const isUpdatePrice =
          !!order.receipting.updatedPrice && !isApprovedTable
        return !isUpdatePrice
          ? `£${getOMSPrice(order)}${hasVat ? ' + VAT' : ''}`
          : `£${(order.receipting.updatedPrice / 100).toFixed(2)}${
              hasVat ? ' + VAT' : ''
            }`
      },
    },
    {
      title: 'UoM',
      dataIndex: 'UoM',
      className: 'restrictShrink',
      key: 'product.UoM',
      render: text => {
        return text
          ? text === 'Each'
            ? `Price ${text}`
            : `Price per ${text}`
          : ''
      },
    },
    {
      title: 'Notes',
      dataIndex: 'receipting.notes',
      className: 'restrictShrink',
      key: 'productNotes',
      width: 180,
      render: notes => <BodyText>{notes ? notes : ''}</BodyText>,
    },
    {
      title: 'Reason for change',
      dataIndex: 'receipting.error',
      className: 'restrictShrink',
      width: 140,
      render: error => <BodyText>{error}</BodyText>,
    },
    {
      title: 'Accept customer request',
      key: 'stockAvailable',
      className: 'restrictShrink text-right',
      render: (text, order) => {
        const { supplierApproved } = order.receipting
        return (
          <DivButtons>
            {isApprovedTable ? (
              supplierApproved === false ? (
                <StockApprovedMessage status={'rejected'}>
                  {layout !== 'sm' && <BodyText>Rejected</BodyText>}
                  {layout !== 'md' && <CloseCircleOutlined />}
                </StockApprovedMessage>
              ) : (
                <StockApprovedMessage status={'approved'}>
                  {layout !== 'sm' && <BodyText>Approved</BodyText>}
                  {layout !== 'md' && <CheckCircleOutlined />}
                </StockApprovedMessage>
              )
            ) : (
              <>
                <AcceptButton
                  size={'default'}
                  onClick={event => {
                    handleCheckOrder(event, { ...order }, true)
                  }}
                  checked={supplierApproved}
                  // disabled={locked}
                >
                  <CheckOutlined
                    style={{
                      fontSize: 17,
                      paddingBottom: 6,
                    }}
                  />
                </AcceptButton>
                <RejectButton
                  size={'default'}
                  onClick={event => {
                    handleCheckOrder(event, { ...order }, false)
                  }}
                  checked={supplierApproved === false}
                  // disabled={locked}
                >
                  <CloseOutlined
                    style={{
                      fontSize: 17,
                      paddingBottom: 6,
                    }}
                  />
                </RejectButton>
              </>
            )}
          </DivButtons>
        )
      },
    },
  ]
}

const BodyTextWrap = styled(BodyText)`
  white-space: break-spaces;
`

const DivButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const BaseButton = styled(Button)`
  width: 60px;
  margin-left: ${({ theme }) => `${theme.spacing.xxs} !important`};
  color: ${props =>
    props.checked && `${props.theme.colours.mayoWhite} !important`};
  &:hover {
    color: ${({ theme }) => `${theme.colours.lettuceGreen} !important`};
  }
`

const AcceptButton = styled(BaseButton)`
  background-color: ${props =>
    props.checked
      ? `${props.theme.colours.lettuceGreen} !important`
      : `${props.theme.colours.bgGrey} !important`};
`

const RejectButton = styled(BaseButton)`
  background-color: ${props =>
    props.checked
      ? `${props.theme.colours.chilliRed} !important`
      : `${props.theme.colours.bgGrey} !important`};
`

const StockApprovedMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing.xxs};
  color: ${props =>
    props.status === 'approved'
      ? props.theme.colours.lettuceGreen
      : props.status === 'rejected'
      ? props.theme.colours.chilliRed
      : 'orange'};
  ${({ theme }) => theme.device('sm')`
    width: 80px;
  `}
  ${({ theme }) => theme.device('md')`
    width: 100px;
  `}
`

export default ChecklistColumn
