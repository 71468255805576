import React, { useEffect, useRef, useState } from 'react'
import { DatePicker, Divider, List, Popconfirm, Tooltip } from 'antd'
import styled from 'styled-components/macro'
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import HeaderScreen from '../../components/shared/header-screen/HeaderScreen'
import { Link } from 'react-router-dom'
import {
  createStock,
  deleteStockTake,
  fetchStockTakes,
  updateStockTake,
} from '../../features/StockSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/styled/Loading'
import moment from 'moment'

const { RangePicker } = DatePicker

const StockList = props => {
  const venueId = props.match.params.venueId
  const dispatch = useDispatch()
  const stocks = useSelector(state => state.stockTakes.stockTakes)
  const isLoading = useSelector(state => state.stockTakes.isLoading)
  const buttonLoading = useSelector(state => state.stockTakes.buttonLoading)
  const [dateOfCreation, setDateOfCreation] = useState([])
  const [visibleCalendar, setVisibleCalendar] = useState(false)
  const refToSelectDate = useRef()

  useEffect(() => {
    dispatch(fetchStockTakes(venueId))
  }, [dispatch, venueId])

  const changeLockStatus = stockTake => {
    dispatch(
      updateStockTake(
        venueId,
        {
          ...stockTake,
          isLocked: !stockTake.isLocked,
        },
        'UNLOCK',
      ),
    )
  }

  const handleDeleteButton = stockTake => {
    dispatch(deleteStockTake(venueId, stockTake, 'DELETE'))
  }

  const handleCreateButtonWithPrev = () => {
    const data = {
      startDate: dateOfCreation[0],
      endDate: dateOfCreation[1],
    }
    refToSelectDate.current.picker.state.value = []
    dispatch(createStock({ ...data, prevStockTake: stocks[0]._id }, venueId))
    setDateOfCreation([])
  }

  const handleCreateButtonWithoutPrev = () => {
    const data = {
      startDate: dateOfCreation[0],
      endDate: dateOfCreation[1],
    }
    refToSelectDate.current.picker.state.value = []
    dispatch(createStock(data, venueId))
    setDateOfCreation([])

    refToSelectDate.current.picker.clearHoverValue()
  }

  const onChangeDate = (value, dateString) => {
    setVisibleCalendar(false)
    if (dateString) {
      setDateOfCreation(dateString)
    } else {
      setDateOfCreation([])
    }
  }

  const disabledDates = current => {
    for (const stock of stocks) {
      current._d.setHours(0)
      current._d.setMinutes(0)
      const isLessCurrentInRange = current <= moment(stock.toPeriod)
      const isGreaterThanMoment = current > moment.now()
      current._d.setHours(23)
      current._d.setMinutes(59)
      const isGreaterCurrentInRange = current >= moment(stock.fromPeriod)

      if (
        (isLessCurrentInRange && isGreaterCurrentInRange) ||
        isGreaterThanMoment
      ) {
        return true
      }
    }
    return false
  }

  const loadingButton = (item, type, icon) => {
    return buttonLoading.isLoading === true &&
      buttonLoading.stockTake === item._id &&
      buttonLoading.buttonType === type ? (
      <LoadingOutlined />
    ) : (
      icon
    )
  }

  const handleOnClickCreateWithoutDate = () => {
    refToSelectDate.current.focus()
  }

  const createButtonCheckDate = element => {
    if (dateOfCreation[0] && dateOfCreation[1]) {
      return element
    } else {
      return (
        <Tooltip placement='bottomLeft' title={'Please choose creation date'}>
          <CustomButton onClick={handleOnClickCreateWithoutDate}>
            Create
          </CustomButton>
        </Tooltip>
      )
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <HeaderScreen
            title={'Your stock takes'}
            subTitle={
              'You can select any date and edit stock take or create a new'
            }
          ></HeaderScreen>
          <StyledAddStoke>
            <strong>Stock take period:</strong>
            <RangePicker
              onFocus={() => setVisibleCalendar(true)}
              onBlur={() => setVisibleCalendar(false)}
              open={visibleCalendar}
              ref={refToSelectDate}
              disabledDate={disabledDates}
              onChange={onChangeDate}
            />
            {stocks.length > 0
              ? createButtonCheckDate(
                  <Popconfirm
                    onConfirm={handleCreateButtonWithPrev}
                    onCancel={handleCreateButtonWithoutPrev}
                    title={'Do you want to get data from last stock take?'}
                    okText={'Yes'}
                    cancelText={'No'}
                  >
                    <CustomButton>Create</CustomButton>
                  </Popconfirm>,
                )
              : createButtonCheckDate(
                  <CustomButton onClick={handleCreateButtonWithoutPrev}>
                    Create
                  </CustomButton>,
                )}
          </StyledAddStoke>
          <Divider orientation='left'>Previous stock takes</Divider>
          <List
            bordered
            dataSource={stocks}
            renderItem={item => (
              <List.Item>
                <StyledRow>
                  <StyledData>
                    {`${dayjs(item.fromPeriod).format('DD MMM YYYY')} 
                          - ${dayjs(item.toPeriod).format('DD MMM YYYY')}`}
                  </StyledData>
                  <StyledButtonContainer>
                    <Link
                      to={
                        !item.isLocked
                          ? `/v/${venueId}/stockTakes/${item._id}`
                          : '#'
                      }
                    >
                      <CustomButton disabled={item.isLocked}>
                        <EditOutlined />
                        Edit
                      </CustomButton>
                    </Link>
                    <CardButton
                      title='Are you sure you want to delete this Stock Take?'
                      placement='topRight'
                      onConfirm={() => handleDeleteButton(item)}
                      onCancel={e => e.stopPropagation()}
                      okText='Yes'
                      cancelText='No'
                    >
                      <CustomButton onClick={e => e.stopPropagation()}>
                        {loadingButton(item, 'DELETE', <DeleteOutlined />)}
                        Delete
                      </CustomButton>
                    </CardButton>
                    <Link
                      to={
                        item.isLocked
                          ? `/v/${venueId}/stockTakes/${item._id}/valuation-report`
                          : '#'
                      }
                    >
                      <CustomButton disabled={!item.isLocked}>
                        Stock report
                      </CustomButton>
                    </Link>
                    <Link
                      to={
                        item.isLocked
                          ? `/v/${venueId}/stockTakes/${item._id}/wastage-report`
                          : '#'
                      }
                    >
                      <CustomButton disabled={!item.isLocked}>
                        Wastage report
                      </CustomButton>
                    </Link>
                    <LockButton
                      onClick={() => changeLockStatus(item)}
                      loading={buttonLoading}
                      style={{
                        backgroundColor: item.isLocked
                          ? ({ theme }) => theme.colours.chilliRed
                          : ({ theme }) => theme.colours.lettuceGreen,
                      }}
                    >
                      {!item.isLocked ? (
                        <>
                          {loadingButton(item, 'LOCK', <LockOutlined />)} Lock
                        </>
                      ) : (
                        <>
                          {loadingButton(item, 'UNLOCK', <UnlockOutlined />)}{' '}
                          Unlock
                        </>
                      )}
                    </LockButton>
                  </StyledButtonContainer>
                </StyledRow>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  )
}

const StyledRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const StyledData = styled.div`
  width: 180px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const StyledAddStoke = styled.div`
  max-width: 650px;
  display: flex;
  gap: 10px;
`

const CustomButton = styled.button`
  padding: 3px 10px;
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  color: ${({ theme }) => theme.colours.mayoWhite};
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  :disabled {
    background-color: ${({ theme }) => theme.colours.chilliRed};
    :hover {
      cursor: not-allowed;
    }
  }
`

const LockButton = styled.button`
  padding: 3px 10px;
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  color: ${({ theme }) => theme.colours.mayoWhite};
  min-width: 90px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  :disabled {
    background-color: ${({ theme }) => theme.colours.chilliRed};
    :hover {
      cursor: not-allowed;
    }
  }
`
const CardButton = styled(Popconfirm)`
  align-self: flex-end;
  margin-left: 12px;
`

export default StockList
