import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const adminOrderSlice = createSlice({
  name: 'adminOrders',
  initialState: {
    adminOrders: [],
    isLoading: false,
  },
  reducers: {
    setAdminOrders: (state, action) => {
      state.adminOrders = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setAdminOrders, setIsLoading } = adminOrderSlice.actions

export const fetchAdminOrders = (FROM_DATE, TO_DATE) => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const session = await Auth.currentAuthenticatedUser()
    const url = `adminOrders?start=${FROM_DATE}&end=${TO_DATE}&username=${session.attributes.sub}`
    const data = await API.get('api', url)
    dispatch(setAdminOrders(data))
    dispatch(setIsLoading(false))
  } catch (e) {
    console.error(e)
    dispatch(setIsLoading(false))
    Sentry.captureException(e)
    return false
  }
}

export default adminOrderSlice
