import { createOrder, setBasket } from '../../features/OrdersSlice'
import React, { useState } from 'react'
import { Button, InputNumber } from 'antd'
import { ShoppingCartOutlined, LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const BasketFunctionality = ({ venueProducts, venueId, order }) => {
  const dispatch = useDispatch()
  const basket = useSelector(state => state.orders.basket)
  const [addProductId, setAddProductId] = useState('')
  const updateLoading = useSelector(state => state.orders.isUpdateLoading)
  const basketProductsIds = basket.map(product => product.product._id)
  const venueProductsIds = venueProducts
    .filter(prod => prod.product)
    .map(vProduct => vProduct.product._id)

  const addToBasket = order => {
    setAddProductId(order._id)
    // eslint-disable-next-line no-unused-vars
    const { _id, ...res } = venueProducts.find(p => p.product._id === order._id)
    dispatch(
      createOrder({
        ...res,
        isSpecialOrder: res?.isSpecialProduct ? true : false,
          specialProductPrice:
              res?.isSpecialProduct && !isNaN(res?.specialPrice)
                  ? res?.specialPrice
                  : 0,
        quantity: res.defaultQuantity || 1,
        OMSSpecialPrice:
          res.OMSSpecialPrice !== 'NaN' ? res.OMSSpecialPrice : 0,
        method: 'create',
      }),
    )
  }
  const pageName = useLocation().pathname.split('/').reverse()[0]

  const updateOrder = order => {
    dispatch(
      createOrder({
        ...order,
        quantity: parseFloat(order.quantity),
        OMSSpecialPrice: order.OMSSpecialPrice ? order.OMSSpecialPrice : 0,
        method: 'update',
      }),
    )
  }
  const onChangeQuantity = (value, product) => {
    dispatch(
      setBasket(
        basket.map(prod => {
          if (prod.product._id === product._id) {
            const newObj = {
              ...prod,
              quantity: value,
            }
            updateOrder(newObj)
            return newObj
          } else return prod
        }),
      ),
    )
  }

  const handleSetQuantity = product => {
    return basket.find(order => {
      return order.product._id === product._id
    }).quantity
  }
  return basketProductsIds.includes(order._id) ? (
    <InputNumber
      style={{ width: '70px' }}
      onChange={value => onChangeQuantity(value, order)}
      value={handleSetQuantity(order)}
    />
  ) : (
    <Button
      disabled={!venueProductsIds.includes(order._id)}
      onClick={() => addToBasket(order, venueId)}
      type={'primary'}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {updateLoading && addProductId === order._id ? (
        <LoadingOutlined />
      ) : (
        <ShoppingCartOutlined />
      )}{' '}
      {pageName === 'catalogue' && <span>Add</span>}
    </Button>
  )
}

export default BasketFunctionality
