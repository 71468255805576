import { Spin } from 'antd'
import React from 'react'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import styled from 'styled-components/macro'

const LoadingScreen = () => {
  return (
    <Splash>
      <Logo src={OMSLogo} alt='Orders Made Simple logo' />
      <Spin size='large' />
    </Splash>
  )
}

const Splash = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: ${({ theme }) => theme.colours.bbqBlack};
  .ant-spin-dot-item {
    background-color: white;
  }
`

const Logo = styled.img`
  width: 100px;
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`

export default LoadingScreen
