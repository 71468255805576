import { Divider, Statistic, Table, Tag } from 'antd'
import Dinero from 'dinero.js'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FadeIn from 'src/components/styled/FadeIn'
import { fetchAccounts } from 'src/features/AccountsSlice'
import { fetchCreditData } from 'src/features/CreditSlice'
import { isEmpty } from 'src/utils/objectFunctions'
import styled from 'styled-components'

const Credit = () => {
  const dispatch = useDispatch()
  const credit = useSelector(state => state.credit)
  const account = useSelector(state => state.auth.account)
  const venue = useSelector(state => state.venue.venue)
  const venues = useSelector(state => state.auth.account.venues)
  const accounts = useSelector(state => state.accounts.accounts)

  // Allow admin to view details of other accounts
  const venueAccountId =
    venues && venue
      ? venues.find(item => item._id === venue._id)?.account
      : null
  const displayAccount = account.isAdmin
    ? accounts.find?.(account => account._id === venueAccountId)
    : account
  const displayInvoices = credit.invoices?.filter(
    invoice => invoice.status !== 'draft',
  )

  useEffect(() => {
    if (!isEmpty(venue)) {
      dispatch(fetchCreditData(venue._id))
      account.isAdmin && dispatch(fetchAccounts())
    }
  }, [dispatch, venue, account.isAdmin])

  return (
    <Wrapper>
      <Stats>
        <Stat
          title='Current credit balance'
          value={Dinero({
            amount: credit.transactions[0]?.ending_balance || 0,
            currency: 'GBP',
          }).toFormat('$0,0.00')}
        />
        <Stat
          title='Days credit after statement'
          value={displayAccount?.creditDays || 0}
        />
        {/* <Stat
          title='Credit Limit'
          value={Dinero({
            amount: displayAccount?.creditLimit || 0,
            currency: 'GBP',
          }).toFormat('$0,0.00')}
        /> */}
      </Stats>

      <Divider />
      <h2>Credit statements</h2>
      <Table
        pagination={false}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        dataSource={(displayInvoices || []).map(invoice => ({
          ...invoice,
          key: invoice.id,
        }))}
        loading={credit.invoices === null}
        columns={[
          {
            title: 'Statement date',
            dataIndex: 'status_transitions.finalized_at',
            key: 'date',
            className: 'restrictShrink',
            render: date => {
              return moment.unix(date).format('DD MMM YYYY')
            },
          },
          {
            title: 'Amount',
            dataIndex: 'amount_due',
            key: 'amount_due',
            className: 'restrictShrink',
            render: value =>
              Dinero({
                amount: value,
                currency: 'GBP',
              }).toFormat('$0,0.00'),
          },
          {
            title: 'Due date',
            dataIndex: 'due_date',
            key: 'due_date',
            className: 'restrictShrink',
            render: date => {
              if (!date) return '—'
              return moment.unix(date).format('DD MMM YYYY')
            },
          },
          {
            title: 'Status',
            key: 'status',
            className: 'restrictShrink',
            render: (text, invoice) => {
              const status = invoice.paid
                ? `Paid on ${moment
                    .unix(invoice.status_transitions.paid_at)
                    .format('DD MMM YYYY')}`
                : invoice.status === 'void'
                ? 'Void'
                : 'Open'
              const now = moment()
              const isOverdue = moment.unix(invoice.due_date).isBefore(now)
              const needsPaid = !invoice.paid && status !== 'Void'
              const color = !needsPaid ? 'green' : isOverdue ? 'red' : 'orange'
              return (
                <Tag color={color}>
                  {isOverdue && needsPaid ? 'Overdue' : status}
                </Tag>
              )
            },
          },
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  background: ${({ theme }) => theme.colours.mayoWhite};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.lg};
  padding-top: 0;
  margin: ${({ theme }) => theme.spacing.sm};
  margin-top: 0;
  border-radius: 6px;
`

const Stats = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Stat = styled(Statistic)`
  margin: 25px 50px 50px 0 !important;
`

export default Credit
