import { FilterOutlined } from '@ant-design/icons'
import { DatePicker, Select, Table } from 'antd'
import Dinero from 'dinero.js'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FadeIn from 'src/components/styled/FadeIn'
import { setSupplierOrderFilter } from 'src/features/FilterSlice'
import {
  clearAllVenueReports,
  fetchSpendingSuppliers,
} from 'src/features/SpendingSlice'
import styled from 'styled-components/macro'
import { SpendingExpandedTable } from './SpendingExpandedTable'

const AdminOrders = () => {
  const dispatch = useDispatch()
  const reference = useRef()
  const { RangePicker } = DatePicker
  const { Option } = Select

  const filterData = useSelector(state => state.filter.supplierOrder)
  const orders = useSelector(state => state.supplierOrder.supplierOrder)
  const loading = useSelector(state => state.supplierOrder.loading)
  const dateFormat = 'YYYY-MM-DD'
  const weeklyDate = [moment().subtract(7, 'days'), moment()]
  const spendingOrders = orders
  const [selectedDate, setSelectedDate] = useState([
    weeklyDate[0].format(dateFormat),
    weeklyDate[1].format(dateFormat),
  ])

  useEffect(() => {
    dispatch(
      fetchSpendingSuppliers(
        weeklyDate[0].format(dateFormat),
        weeklyDate[1].format(dateFormat),
      ),
    )
  }, [])

  const tableRecords = filterData.length
    ? spendingOrders.filter(record => filterData.includes(record._id.supplier))
    : spendingOrders

  const onDateSelection = (value, dateString) => {
    setSelectedDate(dateString)
    dispatch(clearAllVenueReports())
    dispatch(fetchSpendingSuppliers(dateString[0], dateString[1]))
  }

  const handleChange = value => {
    dispatch(setSupplierOrderFilter(value ?? []))
  }

  const stringSort = (a, b) => String(a).trim().localeCompare(String(b).trim())
  const expandedRowRender = supplierTitle => {
    return (
      <SpendingExpandedTable
        supplierTitle={supplierTitle}
        startDate={selectedDate[0]}
        endDate={selectedDate[1]}
      />
    )
  }
  return (
    <Wrapper>
      <Form>
        <RangePicker
          format={dateFormat}
          defaultValue={weeklyDate}
          onChange={onDateSelection}
          style={{ width: '450px', marginRight: '10px' }}
          ranges={{
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
        />
        <Div>
          <Select
            ref={reference}
            mode='multiple'
            showSearch={true}
            allowClear
            placeholder='Search'
            onChange={handleChange}
            style={{ width: '350px' }}
          >
            {spendingOrders.map(supplier => (
              <Option key={supplier._id} value={supplier._id.supplier}>
                {supplier._id.supplier}
              </Option>
            ))}
          </Select>
          <FilterOutlined
            onClick={() => reference.current.focus()}
            style={{
              color: 'gray',
              position: 'absolute',
              left: '-3%',
              margin: '8px 0 0 17px',
              cursor: 'pointer',
            }}
          />
        </Div>
      </Form>
      <Br />
      <StyledParentTable
        scroll={{
          scrollToFirstRowOnChange: true,
          x: false,
        }}
        dataSource={tableRecords.map(data => ({
          ...data,
          key: data._id.supplier,
        }))}
        loading={loading}
        expandedRowRender={record => expandedRowRender(record._id.supplier)}
        pagination={false}
        columns={[
          {
            title: 'Supplier',
            dataIndex: '_id.supplier',
            key: 'supplierTitle',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a._id.supplier, b._id.supplier),
            sortDirections: ['ascend', 'descend'],
          },
          // {
          //   title: 'Second Title',
          //   dataIndex:'secondTitle[0]',
          //   key: 'supplierSecondTitle',
          //   className: 'restrictShrink',
          // },
          {
            title: 'Total orders',
            dataIndex: 'orders',
            key: 'totalOrders',
            className: 'restrictShrink',
            sorter: (a, b) => a.totalSpend - b.totalSpend,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Total venues ordering',
            dataIndex: 'venueIds',
            key: 'totalVenuesOrders',
            className: 'restrictShrink',
            sorter: (a, b) => a.totalSpend - b.totalSpend,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Total spend',
            dataIndex: 'totalSpendWithoutVat',
            key: 'totalSpend',
            className: 'restrictShrink',
            sorter: (a, b) => a.totalSpendWithoutVat - b.totalSpendWithoutVat,
            sortDirections: ['ascend', 'descend'],
            render: creditLimit =>
              Dinero({
                amount: parseInt(creditLimit) || 0,
                currency: 'GBP',
              }).toFormat('$0,0.00'),
          },
        ]}
      />
    </Wrapper>
  )
}
const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 95px;
  }
`
const StyledParentTable = styled(Table)`
  .ant-table-expanded-row ant-table-expanded-row-level-1 {
    margin-left: 50px;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: black;
    border-color: ${({ theme }) => theme.colours.panSilver};
  }
`

const Form = styled.div`
  display: flex;
  .ant-select-selection {
    padding-left: 20px;
  }
`
const Div = styled.div`
  position: relative;
`
const Br = styled.br`
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`

export default AdminOrders
