import React, { useState } from 'react'
import { Modal, TreeSelect, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { fetchVenueOrders } from 'src/features/OrdersSlice'
import Loading from 'src/components/styled/Loading'
import { CSVLink } from 'react-csv'

const VenuesReportModal = ({ visibleVenuesModal, setVsibleVenuesModal }) => {
  const venues = useSelector(state => state.auth.account.venues)
  const dispatch = useDispatch()
  const [selectedVenueIds, setSelectedVenuesIds] = useState([])
  const venueOrders = useSelector(state => state.orders.venueOrders)
  const dateFormat = 'DD/MM/YYYY'
  const venueOptions = venues.map(item => ({
    title: item.title,
    value: item._id,
  }))
  const allIds = venueOptions.map(({ value }) => value)
  const [date, setDate] = useState({
    start: moment().subtract(40, 'days').format(dateFormat),
    end: moment().format(dateFormat),
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isDataFetched, setIsDataFetched] = useState(false)
  const onChangeDate = date => {
    setDate({ start: date[0], end: date[1] })
  }

  const handleOk = async () => {
    setIsLoading(true)
    await dispatch(
      fetchVenueOrders(Array.from(selectedVenueIds), date.start, date.end),
    )
    setIsLoading(false)
    setIsDataFetched(true)
  }

  const headers = [
    { label: 'PO Number', key: 'PO' },
    { label: 'Title', key: 'Title' },
    { label: 'Quantity', key: 'Quantity' },
    { label: 'Order Date', key: 'OrderDate' },
    { label: 'Item Price', key: 'ItemPrice' },
    { label: 'Total Price', key: 'TotalPrice' },
    { label: 'Venue', key: 'Venue' },
    { label: 'Ordered By', key: 'Buyer' },
    { label: 'Supplier Name', key: 'Supplier' },
    { label: 'Order Status', key: 'Status' },
  ]

  console.log('Venue Orders:', venueOrders) // Validate data format

  return (
    <>
      <Modal
        width={'40%'}
        title={'Select venues '}
        visible={visibleVenuesModal}
        onCancel={() => {
          setVsibleVenuesModal(false)
          setIsDataFetched(false)
        }}
        footer={[
          <Button
            key='cancel'
            style={{ marginRight: 10 }}
            onClick={() => {
              setVsibleVenuesModal(false)
              setIsDataFetched(false)
            }}
          >
            Cancel
          </Button>,
          <CSVLink
            key='download'
            data={venueOrders}
            headers={headers}
            filename={`Venues-Orders-Report.csv`}
            className='ant-btn ant-btn-primary'
            style={{ display: isDataFetched ? 'inline-block' : 'none' }}
            onClick={() => setVsibleVenuesModal(false)}
          >
            Download
          </CSVLink>,
          <Button
            key='ok'
            type='primary'
            onClick={isDataFetched ? null : handleOk}
            style={{ display: isDataFetched ? 'none' : 'inline-block' }}
          >
            Ok
          </Button>,
        ]}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TreeSelect
              treeNodeFilterProp='title'
              allowClear={true}
              placeholder='Select Venues ...'
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              dropdownStyle={{ maxHeight: '300px' }}
              style={{ marginBottom: '15px', width: '355px' }}
              onChange={ids => setSelectedVenuesIds(ids)}
              value={selectedVenueIds}
              maxTagCount={1}
              maxTagPlaceholder={omittedValues =>
                `+ ${omittedValues.length} Venues ...`
              }
              treeData={[
                {
                  title:
                    selectedVenueIds.length > 0 ? (
                      <span
                        onClick={() => setSelectedVenuesIds([])}
                        style={{
                          display: 'inline-block',
                          color: '#b5d922',
                          cursor: 'pointer',
                        }}
                      >
                        Unselect all
                      </span>
                    ) : (
                      <span
                        onClick={() => setSelectedVenuesIds(allIds)}
                        style={{
                          display: 'inline-block',
                          color: '#b5d922',
                          cursor: 'pointer',
                        }}
                      >
                        Select all
                      </span>
                    ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true,
                },
                ...venueOptions,
              ]}
            />
            <StyledRangePicker
              defaultValue={[moment().subtract(40, 'days'), moment()]}
              onChange={(value, string) => onChangeDate(string)}
              format={dateFormat}
            />
          </>
        )}
      </Modal>
    </>
  )
}

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
    width: 355px;
  }
`

export default VenuesReportModal
