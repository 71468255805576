import { Layout, Menu } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import OMSErrorBoundary from 'src/components/shared/ErrorBoundary'
import styled from 'styled-components/macro'

const { Item } = Menu

const AdminRoute = ({ children, isAdmin, supplierAdmin }) => {
  const location = useLocation()
  const pathFragments = location.pathname.split('/')
  const hasSubMenu = false
  const slug = hasSubMenu
    ? pathFragments[pathFragments.length - 2]
    : pathFragments[pathFragments.length - 1]

  return isAdmin ? (
    <>
      <StyledMenu mode='horizontal' selectedKeys={[slug]}>
        <Item key='orders'>
          <Link to={`/admin/orders`}>Orders</Link>
        </Item>
        <Item key='spending'>
          <Link to={`/admin/spending`}>Spending</Link>
        </Item>
        <Item key='products'>
          <Link to={`/admin/products`}>Products</Link>
        </Item>
        <Item key='suppliers'>
          <Link to={`/admin/suppliers`}>Suppliers</Link>
        </Item>
        <Item key='accounts'>
          <Link to={`/admin/accounts`}>Accounts</Link>
        </Item>
      </StyledMenu>
      <StyledLayout hassubmenu={hasSubMenu ? 1 : 0}>
        <OMSErrorBoundary>{children}</OMSErrorBoundary>
      </StyledLayout>
    </>
  ) : supplierAdmin ? (
    <>
      <StyledMenu mode='horizontal' selectedKeys={[slug]}>
        <Item key='orders'>
          <Link to={`/supplier/orders`}>Orders</Link>
        </Item>
        {/* <Item key='spending'>
          <Link to={`/supplier/spending`}>Spending</Link>
        </Item> */}
        <Item key='products'>
          <Link to={`/supplier/products`}>Products</Link>
        </Item>
      </StyledMenu>
      <StyledLayout hassubmenu={hasSubMenu ? 1 : 0}>
        <OMSErrorBoundary>{children}</OMSErrorBoundary>
      </StyledLayout>
    </>
  ) : null
}

const StyledMenu = styled(Menu)`
  height: 52px;
  background: ${({ theme }) => theme.colours.mayoWhite};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledLayout = styled(Layout)`
  background: ${({ theme }) => theme.colours.mayoWhite} !important;
  min-height: calc(100vh - 167px) !important;
  padding: ${({ theme, hassubmenu }) =>
    hassubmenu ? 0 : `${theme.spacing.lg} ${theme.spacing.xxl}`};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${({ theme, hassubmenu }) =>
      hassubmenu ? 0 : `${theme.spacing.lg} ${theme.spacing.md}`};
  }
`

export default AdminRoute
