import { Button, Modal, Popconfirm, Table, Tag, Select } from 'antd'
import React, { useEffect, useState, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Title from 'src/branding/Title'
import FadeIn from 'src/components/styled/FadeIn'
import {
  addSupplier,
  deleteSupplier,
  // downloadSupplierVenuesCSV,
  editSupplier,
  fetchAllSuppliers,
  rebuildSupplierSearchIndex,
  resetSearchReindex,
} from 'src/features/SupplierSlice'
import EditSupplierModal from 'src/screens/suppliers/EditSupplierModal'
import SupplierCard from 'src/screens/suppliers/SupplierCard'
import styled, { ThemeContext } from 'styled-components/macro'
import Sentry from 'src/utils/sentry'
import { CSVButton } from '../products/Products'
import { API } from 'aws-amplify'
import { arrayToCSVSupplier } from '../products/Utils'
import { useWindowWidth } from 'src/utils/useWindowWidth'
// import LoadingButton from 'src/components/styled/LoadingButton'

export const calculateTotalSpend = array => {
  let totalPrice = 0
  array?.map(order => {
    totalPrice += order?.isSpecialOrder
      ? order?.OMSSpecialPrice
        ? order?.OMSSpecialPrice * order.quantity
        : order?.specialProductPrice * order.quantity
      : order?.OMSPrice * order.quantity
  })
  return totalPrice / 100
}

const Suppliers = () => {
  const dispatch = useDispatch()
  const [csvSuppliers, setCsvSuppliers] = useState([])
  const [csvLoading, setCsvLoading] = useState(false)
  const csvButton = useRef()
  const account = useSelector(state => state.auth.account)
  const supplierAdmin = account && account.supplierAdmin
  const [selectedSupplier, setSelectedSupplier] = useState(
    supplierAdmin ? account.supplier._id : null,
  )
  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg
  const { Option } = Select
  const [editingSupplier, setEditingSupplier] = useState(null)
  const suppliers = useSelector(state => state.suppliers.suppliers)
  console.log('suppliers', suppliers?.length)

  useEffect(() => {
    if (suppliers.length === 0) dispatch(fetchAllSuppliers())
  }, [dispatch])

  const stringSort = (a, b) => String(a).trim().localeCompare(String(b).trim())

  // const onDownLoadCSV = supplierId => {
  //   dispatch(downloadSupplierVenuesCSV(supplierId))
  // }

  return (
    <Wrapper>
      <Title>Suppliers</Title>
      <DivContainer gutter={16}>
        <DivFlex>
          <Select
            placeholder='Select a supplier'
            style={{ width: isMobile ? '100%' : 250 }}
            onChange={e => {
              setSelectedSupplier(e)
            }}
          >
            {suppliers.map(supplier => {
              return (
                <Option key={supplier._id} value={supplier._id}>
                  {supplier.title}&nbsp;{supplier.secondTitle}
                </Option>
              )
            })}
          </Select>
          <CSVButton
            ref={csvButton}
            isMobile={isMobile}
            data={csvSuppliers}
            fetchCSVData={async () => {
              if (!selectedSupplier) {
                alert('Choose a supplier first')
                return false
              }
              setCsvLoading(true)
              try {
                const { data } = await API.get(
                  'api',
                  `venueSuppliers/${selectedSupplier}`,
                )
                if (data) {
                  const formattedSuppliers = arrayToCSVSupplier(data)
                  setCsvSuppliers(formattedSuppliers)
                  await csvButton.current.link.click()
                  setCsvSuppliers([])
                  setCsvLoading(false)
                }
              } catch (e) {
                console.log(e)
                Sentry.captureException(e)
                setCsvSuppliers([])
                setCsvLoading(false)
              }
            }}
            filename={`oms-suppliers-export.csv`}
            loading={csvLoading}
          >
            Download CSV
          </CSVButton>
        </DivFlex>
      </DivContainer>
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: false,
        }}
        dataSource={(suppliers || []).map(supplier => ({
          ...supplier,
          key: supplier._id,
        }))}
        loading={suppliers.length < 1}
        pagination={false}
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.title, b.title),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Second title',
            dataIndex: 'secondTitle',
            key: 'secondTitle',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.secondTitle, b.secondTitle),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Order email',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.emailAddress, b.emailAddress),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Account number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            className: 'restrictShrink',
            sorter: (a, b) => stringSort(a.accountNumber, b.accountNumber),
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Minimum order',
            dataIndex: 'minOrderValue',
            key: 'minOrderValue',
            className: 'restrictShrink',
            render: text => <span>£{text ? text / 100 : 0}</span>,
            sorter: (a, b) => a.minOrderValue - b.minOrderValue,
            sortDirections: ['ascend', 'descend'],
          },
          {
            title: 'Delivery days',
            dataIndex: 'deliveryDays',
            key: 'deliveryDays',
            className: 'restrictShrink',
            render: text => {
              return <span>{text && text.join(', ')}</span>
            },
            filters: [
              {
                text: 'Mon',
                value: 'mon',
              },
              {
                text: 'Tue',
                value: 'tue',
              },
              {
                text: 'Wed',
                value: 'wed',
              },
              {
                text: 'Thu',
                value: 'thu',
              },
              {
                text: 'Fri',
                value: 'fri',
              },
              {
                text: 'Sat',
                value: 'sat',
              },
              {
                text: 'Sun',
                value: 'sun',
              },
            ],
            onFilter: (value, record) => record.deliveryDays.includes(value),
          },
          {
            title: 'Credit status',
            dataIndex: 'allowsCreditPurchase',
            key: 'allowsCreditPurchase',
            className: 'restrictShrink',
            render: (text, supplier) => {
              return (
                <Tag color={supplier.allowsCreditPurchase ? 'green' : 'orange'}>
                  {supplier.allowsCreditPurchase
                    ? 'Allows credit'
                    : 'Pay on order'}
                </Tag>
              )
            },
          },
          {
            title: 'Search index',
            key: 'searchIndex',
            className: 'restrictShrink',
            render: (text, supplier) =>
              supplier.searchReindexResponse ? (
                supplier.searchReindexResponse.success ? (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      dispatch(resetSearchReindex({ id: supplier._id }))
                    }
                  >
                    Reindexed{' '}
                    {parseInt(
                      supplier.searchReindexResponse.recordsUpserted,
                    ).toLocaleString()}{' '}
                    products
                  </span>
                ) : (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      dispatch(resetSearchReindex({ id: supplier._id }))
                    }
                  >
                    Failed to rebuild
                  </span>
                )
              ) : (
                <Button
                  type='secondary'
                  loading={supplier.loadingSearchReindex}
                  onClick={() => {
                    dispatch(rebuildSupplierSearchIndex(supplier))
                  }}
                >
                  <span>Rebuild</span>
                </Button>
              ),
          },
          {
            title: 'Action',
            key: 'action',
            className: 'restrictShrink',
            render: (text, supplier) => (
              <>
                <StyledButton
                  type={'dashed'}
                  onClick={() => {
                    setEditingSupplier(supplier)
                  }}
                >
                  <span>Edit</span>
                </StyledButton>
                &nbsp;
                <Popconfirm
                  title='Are you sure to delete this supplier?'
                  placement='topRight'
                  onConfirm={() => {
                    dispatch(deleteSupplier(supplier))
                  }}
                  okText='Yes'
                  cancelText='No'
                >
                  <StyledButton type={'danger'}>
                    <span>Delete</span>
                  </StyledButton>
                </Popconfirm>
                {/* <LoadingButton
                  style={{
                    marginTop: '3px',
                  }}
                  onClick={() => {
                    onDownLoadCSV(supplier?._id)
                  }}
                >
                  Download Venues
                </LoadingButton> */}
              </>
            ),
          },
        ]}
      />
      <SupplierCard
        saveSupplier={supplier => {
          dispatch(addSupplier(supplier))
        }}
      />

      <Modal
        title='Edit supplier'
        visible={editingSupplier !== null}
        onOk={() => {
          dispatch(
            editSupplier({
              ...editingSupplier,
            }),
          )
          setEditingSupplier(null)
        }}
        onCancel={() => {
          setEditingSupplier(null)
        }}
      >
        {editingSupplier && (
          <EditSupplierModal
            supplier={editingSupplier}
            setEditingSupplier={supplier => {
              setEditingSupplier(supplier)
            }}
          />
        )}
      </Modal>
    </Wrapper>
  )
}

const DivContainer = styled.div`
  display: flex;
  width: 100%;
`

const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 95px;
  }
`
const StyledButton = styled(Button)`
  margin: 3px 3px 3px 0;
  width: 80px;
`
const DivFlex = styled.div`
  display: flex;
  gap: 20px;
  width: 60%;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'flex-start')};
  padding-top: 8px;
  flex-wrap: wrap;
`

export default Suppliers
