const subtitle = {
  orders: 'Browse through all your orders and download your daily invoices.',
  ordered: 'Everything you need to know about the products you’re buying.',
  spendTime: 'Monitor your supplier spending and check your monthly outgoings',
  credit: 'Check your credit balance and keep track of your credit statements.',
}

const getSubtitle = slug => subtitle[slug]

export default getSubtitle
