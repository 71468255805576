import { Button, Divider, Input, Select } from 'antd'
import React, { useRef, useState } from 'react'
import useOutsideClick from 'src/utils/useOutsideClick'
import wordCaps from 'src/utils/wordCaps'
import styled from 'styled-components/macro'

const { Option } = Select

const AutocompleteInput = ({ fieldName, options = [], selected, onChange }) => {
  const ref = useRef()
  const [items, setItems] = useState(options)
  const [newValue, setNewValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const onNewValueChange = event => setNewValue(event.target.value)

  const addItem = () => {
    const sanitised = wordCaps(newValue).trim()
    if (!sanitised) return false
    if (!options.includes(sanitised)) {
      setItems(current => [...current, sanitised])
    }
    onChange(sanitised)
    setNewValue('')
    return true
  }

  const handleKeyPress = e => (e.key === 'Enter' ? addItem() : false)

  useOutsideClick(ref, () => {
    if (isOpen) setIsOpen(false)
  })

  return (
    <Wrapper onClick={() => setIsOpen(current => !current)} ref={ref}>
      <Label>{fieldName}</Label>
      <Root
        placeholder={`Select or create a ${fieldName}`}
        open={isOpen}
        onSelect={val => {
          onChange(val)
          setIsOpen(false)
        }}
        value={selected ? String(selected).trim() : undefined}
        dropdownRender={menu => (
          <Dropdown onClick={e => e.stopPropagation()}>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <NewValueRow>
              <Input
                value={newValue}
                onChange={onNewValueChange}
                onKeyPress={handleKeyPress}
              />
              <Button type='link' onClick={addItem}>
                Add {fieldName}
              </Button>
            </NewValueRow>
          </Dropdown>
        )}
      >
        {items.map((item, index) => {
          return (
            <Option key={index} value={item}>
              {item}
            </Option>
          )
        })}
      </Root>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Label = styled.span`
  min-width: max-content;
  height: 30px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  background-color: ${({ theme }) => theme.colours.ordersTabGrey};
  border: 1px solid ${({ theme }) => theme.colours.panSilver};
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
`

const Root = styled(Select)`
  width: 100%;
`

const Dropdown = styled.div``

const NewValueRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 8px 12px 12px;
`

export default AutocompleteInput
