import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Table, Tag } from 'antd'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import FadeIn from 'src/components/styled/FadeIn'
import Loading from 'src/components/styled/Loading'
import { setCatalogueFilter } from 'src/features/FilterSlice'
import { fetchAllSuppliers } from 'src/features/SupplierSlice'
import {
  editVenueSupplier,
  getVenueSuppliers,
} from 'src/features/VenueSuppliersSlice'
import EditVenueSupplierModal from 'src/screens/venue-suppliers/EditVenueSupplierModal'
import useLunrSearch from 'src/utils/useLunrSearch'
import styled from 'styled-components/macro'

const { Search } = Input

const VenueSuppliers = props => {
  const { venueId } = props.match.params
  const dispatch = useDispatch()
  const account = useSelector(state => state.auth.account)
  const suppliers = useSelector(state => state.suppliers.suppliers)
  const venueSuppliers = useSelector(
    state => state.venueSuppliers.venueSuppliers,
  )
  const isLoading = useSelector(state => state.venueSuppliers.isLoading)
  const accountCreditApproved = useSelector(
    state => state.auth.account.creditApproved,
  )
  const isSomeVenueSupplierActive = useSelector(
    state => state.venueSuppliers.isSomeVenueSupplierActive,
  )
  const isAdmin = account && account.isAdmin
  const isTfrAdmin = account && account.TFRAdmin
  const [searchTerm, setSearchTerm] = useState('')
  const [searchVenueSuppliers, setSearchVenueSuppliers] = useState([])
  const [editingVenueSupplier, setEditingVenueSupplier] = useState(null)
  const linkPrefix = venueId ? `/v/${venueId}/catalogue` : '/admin/orders'
  const history = useHistory()

  useEffect(() => {
    if (suppliers.length === 0) dispatch(fetchAllSuppliers())
    if (venueSuppliers.length === 0 && venueId)
      dispatch(getVenueSuppliers(venueId))
  }, [dispatch, venueId])

  const resetPagination = useCallback(() => {}, [])

  useLunrSearch(
    venueSuppliers,
    [
      'supplierTitle',
      'accountNumber',
      'minOrderValue',
      'deliveryDays',
      'deliveryFee',
    ],
    searchTerm,
    setSearchVenueSuppliers,
    resetPagination,
  )

  const supplierNameRedirect = record => {
    if (isSomeVenueSupplierActive) {
      dispatch(setCatalogueFilter(record.supplierTitle))
      history.push(linkPrefix)
    }
  }
  const content = (
    <div>
      <p style={{ maxWidth: 300 }}>
        Some suppliers need a few days notice before becoming available for
        orders, if you would like to request access to more suppliers please let
        us know at info@ordersmadesimple.com
      </p>
    </div>
  )

  const dataSource = isAdmin
    ? searchVenueSuppliers.map(venueSupplier => ({
        ...venueSupplier,
        key: venueSupplier._id,
        phoneNumber: venueSupplier?.supplier?.phoneNumber || '',
        secondTitle: venueSupplier?.supplier?.secondTitle || '',
      }))
    : isTfrAdmin
    ? searchVenueSuppliers
        .filter(
          venueSupplier =>
            venueSupplier.isActive || venueSupplier.supplier.TFRSupplier,
        )
        .map(venueSupplier => ({
          ...venueSupplier,
          key: venueSupplier._id,
          phoneNumber: venueSupplier?.supplier?.phoneNumber || '',
          secondTitle: venueSupplier?.supplier?.secondTitle || '',
        }))
    : searchVenueSuppliers
        .filter(venueSupplier => venueSupplier.isActive)
        .map(venueSupplier => ({
          ...venueSupplier,
          key: venueSupplier._id,
          phoneNumber: venueSupplier?.supplier?.phoneNumber || '',
        }))

  return (
    <Wrapper isSomeVenueSupplierActive={isSomeVenueSupplierActive}>
      <HeaderScreen
        title={'Your active suppliers'}
        subTitle={
          'These suppliers are ready to take your order, click on a supplier to view their catalogue'
        }
        popoverTitle={'Want more suppliers?'}
        popoverContent={content}
      >
        <Search
          size='large'
          placeholder='Search'
          onSearch={value => setSearchTerm(value)}
          style={{ width: 300 }}
          allowClear
        />
      </HeaderScreen>

      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Table
            onRow={record => {
              return {
                onClick: () => {
                  supplierNameRedirect(record)
                },
              }
            }}
            scroll={{
              scrollToFirstRowOnChange: true,
              x: true,
            }}
            dataSource={dataSource}
            pagination={false}
            columns={[
              {
                title: 'Title',
                dataIndex: 'supplierTitle',
                key: 'supplierTitle',
                className: 'restrictShrink',
                sorter: (a, b) =>
                  String(a.supplierTitle)
                    .trim()
                    .localeCompare(String(b.supplierTitle).trim()),
                sortDirections: ['ascend', 'descend'],
                defaultSortOrder: 'ascend',
              },

              isAdmin || isTfrAdmin
                ? {
                    title: 'Second title',
                    dataIndex: 'secondTitle',
                    key: 'secondTitle',
                    className: 'restrictShrink',
                    sorter: (a, b) =>
                      String(a.secondTitle)
                        .trim()
                        .localeCompare(String(b.secondTitle).trim()),
                    sortDirections: ['ascend', 'descend'],
                    defaultSortOrder: 'ascend',
                  }
                : {},

              {
                title: 'Account number',
                dataIndex: 'accountNumber',
                key: 'accountNumber',
                className: 'restrictShrink',
              },

              {
                title: 'Minimum order',
                dataIndex: 'minOrderValue',
                key: 'minOrderValue',
                className: 'restrictShrink',
                render: text => <span>£{text / 100}</span>,
                sorter: (a, b) => a.minOrderValue - b.minOrderValue,
                sortDirections: ['ascend', 'descend'],
              },

              {
                title: 'Delivery Fee',
                dataIndex: 'deliveryFee',
                key: 'deliveryFee',
                className: 'restrictShrink',
                render: text => {
                  return <span>{text}</span>
                },
              },

              {
                title: 'Delivery days',
                dataIndex: 'deliveryDays',
                key: 'deliveryDays',
                className: 'restrictShrink',
                render: text => {
                  return <span>{text && text.join(', ')}</span>
                },
              },

              ...(accountCreditApproved && isAdmin
                ? [
                    {
                      title: 'Credit status',
                      dataIndex: 'allowsCreditPurchase',
                      key: 'allowsCreditPurchase',
                      className: 'restrictShrink',
                      render: (text, venueSupplier) => {
                        const supplierId = venueSupplier.supplier
                        const supplier = suppliers.find(
                          supplier => supplier._id === supplierId,
                        )
                        return (
                          <Tag
                            color={
                              supplier?.allowsCreditPurchase
                                ? 'green'
                                : 'orange'
                            }
                          >
                            {supplier?.allowsCreditPurchase
                              ? 'Credit terms'
                              : 'Pay on order'}
                          </Tag>
                        )
                      },
                    },
                  ]
                : []),

              {
                title: 'Order cut off time',
                dataIndex: 'orderCutOffTime',
                key: 'orderCutOffTime',
                className: 'restrictShrink',
              },

              {
                title: 'Phone number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                className: 'restrictShrink',
              },

              isTfrAdmin || isAdmin
                ? {
                    title: 'Supplier in app',
                    key: 'actions',
                    className: 'restrictShrink',
                    render: (text, venueSupplier) => (
                      <ButtonsCell>
                        <Icon
                          onClick={event => {
                            event.preventDefault()
                            event.stopPropagation()
                          }}
                        >
                          {venueSupplier.isActive ? (
                            <CheckOutlined style={{ color: 'green' }} />
                          ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                          )}
                        </Icon>
                        <Button
                          type={'dashed'}
                          onClick={event => {
                            setEditingVenueSupplier(venueSupplier)
                            event.stopPropagation()
                          }}
                        >
                          <span>Edit</span>
                        </Button>
                      </ButtonsCell>
                    ),
                    sorter: (a, b) => a.isActive - b.isActive,
                    sortDirections: ['descend', 'ascend'],
                  }
                : {
                      title: 'Edit',
                      key: 'actions',
                      className: 'restrictShrink',
                      render: (text, venueSupplier) => (
                          <ButtonsCell>

                              <Button
                                  type={'dashed'}
                                  onClick={event => {
                                      setEditingVenueSupplier(venueSupplier)
                                      event.stopPropagation()
                                  }}
                              >
                                  <span>Edit</span>
                              </Button>
                          </ButtonsCell>
                      ),

                  },
            ]}
          />

          <Modal
            title='Edit Supplier'
            visible={editingVenueSupplier !== null}
            onOk={() => {
              dispatch(
                editVenueSupplier({
                  ...editingVenueSupplier,
                }),
              )
              setEditingVenueSupplier(null)
            }}
            onCancel={() => {
              setEditingVenueSupplier(null)
            }}
          >
            {editingVenueSupplier && (
              <EditVenueSupplierModal
                venueSupplier={editingVenueSupplier}
                account={account}
                setEditingVenueSupplier={venueSupplier => {
                  setEditingVenueSupplier(venueSupplier)
                }}
              />
            )}
          </Modal>
        </Fragment>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  .selected {
    background: rgba(180, 216, 32, 0.33);
  }
  .restrictShrink {
    min-width: 95px;
  }
  .ant-table-row {
    cursor: ${props =>
      props.isSomeVenueSupplierActive ? 'pointer' : 'default'};
    &:hover {
      background-color: ${({ theme }) => theme.colours.bgGrey};
      > td {
        background: none !important;
      }
    }
  }
`

const ButtonsCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Icon = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
`

export default VenueSuppliers
