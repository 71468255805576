import { Icon, Popover } from 'antd'
import React from 'react'
import { BodyText, H3 } from 'src/branding/typography'
import { SpaceBetween } from 'src/components/styled/FlexBox'
import styled from 'styled-components'
//import AddInvoices from "../../AddInvoices";

const HeaderScreen = ({
  title = '',
  subTitle = '',
  textColor = '',
  popoverContent = '',
  popoverTitle = '',
  children,
  //slug = "",
  //venueId
}) => {
  return (
    <HeaderWrapper>
      <Row>
        <div>
          <LeftColumn>
            <Title>{title}</Title>
            {popoverContent ? (
              <Popover
                placement='left'
                content={popoverContent}
                title={popoverTitle}
              >
                <Icon style={{ marginTop: 2 }} type='question-circle' />
              </Popover>
            ) : (
              ''
            )}
            {/*{slug === 'orders' ?*/}
            {/*    <AddInvoices venueId={venueId}/> : null*/}
            {/*}*/}
          </LeftColumn>
          <SubTitle color={textColor}>{subTitle}</SubTitle>
        </div>
        {children}
      </Row>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`
const Row = styled(SpaceBetween)`
  align-items: flex-end;
`

const LeftColumn = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.tiny};
  display: flex;
  align-items: center;
`

const Title = styled(H3)`
  margin: 0 ${({ theme }) => theme.spacing.xxs} 0 0;
`

const SubTitle = styled(BodyText)`
  color: ${({ theme }) => theme.colours.oliveGrey};
`

export default HeaderScreen
