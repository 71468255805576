import { Button, InputNumber } from 'antd'
import React from 'react'
import ProductImage from '../../../components/shared/ProductImage'

const BasketTableColumn = (
  selectedProducts,
  handleRemoveProduct,
  onChangeQuantity,
  updateLoading,
  removeProductId,
  isPricesHidden,
) => {
  return [
    {
      title: '',
      dataIndex: 'product.cloudinaryImageId',
      key: 'cloudinaryImageId',
      width: '7%',
      render: (_, product) => (
        <ProductImage imageId={product?.product?.cloudinaryImageId} />
      ),
    },
    {
      title: 'Title',
      key: 'title',
      width: '25%',
      render: (_, item) => {
        return item?.venueProduct?.title || item?.product?.title
      },
    },
    {
      title: 'Size',
      dataIndex: 'product.size',
      key: 'size',
      width: '14%',
    },
    {
      title: 'Price',
      dataIndex: '',
      key: 'OMSPrice',
      width: '10%',
      render: (_, item) => {
        return isPricesHidden
            ? ''
            : Number(item?.isSpecialOrder
              ? item?.specialProductPrice
              : item.product.price).toFixed(2)
      }
    },
    // {
    //   title: 'Delivery Fee',
    //   key: 'DeliveryFee',
    //   width: '10%',
    //   render: item => {
    //     return item?.venueSuppplier?.deliveryFee
    //       ? item?.venueSuppplier?.deliveryFee
    //       : 0
    //   },
    // },
    {
      title: 'Supplier',
      dataIndex: 'product.supplier.title',
      key: 'supplierTitle',
      width: '22%',
    },
    {
      title: 'SKU',
      dataIndex: 'product.sku',
      key: 'sku',
      width: '7%',
    },
    {
      title: 'Amount',
      dataIndex: 'product.quantity',
      key: 'quantity',
      width: '8%',
      render: (_, product) => (
        <InputNumber
          style={{ width: 50 }}
          value={product.quantity}
          onChange={value => onChangeQuantity(value, product)}
        />
      ),
    },
    {
      title: 'Action',
      key: 'remove',
      width: '8%',
      render: (_, product) => (
        <Button
          loading={updateLoading && removeProductId === product._id}
          type={'danger'}
          onClick={() => handleRemoveProduct(product)}
        >
          Remove
        </Button>
      ),
    },
  ]
}

export default BasketTableColumn
