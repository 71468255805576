import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Popconfirm, Table, Tooltip } from 'antd'
import { convertArrayToCSV } from 'convert-array-to-csv'
import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { CSVLink } from 'react-csv'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import { BodyText, H1, H3, H4 } from 'src/branding/typography'
import LoadingScreen from 'src/components/shared/LoadingScreen'
import FadeIn from 'src/components/styled/FadeIn'
import { SpaceBetween } from 'src/components/styled/FlexBox'
import LoadingButton from 'src/components/styled/LoadingButton'
import {
  fetchOrdersChecklist,
  lockOrder,
  // notifications,
  supplierNotifications,
} from 'src/features/ChecklistSlice'
import { getVenue } from 'src/features/VenueSlice'
import { editVenueSuppliers } from 'src/features/VenueSuppliersSlice'
import TableColumnsCheckList from 'src/screens/order-checklist/TableColumnsChecklist'
import { findUnique } from 'src/utils/arrayFunctions'
import GetWeekDay from 'src/utils/getWeekDays'
import { getTotalOMSPrice } from 'src/utils/priceFunctions'
import { useWindowWidth, windowSize } from 'src/utils/useWindowWidth'
import styled from 'styled-components/macro'
import Talk from 'talkjs'
import { createNewReceipting } from '../../features/ReceiptingSlice'

dayjs.extend(advancedFormat)

const OrderChecklist = props => {
  const dispatch = useDispatch()
  const { isLoading: isLoadingVenue } = useSelector(
    state => state.venue.isLoading,
  )
  const {
    // venueDeliveryDays,
    addressLine1,
    addressLine2,
    addressCity,
    addressPostCode,
    phoneNumber,
    title,
  } = useSelector(state => state.venue.venue) || {}
  const isAdmin = useSelector(state => state.auth.account.isAdmin || null)
  const {
    venueSupplier,
    approvedOrders,
    noApprovedOrders: noApprovedOrdersFromServer,
    locked,
    orderError,
    isLoading: isLoadingChecklist,
  } = useSelector(state => state.checklist)
  const isSubstitutedColumnShown = approvedOrders.some(
    currentOrder => currentOrder.supplier.integrationType === 'Brakes',
  )
  const {
    accountNumber,
    supplierTitle,
    hasVenueUpdateAddress,
    supplier,
    venue,
    // hasVenueUpdateDeliveryDays,
  } = venueSupplier || {}

  const { venueId, supplierId, date, orderNumber = 'all' } = props.match.params
  const isLoading = isLoadingVenue || isLoadingChecklist

  const [noApprovedOrders, setNoApprovedOrders] = useState([])
  const [confirmingOrder, setConfirmingOrder] = useState(false)
  const [noApprovedTotal, setNoApprovedTotal] = useState(0)
  const [approvedTotal, setApprovedTotal] = useState(0)
  const [vatTotalForAprov, setVatTotalForAprov] = useState(0)
  const [vatTotalForNoAprov, setVatTotalForNoAprov] = useState(0)
  const [totalAmountForAprov, setTotalAmountForAprov] = useState(0)
  const [totalAmountForNoAprov, setTotalAmountForNoAprov] = useState(0)
  const [areEveryOrderConfirmed, setAreEveryOrderConfirmed] = useState(false)
  const [chatButton, setChatButton] = useState(true)
  const [editable, setEditable] = useState(false)
  const [isManuallyAdded, setIsManuallyAdded] = useState(false)

  const completed = noApprovedOrders.length === 0 || locked

  const [receiptingState, setReceiptingState] = useState([])

  const layout = windowSize(useWindowWidth())
  const buttonSize =
    layout === 'md' || layout === 'sm' || layout === 'xs' ? 'default' : 'large'

  const orderDate = date

  const displayDate = `${GetWeekDay(dayjs(orderDate).get('day'))}  ${dayjs(
    orderDate,
  )
    .format('DD/MM/YYYY')
    .split('/')
    .join(' / ')}`

  const notes = findUnique(
    [...noApprovedOrders, ...approvedOrders]
      .filter(
        order =>
          order.notes !== undefined &&
          order.notes !== null &&
          order.notes !== '',
      )
      .map(order => ({ notes: order.notes })),
    order => order.notes,
    'values',
  )

  const requiredDeliveryDays = [...noApprovedOrders, ...approvedOrders]
    .filter(
      order =>
        order.requiredDeliveryDay !== null &&
        order.requiredDeliveryDay !== undefined,
    )
    .map(order => ({ requiredDeliveryDay: order.requiredDeliveryDay }))

  const requiredDeliveryDayToShow =
    requiredDeliveryDays &&
    requiredDeliveryDays.length > 0 &&
    requiredDeliveryDays[0].requiredDeliveryDay
      ? dayjs(
          requiredDeliveryDays[requiredDeliveryDays.length - 1]
            .requiredDeliveryDay,
        ).format('dddd Do MMMM YYYY')
      : 'Next available delivery day'

  const csvOrderDate = dayjs(orderDate).format('DD-MM-YYYY')
  const csvDeliveryDate =
    requiredDeliveryDays &&
    requiredDeliveryDays.length > 0 &&
    requiredDeliveryDays[0].requiredDeliveryDay
      ? dayjs(
          requiredDeliveryDays[requiredDeliveryDays.length - 1]
            .requiredDeliveryDay,
        ).format('DD-MM-YYYY')
      : dayjs(orderDate).add(1, 'day').format('DD-MM-YYYY')

  const csvData = useRef([])
  const csvLink = useRef(null)

  useEffect(() => {
    dispatch(getVenue(venueId))
    dispatch(fetchOrdersChecklist(venueId, supplierId, date, orderNumber))
  }, [])

  useEffect(() => {
    const noShowLoading = true
    dispatch(
      fetchOrdersChecklist(
        venueId,
        supplierId,
        date,
        orderNumber,
        noShowLoading,
      ),
    )
  }, [locked])

  useEffect(() => {
    if (orderError) setConfirmingOrder(false)
  }, [orderError])

  useEffect(() => {
    if (approvedOrders.length > 0) {
      const total = getTotalOMSPrice(approvedOrders)
      setVatTotalForAprov(total.VAT)
      setApprovedTotal(total.total)
      setTotalAmountForAprov(total.totalVat)
    }
  }, [approvedOrders])

  useEffect(() => {
    if (approvedOrders.length > 0 && receiptingState.length > 0) {
      const updatedApprovedOrders = approvedOrders.map(order => {
        const findReceipting = receiptingState.find(
          receipting => order._id === receipting.order,
        )
        return findReceipting
          ? {
              ...order,
              receipting: order?.receipting
                ? { ...order?.receipting, ...findReceipting }
                : findReceipting,
            }
          : order
      })

      const total = getTotalOMSPrice(updatedApprovedOrders)
      setApprovedTotal(total.total)
      setVatTotalForAprov(total.VAT)
      setTotalAmountForAprov(total.totalVat)
    }
  }, [receiptingState])

  useEffect(() => {
    setNoApprovedOrders([...noApprovedOrdersFromServer])
    if (noApprovedOrdersFromServer.length > 0) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [noApprovedOrdersFromServer])

  useEffect(() => {
    if (noApprovedOrders) {
      const prices = getTotalOMSPrice(noApprovedOrders)
      setNoApprovedTotal(prices.total)
      setVatTotalForNoAprov(prices.VAT)
      setTotalAmountForNoAprov(prices.totalVat)
      setAreEveryOrderConfirmed(
        noApprovedOrders.every(order => order.supplierConfirmed !== undefined),
      )
    }
  }, [noApprovedOrders])

  const talkhandle = async () => {
    Talk.ready
      .then(async function () {
        const me = new Talk.User({
          id: supplier,
          name: supplierTitle,
          welcomeMessage: 'Hey there! How are you? :-)',
          role: 'default',
        })
        const other = new Talk.User({
          id: venue,
          name: title,
          welcomeMessage: 'Hey there! Send us a message to start a chat 😁:',
          role: 'venues',
        })
        window.talkSession = new Talk.Session({
          appId: 'GR4DzdvG',
          me: me,
        })
        const conversation = window.talkSession.getOrCreateConversation(
          Talk.oneOnOneId(me, other),
        )
        conversation.setParticipant(me)
        conversation.setParticipant(other)
        const popup = window.talkSession.createPopup(conversation, {
          keepOpen: true,
        })
        popup.onSendMessage(() => {
          // ... make backend request here
          try {
            supplierNotifications(venue, supplier)
          } catch (e) {
            console.log(e)
          }
        })
        popup.select(conversation)
        await popup.mount({ show: true })
        setChatButton(false)
        return
      })
      .catch(err => console.log(err))
  }

  const completeOrder = () => {
    setConfirmingOrder(true)
    dispatch(lockOrder(venueId, supplierId, noApprovedOrders, orderError))
    dispatch(
      editVenueSuppliers({
        venue: venueId,
        hasVenueUpdateAddress: false,
        hasVenueUpdateDeliveryDays: false,
      }),
    )
  }

  const hanldeApproveAll = event => {
    const { checked } = event.target
    setNoApprovedOrders([
      ...noApprovedOrders.map(order => ({
        ...order,
        supplierConfirmed: checked || undefined,
      })),
    ])
  }

  // const renderVenueDeliveryDays = () => (
  //   <VenueDeliveryDaysHolder>
  //     <DeliveryDaysRow>
  //       <H4>Delivery availability:</H4>
  //       {hasVenueUpdateDeliveryDays && (
  //         <ErrorTag>
  //           <ExclamationCircleOutlined
  //             style={{
  //               fontSize: 17,
  //               paddingRight: 4,
  //             }}
  //           />
  //           Delivery days have been updated
  //         </ErrorTag>
  //       )}
  //     </DeliveryDaysRow>
  //     <p>
  //       Please deliver at the earliest opportunity, on one of the venues
  //       available days.
  //     </p>
  //     <DeliveryDays>
  //       {Object.entries(venueDeliveryDays).map(([day, value]) =>
  //         value ? <DayBox>{wordCaps(day).slice(0, 3)}</DayBox> : null,
  //       )}
  //     </DeliveryDays>
  //   </VenueDeliveryDaysHolder>
  // )

  const renderApprovedButton = () => (
    <ApprovedButton
      size={buttonSize}
      loading={confirmingOrder && !locked}
      disabled={!areEveryOrderConfirmed || completed}
      completed={completed ? 1 : 0}
      block
    >
      {completed ? 'Order completed' : 'Approve Order'}
    </ApprovedButton>
  )

  const renderEditButton = () => {
    return (
      <EditButton size={buttonSize} block onClick={() => setEditable(true)}>
        Edit Checklist
      </EditButton>
    )
  }

  const renderSaveButton = () => {
    return (
      <Popconfirm
        title='Are you sure you want to confirm this changes?'
        placement='topRight'
        onConfirm={async e => {
          e.stopPropagation()
          setEditable(false)
          const newStateByLater = receiptingState.map(receipt => {
            const findOrder = approvedOrders.find(
              order =>
                order.receipting &&
                order.receipting.error ===
                  'Adjustment due to Invoice received' &&
                receipt.order === order._id,
            )

            return findOrder
              ? { ...findOrder.receipting, ...receipt, _id: undefined }
              : receipt
          })

          await dispatch(
            createNewReceipting(newStateByLater, {
              venueId,
              supplierId,
              PONumber: orderNumber,
            }),
          )
          dispatch(fetchOrdersChecklist(venueId, supplierId, date, orderNumber))
        }}
        onCancel={e => {
          e.stopPropagation()
        }}
        okText='Yes'
        cancelText='No'
      >
        <EditButton size={buttonSize} block>
          Save
        </EditButton>
      </Popconfirm>
    )
  }

  const csvHeaders = ['SKU', 'Qty', 'Size', 'Stock', 'Price', 'UoM', 'Notes']

  useEffect(() => {
    const data = approvedOrders.map(order => {
      return [
        order.sku,
        order.quantity, // Column C
        order.size, // Column D
        order.title,
        order.price,
        order.UoM,
        order.productNotes ? order.productNotes : order.product.productNotes,
      ]
    })
    data.push([['Total', `£${(+approvedTotal + +noApprovedTotal).toFixed(2)}`]])
    data.push([[]])
    data.push([
      ['Account number', accountNumber],
      ['Order Date', csvOrderDate],
      ['Delivery Date', csvDeliveryDate],
      ['Purchase number', orderNumber],
    ])

    csvData.current = convertArrayToCSV(data, {
      header: csvHeaders,
    })
  }, [approvedOrders])

  const downloadCsv = useCallback(() => {
    csvLink.current.link.click()
  }, [csvData.current])

  useEffect(() => {
    if (approvedOrders[0]) {
      approvedOrders[0].placedVia === 'Order placed manually on the dashboard'
        ? setIsManuallyAdded(true)
        : setIsManuallyAdded(false)
    }
  }, [approvedOrders])

  return (
    <Fragment>
      {isLoading ? (
        <LoadingScreen />
      ) : noApprovedOrders.length === 0 && approvedOrders.length === 0 ? (
        <Wrapper>
          <CenterScreen>
            <H4>There are currently no orders attributed to this checklist.</H4>
            <H4>Please check with OMS to see if there has been an error.</H4>
          </CenterScreen>
        </Wrapper>
      ) : (
        <Wrapper>
          <Helmet>
            <title>Order checklist</title>
          </Helmet>

          <Header>
            <HeaderContent>
              <LeftHeader>
                {noApprovedOrders.length > 0 ? (
                  <BodyText>
                    Hey {supplierTitle}, you&apos;ve received a new order!
                  </BodyText>
                ) : (
                  <BodyText>
                    Hey {supplierTitle}, here are today&apos;s accepted orders!
                  </BodyText>
                )}
                <VenueName>{title}</VenueName>
                {accountNumber && (
                  <BodyText>Account number: {accountNumber}</BodyText>
                )}
              </LeftHeader>
              <Logo src={OMSLogo} alt='Orders Made Simple logo' />
            </HeaderContent>
          </Header>

          <OrderDetails>
            <OrderDetailsHeader>
              <H3>Order details</H3>
              <ImportantLabel>
                <ExclamationCircleOutlined />
                <span>Important</span>
              </ImportantLabel>
            </OrderDetailsHeader>
            <BodyText>
              If you can&apos;t meet these specifications, please contact the
              venue to let them know
            </BodyText>
            {chatButton && (
              <ChatBox onClick={() => talkhandle()}>Message customer</ChatBox>
            )}
            <OrdersDetailsText>Required delivery date</OrdersDetailsText>
            <OrderDetailsNotes>{requiredDeliveryDayToShow}</OrderDetailsNotes>
            {notes && notes.length > 0 && (
              <Fragment>
                <OrdersDetailsText>Order notes</OrdersDetailsText>
                <NotesWrapper>
                  {notes.map((note, index) => (
                    <OrderDetailsNotes key={index.toString()}>
                      {note.notes}
                    </OrderDetailsNotes>
                  ))}
                </NotesWrapper>
              </Fragment>
            )}
          </OrderDetails>

          {noApprovedOrders.length > 0 && (
            <InnerBody>
              <TableHeader>
                <SpaceBetween>
                  <H4>New orders on {displayDate}</H4>
                  <H4>
                    <Checkbox onChange={event => hanldeApproveAll(event)}>
                      Approve all
                    </Checkbox>
                  </H4>
                </SpaceBetween>
                {orderNumber && orderNumber !== 'all' && (
                  <OrderNumber>Purchase order # {orderNumber}</OrderNumber>
                )}
                <BodyText>
                  Please let us know how many items you have in stock by editing
                  the delivery.
                </BodyText>
              </TableHeader>
              <Table
                scroll={{
                  scrollToFirstRowOnChange: true,
                }}
                dataSource={noApprovedOrders.map((order, index) => ({
                  key: order._id,
                  index: index,
                  ...order,
                }))}
                pagination={false}
                columns={TableColumnsCheckList(
                  receiptingState,
                  setReceiptingState,
                  editable,
                  isAdmin,
                  completed,
                  noApprovedOrders,
                  setNoApprovedOrders,
                  'noApproved',
                  locked,
                  layout,
                )}
              />
              {approvedOrders.length > 0 && (
                <RowTotalPrice>
                  <BodyText>Total of orders to approve: </BodyText>
                  <BodyText>£{noApprovedTotal}</BodyText>
                </RowTotalPrice>
              )}
            </InnerBody>
          )}

          {noApprovedOrders.length > 0 && approvedOrders.length > 0 && (
            <GreenDivider>
              <Divider />
            </GreenDivider>
          )}

          {approvedOrders.length > 0 && (
            <InnerBody>
              <TableHeader>
                <SpaceBetween>
                  <H4>Orders you approved on {displayDate}</H4>
                  <DownloadButton onClick={downloadCsv}>
                    Download CSV
                  </DownloadButton>
                  <CSVLink
                    ref={csvLink}
                    // headers={csvHeaders}
                    data={csvData.current}
                    filename={`orders-${title}-${displayDate}`}
                    style={{ display: 'none' }}
                    target='_self'
                  ></CSVLink>
                </SpaceBetween>
                {orderNumber && orderNumber !== 'all' && (
                  <OrderNumber>Purchase order # {orderNumber}</OrderNumber>
                )}
                {isManuallyAdded && (
                  <ManuallyInvoice>
                    Order Placed Manually On the Dashboard
                  </ManuallyInvoice>
                )}
              </TableHeader>
              <Table
                scroll={{
                  scrollToFirstRowOnChange: true,
                }}
                dataSource={approvedOrders.map((order, index) => ({
                  key: order._id,
                  index: index,
                  ...order,
                }))}
                pagination={false}
                columns={TableColumnsCheckList(
                  receiptingState,
                  setReceiptingState,
                  editable,
                  isAdmin,
                  completed,
                  approvedOrders,
                  () => {},
                  'approved',
                  locked,
                  layout,
                  isSubstitutedColumnShown,
                )}
              />
              {noApprovedOrders.length > 0 && (
                <RowTotalPrice>
                  <BodyText>Total of orders you approved: </BodyText>
                  <BodyText>£{approvedTotal}</BodyText>
                </RowTotalPrice>
              )}
            </InnerBody>
          )}

          {noApprovedOrders.length > 0 && approvedOrders.length > 0 && (
            <GreenDivider>
              <Divider />
            </GreenDivider>
          )}

          <InnerBody>
            <RowTotalPrice total>
              <BodyText>
                <b>Total ex VAT:</b>
              </BodyText>
              <BodyText>
                <b>£{(+approvedTotal + +noApprovedTotal).toFixed(2)}</b>
              </BodyText>
            </RowTotalPrice>
            <RowTotalPrice total>
              <BodyText>
                <b>VAT Amount:</b>
              </BodyText>
              <BodyText>
                <b>£{(+vatTotalForAprov + +vatTotalForNoAprov).toFixed(2)}</b>
              </BodyText>
            </RowTotalPrice>

            <RowTotalPrice total>
              <BodyText>
                <b>Total Amount</b>
              </BodyText>
              <BodyText>
                <b>
                  £{(+totalAmountForAprov + +totalAmountForNoAprov).toFixed(2)}
                </b>
              </BodyText>
            </RowTotalPrice>

            <RowButton>
              <Buttons>
                {isAdmin && completed && !editable && renderEditButton()}
                {isAdmin && completed && editable && renderSaveButton()}
                {completed ? (
                  renderApprovedButton()
                ) : !areEveryOrderConfirmed ? (
                  <Tooltip
                    placement='topRight'
                    title={
                      !areEveryOrderConfirmed
                        ? 'You need to accept or reject every product'
                        : ''
                    }
                  >
                    {renderApprovedButton()}
                  </Tooltip>
                ) : (
                  <Popconfirm
                    title='Are you sure you want to confirm this order?'
                    placement='topRight'
                    onConfirm={e => {
                      e.stopPropagation()
                      completeOrder()
                    }}
                    onCancel={e => {
                      e.stopPropagation()
                    }}
                    okText='Yes'
                    cancelText='No'
                  >
                    {renderApprovedButton()}
                  </Popconfirm>
                )}
                {orderError && noApprovedOrders.length > 0 && (
                  <ErrorMessage>
                    There has been an error accepting this order. Please contact
                    info@ordersmadesimple.com
                  </ErrorMessage>
                )}
              </Buttons>
            </RowButton>
            <RowRemainder>
              <OrdersDetailsTextRemainder>
                Required delivery date: {requiredDeliveryDayToShow}
              </OrdersDetailsTextRemainder>
            </RowRemainder>
          </InnerBody>

          <Footer>
            <Inner>
              <AddressRow>
                <LeftColumn>
                  <H4>Deliver to:</H4>
                  <p>
                    <BodyText>
                      <b>{title}</b>
                    </BodyText>
                    <BodyText>{addressLine1 && addressLine1}</BodyText>
                    <BodyText>{addressLine2 && addressLine2}</BodyText>
                    <BodyText>{addressCity && addressCity}</BodyText>
                    <BodyText>{addressPostCode && addressPostCode}</BodyText>
                    <BodyText>{phoneNumber && phoneNumber}</BodyText>
                  </p>
                </LeftColumn>
                {hasVenueUpdateAddress && (
                  <ErrorTag>
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: 17,
                        paddingRight: 4,
                      }}
                    />
                    Delivery address has been updated
                  </ErrorTag>
                )}
              </AddressRow>

              {/* {venueDeliveryDays && renderVenueDeliveryDays()} */}

              <BodyText textAlign={'center'}>
                If you have any issues, please contact{' '}
                <a href='mailto:info@ordersmadesimple.com'>
                  info@ordersmadesimple.com
                </a>
              </BodyText>
            </Inner>
          </Footer>
        </Wrapper>
      )}
    </Fragment>
  )
}

const Wrapper = styled(FadeIn)`
  background-color: ${({ theme }) => theme.colours.mayoWhite};
`

const Inner = styled.div`
  width: 60%;
  margin: 0 auto;
  ${({ theme }) => theme.device('sm')`
      padding: 0 15px;
  `}
`

const Header = styled.header`
  background: ${({ theme }) => theme.colours.bbqBlack};
  padding: ${({ theme }) => `${theme.spacing.xl} 0`};
`

const VenueName = styled(H1)`
  line-height: ${({ theme }) => theme.spacing.xl};
  padding: 10px 0;
  color: ${({ theme }) => theme.colours.lettuceGreen};
  margin-bottom: 0;
`

const HeaderContent = styled(Inner)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  color: ${({ theme }) => theme.colours.mayoWhite};
`

const Logo = styled.img`
  height: 108px;
`

const ErrorTag = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.bgGrey};
  border: 1px solid ${({ theme }) => theme.colours.chilliRed};
  color: ${({ theme }) => theme.colours.chilliRed};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing.tiny};
`

const InnerBody = styled(Inner)`
  .ant-table-thead * {
    background-color: ${({ theme }) => theme.colours.mayoWhite};
    white-space: nowrap;
  }
  .restrictShrink {
    padding: 12px 3px;
    ${({ theme }) => theme.device('sm')`
      padding: 14px 4px;
    `}
    ${({ theme }) => theme.device('md')`
      padding: 16px 5px;
    `}
  }
  .restrictShrink:first-child {
    white-space: nowrap;
    padding-left: 0;
  }
  .restrictShrink:last-child {
    padding-right: 0;
  }
  .restrictShrink.quantity-padding-left {
    padding-left: 5px;
    ${({ theme }) => theme.device('sm')`
      padding-left: 10px;
    `}
  }
  .text-right {
    text-align: right;
  }
`

const OrderNumber = styled.div`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: bold;
  font-size: 1rem; // 16px
`

const ManuallyInvoice = styled.div`
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => `${theme.colours.lettuceGreen} !important`};
  font-size: 1rem; // 16px
`

const TableHeader = styled.div`
  margin: ${({ theme }) => `${theme.spacing.xl} 0 ${theme.spacing.lg} 0`};
`

const GreenDivider = styled.div`
  background-color: ${({ theme }) =>
    `${theme.colours.lettuceGreen} !important`};
  max-width: 700px;
  margin: 0 auto;
`

const RowTotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ total, theme }) =>
    total ? `${theme.spacing.lg} 0 0 0` : `${theme.spacing.lg} 0`};
`

const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colours.bbqBlack};
  color: ${({ theme }) => theme.colours.mayoWhite};
  padding: ${({ theme }) => `${theme.spacing.sm} 0`};
  text-align: center;
`

const AddressRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const LeftColumn = styled.div`
  p {
    display: flex;
    flex-direction: column;
  }
  H4 {
    text-align: left;
    color: white;
  }
`

// const DeliveryDaysRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
// `

const RowButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const RowRemainder = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 12px;
`

const Buttons = styled.div`
  width: 200px;
  margin: ${({ theme }) => `${theme.spacing.lg} 0 ${theme.spacing.xl} 0`};
`

const CenterScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const ApprovedButton = styled(LoadingButton)`
  cursor: ${props => props.completed && 'default'};
  background-color: ${props =>
    props.completed && `${props.theme.colours.seaweedGreen} !important`};
  color: ${props =>
    props.completed && `${props.theme.colours.mayoWhite} !important`};
`
const EditButton = styled(LoadingButton)`
  background-color: ${props =>
    `${props.theme.colours.lettuceGreen} !important`};
  color: ${props => `${props.theme.colours.mayoWhite} !important`};
  margin-bottom: 10px;
`
const DownloadButton = styled(LoadingButton)``

const ErrorMessage = styled(H4)`
  padding-top: ${({ theme }) => `${theme.spacing.lg}`};
  color: ${({ theme }) => `${theme.colours.chilliRed}`};
`

const OrderDetails = styled(Inner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-top: 50px;
  border: 1.5px solid ${({ theme }) => theme.colours.panSilver};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing.sm} !important;
`

const OrdersDetailsText = styled(H4)`
  color: ${({ theme }) => theme.colours.chilliRed};
  margin: 10px 0 5px 0;
`
const OrdersDetailsTextRemainder = styled(H4)`
  color: ${({ theme }) => theme.colours.chilliRed};
  margin: 10px 0 5px 0;
`

const OrderDetailsNotes = styled(BodyText)`
  font-weight: 700;
  width: fit-content;
`

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`

const OrderDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0 0 5px 0;
  }
`

const ImportantLabel = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colours.chilliRed};
  color: ${({ theme }) => theme.colours.chilliRed};
  border-radius: 5px;
  height: 30px;
  span {
    margin: 0 5px;
  }
`
const ChatBox = styled(Button)`
  width: fit-content;
  margin-top: ${({ theme }) => theme.spacing.xxs};
`

// const VenueDeliveryDaysHolder = styled.div`
//   padding: ${({ theme }) => `${theme.spacing.xs} 0`};
//   text-align: left;
// `

// const DeliveryDays = styled.div`
//   display: flex;
//   flex-direction: row;
// `

// const DayBox = styled.div`
//   background-color: ${({ theme }) => theme.colours.avocadoGreen};
//   border-color: ${({ theme }) => theme.colours.mayoWhite};
//   margin: ${({ theme }) => `${theme.spacing.tiny}`};
//   font-weight: normal !important;
//   display: flex;
//   border: 1px solid #d9d9d9;
//   border-radius: 4px;
//   width: 60px;
//   justify-content: center;
// `

export default OrderChecklist
