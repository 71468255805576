import React from 'react'

const EnableFunctionality = () => {
  return (
    <div
      style={{
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      Please, contact with{' '}
      <a href='mailto:info@ordersmadesimple.com'>info@ordersmadesimple.com</a>{' '}
      to enable this functionality
    </div>
  )
}

export default EnableFunctionality
