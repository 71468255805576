import { DatePicker } from 'antd'
import styled from 'styled-components/macro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilterDates,
  setReceiptingExist,
} from '../../features/ReceiptingSlice'
import moment from 'moment'

export function DateRangeFilter() {
  const dateFormat = 'DD/MM/YYYY'
  const dispatch = useDispatch()

  const filterDates = useSelector(state => state.receipting.filterDates)

  const onChangeDate = date => {
    dispatch(setReceiptingExist(null))
    dispatch(
      setFilterDates({ start: date[0].valueOf(), end: date[1].valueOf() }),
    )
  }

  return (
    <StyledRangePicker
      //defaultValue={[moment().subtract(40, 'days'), moment()]}
      defaultValue={[moment(filterDates.start), moment(filterDates.end)]}
      onChange={value => onChangeDate(value)}
      format={dateFormat}
    />
  )
}

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
  }
`
