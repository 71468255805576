import Logo from '../../assets/images/OMSLogoBlk.png'
import React, { useEffect, useRef } from 'react'
import * as dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { VAT_PERCENTAGE, VAT_RATE } from '../../config'
import { Button } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { fetchStockTakeProducts } from '../../features/StockSlice'
import styled from 'styled-components/macro'

const ValuationPDF = props => {
  const {
    match: {
      params: { stockTakeId, venueId },
    },
  } = props
  const venue = useSelector(state => state.venue.venue)

  const pdfContentRef = useRef()
  const location = useLocation().pathname.split('/')
  const endpointName = location[location.length - 1]
  const fieldName =
    endpointName === 'valuation-report-pdf' ? 'inStock' : 'wastage'
  const dispatch = useDispatch()
  const stockTakeProductsFromDb = useSelector(
    state => state.stockTakes.stockTakeProducts,
  )
  const stockTake = useSelector(state => state.stockTakes.stockTakes)?.find(
    stockTake => stockTake._id === stockTakeId,
  )
  const stockTakeProducts =
    fieldName !== 'wastage'
      ? stockTakeProductsFromDb?.filter(product => product.inStock > 0)
      : stockTakeProductsFromDb?.filter(product => product.wastage > 0)
  const totals = {
    net: stockTakeProducts
      .reduce((sum, item) => {
        const currentQuantity = item[fieldName]
        return sum + item.price * currentQuantity
      }, 0)
      .toFixed(2),
    VAT: stockTakeProducts
      .reduce((sum, item) => {
        const currentQuantity = item[fieldName]
        return (
          sum +
          (item.hasVat ? item.price * currentQuantity * VAT_PERCENTAGE : 0)
        )
      }, 0)
      .toFixed(2),
    gross: stockTakeProducts
      .reduce((sum, item) => {
        const currentQuantity = item[fieldName]
        return (
          sum +
          (item.hasVat
            ? item.price * currentQuantity * VAT_RATE
            : item.price * currentQuantity)
        )
      }, 0)
      .toFixed(2),
  }
  useEffect(() => {
    dispatch(fetchStockTakeProducts(venueId, stockTakeId))
  }, [venueId, dispatch])

  const toPdf = () => {
    var mywindow = window.open('', 'PRINT', 'height=600,width=910')
    mywindow.document.write('<html><head><title>OMS Report</title>')
    mywindow.document.write(
      `${
        document.getElementsByTagName('head')[0].innerHTML
      }<style>  .invoice-box {
background-color: white;
width: 100%;
}
#column-names {
                background-color: #eee;   
            }</style></head>`,
    )
    mywindow.document.write('</head><body >')
    mywindow.document.write(pdfContentRef.current.innerHTML)
    mywindow.document.write('</body></html>')

    mywindow.focus()

    mywindow.print()

    return true
  }

  return (
    <>
      <ToolBar>
        <Button>
          <Link
            to={
              fieldName === 'wastage'
                ? `/v/${venueId}/stockTakes/${stockTakeId}/wastage-report`
                : `/v/${venueId}/stockTakes/${stockTakeId}/valuation-report`
            }
          >
            Go back
          </Link>
        </Button>
      </ToolBar>
      <ToolBar>
        <Button onClick={toPdf}>Print PDF</Button>
      </ToolBar>
      <StyledDiv className={'invoice-box'}>
        <div ref={pdfContentRef} className='invoice-box'>
          <table cellPadding='0' cellSpacing='0' style={{ width: '100%' }}>
            <tbody>
              <tr className='top'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td className='title'>
                          <img src={Logo} alt='' />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr className='information'>
                <td colSpan=''>
                  <div
                    style={{
                      marginTop: '30px',
                      width: '100%',
                    }}
                  >
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td>Venue title: {venue.title}</td>
                        </tr>
                        <tr>
                          <td>Street: {venue.addressLine2}</td>
                        </tr>
                        <tr>
                          <td>Number of house: {venue.addressLine1}</td>
                        </tr>
                        <tr>
                          <td>Address post code: {venue.addressPostCode}</td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ height: '10px' }}></div>
                          </td>
                        </tr>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td colSpan='4'>
                            {fieldName !== 'wastage' ? 'Stock' : 'Wastage'}{' '}
                            Report for{' '}
                            {`${dayjs(stockTake?.fromPeriod).format(
                              'DD MMM YYYY',
                            )} 
                                                - ${dayjs(
                                                  stockTake?.toPeriod,
                                                ).format('DD MMM YYYY')}`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ height: '10px' }}></div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            backgroundColor: '#eee',
                            fontWeight: 'bold',
                          }}
                          id='column-names'
                        >
                          <td className='typeHeader'>Product</td>
                          <td className='typeHeader'>Size</td>
                          <td className='typeHeader'>UoM</td>
                          <td className='typeHeader'>Supplier</td>
                          <td className='typeHeader'>SKU</td>
                          <td className='typeHeader'>
                            {fieldName === 'wastage' ? 'Wastage' : 'In Stock'}
                          </td>
                          <td className='typeHeader'>Price</td>
                          <td className='typeHeader'>VAT</td>
                          <td className='typeHeader'>Net</td>
                          <td className='typeHeader'>Gross</td>
                        </tr>
                        {stockTakeProducts.map(product => {
                          const currentQuantity = product[fieldName]
                          return (
                            <tr
                              key={product._id}
                              style={{ borderBottom: '3px solid #eee' }}
                            >
                              <td>{product.title}</td>
                              <td>{product.size}</td>
                              <td>{product.UoM}</td>
                              <td>{product.supplierTitle}</td>
                              <td>{product.sku}</td>
                              <td>
                                {fieldName !== 'wastage'
                                  ? product.wastage
                                  : product.inStock}
                              </td>
                              <td style={{ whiteSpace: 'nowrap' }}>
                                {product.price}
                              </td>
                              <td style={{ whiteSpace: 'nowrap' }}>
                                {(product.hasVat
                                  ? VAT_PERCENTAGE *
                                    (currentQuantity * product.price)
                                  : 0
                                ).toFixed(2)}
                              </td>
                              <td style={{ whiteSpace: 'nowrap' }}>
                                {(product.price * currentQuantity).toFixed(2)}
                              </td>
                              <td style={{ whiteSpace: 'nowrap' }}>
                                {(product.hasVat
                                  ? VAT_RATE * currentQuantity * product.price
                                  : currentQuantity * product.price
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )
                        })}
                        <tr>
                          <td>
                            <div style={{ height: '20px' }}></div>
                          </td>
                        </tr>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td colSpan='7'></td>

                          <td>Total net:</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            £{totals.VAT}
                          </td>
                          <td colSpan='7'></td>
                        </tr>

                        <tr style={{ fontWeight: 'bold' }}>
                          <td colSpan='7'></td>

                          <td>Total VAT:</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            £{totals.net}
                          </td>
                          <td colSpan='7'></td>
                        </tr>

                        <tr style={{ fontWeight: 'bold' }}>
                          <td colSpan='7'></td>

                          <td>Total gross:</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            £{totals.gross}
                          </td>
                          <td colSpan='7'></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </StyledDiv>
    </>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
  background-color: white;
`

const ToolBar = styled.div`
  margin-bottom: 25px;
`

export default ValuationPDF
