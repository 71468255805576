import { Checkbox, Input } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutocompleteInput from 'src/components/shared/AutocompleteInput'
import wordCaps from 'src/utils/wordCaps'
import styled from 'styled-components/macro'
import { editVenue } from '../../features/VenueSlice'

const EditVenueProductModal = ({ product, setEditedProduct }) => {
  const venueProducts = useSelector(state => state.venueProducts.products)
  const account = useSelector(state => state.auth.account)
  const venue = useSelector(state => state.venue.venue)
  const dispatch = useDispatch()

  const isAdmin = account && account.isAdmin
  const isTFRAdmin = account && account.TFRAdmin

  const existingZones = [
    ...new Set(venueProducts.map(product => wordCaps(product.zone).trim())),
  ]
  const existingTypes = [
    ...new Set(venueProducts.map(product => wordCaps(product.type).trim())),
  ]
  return (
    <Wrapper>
      <Row>
        <InputComp
          value={product.title}
          onChange={e => {
            setEditedProduct({ ...product, title: e.target.value })
          }}
          addonBefore={'Title'}
          placeholder='Title'
        />
      </Row>
      <Row>
        <AutocompleteInput
          fieldName='Zone'
          options={existingZones}
          selected={product.zone}
          onChange={val => {
            setEditedProduct({
              ...product,
              zone: val,
            })
          }}
        />
      </Row>
      <Row>
        <AutocompleteInput
          fieldName='Secondary Zone'
          options={venue.secondaryZones}
          selected={product.secondaryZone}
          onChange={val => {
            if (!venue.secondaryZones.includes(val)) {
              const updatedVenue = {
                ...venue,
                secondaryZones: [...venue.secondaryZones, val],
              }
              dispatch(editVenue(updatedVenue, venue._id))
            }

            setEditedProduct({
              ...product,
              secondaryZone: val,
            })
          }}
        />
      </Row>
      <Row>
        <InputComp
          value={product.defaultQuantity}
          onChange={e => {
            setEditedProduct({
              ...product,
              defaultQuantity: e.target.value,
            })
          }}
          addonBefore={'Default Quantity'}
          placeholder='Default Quantity'
        />
      </Row>
      <Row>
        <AutocompleteInput
          fieldName='Type'
          options={existingTypes}
          selected={product.type}
          onChange={val => {
            setEditedProduct({
              ...product,
              type: val,
            })
          }}
        />
      </Row>
      <Row>
        <InputComp
          value={product.monthlyLimit}
          onChange={e => {
            setEditedProduct({
              ...product,
              monthlyLimit: e.target.value,
            })
          }}
          addonBefore={'Monthly Order Limit'}
          placeholder='Leave blank for no limit'
        />
      </Row>
      <Row>
        <Checkbox
          onChange={() => {
            setEditedProduct({
              ...product,
              isFavourite: !product.isFavourite,
            })
          }}
          checked={product.isFavourite}
        >
          Is Favourite
        </Checkbox>
      </Row>
      {isAdmin || isTFRAdmin ? (
        <Row>
          <Checkbox
            onChange={() => {
              setEditedProduct({
                ...product,
                isSpecialProduct: !product.isSpecialProduct,
              })
            }}
            checked={product.isSpecialProduct}
          >
            Is Special
          </Checkbox>
        </Row>
      ) : (
        ''
      )}

      {isAdmin || isTFRAdmin
        ? product.isSpecialProduct && (
            <Wrapper>
              <Row>
                <InputComp
                  value={isNaN(product.specialPrice) ? 0 : product.specialPrice}
                  onChange={e => {
                    if (e.target.value > 0 || e.target.value === '') {
                      setEditedProduct({
                        ...product,
                        specialPrice: e.target.value,
                      })
                    }
                  }}
                  addonBefore={'Special price'}
                  placeholder='Special price'
                />
              </Row>
              <Row>
                <InputComp
                  type='number'
                  value={
                    isNaN(product.OMSSpecialPrice) ? 0 : product.OMSSpecialPrice
                  }
                  onChange={e => {
                    if (e.target.value > 0 || e.target.value === '') {
                      setEditedProduct({
                        ...product,
                        OMSSpecialPrice: e.target.value,
                      })
                    }
                  }}
                  addonBefore={'Oms Special price'}
                  placeholder='Oms Special price'
                />
              </Row>
            </Wrapper>
          )
        : ''}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const InputComp = styled(Input)``

const Row = styled.div`
  margin: 15px 0;
`

export default EditVenueProductModal
