import { AutoComplete, Input, InputNumber } from 'antd'
const { Option } = AutoComplete
import React from 'react'

const TableColumn = (
  supplierProducts,
  filteredProducts,
  setFilteredProducts,
  setOrdersToAdding,
) => {
  const onSelectProduct = productId => {
    const selectedProduct = filteredProducts.find(
      product => product._id === productId,
    )
    // eslint-disable-next-line no-unused-vars
    const { _id, id, __v, ...rest } = selectedProduct
    setOrdersToAdding(prev => {
      return { ...prev, product: productId, ...rest }
    })
  }

  const onSkuSearch = value => {
    setOrdersToAdding(prev => {
      return { ...prev, sku: value }
    })
    setFilteredProducts(
      supplierProducts.filter(product => {
        return product.sku.toLowerCase().includes(value.toLowerCase())
      }),
    )
  }

  const onTitleSearch = value => {
    setOrdersToAdding(prev => {
      return { ...prev, title: value }
    })
    setFilteredProducts(
      supplierProducts.filter(product => {
        return product.title.toLowerCase().includes(value.toLowerCase())
      }),
    )
  }

  const onChangeQuantity = value => {
    setOrdersToAdding(prev => {
      return { ...prev, quantity: value }
    })
  }

  const onChangePrice = value => {
    setOrdersToAdding(prev => {
      return { ...prev, price: value.target.value }
    })
  }

  return [
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: '15%',
      render: (_, product) => (
        <AutoComplete
          onSelect={onSelectProduct}
          value={product.sku}
          onSearch={val => onSkuSearch(val, product)}
          notFoundContent={'Products not found'}
        >
          {filteredProducts?.map(prod => (
            <Option key={product.key} value={prod._id}>
              {prod.sku ? prod.sku : 'No SKU'}
            </Option>
          ))}
        </AutoComplete>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (_, product) => (
        <AutoComplete
          onSelect={onSelectProduct}
          value={product.title}
          onSearch={val => onTitleSearch(val, product)}
          notFoundContent={'Products not found'}
        >
          {filteredProducts?.map(prod => (
            <Option key={product.key} value={prod._id}>
              {prod.title}
            </Option>
          ))}
        </AutoComplete>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (_, product) => (
        <InputNumber
          min={1}
          value={product.quantity}
          onChange={value => onChangeQuantity(value, product)}
        />
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Price Per Unit',
      dataIndex: 'price',
      render: (_, product) => (
        <Input
          style={{ width: '70px' }}
          value={product.price}
          onChange={value => onChangePrice(value, product)}
        />
      ),
    },
  ]
}

export default TableColumn
