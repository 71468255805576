import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { SuccessNotification } from 'src/notifications/SuccessNotification'
import Sentry from 'src/utils/sentry'

const initialState = { products: [], isLoading: false }

const venueProductsSlice = createSlice({
  name: 'venueProducts',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
    addProduct: (state, action) => {
      state.products.push(action.payload)
    },
    deleteProduct: (state, action) => {
      state.products = state.products.filter(
        item => item._id !== action.payload,
      )
    },
    editProduct: (state, action) => {
      const index = state.products.findIndex(
        product => product._id === action.payload._id,
      )
      if (index !== -1) state.products[index] = action.payload
    },
    editProductProduct: (state, action) => {
      // As in the "product" within the venue product
      const newState = state.products.map(item => {
        if (item.product._id === action.payload._id) {
          item.product = action.payload
        }
        return item
      })
      state.products = newState
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    resetVenueProducts: state => {
      state = initialState
      return state
    },
  },
})

export const {
  setProducts,
  addProduct,
  deleteProduct,
  editProduct,
  editProductProduct,
  setIsLoading,
  resetVenueProducts,
} = venueProductsSlice.actions

export const fetchVenueProducts = venueId => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const data = await API.get('api', `venue/${venueId}/products`)
    dispatch(setProducts(data))
    dispatch(setIsLoading(false))
  } catch (e) {
    dispatch(setIsLoading(false))
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const addVenueProduct = data => async dispatch => {
  try {
    const venueProduct = await API.post('api', `venue/${data.venue}/product`, {
      body: data,
    })
    dispatch(addProduct(venueProduct))
    SuccessNotification({
      message: 'Added to products',
      description: `"${data.title}" has been added to your products and is now available to order via the app`,
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const editVenueProduct = data => async dispatch => {
  try {
    if (!data?.isSpecialProduct) {
      delete data?.specialPrice
      delete data?.OMSSpecialPrice
    }

    if (!data.secondaryZone) {
      data.secondaryZone = ''
    }
    const _product = await API.post('api', `venue/${data.venue}/product`, {
      body: data,
    })
    dispatch(editProduct(_product))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const deleteVenueProduct = (venueProduct, venueId) => async dispatch => {
  try {
    await API.del('api', `venue/${venueId}/product/${venueProduct._id}`)
    dispatch(deleteProduct(venueProduct._id))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const deleteAllVenueProducts = venueId => async dispatch => {
  try {
    await API.del('api', `venue/${venueId}/products`)
    dispatch(setProducts([]))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const uploadVenueProductsCSV = (data, venueId) => async () => {
  const writeProducts = await API.post('api', `admin/venueProducts-csv`, {
    body: { items: data, venueId },
  })

  //generate alert to notify about unaddable products
  if (writeProducts.error || writeProducts.exists) {
    let errorMessage = []
    let existsMessage = []

    if (writeProducts.error && writeProducts.error.length > 0) {
      errorMessage = writeProducts.error
        .map(errored => {
          if (errored.product) {
            return `Product ID: ${errored.product}; zone: ${errored.zone}; defaultQuantity: ${errored.defaultQuantity}`
          } else {
            return null
          }
        })
        .filter(msg => msg)
    }

    if (writeProducts.exists && writeProducts.exists.length > 0) {
      existsMessage = writeProducts.exists
        .map(existing => {
          if (existing.product) {
            return `Product ID: ${existing.product}; zone: ${existing.zone}; defaultQuantity: ${existing.defaultQuantity}`
          } else {
            return null
          }
        })
        .filter(msg => msg)
    }

    if (errorMessage.length || existsMessage.length) {
      const formattedErrMessage = errorMessage.toString().replace(',', '\n')
      const formattedExstMessage = existsMessage.toString().replace(',', '\n')
      alert(
        `${
          formattedErrMessage &&
          `The following product(s) could not be uploaded:
${formattedErrMessage}`
        }
${
  formattedExstMessage &&
  `The following product(s) already exist and have been skipped: 
${formattedExstMessage}`
}`,
      )
    }
  }
}

export default venueProductsSlice
