import { Button, Card, Input, Popconfirm, TreeSelect } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FadeIn from 'src/components/styled/FadeIn'
import { resetCatalogueSearch } from 'src/features/CatalogueSearchSlice'
import { resetOrdersFilter } from 'src/features/FilterSlice'
import { resetOrders } from 'src/features/OrdersSlice'
import { fetchAllSuppliers } from 'src/features/SupplierSlice'
import { resetVenueProducts } from 'src/features/VenueProductsSlice'
import {
  createVenue,
  deleteVenue,
  pushAppNotification,
  setVenue,
} from 'src/features/VenueSlice'
import { resetVenueSuppliers } from 'src/features/VenueSuppliersSlice'
import styled from 'styled-components/macro'
import DownloadAllInfo from './DownloadAllInfo'
import VenuesReportModal from './VenuesReportModal'

const Venues = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const username = useSelector(state => state.auth.cognito.username)
  const isTFRVenue = useSelector(state => state.auth?.account?.TFRAdmin)
  const venues = useSelector(state => state.auth.account.venues)
  const suppliers = useSelector(state => state.suppliers.suppliers)
  const isAdmin = useSelector(state => state.auth.account.isAdmin)

  const [venueTitle, setVenueTitle] = useState('')
  const [pushNotificationText, setPushNotificationText] = useState('')
  const [supplierNotificationText, setSupplierNotificationText] = useState('')
  const [selectedVenueIds, setSelectedVenuesIds] = useState([])
  const [selectedSupplierIds, setSelectedSupplierIds] = useState([])
  const [filteredVenues, setFilteredVenues] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [visibleVenuesModal, setVsibleVenuesModal] = useState(false)
  useEffect(() => {
    dispatch(setVenue(null))
    dispatch(fetchAllSuppliers())
    dispatch(resetOrdersFilter())
    dispatch(resetVenueSuppliers())
    dispatch(resetVenueProducts())
    dispatch(resetCatalogueSearch())
    dispatch(resetOrders())
  }, [])
  useEffect(() => {
    setFilteredVenues(venues)
  }, [venues])
  useEffect(() => {
    setFilteredVenues(
      venues.filter(venue => {
        return venue.title.toLowerCase().includes(searchQuery.toLowerCase())
      }),
    )
  }, [searchQuery])

  const handleCardClick = venue => {
    dispatch(setVenue(venue))
    history.push(`/v/${venue._id}`)
  }

  const handleConfirm = (event, venueId) => {
    event.stopPropagation()
    dispatch(deleteVenue(venueId))
  }

  const onClickCreateVenue = () => {
    setVenueTitle('')
    dispatch(
      createVenue({
        title: venueTitle,
        username: username,
        TFRVenue: isTFRVenue,
      }),
    )
  }

  const onClickPushNotification = () => {
    setPushNotificationText('')
    dispatch(
      pushAppNotification(
        pushNotificationText,
        selectedVenueIds,
        selectedSupplierIds,
      ),
    )
  }
  const onClickSupplierNotification = () => {
    setSupplierNotificationText('')
    dispatch(
      pushAppNotification(
        supplierNotificationText,
        selectedVenueIds,
        selectedSupplierIds,
      ),
    )
  }

  const supplierOptions = suppliers.map(item => {
    return {
      title: item.title,
      value: item._id,
    }
  })

  const venueOptions = venues.map(item => {
    return {
      title: item.title,
      value: item._id,
    }
  })
  const allSupplierIds = supplierOptions.map(({ value }) => value)
  const allIds = venueOptions.map(({ value }) => value)
  return (
    <Wrapper>
      <VenuesReportModal
        visibleVenuesModal={visibleVenuesModal}
        setVsibleVenuesModal={setVsibleVenuesModal}
      />
      <FilterWrapper>
        {isAdmin && <DownloadAllInfo venues={venues} />}
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
          }}
        >
          <Input
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder={'Enter venue title'}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              style={{
                background: '#b5d922',
                width: 250,
                marginLeft: 20,
                color: 'white',
              }}
              type={'primary'}
              onClick={() => setVsibleVenuesModal(true)}
            >
              Venue Orders Report
            </Button>
          </div>
        </div>
      </FilterWrapper>
      <CardWrapper>
        {filteredVenues &&
          filteredVenues.map(venue => {
            const {
              _id: venueId,
              addressLine1,
              addressLine2,
              addressCity,
              addressPostCode,
              phoneNumber,
              title,
              code,
            } = venue
            const hasAllSettings =
              addressLine1 &&
              addressLine2 &&
              addressCity &&
              addressPostCode &&
              phoneNumber

            return (
              <CardComp key={venueId} onClick={() => handleCardClick(venue)}>
                <LogoContainer>
                  <Img src={venue.venueLogo} alt='' />
                </LogoContainer>
                <H3>{title}</H3>
                <StyledContainer>
                  {(hasAllSettings && <pre>{code}</pre>) ||
                    'Complete set up to  get a code'}
                  <CardButton
                    title='Are you sure to delete this venue?'
                    placement='topRight'
                    onConfirm={event => handleConfirm(event, venueId)}
                    onCancel={e => e.stopPropagation()}
                    okText='Yes'
                    cancelText='No'
                  >
                    {isAdmin && (
                      <Button
                        type={'danger'}
                        size={'small'}
                        onClick={e => e.stopPropagation()}
                      >
                        <span>Delete</span>
                      </Button>
                    )}
                  </CardButton>
                </StyledContainer>
              </CardComp>
            )
          })}
      </CardWrapper>

      {(venues.length < 1 || isAdmin || isTFRVenue) && (
        <Row>
          <ActionCard>
            <h4>Create venue</h4>
            <InputComp
              value={venueTitle}
              onChange={e => {
                setVenueTitle(e.target.value)
              }}
              placeholder='Venue title'
            />
            <ActionButton
              title='Are you sure to create this venue?'
              placement='topRight'
              onConfirm={event => onClickCreateVenue(event)}
              okText='Yes'
              cancelText='No'
            >
              <Button style={{ marginTop: '42px' }}>Add</Button>
            </ActionButton>
          </ActionCard>
          {isAdmin && (
            <ActionCard>
              <h4>Send push notification to suppliers</h4>
              <InputComp
                value={supplierNotificationText}
                onChange={e => {
                  setSupplierNotificationText(e.target.value)
                }}
                placeholder='Notification text'
              />
              <TreeSelect
                treeNodeFilterProp='title'
                allowClear={true}
                placeholder='Select a supplier'
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                dropdownStyle={{ maxHeight: '300px' }}
                style={{ marginBottom: '15px' }}
                onChange={ids => setSelectedSupplierIds(ids)}
                value={selectedSupplierIds}
                maxTagCount={1}
                maxTagPlaceholder={omittedValues =>
                  `+ ${omittedValues.length} Supplier ...`
                }
                treeData={[
                  {
                    title:
                      selectedSupplierIds.length > 0 ? (
                        <span
                          onClick={() => setSelectedSupplierIds([])}
                          style={{
                            display: 'inline-block',
                            color: '#b5d922',
                            cursor: 'pointer',
                          }}
                        >
                          Unselect all
                        </span>
                      ) : (
                        <span
                          onClick={() => setSelectedSupplierIds(allSupplierIds)}
                          style={{
                            display: 'inline-block',
                            color: '#b5d922',
                            cursor: 'pointer',
                          }}
                        >
                          Select all
                        </span>
                      ),
                    value: 'xxx',
                    disableCheckbox: true,
                    disabled: true,
                  },
                  ...supplierOptions,
                ]}
              />
              <ActionButton
                title='Are you sure to send this notification?'
                placement='topRight'
                onConfirm={onClickSupplierNotification}
                okText='Yes'
                cancelText='No'
              >
                <Button>Send</Button>
              </ActionButton>
            </ActionCard>
          )}

          {isAdmin && (
            <ActionCard>
              <h4>Send push notification to venues</h4>
              <InputComp
                value={pushNotificationText}
                onChange={e => {
                  setPushNotificationText(e.target.value)
                }}
                placeholder='Notification text'
              />
              <TreeSelect
                treeNodeFilterProp='title'
                allowClear={true}
                placeholder='Select a venue'
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                dropdownStyle={{ maxHeight: '300px' }}
                style={{ marginBottom: '15px' }}
                onChange={ids => setSelectedVenuesIds(ids)}
                value={selectedVenueIds}
                maxTagCount={1}
                maxTagPlaceholder={omittedValues =>
                  `+ ${omittedValues.length} Venues ...`
                }
                treeData={[
                  {
                    title:
                      selectedVenueIds.length > 0 ? (
                        <span
                          onClick={() => setSelectedVenuesIds([])}
                          style={{
                            display: 'inline-block',
                            color: '#b5d922',
                            cursor: 'pointer',
                          }}
                        >
                          Unselect all
                        </span>
                      ) : (
                        <span
                          onClick={() => setSelectedVenuesIds(allIds)}
                          style={{
                            display: 'inline-block',
                            color: '#b5d922',
                            cursor: 'pointer',
                          }}
                        >
                          Select all
                        </span>
                      ),
                    value: 'xxx',
                    disableCheckbox: true,
                    disabled: true,
                  },
                  ...venueOptions,
                ]}
              />
              <ActionButton
                title='Are you sure to send this notification?'
                placement='topRight'
                onConfirm={onClickPushNotification}
                okText='Yes'
                cancelText='No'
              >
                <Button>Send</Button>
              </ActionButton>
            </ActionCard>
          )}
        </Row>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.xxl}`};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.md}`};
  }
`

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const CardComp = styled(Card)`
  cursor: pointer;
  background: ${({ theme }) => theme.colours.mayoWhite};
  margin: ${({ theme }) => theme.spacing.sm} 0 !important;
  width: calc(25% - 30px);
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: calc(100% - 30px);
  }
  border-radius: 5px !important;
  > .ant-card-body {
    display: flex;
    flex-direction: column;
  }
`
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: auto;
  margin-bottom: 10px;
`
const Img = styled.img`
  max-height: 120px;
  max-width: 194px;
`

const H3 = styled.h3`
  text-align: center;
  height: 66px;
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`

const ActionCard = styled(Card)`
  background: ${({ theme }) => theme.colours.mayoWhite};
  margin: ${({ theme }) => theme.spacing.sm} 0 !important;
  border-radius: 5px !important;
  width: calc(25% - 30px);
  > .ant-card-body {
    display: flex;
    flex-direction: column;
  }
  input,
  .ant-select-selection--multiple {
    background: ${({ theme }) => theme.colours.bgGrey};
  }
`

const ActionButton = styled(Popconfirm)`
  width: 100px;
  align-self: flex-end;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardButton = styled(Popconfirm)`
  align-self: flex-end;
  margin-bottom: 10px;
  margin-left: 12px;
`

const InputComp = styled(Input)`
  margin-bottom: ${({ theme }) => theme.spacing.sm} !important;
`

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export default Venues
