import {
  FileDoneOutlined,
  FundProjectionScreenOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import React, { Fragment } from 'react'
import { BodyText, H3 } from 'src/branding/typography'
import FadeIn from 'src/components/styled/FadeIn'
import { Center } from 'src/components/styled/FlexBox'
import styled from 'styled-components/macro'
import {
  basket,
  catalogue,
  invoices,
  orderedProducts,
  spendOverTime,
  statement,
  venueProducts,
} from './Text'

const NoSupplierSelectedComponent = (
  <BodyText textAlign='center'>
    There are currently no suppliers available to you, please contact the team
    at <a href='mailto:info@ordersmadesimple.com'>info@ordersmadesimple.com</a>{' '}
    to gain access to supplier catalogues
  </BodyText>
)

const NoDataScreen = ({ page = '' }) => {
  return (
    <Wrapper>
      <Card>
        {page === 'orders' && (
          <Fragment>
            <FileDoneOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{invoices.header}</H3>
            <BodyText>{invoices.text}</BodyText>
          </Fragment>
        )}
        {page === 'statement' && (
          <Fragment>
            <FileDoneOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{statement.header}</H3>
            <BodyText>{statement.text}</BodyText>
          </Fragment>
        )}
        {page === 'basket' && (
          <Fragment>
            <ShoppingCartOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{basket.header}</H3>
            <BodyText>{basket.text}</BodyText>
          </Fragment>
        )}
        {page === 'ordered' && (
          <Fragment>
            <ShoppingCartOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{orderedProducts.header}</H3>
            <BodyText>{orderedProducts.text}</BodyText>
          </Fragment>
        )}
        {page === 'spendTime' && (
          <Fragment>
            <FundProjectionScreenOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{spendOverTime.header}</H3>
            <BodyText>{spendOverTime.text}</BodyText>
          </Fragment>
        )}
        {page === 'catalogue' && (
          <Fragment>
            <ShoppingCartOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{catalogue.header}</H3>
            {NoSupplierSelectedComponent}
          </Fragment>
        )}
        {(page === 'venueProductsNoSupplier' ||
          page === 'venueProductsNoProduct') && (
          <Fragment>
            <ShoppingCartOutlined
              style={{
                fontSize: 75,
              }}
            />
            <H3>{venueProducts.header}</H3>
            {page === 'venueProductsNoSupplier' && NoSupplierSelectedComponent}
            {page === 'venueProductsNoProduct' && (
              <BodyText>{venueProducts.textProduct}</BodyText>
            )}
          </Fragment>
        )}
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)``

const Card = styled(Center)`
  flex-direction: column;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colours.mayoWhite} !important;
  height: 380px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  > span {
    margin-bottom: ${({ theme }) => theme.spacing.md};
    max-width: 650px;
  }
`

export default NoDataScreen
