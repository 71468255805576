import React, { useState } from 'react'
import { Modal, Input, DatePicker } from 'antd'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { sendSalesOrPurchaseData } from 'src/features/WeeklySalesSlice'
import dayjs from 'dayjs'

const MisCostModal = ({
  visibleMiscCostModal,
  setVisibleMiscCostModal,
  dateRange,
}) => {
  const [date, setDate] = useState('')
  const [purcheses, setPurchases] = useState('')
  const [text, setText] = useState('')
  const dispatch = useDispatch()
  const venueId = useSelector(state => state.venue?.venue?._id)
  const onChangeDate = dateString => {
    if (dateString) {
      setDate(dateString)
    } else {
      console.log('error')
    }
  }

  return (
    <Modal
      width={'40%'}
      title={'Add Misc Costs'}
      okText={'Save'}
      visible={visibleMiscCostModal}
      //confirmLoading={addInvoiceLoading}
      //okButtonProps={{ disabled: disabled }}
      onOk={async () => {
        const body = {
          date: dayjs(date).format('YYYY-MM-DD'),
        }

        body.purchase = {
          text,
          amount: purcheses,
        }
        dispatch(sendSalesOrPurchaseData(venueId, body, dateRange))
        setVisibleMiscCostModal(false)
      }}
      onCancel={() => {
        setVisibleMiscCostModal(false)
      }}
    >
      <SalesWrapper>
        <DatePicker
          onChange={onChangeDate}
          style={{ width: '60%', marginBottom: '20px' }}
        />
        <Input
          style={{ width: '60%', marginBottom: '20px' }}
          placeholder='Purchases Figure'
          value={purcheses}
          onChange={event => {
            setPurchases(event.target.value)
          }}
        />
        <Input
          style={{ width: '60%', marginBottom: '20px' }}
          placeholder='Wages,Utility Bills'
          value={text}
          onChange={event => {
            setText(event.target.value)
          }}
        />
      </SalesWrapper>
    </Modal>
  )
}
const SalesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export default MisCostModal
