import { Form, Icon, Input } from 'antd'
import React from 'react'
import LoadingButton from 'src/components/styled/LoadingButton'

const { Item } = Form

const NormalRegisterForm = ({ submit, form, user }) => {
  const { getFieldDecorator } = form
  const { isLoading } = user

  const handleSubmit = () => {
    form.validateFields((error, values) => {
      if (!error) {
        submit({
          ...values,
          email: values.email.toLowerCase(),
        })
      } else console.log('Error', error)
    })
  }

  return (
    <Form
      onSubmit={event => {
        event.preventDefault()
        if (!isLoading) {
          handleSubmit()
        }
      }}
      className='login-form'
    >
      <Item>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Please input your name' }],
        })(
          <Input
            prefix={<Icon type='smile' style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder='Your Name'
          />,
        )}
      </Item>
      <Item>
        {getFieldDecorator('email', {
          rules: [
            { required: true, message: 'Please input your email address' },
            { type: 'email', message: 'The input is not valid email' },
          ],
        })(
          <Input
            prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder='Your Email Address'
          />,
        )}
      </Item>
      <Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Please enter a password' }],
        })(
          <Input.Password
            prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='password'
            placeholder='Password'
          />,
        )}
      </Item>
      <Item style={{ marginBottom: 10 }}>
        <LoadingButton
          style={{ marginBottom: 10 }}
          type='primary'
          htmlType='submit'
          className='login-form-button'
          loading={isLoading}
        >
          Register
        </LoadingButton>
        <a href='/login'>Log In</a>
      </Item>
    </Form>
  )
}

const WrappedNormalRegisterForm = Form.create({ name: 'register' })(
  NormalRegisterForm,
)

export default WrappedNormalRegisterForm
