import React from 'react'
import styled from 'styled-components'
import { Checkbox, Input, InputNumber, Select, Tag } from 'antd'
const { Option } = Select

const ProductColumns = (
  errors,
  setErrors,
  isEditable,
  weightedItems,
  setWeightedItems,
  openHistory,
) => {
  const errorOptions = [
    'Damaged',
    'Quality Issue',
    'Not Delivered',
    'Something Else',
    'Substitution',
    'Supplier / Invoice adjustment',
    'Weighted item',
  ]
  const isAlternativeReceived = record => {
    const current = errors.find(item => item?.order === record._id)
    return current ? current.error === 'Substitution' : false
  }

  const isErrorCheck = (e, record) => {
    if (e.target.checked) {
      setErrors([
        ...errors,
        {
          order: record._id,
          error: '',
          supplier: record.supplier,
          purchaseOrderNumber: record.purchaseOrderNumber,
          status: 'Sent to supplier',
        },
      ])
    } else {
      setErrors(errors.filter(error => error.order !== record._id))
    }
  }

  const onDetailsChange = (e, record, field, updatedValue) => {
    const currentOrder = errors.find(item => {
      return item.order === record._id
    })
    const currentWeightedItem = weightedItems.find(
      item => item.order === record._id,
    )
    let updatedOrder
    switch (field) {
      case 'title':
        updatedOrder = {
          ...currentOrder,
          updatedTitle: e.target.value,
          orderedTitle: record.orderedTitle || record.title,
          prevTitle: record.receipting?.updatedTitle || record.title,
        }
        setErrors(
          errors.map(item => (item.order === record._id ? updatedOrder : item)),
        )
        break
      case 'price':
        updatedOrder = {
          ...currentOrder,
          updatedPrice: e * 100,
          orderedPrice: record.orderedPrice || record.price,
          prevPrice: record.receipting?.updatedPrice || record.price,
        }
        setErrors(
          errors.map(item => (item.order === record._id ? updatedOrder : item)),
        )
        break
      case 'size':
        updatedOrder = {
          ...currentOrder,
          updatedSize: e.target.value,
          orderedSize: record.orderedSize || record.size,
          prevSize: record.receipting?.updatedSize || record.size,
        }
        setErrors(
          errors.map(item => (item.order === record._id ? updatedOrder : item)),
        )
        break
      case 'qty': {
        const errorProduct = errors.find(error => error.order === record._id)
        if (errorProduct.error === 'Supplier / Invoice adjustment') {
          updatedOrder = {
            ...currentOrder,
            updatedQuantity: e,
            orderedQuantity: record.orderedQuantity || record.quantity,
            previousQty: record.orderedQuantity || record.quantity,
          }
        } else {
          updatedOrder = {
            ...currentOrder,
            updatedQuantity: updatedValue,
            orderedQuantity: record.orderedQuantity || record.quantity,
            previousQty: e,
          }
        }
        setErrors(
          errors.map(item => (item.order === record._id ? updatedOrder : item)),
        )
        break
      }
      case 'sku':
        updatedOrder = {
          ...currentOrder,
          updatedSku: e.target.value,
          orderedSku: record.orderedSku || record.sku,
          prevSku: record.receipting?.updatedSku || record.sku,
        }
        setErrors(
          errors.map(item => (item.order === record._id ? updatedOrder : item)),
        )
        break
      case 'note':
        updatedOrder = { ...currentOrder, notes: e.target.value }
        setErrors(
          errors.map(item => (item.order === record._id ? updatedOrder : item)),
        )
        break
      case 'weight':
        if (!currentWeightedItem) {
          setWeightedItems([
            ...weightedItems,
            {
              order: record._id,
              error: 'Weighted item',
              supplier: record.supplier,
              purchaseOrderNumber: record.purchaseOrderNumber,
              status: 'Accepted',
              weight: e,
              orderedQuantity: record.orderedQuantity || record.quantity,
            },
          ])
        } else {
          e !== record.quantity
            ? setWeightedItems(
                weightedItems.map(item => {
                  return item.order !== record._id
                    ? item
                    : { ...currentWeightedItem, weight: e }
                }),
              )
            : setWeightedItems(
                weightedItems.filter(item => item.order !== record._id),
              )
        }
        break
      default:
        break
    }
  }

  const notesInputValue = record => {
    const notes = errors.find(item => {
      return item.order === record._id
    })?.notes
    return notes ? notes : ''
  }

  const onErrorSelect = (value, record) => {
    const errorProduct = errors.find(error => error.order === record._id)
    errorProduct.error = value
    errorProduct.status =
      value !== 'Weighted item' && value !== 'Supplier / Invoice adjustment'
        ? 'Sent to supplier'
        : 'Accepted'
    setErrors(
      errors.map(error =>
        error.order === errorProduct.order ? errorProduct : error,
      ),
    )
  }

  const selectDataIndex = (record, field) => {
    switch (field) {
      case 'title': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedTitle ||
              record.title ||
              record.product.title
          : record.title || record.product.title
      }
      case 'price': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedPrice ||
              record.price ||
              record.product.price
          : record.price || record.product.price
      }
      case 'size': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedSize || record.size || record.product.size
          : record.size || record.product.size
      }
      case 'quantity': {
        return record.status === 'Rejected by supplier'
          ? record.quantity
          : record.receipting?.updatedQuantity >= 0 &&
            record.receipting?.error === 'Supplier / Invoice adjustment'
          ? record.receipting?.updatedQuantity
          : record?.receipting?.previousQty >= 0
          ? record.receipting?.previousQty
          : record?.updatedQuantity
          ? record?.updatedQuantity
          : record?.quantity
      }
      case 'sku': {
        return record.status !== 'Rejected by supplier'
          ? record.receipting?.updatedSku || record.sku || record.product.sku
          : record.sku || record.product.sku
      }
      case 'updatedQuantity': {
        return record.status !== 'Rejected by supplier'
          ? record.updatedQuantity
          : record.quantity
      }
    }
  }

  const getTotalPrice = record => {
    const currentField = errors.find(error => error.order === record._id)
    const price =
      record.status !== 'Rejected by supplier'
        ? record.receipting?.updatedPrice ||
          record.price ||
          record.product.price
        : record.price || record.product.price

    let totalPrice = 0

    if (currentField) {
      totalPrice =
        record.status === 'Rejected by supplier'
          ? price * record.quantity
          : currentField?.updatedQuantity
          ? currentField.updatedQuantity * price
          : price * record.quantity
    } else {
      totalPrice =
        record.status === 'Rejected by supplier'
          ? price * record.quantity
          : record.receipting?.updatedQuantity >= 0 &&
            record.receipting.error != 'Supplier / Invoice adjustment'
          ? price * record.receipting?.previousQty
          : record?.updatedQuantity
          ? price * record?.updatedQuantity
          : price * record.quantity
    }
    return (totalPrice / 100).toFixed(2)
  }

  return [
    {
      title: 'Title',
      dataIndex: 'title' || 'product.title',
      render: (text, record) => {
        const title = selectDataIndex(record, 'title')
        return !isAlternativeReceived(record) ? (
          title
        ) : (
          <Input
            defaultValue={title}
            onChange={e => onDetailsChange(e, record, 'title')}
          />
        )
      },
    },
    {
      title: 'Item Price',
      dataIndex: 'price' || 'product.price',
      render: (text, record) => {
        const price = selectDataIndex(record, 'price')
        return !isEditable(record, 'price') ? (
          `£${(price / 100).toFixed(2)}`
        ) : (
          <InputNumber
            defaultValue={Number((price / 100).toFixed(2))}
            onChange={e => onDetailsChange(e, record, 'price')}
            min={0}
          />
        )
      },
    },
    {
      title: 'Total Price',
      render: (_, record) => {
        return `£${getTotalPrice(record)}`
      },
    },
    {
      title: 'Size',
      width: '10%',
      dataIndex: 'size' || 'product.size',
      render: (text, record) => {
        const size = selectDataIndex(record, 'size')
        return !isAlternativeReceived(record) ? (
          size
        ) : (
          <Input
            defaultValue={size}
            onChange={e => onDetailsChange(e, record, 'size')}
          />
        )
      },
    },
    {
      title: 'Ordered Qty',
      dataIndex: 'orderedQuantity',
      render: (text, record) => {
        return text ? text : record.quantity
      },
    },
    {
      title: 'Received Qty',
      render: (_, record) => {
        const qty = selectDataIndex(
          record,
          record.receipting?.updatedQuantity >= 0
            ? 'quantity'
            : record?.updatedQuantity
            ? 'updatedQuantity'
            : 'quantity',
        )
        const isErrorSelected = !!errors.find(
          error => error.order === record._id,
        )?.error
        return isEditable(record, 'qty') ? (
          <InputNumber
            defaultValue={qty}
            disabled={!isErrorSelected}
            onChange={e => {
              let updatedValue = qty - e
              onDetailsChange(e, record, 'qty', updatedValue)
            }}
            min={0}
          />
        ) : (
          qty
        )
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku' || 'product.sku',
      width: '10%',
      render: (text, record) => {
        const sku = selectDataIndex(record, 'sku')
        return !isAlternativeReceived(record) ? (
          sku
        ) : (
          <Input
            defaultValue={sku}
            onChange={e => onDetailsChange(e, record, 'sku')}
          />
        )
      },
    },
    {
      title: 'Weighted',
      dataIndex: 'UoM',
      render: (UoM, record) => {
        if (UoM !== 'Kg' && record.product.UoM !== 'Kg') {
          return 'Not Weighted'
        }
        const weight =
          record.receipting?.weight ??
          record.receipting?.previousQty ??
          record.quantity
        return isEditable(record, 'weight') ? (
          <>
            <InputNumber
              defaultValue={weight}
              onChange={e => onDetailsChange(e, record, 'weight')}
              min={0}
            />
            <span>Kg</span>
          </>
        ) : (
          `${weight} Kg`
        )
      },
    },

    {
      title: 'Actions',
      dataIndex: 'status',
      render: (status, record) => {
        if (record.supplierConfirmed === false) {
          return <Tag color={'magenta'}>Rejected by Supplier</Tag>
        }
        return record.status === undefined && record.title === undefined ? (
          <Tag color={'orange'}>Waiting on Supplier Approval</Tag>
        ) : (
          <>
            <ActionWrapper>
              <Checkbox
                className='checkbox'
                onChange={e => isErrorCheck(e, record)}
                checked={!!errors.find(error => error.order === record._id)}
              >
                Report Error
              </Checkbox>
              <ColumnFlex className='select'>
                <Select
                  style={{ marginLeft: '10px', width: '180px' }}
                  onSelect={value => onErrorSelect(value, record)}
                  disabled={!errors.find(error => error.order === record._id)}
                  value={
                    errors.find(error => error.order === record._id)
                      ? errors.find(error => error.order === record._id).error
                      : ''
                  }
                >
                  {errorOptions.map(item => {
                    return (
                      <Option
                        key={item}
                        value={item}
                        style={{ whiteSpace: 'normal' }}
                      >
                        {item}
                      </Option>
                    )
                  })}
                </Select>
                <History onClick={() => openHistory(record._id)}>
                  History
                </History>
              </ColumnFlex>
              <Input
                className='notes'
                style={{ marginLeft: '10px' }}
                onChange={e => onDetailsChange(e, record, 'note')}
                value={notesInputValue(record)}
                disabled={!errors.find(error => error.order === record._id)}
                placeholder='Add details about error product, please...'
              />
            </ActionWrapper>
          </>
        )
      },
    },
  ]
}

const ActionWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  .notes {
    flex: 1 1 100%;
  }
  .checkbox {
    flex: 0 0 25%;
  }
  .select {
    flex: 0 0 120px;
  }
`
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .select {
    flex: 0 0 120px;
  }
`
const History = styled.div`
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`

export default ProductColumns
