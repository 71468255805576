import { Button } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { saveAndAcceptedToDataplus } from '../../features/DataplusIntagrationSlice'

export default function SaveToDataplusBtn({ orderIds, venueId, PONumber }) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  async function handleAddToDataplus() {
    try {
      setLoading(true)
      await dispatch(
        saveAndAcceptedToDataplus(venueId, {
          orderIds,
          PONumber,
        }),
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log('Error in handleAddToDataplus', handleAddToDataplus)
    }
  }

  return (
    <Button loading={loading} type={'primary'} onClick={handleAddToDataplus}>
      Accepted and Saved
    </Button>
  )
}
