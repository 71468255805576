import { Button } from 'antd'
import styled from 'styled-components/macro'

const LoadingButton = styled(Button)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon.anticon-loading {
      margin-left: -25px !important;
    }
  }
`
export default LoadingButton
