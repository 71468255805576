import React, { useState } from 'react'
import { Modal, Input, DatePicker } from 'antd'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { sendSalesOrPurchaseData } from 'src/features/WeeklySalesSlice'
import dayjs from 'dayjs'

const SalesModal = ({ visibleSalesModal, setVisibleSalesModal, dateRange }) => {
  const [sales, setSales] = useState('')
  const [date, setDate] = useState('')
  const venueId = useSelector(state => state.venue?.venue?._id)
  const dispatch = useDispatch()
  const onChangeDate = dateString => {
    if (dateString) {
      setDate(dateString)
    } else {
      console.log('error')
    }
  }

  return (
    <Modal
      width={'40%'}
      title={'Enter Data'}
      okText={'Save'}
      visible={visibleSalesModal}
      //confirmLoading={addInvoiceLoading}
      //okButtonProps={{ disabled: disabled }}
      onOk={async () => {
        const body = {
          date: dayjs(date).format('YYYY-MM-DD'),
        }

        body.sale = Number(sales)
        body.isSale = true
        dispatch(sendSalesOrPurchaseData(venueId, body, dateRange))
        setVisibleSalesModal(false)
      }}
      onCancel={() => {
        setVisibleSalesModal(false)
      }}
    >
      <SalesWrapper>
        <DatePicker
          onChange={onChangeDate}
          style={{ width: '60%', marginBottom: '20px' }}
        />
        <Input
          style={{ width: '60%' }}
          placeholder='Sales Figure'
          value={sales}
          onChange={event => {
            setSales(event.target.value)
          }}
        />
      </SalesWrapper>
    </Modal>
  )
}
const SalesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export default SalesModal
