import { Button, DatePicker, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { H3 } from 'src/branding/typography'
import { resetOrdersFilter, setOrdersFilter } from 'src/features/FilterSlice'
import {
  fetchOrders,
  fetchStatementOrders,
  updateFiltered,
  updateStatementFiltered,
} from 'src/features/OrdersSlice'
import { fetchAllSuppliers } from 'src/features/SupplierSlice'
import { findUnique } from 'src/utils/arrayFunctions'
import styled from 'styled-components/macro'

const { Option } = Select

const OrdersFilter = ({ venueId = '' }) => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.filter.orders)
  const allSuppliers = useSelector(state => state.suppliers.suppliers)
  const venuesOptions = useSelector(state => state.auth.account.venues)
  const orders = useSelector(state => state.orders.orders)
  const statementOrders = useSelector(state => state.orders.statementOrders)
  const [isEndOpen, setIsEndOpen] = useState(false)
  const [typesOptions, setTypesOptions] = useState([])
  const [suppliersOptions, setSuppliersOptions] = useState([])
  const { startDate, endDate, types, locations, suppliers } = filter
  const dateFormat = 'DD/MM/YYYY'
  // const endpoint = useLocation().pathname.split('/').reverse()[0]
  useEffect(() => {
    if (allSuppliers.length === 0) dispatch(fetchAllSuppliers())

    const filterCopy = { ...filter }
    const sevenDaysBefore = moment().subtract(31, 'days').format(dateFormat)
    const today = moment().format(dateFormat)
    filterCopy.startDate = sevenDaysBefore
    filterCopy.endDate = today
    dispatch(setOrdersFilter(filterCopy))
    dispatch(fetchStatementOrders(venueId, sevenDaysBefore, today))
    dispatch(fetchOrders(venueId, sevenDaysBefore, today))
  }, [])

  useEffect(() => {
    if (orders.length > 0) {
      setTypesOptions(
        findUnique(
          orders,
          order => order?.type || order?.product?.type || '',
          'keys',
        ).sort((a, b) => (a > b ? 1 : -1)),
      )
      setSuppliersOptions(
        findUnique(orders, order => order.supplier, 'values')
          .map(order => ({
            supplier: order.supplier || '',
            supplierTitle: order?.product?.supplier?.title || '',
          }))
          .sort((a, b) => (a.supplierTitle > b.supplierTitle ? 1 : -1)),
      )
    }
  }, [orders])

  useEffect(() => {
    if (
      suppliers.length === 0 &&
      types.length === 0 &&
      locations.length === 0
    ) {
      dispatch(updateStatementFiltered([...statementOrders]))
      dispatch(updateFiltered([...orders]))
    } else {
      let filteredOrdersCopy = [...orders]
      let filteredStatementOrdersCopy = [...statementOrders]
      if (suppliers.length > 0) {
        filteredOrdersCopy = filteredOrdersCopy.filter(order =>
          order.supplier
            ? suppliers.includes(order.product?.supplier.title)
            : false,
        )
        filteredStatementOrdersCopy = filteredStatementOrdersCopy.filter(
          order =>
            order._id.supplier
              ? suppliers.includes(order._id.supplier.title)
              : false,
        )
      }
      if (types.length > 0) {
        const typesWithoutIndex = types.map(type =>
          type.substring(0, type.length - 1),
        )
        filteredOrdersCopy = filteredOrdersCopy.filter(order =>
          order.type ? typesWithoutIndex.includes(order.type) : false,
        )
        filteredStatementOrdersCopy = filteredStatementOrdersCopy.filter(
          order => {
            let arr = []
            order.data.forEach(item => {
              if (typesWithoutIndex.includes(item.type)) {
                arr.push(true)
              } else {
                arr.push(false)
              }
            })
            return arr.includes(true)
          },
        )
      }
      if (locations.length > 0) {
        filteredOrdersCopy = filteredOrdersCopy.filter(order =>
          order.venue ? locations.includes(order.venue) : false,
        )
      }
      dispatch(updateStatementFiltered(filteredStatementOrdersCopy))
      dispatch(updateFiltered(filteredOrdersCopy))
    }
  }, [orders, suppliers, types, locations, statementOrders])

  const handleRefreshButton = event => {
    dispatch(resetOrdersFilter())
    event.target.blur()
  }

  const handleStartOpenChange = open => {
    if (!open) setIsEndOpen(true)
  }

  const disabledStartDate = startValue =>
    endDate
      ? startValue.valueOf() > moment(endDate, dateFormat).valueOf()
      : startValue.valueOf() > moment().valueOf()

  const disabledEndDate = endValue =>
    endValue.valueOf() < moment(startDate, dateFormat).valueOf() ||
    endValue.valueOf() > moment().valueOf()

  const onChange = (field, value) => {
    const filterCopy = { ...filter }
    filterCopy[field] =
      field === 'startDate' || field === 'endDate'
        ? value.format(dateFormat)
        : [...value]
    dispatch(setOrdersFilter(filterCopy))
  }

  const onChangeEndDate = data => {
    onChange('endDate', data)
    dispatch(fetchStatementOrders(venueId, startDate, data.format(dateFormat)))
    dispatch(fetchOrders(venueId, startDate, data.format(dateFormat)))
  }

  return (
    <Wrapper>
      <H3>Filter</H3>
      <Form>
        <DatePicker
          placeholder={startDate}
          disabledDate={disabledStartDate}
          // value={startDate ? moment(startDate, dateFormat) : null}
          format={dateFormat}
          onChange={data => onChange('startDate', data)}
          onOpenChange={handleStartOpenChange}
          // showToday={false}
        />
        <DatePicker
          placeholder={endDate}
          disabledDate={disabledEndDate}
          // value={endDate ? moment(endDate, dateFormat) : null}
          format={dateFormat}
          onChange={onChangeEndDate}
          open={isEndOpen}
          onOpenChange={open => setIsEndOpen(open)}
        />

        {venueId ? (
          <Select
            allowClear
            mode='multiple'
            placeholder='Product type'
            onChange={data => onChange('types', data)}
            value={types}
          >
            {typesOptions.map((type, index) => {
              return <Option key={`${type}${index}`}>{type}</Option>
            })}
          </Select>
        ) : (
          <Select
            allowClear
            mode='multiple'
            placeholder='Location'
            onChange={data => onChange('locations', data)}
            value={locations}
          >
            {venuesOptions.map(venue => (
              <Option key={venue._id}>{venue.title}</Option>
            ))}
          </Select>
        )}
        <Select
          allowClear
          mode='multiple'
          placeholder='Supplier'
          onChange={data => onChange('suppliers', data)}
          value={suppliers}
        >
          {suppliersOptions.map(supplier => (
            <Option key={supplier.supplier} value={supplier.supplierTitle}>
              {supplier.supplierTitle}
            </Option>
          ))}
        </Select>
      </Form>
      <Button
        style={{ color: 'white', background: 'black', border: 'none' }}
        onClick={handleRefreshButton}
      >
        Clear
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  min-width: 270px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.lg} 0`};
  border-radius: 5px;
  margin-right: ${({ theme }) => theme.spacing.sm};
  h3 {
    margin: 0;
    color: black;
  }
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing.lg} 0`};
  .ant-calendar-picker,
  .ant-select {
    width: 245px;
    padding-bottom: ${({ theme }) => theme.spacing.sm};
  }
  .anticon.anticon-calendar.ant-calendar-picker-icon {
    top: 30%;
  }
  input::placeholder {
    color: black;
    font-weight: 500;
  }
`

export default OrdersFilter
