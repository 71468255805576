import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import TableColumn from './tableColumn'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSupplierProducts } from '../../../features/ReceiptingSlice'

const AddProductModal = ({ ordersToAdding, setOrdersToAdding }) => {
  const dispatch = useDispatch()
  const supplierProducts = useSelector(
    state => state.receipting.supplierProducts,
  )
  const [filteredProducts, setFilteredProducts] = useState([])
  useEffect(() => {
    const { venue: venueId, supplier: supplierId } = ordersToAdding
    dispatch(fetchSupplierProducts(venueId, supplierId))
  }, [])
  useEffect(() => {
    setFilteredProducts(supplierProducts)
  }, [supplierProducts])
  return (
    <div>
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
        }}
        dataSource={[ordersToAdding]}
        pagination={false}
        columns={TableColumn(
          supplierProducts,
          filteredProducts,
          setFilteredProducts,
          setOrdersToAdding,
        )}
        rowKey={ordersToAdding.supplier}
      />
    </div>
  )
}

export default AddProductModal
