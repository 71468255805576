import React, { useEffect } from 'react'
import { Input, Checkbox } from 'antd'
import styled from 'styled-components/macro'
import { DateRangeFilter } from './DateRangeFilter'
import { fetchReceiptingOrders } from '../../features/ReceiptingSlice'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'

const ReceiptingFilter = ({
  venueId,
  isPoChecked,
  setIsPoChecked,
  isSupplierChecked,
  setIsSupplierChecked,
  searchQuery,
  setSearchQuery,
  isEmptySearch,
  setIsEmptySearch,
}) => {
  const dispatch = useDispatch()
  const dateFormat = 'DD/MM/YYYY'
  const filterDates = useSelector(state => state.receipting.filterDates)
  const receiptingExist = useSelector(
    state => state.receipting.receiptingOrdersAlreadyExists,
  )

  const { Search } = Input

  useEffect(() => {
    setIsEmptySearch(false)
  }, [searchQuery])

  useEffect(() => {
    if (receiptingExist === venueId) return
    dispatch(
      fetchReceiptingOrders(
        venueId,
        moment(filterDates.start).format(dateFormat),
        moment(filterDates.end).format(dateFormat),
      ),
    )
  }, [filterDates, venueId])

  const handleCheckbox = () => {
    setIsPoChecked(!isPoChecked)
    setIsSupplierChecked(!isSupplierChecked)
  }

  const onSearch = () => {
    if (!searchQuery.trim()) {
      return setIsEmptySearch(true)
    }
  }

  return (
    <Wrapper style={{ alignItems: 'center' }}>
      <DateRangeFilter />

      <div style={inputContainer}>
        <Search
          size='large'
          placeholder='Search by'
          style={{ width: 300, margin: '0 5px 0 10px' }}
          type='text'
          value={searchQuery}
          allowClear
          onPressEnter={onSearch}
          onChange={e => {
            setSearchQuery(e.target.value.toLowerCase())
          }}
        />
        {isEmptySearch && <SearchError>Please enter a search term</SearchError>}
      </div>

      <div style={{ margin: '0 10px' }}>
        <Checkbox checked={isSupplierChecked} onChange={handleCheckbox}>
          Supplier
        </Checkbox>
        <Checkbox checked={isPoChecked} onChange={handleCheckbox}>
          PON
        </Checkbox>
      </div>
      {/*<Button onClick={onSearch} style={{*/}
      {/*  color: '#ffffff',*/}
      {/*  backgroundColor: '#b5d922',*/}
      {/*    opacity:!isPoChecked && !isSupplierChecked ? 0.4 : 1,*/}
      {/*  borderColor: '#b5d922',*/}
      {/*  height: 40,*/}
      {/*  width: 80*/}
      {/*}} disabled={!isPoChecked && !isSupplierChecked}>Search</Button>*/}
    </Wrapper>
  )
}

const inputContainer = {
  display: 'flex',
  position: 'relative',
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 15px;
`
const SearchError = styled.p`
  color: #ff4d4f;
  position: absolute;
  bottom: 100%;
  padding: 0;
  margin: 0;
  left: 10px;
`
export default ReceiptingFilter
