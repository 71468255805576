import { Modal, Table, Tag, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NoDataScreen from 'src/components/shared/no-data-screen/NoDataScreen'
import FadeIn from 'src/components/styled/FadeIn'
import Loading from 'src/components/styled/Loading'
import styled from 'styled-components/macro'
import _ from 'underscore'
import BasketTableColumn from './BasketTableColumn'
import {
  createOrder,
  fetchOrders,
  sendOrder,
  setBasket,
} from '../../../features/OrdersSlice'
import OrderProductsModal from './order-products-modal/OrderProductsModal'
import dayjs from 'dayjs'

const Basket = ({ venueId }) => {
  const orders = useSelector(state => state.orders.basket)
  const venueSuppliers = useSelector(
    state => state.venueSuppliers.venueSuppliers,
  )
  const isLoading = useSelector(state => state.orders.isLoading)
  const account = useSelector(state => state.auth.account)
  const venue = useSelector(state => state.venue.venue)
  const [removeProductId, setRemoveProductId] = useState('')
  const updateLoading = useSelector(state => state.orders.isUpdateLoading)
  const [orderModalShow, setOrderModalShow] = useState(false)
  const [order, setOrder] = useState({})
  let belowMinimumPrice = false
  let belowOrderMinimumOrder = false
  const dispatch = useDispatch()
  const VAT_RATE = 1.2
  const isPricesHidden = venue.isPricesHidden || false
  useEffect(() => {
    dispatch(fetchOrders(venueId))
  }, [dispatch, venueId])

  const getTotalPrice = orders => {
    const totalSum = orders.reduce((sum, item) => {
      const price = item?.isSpecialOrder
        ? item.specialProductPrice && item.product.hasVat
          ? Number(item.specialProductPrice) * VAT_RATE
          : Number(item.specialProductPrice)
        : item.product && item.product.OMSPrice
        ? item.product && item.product.hasVat
          ? Number(item.product.OMSPrice) * VAT_RATE
          : Number(item.product.OMSPrice)
        : 0
      const orderPrice = item.quantity ? price * item.quantity : price
      return sum + orderPrice
    }, 0)
    return totalSum
  }
  const getGrandTotalPrice = orders => {
    const totalSum = orders.reduce((sum, item) => {
      const price = item?.isSpecialOrder
        ? item.specialProductPrice && item.product.hasVat
          ? Number(item.specialProductPrice) * VAT_RATE
          : Number(item.specialProductPrice)
        : item.product && item.product.OMSPrice
        ? item.product && item.product.hasVat
          ? Number(item.product.OMSPrice) * VAT_RATE
          : Number(item.product.OMSPrice)
        : 0
      const orderPrice = item.quantity ? price * item.quantity : price
      return sum + orderPrice
    }, 0)
    return totalSum
  }

  const handleRemoveProduct = product => {
    setRemoveProductId(product._id)
    dispatch(
      createOrder({
        ...product,
        quantity: 0,
      }),
    )
  }

  const updateOrder = order => {
    dispatch(
      createOrder({
        ...order,
        quantity: parseFloat(order.quantity),
        method: 'update',
      }),
    )
  }

  const onChangeQuantity = (value, product) => {
    dispatch(
      setBasket(
        orders.map(prod => {
          if (prod._id === product._id) {
            const newObj = {
              ...prod,
              quantity: value,
            }
            updateOrder(newObj)
            return newObj
          } else return prod
        }),
      ),
    )
  }

  const getCountOfProducts = () => {
    return orders.reduce((sum, item) => {
      return sum + item.quantity
    }, 0)
  }
  const validateDeliveryDay = date => {
    const day = dayjs(date).format('ddd').toLowerCase()
    const warnings = []
    const suppliers = orders.map(order => order.product.supplier)
    const uniqueSuppliers = _(suppliers).unique('_id')
    for (const supplier of uniqueSuppliers) {
      if (supplier && !supplier.deliveryDays.includes(day)) {
        warnings.push(supplier.title)
      }
    }
    if (warnings.length === 0) return true

    if (warnings.length === uniqueSuppliers.length) {
      notification.error({
        message: 'Invalid Delivery Day',
        description:
          'None of the suppliers deliver on the selected date. Please select another date.',
      })
      return false
    } else {
      notification.warning({
        message: 'Partial Delivery Issue',
        description: `${warnings.join(
          ', ',
        )} don't deliver on the selected date. You may receive orders on their next available delivery day.`,
      })
      return true
    }
  }

  const handleOrderProducts = async () => {
    const body = {
      notes: order.notes,
      requiredDeliveryDay: order.deliveryDate,
      placedVia: account.name || account.emailAddress || null,
      placedBy: account.emailAddress || null,
    }
    dispatch(sendOrder(venueId, body))
    setOrderModalShow(false)
  }

  const suppliersGrouped = _(orders).groupBy(
    order => order?.product?.supplier?.title,
  )

  const notMinimumNotification = () => {
    notification.error({
      message: 'Not minimum order price',
      description:
        "One of your orders is below the supplier's minimum amount. This order will remain unsent.",
      placement: 'RadiusBottomrightOutlined',
      bottom: 100,
    })
  }

  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : !Array.isArray(orders) || orders.length < 1 ? (
        <NoDataScreen page='basket' />
      ) : (
        Object.entries(suppliersGrouped).map(([key, groupedOrders]) => {
          const minimumOrderValue = (
            venueSuppliers?.find(
              supp =>
                supp?.venue === venueId &&
                supp?.supplier?._id ===
                  groupedOrders[0]?.product?.supplier?._id,
            )?.minOrderValue / 100
          ).toFixed(2)
          let supplierTotalPrice = getTotalPrice(groupedOrders)?.toFixed(2)
          belowOrderMinimumOrder =
            getTotalPrice(groupedOrders) < minimumOrderValue
          if (belowOrderMinimumOrder) {
            belowMinimumPrice = true
          }
          return (
            <SupplierTable key={key}>
              <TableHeader>
                <h3>{key}</h3>
                <div>
                  <h3>{supplierTotalPrice}</h3>
                  {minimumOrderValue &&
                    (getTotalPrice(groupedOrders) < minimumOrderValue ? (
                      <Tag
                        color='magenta'
                        style={{ fontSize: '14px', marginBottom: '15px' }}
                      >
                        Minimum spending limit is {minimumOrderValue}
                      </Tag>
                    ) : (
                      <Tag
                        color='green'
                        style={{ fontSize: '14px', marginBottom: '15px' }}
                      >
                        Minimum spending limit was reached
                      </Tag>
                    ))}
                </div>
              </TableHeader>
              <Table
                bordered
                style={{
                  background: 'white',
                }}
                pagination={false}
                dataSource={groupedOrders.map((prod, index) => ({
                  key: prod._id,
                  index: index,
                  ...prod,
                }))}
                columns={BasketTableColumn(
                  orders,
                  handleRemoveProduct,
                  onChangeQuantity,
                  updateLoading,
                  removeProductId,
                  isPricesHidden,
                )}
              />
            </SupplierTable>
          )
        })
      )}
      <Modal
        width={'40%'}
        title={'Order products'}
        okText={'Order now'}
        visible={orderModalShow}
        confirmLoading={isLoading}
        onOk={async () => {
          await handleOrderProducts()
        }}
        onCancel={() => {
          setOrderModalShow(false)
        }}
      >
        <OrderProductsModal
          order={order}
          setOrder={setOrder}
          validateDeliveryDay={validateDeliveryDay}
        />
      </Modal>
      <TableFooter
        style={{ display: (isLoading || orders.length === 0) && 'none' }}
      >
        <FooterText>
          Order total: {parseFloat(getGrandTotalPrice(orders))?.toFixed(2)}
        </FooterText>
        <FooterText>
          Suppliers: {Object.keys(suppliersGrouped).length}
        </FooterText>
        <FooterText>Products: {getCountOfProducts()}</FooterText>
        <StyledButton
          onClick={() =>
            belowMinimumPrice
              ? notMinimumNotification()
              : setOrderModalShow(true)
          }
        >
          Order Now
        </StyledButton>
      </TableFooter>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  width: 100%;
`
const TableFooter = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colours.avocadoGreen};
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const FooterText = styled.h3`
  color: ${({ theme }) => theme.colours.mayoWhite};
`
const StyledButton = styled.button`
  color: ${({ theme }) => theme.colours.mayoWhite};
  background-color: ${({ theme }) => theme.colours.avocadoGreen};
  border: 1px solid ${({ theme }) => theme.colours.mayoWhite};
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;

  &:hover {
    color: ${({ theme }) => theme.colours.seaweedGreen};
    border-color: ${({ theme }) => theme.colours.seaweedGreen};
    cursor: pointer;
  }

  &:disabled {
    color: ${({ theme }) => theme.colours.oliveGrey};
    border-color: ${({ theme }) => theme.colours.oliveGrey};
    cursor: pointer;
  }
`

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SupplierTable = styled.div`
  margin-bottom: 40px;
`

export default Basket
