import React, { useEffect } from 'react'
import { Button, Col, Row, Table } from 'antd'
import { GoldenFilled } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import Loading from '../../components/styled/Loading'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { fetchStockTakeProducts } from '../../features/StockSlice'
import { VAT_PERCENTAGE, VAT_RATE } from '../../config'
import ValuationCSV from './ValuationCSV'

const Valuation = props => {
  const {
    match: {
      params: { stockTakeId, venueId },
    },
  } = props
  const isLoading = useSelector(state => state.stockTakes.isLoading)
  const stockTake = useSelector(state => state.stockTakes.stockTakes)?.find(
    stockTake => stockTake._id === stockTakeId,
  )
  const dispatch = useDispatch()
  const stockTakeProductsFromDb = useSelector(
    state => state.stockTakes.stockTakeProducts,
  )
  const location = useLocation();
  const endpoint = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const isEndPointWastage = endpoint === "wastage-report";
  const isEndPointStock = endpoint === "valuation-report";

  const stockTakeProducts = isEndPointWastage ? stockTakeProductsFromDb?.filter(
      product => product.wastage > 0,
  ) : stockTakeProductsFromDb?.filter(
      product => product.inStock > 0,
  )
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      sorter: (a, b) =>
        String(a.title).trim().localeCompare(String(b.title).trim()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) =>
        String(a.size).trim().localeCompare(String(b.size).trim()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'UoM',
      dataIndex: 'UoM',
      key: 'UoM',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierTitle',
      key: 'supplier',
      width: '12%',
      sorter: (a, b) =>
        String(a.supplierTitle)
          .trim()
          .localeCompare(String(b.supplierTitle).trim()),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Sku',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: price => {
        return `£${price}`
      },
      sorter: (a, b) =>
        (a.hasVat ? VAT_RATE * a.price : a.price) -
        (b.hasVat ? VAT_RATE * b.price : b.price),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Prev qty',
      dataIndex: 'lastQuantity',
      key: 'lastQuantity',
      className: 'smallColumn',
    },
    {
      title: 'Received',
      dataIndex: 'qtySinceLastStockTake',
      key: 'qtySinceLastStockTake',
      className: 'smallColumn',
    },
    {
      title: 'In Stock',
      dataIndex: 'inStock',
      key: 'inStock',
      className: 'smallColumn',
      hidden: isEndPointStock,
      sorter: (a, b) => a.inStock - b.inStock,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Stock value',
      key: 'stockAmount',
      className: 'smallColumn',
      hidden: isEndPointStock,
      render: product => {
        return `£${(product.hasVat
          ? product.price * product.inStock * VAT_RATE
          : product.price * product.inStock
        ).toFixed(2)}`
      },
    },
    {
      title: 'Wastage',
      dataIndex: 'wastage',
      key: 'wastage',
      className: 'smallColumn',
      hidden: isEndPointWastage,
    },
    {
      title: 'Wastage value',
      key: 'wastageAmount',
      className: 'smallColumn',
      hidden: isEndPointWastage,
      render: product => {
        return `£${(product.hasVat
          ? product.price * product.wastage * VAT_RATE
          : product.price * product.wastage
        ).toFixed(2)}`
      },
    },
    {
      title: 'Weighted',
      key: 'weighted',
      className: 'weightedColumn smallColumn',
      render: product => {
        const includesInUoM = product.UoM
          ? product.UoM.toLowerCase() === 'kg' ||
            product.UoM.toLowerCase() === 'g'
          : false
        return includesInUoM ? <GoldenFilled /> : ''
      },
    },
    {
      title: 'Net',
      key: 'net',
      render: product => {
        return `£${(product.price * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) => a.price * a.inStock - b.price * b.inStock,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'VAT',
      key: 'vat',
      render: product => {
        const vat = product.hasVat ? product.price * VAT_PERCENTAGE : 0
        return `£${(vat * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) =>
        (a.hasVat ? a.price * VAT_PERCENTAGE * a.inStock : 0) - b.hasVat
          ? b.price * VAT_PERCENTAGE * b.inStock
          : 0,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Net',
      key: 'net',
      render: product => {
        return `£${(product.price * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) => a.price * a.inStock - b.price * b.inStock,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Gross',
      key: 'gross',
      render: product => {
        const priceWithVat = product.hasVat
          ? product.price * VAT_RATE
          : product.price
        return `£${(priceWithVat * product.inStock).toFixed(2)}`
      },
      sorter: (a, b) =>
        (a.hasVat ? VAT_RATE * a.price * a.inStock : a.price * a.inStock) -
        (b.hasVat ? VAT_RATE * b.price * b.inStock : b.price * b.inStock),
      sortDirections: ['ascend', 'descend'],
    },
  ].filter(item => item.hidden === undefined || item.hidden === true)
  const totals = {
    net: stockTakeProducts
      .reduce((sum, item) => {
        const currentQuantity = isEndPointWastage ? item.wastage : item.inStock
        return sum + item.price * currentQuantity
      }, 0)
      .toFixed(2),
    VAT: stockTakeProducts
      .reduce((sum, item) => {
        const currentQuantity = isEndPointWastage ? item.wastage : item.inStock
        return (
          sum +
          (item.hasVat ? item.price * currentQuantity * VAT_PERCENTAGE : 0)
        )
      }, 0)
      .toFixed(2),
    gross: stockTakeProducts
      .reduce((sum, item) => {
        const currentQuantity = isEndPointWastage ? item.wastage : item.inStock
        return (
          sum +
          (item.hasVat
            ? item.price * currentQuantity * VAT_RATE
            : item.price * currentQuantity)
        )
      }, 0)
      .toFixed(2),
  }

  useEffect(() => {
    dispatch(fetchStockTakeProducts(venueId, stockTakeId))
  }, [venueId, dispatch])

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <Button>
            <Link to={`/v/${venueId}/stockTakes`}>Go back</Link>
          </Button>
          <StyledDiv>
            <h1>
              {`Stock ${isEndPointStock ? 'valuation' : 'wastage'}`}
              <StyledSpan>
                {`${dayjs(stockTake?.fromPeriod).format('DD MMM YYYY')} 
                          - ${dayjs(stockTake?.toPeriod).format(
                            'DD MMM YYYY',
                          )}`}
              </StyledSpan>
            </h1>
            <ValuationCSV
              stockTakeProducts={stockTakeProducts}
              stockTake={stockTake}
              totals={totals}
              endpoint={endpoint}
            />
          </StyledDiv>
          <Table
            pagination={false}
            bordered
            dataSource={stockTakeProducts}
            scroll={{x: 1800, y: '55vh'}}
            columns={columns}
            footer={() => {
              return (
                <Row>
                  <Row>
                    <Col span={19}></Col>
                    <Col span={3}>
                      <strong>{`${isEndPointWastage ? 'Wastage' : 'Valuation'} Net Total:`}</strong>
                    </Col>
                    <Col span={2}>£{totals.net}</Col>
                  </Row>
                  <Row>
                    <Col span={19}></Col>
                    <Col span={3}>
                      <strong>{`${isEndPointWastage ? 'Wastage' : 'Valuation'} Total VAT:`}</strong>
                    </Col>
                    <Col span={2}>£{totals.VAT}</Col>
                  </Row>
                  <Row>
                    <Col span={19}></Col>
                    <Col span={3}>
                      <strong>{`${isEndPointWastage ? 'Wastage' : 'Valuation'} Gross total:`}</strong>
                    </Col>
                    <Col span={2}>£{totals.gross}</Col>
                  </Row>
                </Row>
              )
            }}
          />
        </div>
      )}
    </>
  )
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`


const StyledSpan = styled.span`
  margin-left: 5px;
  font-size: 20px;
`

export default Valuation
