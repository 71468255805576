import { useEffect, useState } from 'react'
import { theme } from 'src/branding/omsTheme'

/**
 * Bundle an inner-width resizing event listener into an easy-to-use hook.
 */

const { breakpoints } = theme

export const windowSize = width =>
  width < breakpoints.xs
    ? 'xs'
    : width < breakpoints.sm
    ? 'sm'
    : width < breakpoints.md
    ? 'md'
    : width < breakpoints.lg
    ? 'lg'
    : width < breakpoints.xl
    ? 'xl'
    : 'xxl'

export const useWindowWidth = () => {
  const [width, setWidth] = useState(
    typeof window !== 'undefined' && window.innerWidth,
  )

  useEffect(() => {
    const handleResize = () =>
      setWidth(typeof window !== 'undefined' && window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return width
}
