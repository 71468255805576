import { VAT_RATE } from 'src/config'

const calculatePrice = (price, hasVat) => {
  const productPriceIncExVAT = hasVat ? price * 100 * VAT_RATE : price * 100
  const val = Math.round(productPriceIncExVAT)
  return (val / 100).toFixed(2)
}

export const getPrice = item => {
  const specialProdprice = item?.isSpecialOrder ? item?.specialProductPrice : ''
  const price =
    item.price !== undefined && !isNaN(item.price)
      ? item.price
      : item.product.price !== undefined && !isNaN(item.product.price)
      ? item.product.price
      : 0
  const hasVat = item.hasVat !== undefined ? item.hasVat : item.product.hasVat
  return calculatePrice(specialProdprice ? specialProdprice : price, hasVat)
}

export const getOMSPrice = item => {
  const specialProdprice = item?.isSpecialOrder ? item?.OMSSpecialPrice : ''
  const price =
    item.OMSPrice !== undefined && !isNaN(item.OMSPrice)
      ? item.OMSPrice
      : item.product.OMSPrice !== undefined
      ? item.product.OMSPrice
      : 0

  let adminReceiptingPrice
  if (
    item?.receipting &&
    item.receipting?.error === 'Adjustment due to Invoice received' &&
    item?.receipting?.updatedPrice >= 0
  ) {
    adminReceiptingPrice = item.receipting?.updatedPrice / 100
  } else {
    adminReceiptingPrice = price
  }
  return calculatePrice(
    adminReceiptingPrice !== price
      ? adminReceiptingPrice
      : specialProdprice
      ? specialProdprice
      : adminReceiptingPrice,
    false,
  )
}

const calculatePriceWithQuantity = (
  price,
  hasVat,
  quantity,
  updatedQuantity,
) => {
  const productPriceIncExVAT = hasVat ? price * 100 * VAT_RATE : price * 100
  let val = productPriceIncExVAT * (updatedQuantity || quantity)
  val = Math.round(val)
  return (val / 100).toFixed(2)
}

export const getOrderPrice = item => {
  // if it is undefined  it will be broke
  // TODO: prevent the undefined price error
  const price =
    item.price !== undefined && !isNaN(item.price)
      ? item.price
      : item.product.price !== undefined
      ? item.product.price
      : 0

  const specialProdprice = item?.isSpecialOrder ? item?.specialProductPrice : ''
  // item.price !== undefined
  //   ? item.price
  //   : item.product.price !== undefined
  //   ? item.product.price
  //   : 0

  const hasVat = item.hasVat !== undefined ? item.hasVat : item.product.hasVat
  const { updatedQuantity, quantity } = item

  return calculatePriceWithQuantity(
    specialProdprice ? specialProdprice : price,
    hasVat,
    quantity,
    updatedQuantity,
  )
}

// For orders already commpleted
export const getOrderPriceNoVAT = item => {
  let price = 0

  if (isNaN(item.price)) {
    price = 0
  } else {
    price = item.price === undefined ? 0 : item.price
  }

  const { updatedQuantity, quantity } = item

  return calculatePriceWithQuantity(price, false, quantity, updatedQuantity)
}

export const getTotalPrice = array =>
  array
    ? array.reduce(
        (accumulator, item) =>
          Number(
            parseFloat(accumulator) + parseFloat(getOrderPrice(item)),
          ).toFixed(2),
        0,
      )
    : 0

export const getTotalPriceNoVAT = array =>
  array
    ? array.reduce(
        (accumulator, item) =>
          Number(
            parseFloat(accumulator) + parseFloat(getOrderPriceNoVAT(item)),
          ).toFixed(2),
        0,
      )
    : 0

export const getTotalOMSPrice = array =>
  array
    ? array.reduce(
        (accumulator, item) => {
          const specialProdprice = item?.isSpecialOrder
            ? item?.OMSSpecialPrice
            : ''
          const price =
            item.OMSPrice !== undefined && !isNaN(item.OMSPrice)
              ? item.OMSPrice
              : item.product.OMSPrice !== undefined
              ? item.product.OMSPrice
              : 0

          // const adminReceiptingPrice =
          //     (item?.receipting?.error === 'Adjustment due to Invoice received' &&
          //         item?.receipting?.updatedPrice / 100) ||
          //     specialProdprice ||
          //     price;

          let adminReceiptingPrice

          if (
            item?.receipting?.error === 'Adjustment due to Invoice received' &&
            item?.receipting?.updatedPrice >= 0
          ) {
            adminReceiptingPrice = item?.receipting?.updatedPrice / 100
          } else {
            adminReceiptingPrice = specialProdprice || price
          }

          const hasVat =
            item.hasVat !== undefined ? item.hasVat : item.product.hasVat
          const { updatedQuantity, quantity, supplierConfirmed, receipting } =
            item

          if (!supplierConfirmed) {
            return accumulator
          }

          // const adminReceiptingQuantity =
          //     (receipting?.error === 'Adjustment due to Invoice received' &&
          //         (item.UoM === 'Kg' && receipting?.weight
          //             ? receipting.weight
          //             : receipting?.updatedQuantity)) ||
          //     updatedQuantity ||
          //     quantity;

          let adminReceiptingQuantity

          if (receipting?.error === 'Adjustment due to Invoice received') {
            if (item.UoM === 'Kg' && receipting?.weight >= 0) {
              adminReceiptingQuantity = receipting?.weight
            } else if (receipting?.updatedQuantity >= 0) {
              adminReceiptingQuantity = receipting?.updatedQuantity
            } else {
              adminReceiptingQuantity = updatedQuantity || quantity
            }
          } else {
            adminReceiptingQuantity = updatedQuantity || quantity
          }

          const total = parseFloat(
            adminReceiptingQuantity * adminReceiptingPrice,
          )
          accumulator.total += total

          if (hasVat) {
            const vat = parseFloat(total * 0.2)
            accumulator.VAT += vat
            accumulator.totalVat += total + vat
          } else {
            accumulator.totalVat += total
          }

          return accumulator
        },
        { total: 0, VAT: 0, totalVat: 0 },
      )
    : { total: 0, VAT: 0, totalVat: 0 }

export const getTotalSpendReceipting = array => {
  const filterdOrders = array.filter(
    order =>
      order.supplierConfirmed === undefined || order.supplierConfirmed === true,
  )
  const totalPrice = filterdOrders?.reduce((total, item) => {
    let quantity = !isNaN(item.updatedQuantity)
      ? item.updatedQuantity
      : !isNaN(item?.receipting?.updatedQuantity) &&
        item?.receipting?.error === 'Supplier / Invoice adjustment'
      ? item?.receipting?.updatedQuantity
      : !isNaN(item?.receipting?.previousQty)
      ? item?.receipting?.previousQty
      : item?.quantity
    const sum =
      item.receiptingStatus === 'Rejected by supplier'
        ? item.product?.price * item.quantity
        : (item.receipting?.updatedPrice >= 0
            ? item.receipting?.updatedPrice
            : item?.price || item?.product?.price) * quantity

    return total + sum
  }, 0)
  return totalPrice
}
