import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import OMSLogo from 'src/assets/images/OMSLogoWht.png'
import styled from 'styled-components/macro'

const logError = () => {}

const ErrorFallback = ({ error, fullscreen }) => {
  const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${fullscreen ? 'min-height: 100vh;' : ''}
    background: ${({ fullscreen }) => (fullscreen ? '#b5d922' : 'transparent')};
  `
  const Logo = styled.img`
    width: 100px;
    margin-bottom: 60px;
  `
  const Message = styled.pre`
    width: 90vw;
    max-width: 500px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    text-align-last: center;
  `
  return (
    <Center role='alert' fullscreen={fullscreen}>
      <Logo src={OMSLogo} alt='' />
      <h2>Something went wrong</h2>
      <Message>{error.message}</Message>
    </Center>
  )
}

const OMSErrorBoundary = ({ children, fullscreen = false }) => (
  <ErrorBoundary
    FallbackComponent={({ error }) => (
      <ErrorFallback error={error} fullscreen={fullscreen} />
    )}
    onError={logError}
  >
    {children}
  </ErrorBoundary>
)

export default OMSErrorBoundary
