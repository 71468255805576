import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import Amplify from 'aws-amplify'
import { ConnectedRouter } from 'connected-react-router'
import 'core-js'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import 'sanitize.css'
import GlobalFonts from 'src/branding/fonts'
import GlobalStyles from 'src/components/styled/globalStyles'
import { theme } from 'src/branding/omsTheme'
import OMSErrorBoundary from 'src/components/shared/ErrorBoundary'
import config from 'src/config'
import App from 'src/screens/app/App'
import { store } from 'src/features/'
import history from 'src/history'
import { ThemeProvider } from 'styled-components'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
})

Sentry.init({
  dsn: config.sentry.DNS,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

render(
  <OMSErrorBoundary fullscreen>
    <ReduxProvider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <GlobalFonts />
          <App />
        </ThemeProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </OMSErrorBoundary>,
  document.getElementById('root'),
)
