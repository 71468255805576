import { Button, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux'
import { H4 } from 'src/branding/typography'
import FadeIn from 'src/components/styled/FadeIn'
import BarChart from 'src/screens/orders/spend-over-time/BarChart'
import LineChart from 'src/screens/orders/spend-over-time/LineChart'
import { findUnique, getInitials } from 'src/utils/arrayFunctions'
import { getTotalPriceNoVAT } from 'src/utils/priceFunctions'
import styled from 'styled-components/macro'
import _ from 'underscore'

const SpendOverTime = () => {
  const orders = useSelector(state => state.orders.filtered)
  const venue = useSelector(state => state.venue.venue)

  const [typesOptions, setTypesOptions] = useState([])

  useEffect(() => {
    if (orders.length > 0) {
      setTypesOptions(
        findUnique(
          orders.filter(order => order.supplierConfirmed),
          order => order?.type || order?.product?.type || '',
          'keys',
        ),
      )
    }
  }, [orders])

  const acceptedOrders = orders
    .filter(order => order.supplierConfirmed)
    .map(order => ({
      ...order,
      monthNumber: dayjs(order.orderedDate).format('YYYY/M'),
      monthName: dayjs(order.orderedDate).format('MMMM'),
    }))

  /* ----------------------------- Suppliers venue ----------------------------- */

  const supplierTable = []
  const supplierChart = []

  const supplierGrouped = _(acceptedOrders).groupBy(order => order.supplier)
  Object.entries(supplierGrouped).forEach(
    ([supplierId, arraySupplierOrders]) => {
      const productGrouped = _(arraySupplierOrders).groupBy(order => order.type)
      const supplierName = arraySupplierOrders[0].product.supplier.title
      const needsInitials =
        supplierName.length > 14 && Object.keys(supplierGrouped).length > 6
      const SupplierObject = {
        supplier: needsInitials ? getInitials(supplierName) : supplierName,
        label: supplierName,
        id: supplierName,
        value: `${getTotalPriceNoVAT(arraySupplierOrders)}`,
      }

      Object.entries(productGrouped).forEach(([type, arrayProductGrouped]) => {
        if (type)
          SupplierObject[type] =
            arrayProductGrouped.length > 0
              ? Number(getTotalPriceNoVAT(arrayProductGrouped)).toFixed(2)
              : 0
      })
      supplierTable.push({
        key: supplierId,
        supplier: supplierName,
        totalAmountNoVAT: `£ ${getTotalPriceNoVAT(arraySupplierOrders)}`,
      })
      supplierChart.push(SupplierObject)
    },
  )

  const supplierTableWithTotal = [
    ...supplierTable.sort((a, b) => (a.supplier > b.supplier ? 1 : -1)),
    {
      key: 'total',
      supplier: 'Total',
      totalAmountNoVAT: `£ ${getTotalPriceNoVAT(acceptedOrders)}`,
    },
  ]

  const suppliersHeaders = [
    { label: 'Supplier', key: 'supplier' },
    { label: 'Net invoicing', key: 'totalAmountNoVAT' },
  ]

  /* ------------------------------- Months venue ------------------------------ */

  const monthGrouped = _(acceptedOrders).groupBy(order => order.monthNumber)

  const monthChart = Object.entries(monthGrouped)
    .map(([monthNumber, arrayMonthOrder]) => ({
      title: venue.title,
      monthNumber,
      monthName: dayjs(monthNumber).format('MMMM'),
      totalAmountNoVAT: getTotalPriceNoVAT(arrayMonthOrder),
    }))
    .sort((a, b) => (a.monthNumber > b.monthNumber ? 1 : -1))

  const monthTable = [
    ...monthChart.map(item => ({
      key: item.monthNumber,
      monthName: item.monthName,
      totalAmountNoVAT: `£ ${item.totalAmountNoVAT}`,
    })),
    {
      key: 'total',
      monthName: 'Total',
      totalAmountNoVAT: `£ ${getTotalPriceNoVAT(acceptedOrders)}`,
    },
  ]

  const totalVenueHeaders = [
    { label: venue.title, key: 'monthName' },
    { label: 'Net invoicing', key: 'totalAmountNoVAT' },
  ]

  return (
    <Wrapper>
      <Card>
        <TitleRow>
          <H4>Spending per Supplier</H4>
          <span>VAT is excluded in all totals</span>
          <Button>
            <CSVLink
              data={supplierTableWithTotal}
              headers={suppliersHeaders}
              filename={`oms-products-spending-per-supplier_${venue.title}.csv`}
            >
              Download CSV
            </CSVLink>
          </Button>
        </TitleRow>
        <Row>
          <ChartWrapper>
            <BarChart
              data={supplierChart.sort((a, b) =>
                a.supplier > b.supplier ? 1 : -1,
              )}
              types={typesOptions}
            />
          </ChartWrapper>
          <TableWrapper>
            <Table
              scroll={
                supplierTable.length > 3
                  ? {
                      x: true,
                      y: 220,
                    }
                  : {}
              }
              dataSource={supplierTableWithTotal}
              loading={false}
              pagination={false}
              columns={[
                {
                  title: 'Supplier',
                  key: 'supplier',
                  dataIndex: 'supplier',
                  className: 'restrictShrink',
                  sorter: (a, b) =>
                    String(a.supplier)
                      .trim()
                      .localeCompare(String(b.supplier).trim()),
                  sortDirections: ['ascend', 'descend'],
                },
                {
                  title: 'Net invoicing',
                  key: 'totalAmountNoVAT',
                  dataIndex: 'totalAmountNoVAT',
                  className: 'restrictShrink price-amoung',
                },
              ]}
            />
          </TableWrapper>
        </Row>
      </Card>
      <Card>
        <TitleRow>
          <H4>Spending by month</H4>
          <span>VAT is excluded in all totals</span>
          <Button>
            <CSVLink
              data={monthTable}
              headers={totalVenueHeaders}
              filename={`oms-products-spending-by-month_${venue.title}.csv`}
            >
              Download CSV
            </CSVLink>
          </Button>
        </TitleRow>
        <Row>
          <ChartWrapper>
            <LineChart data={monthChart} venue={venue.title} />
          </ChartWrapper>
          <TableWrapper>
            <Table
              scroll={
                monthTable.length > 3
                  ? {
                      x: true,
                      y: 220,
                    }
                  : {}
              }
              dataSource={monthTable}
              loading={false}
              pagination={false}
              columns={[
                {
                  title: venue.title,
                  key: 'monthName',
                  dataIndex: 'monthName',
                  className: 'restrictShrink',
                },
                {
                  title: 'Net invoicing',
                  key: 'totalAmountNoVAT',
                  dataIndex: 'totalAmountNoVAT',
                  className: 'restrictShrink',
                },
              ]}
            />
          </TableWrapper>
        </Row>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)`
  .ant-table-tbody {
    tr:last-child {
      background-color: ${({ theme }) => theme.colours.bbqBlack};
      color: ${({ theme }) => theme.colours.mayoWhite};
      &:hover {
        color: ${({ theme }) => theme.colours.bbqBlack};
      }
    }
    .price-amoung {
      white-space: nowrap;
    }
  }
`

const Card = styled.section`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  border: 1px solid ${({ theme }) => theme.colours.bgGrey};
  border-radius: 6px;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  h4 {
    margin: 0;
  }
  span {
    margin-left: auto;
  }
  button {
    margin-left: ${({ theme }) => theme.spacing.sm};
  }
`

const Row = styled.div`
  display: flex;
  height: 300px;
  justify-content: space-between;
`

const ChartWrapper = styled.div`
  width: 100%;
  height: 300px;
`
const TableWrapper = styled.div`
  min-width: 375px;
`

export default SpendOverTime
