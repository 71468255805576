import React, { useEffect, useState } from 'react'
import { Modal, Input, AutoComplete, Button } from 'antd'

const { Option } = AutoComplete
function AutoCompleteFilter({
  initialSuppleirs,
  addCustomSupplier,
  updateFiles,
  index,
  custom = false,
}) {
  const [filteredSupplier, setFilteredSupplier] = useState(
    initialSuppleirs || [],
  )
  const [inputValue, setInputValue] = useState('')
  const [otherModal, setOtherModal] = useState(false)
  const [customSupplier, setCustomSupplier] = useState(custom || '')

  useEffect(() => {
    setFilteredSupplier(initialSuppleirs)
  }, [initialSuppleirs])
  const handleSearch = value => {
    setFilteredSupplier(
      initialSuppleirs.filter(supplier =>
        supplier.supplierTitle.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  }

  const handleSelect = (value, option) => {
    if (value === 'Other') {
      setOtherModal(true)
    } else {
      updateFiles(option.key, value, index)
    }
  }

  const onBtnSave = () => {
    const newSupplier = {
      _id: inputValue,
      supplier: { _id: inputValue },
      supplierTitle: inputValue,
    }
    updateFiles(null, inputValue, index)
    addCustomSupplier(newSupplier)
    setFilteredSupplier(prevState => [...prevState, newSupplier])
    setCustomSupplier(inputValue)
    setInputValue('')
    setOtherModal(false)
  }

  return (
    <>
      <AutoComplete
        onSearch={handleSearch}
        onSelect={handleSelect}
        onChange={e => {
          setCustomSupplier(e)
          handleSearch(e)
        }}
        notFoundContent={'Supplier not found'}
        placeholder={'Choose you supplier'}
        value={customSupplier && !otherModal ? customSupplier : null}
      >
        {[
          ...filteredSupplier,
          { _id: 'other', supplier: { _id: 'other' }, supplierTitle: 'Other' },
        ].map(supplier => (
          <Option key={supplier.supplier._id} value={supplier.supplierTitle}>
            {supplier.supplierTitle}
          </Option>
        ))}
      </AutoComplete>
      <Modal
        width={'14%'}
        title={
          <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
            Enter your supplier
          </p>
        }
        okText={'Save'}
        visible={otherModal}
        closeIcon={null}
        centered={true}
        //confirmLoading={addInvoiceLoading}
        //okButtonProps={{ disabled: disabled }}
        closable={false}
        footer={false}
        onOk={async () => {
          //await handleConfirmUpload()
        }}
        onCancel={() => {
          setOtherModal(false)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Input
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value)
            }}
          />
          <Button
            type={'primary'}
            style={{ marginTop: '10px' }}
            onClick={onBtnSave}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default AutoCompleteFilter
