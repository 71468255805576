import { Button } from 'antd'
import * as dayjs from 'dayjs'
import Dinero from 'dinero.js'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Logo from 'src/assets/images/OMSLogoBlk.png'
import { fetchInvoice } from 'src/features/InvoiceSlice'
import { fetchVenueProducts } from 'src/features/VenueProductsSlice'
import styled from 'styled-components'
import _ from 'underscore'
import { VAT_RATE } from '../../config'

const Invoice = props => {
  const dispatch = useDispatch()
  const items = useSelector(state => state.invoice.items)
  const venue = useSelector(state => state.venue.venue)
  const venueProducts = useSelector(state => state.venueProducts.products)
  const statementOrders = useSelector(
    state => state.orders.filteredStatementOrders,
  )
  const [dateOrders, setDateOrders] = useState([])
  const ordersGroupedByType = _(dateOrders).groupBy(item => item.supplier.title)
  const invoice = useRef()
  const {
    match: {
      params: { date = '', venueId, startDate = '', endDate = '' },
    },
  } = props

  useEffect(() => {
    if (date) {
      const dateOrdersArr = items
        .filter(item => {
          return item.supplierConfirmed === true
        })
        .map(item => {
          const venueProduct = venueProducts.find(
            vP => vP.product._id === item._id,
          )
          return {
            ...item,
            title: item.title,
            type: venueProduct?.type || item.type || '',
          }
        })
      setDateOrders(dateOrdersArr)
    }
  }, [items, venueProducts])

  useEffect(() => {
    if (date) {
      dispatch(fetchVenueProducts(venueId))
      dispatch(fetchInvoice(venueId, date))
    }
  }, [dispatch, venueId, date])

  const toPdf = () => {
    var mywindow = window.open('', 'PRINT', 'height=600,width=910')
    mywindow.document.write('<html><head><title>OMS Invoice</title>')
    mywindow.document.write(
      `${
        document.getElementsByTagName('head')[0].innerHTML
      }<style>  .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        line-height: 24px;
        font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        color: #555;
      }

      .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
      }
    
      .invoice-box table td {
        padding: 5px;
        vertical-align: top;
      }

      .invoice-box table td.typeHeader {
        font-size: 26px;
        font-weight: bold;
      }
    
      .invoice-box table tr td:nth-child(2) {
        text-align: left;
      }
    
      .invoice-box table tr.top table td {
        padding-bottom: 20px;
      }
    
      .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
      }
    
      .invoice-box table tr.information table td {
        padding-bottom: 40px;
      }
    
      .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }
    
      .invoice-box table tr.details td {
        padding-bottom: 20px;
      }
    
      .invoice-box table tr.item td {
        border-bottom: 1px solid #eee;
      }
    
      .invoice-box table tr.item.last td {
        border-bottom: none;
      }
    
      .invoice-box table tr.total td:nth-child(2),
      .invoice-box table tr.total td:nth-child(3) {
        border-top: 2px solid #eee;
        font-weight: bold;
      }
    
      @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
          width: 100%;
          display: block;
          text-align: center;
        }
    
        .invoice-box table tr.information table td {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    
      /** RTL **/
      .rtl {
        direction: rtl;
        font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
          sans-serif;
      }
    
      .rtl table {
        text-align: right;
      }
    
      .rtl table tr td:nth-child(2) {
        text-align: left;
      }</style></head>`,
    )
    mywindow.document.write('</head><body >')
    mywindow.document.write(invoice.current.innerHTML)
    mywindow.document.write('</body></html>')

    // mywindow.document.close() // necessary for IE >= 10
    mywindow.focus() // necessary for IE >= 10*/

    mywindow.print()
    // mywindow.close()

    return true
  }

  let invoiceTotal = Dinero({
    amount: 0,
    currency: 'GBP',
  })

  let totalVat = Dinero({
    amount: 0,
    currency: 'GBP',
  })

  let totalExVat = Dinero({
    amount: 0,
    currency: 'GBP',
  })

  return (
    <Wrapper>
      <ToolBar>
        <Button>
          <Link to={`/v/${venueId}/purchasing/orders`}>Go back</Link>
        </Button>
      </ToolBar>
      <ToolBar>
        <Button onClick={toPdf}>Print PDF</Button>
      </ToolBar>

      <div ref={invoice}>
        <div className='invoice-box' ref={invoice}>
          <table cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr className='top'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td className='title'>
                          <img src={Logo} alt='' />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr className='information'>
                <td colSpan='5'>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          Orders Made Simple Limited
                          <br />
                          T/A Orders Made Simple
                          <br />
                          <br />
                          84 Commercial Street
                          <br />
                          Commercial Quay
                          <br />
                          Edinburgh
                          <br />
                          EH6 6LX
                          <br /> <br />
                          SC633011
                          <br />
                          VAT Number: 347 8756 47
                        </td>

                        <td>
                          {venue.title}
                          <br />
                          {venue.addressLine1}
                          <br />
                          {venue.addressLine2}
                          <br />
                          {venue.addressCity}
                          <br />
                          {venue.addressPostCode}
                          <br /> <br />
                          Invoice No:{venue.title.substring(0, 4).toUpperCase()}
                          {date.replace(/-/g, '')}
                          <br /> <br />
                          Date:{' '}
                          {date
                            ? dayjs(date).format('DD/MM/YYYY')
                            : `${dayjs(startDate).format(
                                'DD/MM/YYYY',
                              )} - ${dayjs(endDate).format('DD/MM/YYYY')}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td colSpan='5'>&nbsp;</td>
              </tr>

              {date ? (
                Object.keys(ordersGroupedByType).map(typeName => {
                  const dateOrders = ordersGroupedByType[typeName]
                  let groupTotal = Dinero({
                    amount: 0,
                    currency: 'GBP',
                  })

                  let groupTotalVat = Dinero({
                    amount: 0,
                    currency: 'GBP',
                  })

                  dateOrders.forEach(order => {
                    const { price, updatedQuantity, quantity, hasVat } = order
                    const productPrice = Dinero({
                      amount: parseInt(price * 100),
                      currency: 'GBP',
                    })

                    const vatAmount = hasVat
                      ? productPrice
                          .multiply(updatedQuantity || quantity)
                          .percentage(20)
                      : Dinero({
                          amount: 0,
                          currency: 'GBP',
                        })

                    const lineTotal = productPrice
                      .multiply(updatedQuantity || quantity)
                      .add(vatAmount)

                    groupTotalVat = groupTotalVat.add(vatAmount)
                    groupTotal = groupTotal.add(lineTotal)
                  })

                  return (
                    <React.Fragment key={typeName}>
                      <tr>
                        <td className='typeHeader' rowSpan='2'>
                          {typeName}
                        </td>
                        <td colSpan='3'></td>
                        <td>
                          <b>Total</b> {groupTotal.toFormat('$0,0.00')}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan='3'></td>
                        <td>
                          <b>Total VAT</b> {groupTotalVat.toFormat('$0,0.00')}
                        </td>
                      </tr>
                      <tr className='heading'>
                        <td>Item</td>
                        <td>Quantity</td>
                        <td>Price</td>
                        <td>Tax amount</td>
                        <td>Amount</td>
                      </tr>

                      {dateOrders.map(order => {
                        const { price, updatedQuantity, quantity, hasVat } =
                          order
                        const productPrice = Dinero({
                          amount: parseInt(price * 100),
                          currency: 'GBP',
                        })

                        let vatAmount = hasVat
                          ? productPrice
                              .multiply(updatedQuantity || quantity)
                              .percentage(20)
                          : Dinero({
                              amount: 0,
                              currency: 'GBP',
                            })

                        let lineTotal = productPrice
                          .multiply(updatedQuantity || quantity)
                          .add(vatAmount)

                        totalVat = totalVat.add(vatAmount)

                        invoiceTotal = invoiceTotal.add(lineTotal)

                        totalExVat = totalExVat.add(
                          productPrice.multiply(updatedQuantity || quantity),
                        )

                        return (
                          <tr key={order._id} className='item'>
                            <td>{order.title}</td>
                            <td>{updatedQuantity || quantity}</td>
                            <td>{productPrice.toFormat('$0,0.00')}</td>
                            <td>
                              {vatAmount
                                ? `${vatAmount.toFormat('$0,0.00')}`
                                : '-'}
                            </td>
                            <td>{lineTotal.toFormat('$0,0.00')}</td>
                          </tr>
                        )
                      })}

                      <tr>
                        <td colSpan='5'>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan='5'>&nbsp;</td>
                      </tr>
                    </React.Fragment>
                  )
                })
              ) : (
                <React.Fragment key='custom'>
                  <tr className='heading'>
                    <td>Supplier</td>
                    <td>Date of the order</td>
                    <td>Value of the order (excluding VAT)</td>
                    <td>Value of VAT</td>
                    <td>Total Due (Value of the order + VAT)</td>
                  </tr>
                  {statementOrders.map(statements => {
                    let rowTotal = Dinero({
                      amount: 0,
                      currency: 'GBP',
                    })

                    let rowVat = Dinero({
                      amount: 0,
                      currency: 'GBP',
                    })

                    let rowExVat = Dinero({
                      amount: 0,
                      currency: 'GBP',
                    })
                    statements.data.forEach(st => {
                      const currentPrice = Dinero({
                        amount: parseInt(st.price * 100 * st.quantity),
                        currency: 'GBP',
                      })
                      const currentVat = Dinero({
                        amount: parseInt(
                          st.hasVat ? st.price * 100 * st.quantity * 0.2 : 0,
                        ),
                        currency: 'GBP',
                      })
                      const currentPriceWithVat = Dinero({
                        amount: parseInt(
                          st.hasVat
                            ? st.price * 100 * st.quantity * VAT_RATE
                            : st.price * 100 * st.quantity,
                        ),
                        currency: 'GBP',
                      })
                      invoiceTotal = invoiceTotal.add(
                        currentPriceWithVat.divide(100),
                      )
                      totalVat = totalVat.add(currentVat.divide(100))
                      totalExVat = totalExVat.add(currentPrice.divide(100))
                      rowTotal = rowTotal.add(currentPriceWithVat.divide(100))
                      rowVat = rowVat.add(currentVat.divide(100))
                      rowExVat = rowExVat.add(currentPrice.divide(100))
                    })
                    return (
                      <tr
                        key={statements._id.purchaseOrderNumber}
                        className='item'
                      >
                        <td>{statements._id.supplier.title}</td>
                        <td>
                          {dayjs(statements.data[0].orderedDate).format(
                            'YYYY-MM-DD',
                          )}
                        </td>
                        <td>{rowExVat.toFormat('$0,0.00')}</td>
                        <td>
                          {rowVat ? `${rowVat.toFormat('$0,0.00')}` : '-'}
                        </td>
                        <td>{rowTotal.toFormat('$0,0.00')}</td>
                      </tr>
                    )
                  })}
                  <tr>
                    <td colSpan='5'>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colSpan='5'>&nbsp;</td>
                  </tr>
                </React.Fragment>
              )}
              <tr className='total'>
                <td colSpan='3'></td>

                <td>Total excl VAT:</td>
                <td>{totalExVat.toFormat('$0,0.00')}</td>
                <td colSpan='3'></td>
              </tr>

              <tr className='total'>
                <td colSpan='3'></td>

                <td>Total VAT:</td>
                <td>{totalVat.toFormat('$0,0.00')}</td>
                <td colSpan='3'></td>
              </tr>

              <tr className='total'>
                <td colSpan='3'></td>

                <td>Total:</td>
                <td>{invoiceTotal.toFormat('$0,0.00')}</td>
                <td colSpan='3'></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  )
}

const ToolBar = styled.div`
  margin-bottom: 25px;
`

const Wrapper = styled.div`
  .invoice-box {
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
  }
  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box table td.typeHeader {
    font-size: 26px;
    font-weight: bold;
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: left;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }

  .invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
  }

  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2),
  .invoice-box table tr.total td:nth-child(3) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
    }

    .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  /** RTL **/
  .rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial,
      sans-serif;
  }

  .rtl table {
    text-align: right;
  }

  .rtl table tr td:nth-child(2) {
    text-align: left;
  }
`

export default Invoice
