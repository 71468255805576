import moment from "moment/moment";

const FullHistoryColumns = () => {
    return([
        {
            title: "Product",
            dataIndex: "productTitle",
            key: "productTitle",
            width: "10%",
        },
        {
            title: "Supplier",
            dataIndex: "supplierTitle",
            key: "supplierTitle",
            width: "10%",
        },
        {
            title: "Error type",
            dataIndex: "error",
            key: "errorType",
            width: "15%",
        },
        {
            title: "Changed by",
            dataIndex: "placedVia",
            key: "creatorName",
            width: "13%",
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "dateOfChanges",
            render: (dateTime) => {
                const date = moment(dateTime).format('DD-MM-YYYY')
                const time = moment(dateTime).format('HH:mm')
                return `${date} ${time}`
            }
        },
        {
            title: "Changes",
            dataIndex: "",
            key: "changeDescription",
            render: (item) => {
                const changes = [];
                for (const key in item) {
                    switch (key) {
                        case 'updatedQuantity': {
                            changes.push(
                                `Change quantity from ${
                                    item.previousQty
                                } to ${item[key]}`,
                            )
                            break
                        }
                        case 'updatedPrice': {
                            changes.push(
                                `Change price from ${
                                    (item.prevPrice / 100).toFixed(2)
                                } to ${(item[key] / 100).toFixed(2)}`,
                            )
                            break
                        }
                        case 'updatedSku': {
                            changes.push(
                                `Change sku from ${item.prevSku} to ${
                                    item[key]
                                }`,
                            )
                            break
                        }
                        case 'updatedSize': {
                            changes.push(
                                `Change size from ${
                                    item.prevSize
                                } to ${item[key]}`,
                            )
                            break
                        }
                        case 'updatedTitle': {
                            changes.push(
                                `Change title from ${
                                    item.prevTitle
                                } to ${item[key]}`,
                            )
                            break
                        }
                    }
                }

                if (!changes.length) {
                    const text = item.error ? "You didn't make any changes" : "You approved this product"
                    changes.push(text)
                }
                return (
                    <>
                        {changes.map(change => (
                            <div key={change}>{change}</div>
                        ))}
                    </>
                )
            }
        },
    ])
}

export default FullHistoryColumns;
