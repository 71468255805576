import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import React from 'react'
import { theme } from 'src/branding/omsTheme'

export const SuccessNotification = ({ message, description }) => {
  notification.open({
    message,
    description,
    duration: 3,
    icon: <CheckCircleOutlined style={{ color: theme.colours.lettuceGreen }} />,
  })
}

export const RejectedNotification = ({ message, description }) => {
  notification.open({
    message,
    description,
    duration: 3,
    icon: <CloseOutlined style={{ color: theme.colours.chilliRed }} />,
  })
}
