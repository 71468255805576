export const findUnique = (array, predicate, getBack) => {
  const found = {}
  array.forEach(item => (found[predicate(item)] = item))
  const result =
    getBack === 'keys'
      ? Object.keys(found).map(key => key)
      : getBack === 'values'
      ? Object.values(found).map(value => value)
      : []

  return result[0] ? result : []
}

const isLetter = str => str.length === 1 && str.match(/[a-z]/i)

export const getInitials = string => {
  if (string) {
    const array = string.split(' ')
    const result = []
    for (const word of array) {
      if (isLetter(word[0])) {
        result.push(word[0].toUpperCase())
      } else {
        return result.join(' ')
      }
    }
    return result.join(' ')
  }
  return ''
}

export const removeLastBracketsWord = string => {
  if (string) {
    const array = string.split(' ')
    const result = []
    for (const word of array) if (word[0] !== `(`) result.push(word)
    return result.join(' ')
  }
  return ''
}
