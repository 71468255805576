import { API } from 'aws-amplify'

export const postPhoneOrders = async data => {
  try {
    await API.post('api', 'venue/phoneOrders', {
      body: data,
    })
  } catch (e) {
    console.log(e)
  }
}
