import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    items: [],
  },
  reducers: {
    setInvoice: (state, action) => {
      state.items = action.payload
    },
  },
})

export const { setInvoice } = invoiceSlice.actions

export const fetchInvoice = (venueId, date) => async dispatch => {
  try {
    const data = await API.get(
      'api',
      `invoice/${venueId}${date ? `/${date}` : ''}`,
    )
    dispatch(setInvoice(data))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default invoiceSlice
