import { createSlice } from '@reduxjs/toolkit'
import Sentry from '../utils/sentry'
import { API } from 'aws-amplify'

const stocksSlice = createSlice({
  name: 'stockTakes',
  initialState: {
    stockTakes: [],
    isLoading: false,
    stockTakeProducts: [],
    buttonLoading: { stockTake: '', isLoading: false, buttonType: '' },
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setStockTakes: (state, action) => {
      state.stockTakes = action.payload
    },
    editStockTake: (state, action) => {
      const index = state.stockTakes.findIndex(
        stockTake => stockTake._id === action.payload._id,
      )
      if (index !== -1) state.stockTakes[index] = action.payload
    },
    updateStockTakes: (state, action) => {
      state.stockTakes.unshift(action.payload)
    },
    deleteStock: (state, action) => {
      state.stockTakes = state.stockTakes.filter(
        stockTake => stockTake._id !== action.payload,
      )
    },
    setStockTakeProducts: (state, action) => {
      state.stockTakeProducts = action.payload
    },
    setButtonLoading: (state, action) => {
      state.buttonLoading = action.payload
    },
  },
})

export const {
  setIsLoading,
  setStockTakes,
  editStockTake,
  updateStockTakes,
  deleteStock,
  setStockTakeProducts,
  setButtonLoading,
} = stocksSlice.actions

export const fetchStockTakes = venueId => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const data = await API.get('api', `venue/${venueId}/stocks`)
    dispatch(setStockTakes(data))
    dispatch(setIsLoading(false))
  } catch (e) {
    dispatch(setIsLoading(false))
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const updateStockTake =
  (venueId, data, buttonType) => async dispatch => {
    try {
      dispatch(
        setButtonLoading({
          stockTake: data._id,
          isLoading: true,
          buttonType: buttonType,
        }),
      )
      const updatedStockTake = await API.post(
        'api',
        `venue/${venueId}/stocks`,
        {
          body: data,
        },
      )
      dispatch(editStockTake(updatedStockTake))
      dispatch(
        setButtonLoading({ stockTake: '', isLoading: false, buttonType: '' }),
      )
    } catch (e) {
      dispatch(
        setButtonLoading({ stockTake: '', isLoading: false, buttonType: '' }),
      )
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const createStock = (data, venueId) => async dispatch => {
  try {
    const newDateOfStock = await API.post('api', `venue/${venueId}/stocks`, {
      body: data,
    })
    dispatch(updateStockTakes(newDateOfStock))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const deleteStockTake =
  (venueId, stockTake, buttonType) => async dispatch => {
    try {
      dispatch(
        setButtonLoading({
          stockTake: stockTake._id,
          isLoading: true,
          buttonType: buttonType,
        }),
      )
      await API.del('api', `venue/${venueId}/stocks/${stockTake._id}`)
      dispatch(deleteStock(stockTake._id))
      dispatch(
        setButtonLoading({ stockTake: '', isLoading: false, buttonType: '' }),
      )
    } catch (e) {
      dispatch(
        setButtonLoading({ stockTake: '', isLoading: false, buttonType: '' }),
      )
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const fetchStockTakeProducts =
  (venueId, stockTakeId) => async dispatch => {
    try {
      dispatch(setIsLoading(true))
      const stockTakeProducts = await API.get(
        'api',
        `venue/${venueId}/stocks/${stockTakeId}/products`,
      )
      dispatch(setStockTakeProducts(stockTakeProducts))
      dispatch(setIsLoading(false))
    } catch (e) {
      dispatch(setIsLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const saveAllChanges = (venueId, data, buttonType) => async dispatch => {
  try {
    dispatch(setButtonLoading({ isLoading: true, buttonType: buttonType }))
    const updatedArray = await API.post(
      'api',
      `venue/${venueId}/stocks/${data.stockTakeId}/products`,
      {
        body: data,
      },
    )
    dispatch(
      setButtonLoading({ stockTake: '', isLoading: false, buttonType: '' }),
    )
    dispatch(setStockTakeProducts(updatedArray))
  } catch (e) {
    dispatch(
      setButtonLoading({ stockTake: '', isLoading: false, buttonType: '' }),
    )
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default stocksSlice
